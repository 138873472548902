<template>
    <modal-container v-if="showEditGroupBuyModal && groupBuy"
        :altImage="groupBuyImage" :openConditions="showEditGroupBuyModal"
        @closeModal="closeEditGroupBuyModal" @updateModalScrollTop="updateModalScrollTop"
    >
        <template #main>
            <div class="w-full mt-4 px-1">
                <h3 class="section-heading mb-3 text-center">
                    Edit {{ pageData.groupBuy.adminLabelSingular }}
                </h3>
                <div class="max-width-box">
                    <div class="w-full mb-1">
                        <div class="w-full">
                            <input @input="saveDebounce()" placeholder="Title" type="text" v-model="groupBuyForm.name" class="form-input edit-field-text-color bg-white py-1 w-full text-center" />
                        </div>
                        <validation-message v-if="!groupBuyForm.name" 
                            message="Title must be entered"
                        />
                    </div>
                    <panel-section
                        :adminSection="true" :sectionCounter="errorsDetail" sectionLabel="Details"
                    >
                        <edit-details
                            :isGiveAway="isGiveAway" :isGroupBuy="isGroupBuy" :isMemberGiveAway="isMemberGiveAway" :pGallery="gallery" :pGroupBuy="groupBuy" :pGroupBuyForm="groupBuyForm" 
                            @saveLocalGallery="saveLocalGallery" @updateDetails="updateDetails" @updateOfflineMedia="updateOfflineMedia"
                        />
                    </panel-section>
                    <panel-section
                        :adminSection="true" sectionLabel="Detail Blocks"
                    >
                        <media-blocks
                            :groupBuy="groupBuy" :pGroupBuyForm="groupBuyForm" 
                            @updateMediaBlocks="updateMediaBlocks"
                        />
                    </panel-section>
                    <panel-section
                        :adminSection="true" sectionLabel="Purchase Page Blocks"
                    >
                        <purchase-media-blocks
                            :groupBuy="groupBuy" :pGroupBuyForm="groupBuyForm" 
                            @updatePurchaseMediaBlocks="updatePurchaseMediaBlocks"
                        />
                    </panel-section>
                    <panel-section
                        :adminSection="true" sectionLabel="Options"
                    >
                        <edit-options
                            :isGiveAway="isGiveAway" :isGroupBuy="isGroupBuy" :isMemberGiveAway="isMemberGiveAway" :pGroupBuyForm="groupBuyForm" 
                            @updateOptions="updateOptions"
                        />
                    </panel-section>
                    <panel-section v-if="isGroupBuy"
                        :adminSection="true" :sectionCounter="errorsProducts" sectionLabel="Products"
                    >
                        <edit-products
                            :pGroupBuyForm="groupBuyForm" :pProducts="products" :pSortedPricingLevels="sortedPricingLevels"
                            @updateProducts="updateProducts"
                        />
                    </panel-section>
                    <panel-section v-if="isGiveAway"
                        :adminSection="true" sectionLabel="Bundles"
                    >
                        <bundles
                            :bundles="bundles" :groupBuy="groupBuy"
                            @updateBundles="updateBundles"
                        />
                    </panel-section>
                    <panel-section v-if="isMemberGiveAway"
                        :adminSection="true" sectionLabel="Tiers"
                    >
                        <tiers
                            :bundles="bundles" :groupBuy="groupBuy"
                            @updateBundles="updateBundles"
                        />
                    </panel-section>
                    <panel-section v-if="isGroupBuy && !groupBuyForm.no_breakpoints"
                        :adminSection="true" :sectionCounter="errorsBreakpoints" sectionLabel="Discount Breakpoints"
                    >
                        <edit-discount-breakpoints
                            :pGroupBuyForm="groupBuyForm" :pProducts="products" :pQuantityLevels="quantityLevels" :pSortedPricingLevels="sortedPricingLevels" 
                            @updateBreakpoints="updateBreakpoints"
                        />
                    </panel-section>
                    <panel-section v-if="isGiveAway || isMemberGiveAway"
                        :adminSection="true" sectionLabel="Eligible Countries"
                    >
                        <eligible-countries
                            :pGroupBuyForm="groupBuyForm"
                            @updateEligibleCountries="updateEligibleCountries"
                        />
                    </panel-section>
                    <panel-section v-if="!isMemberGiveAway"
                        :adminSection="true" :sectionCounter="errorsShipping" sectionLabel="Shipping"
                    >
                        <edit-shipping
                            :isGiveAway="isGiveAway" :pEndTime="endTime" :pEta="eta" :pFormLoaded="formLoaded" :pGotGroupBuy="gotGroupBuy" :pGroupBuy="groupBuy" 
                            :pGroupBuyForm="groupBuyForm"
                            @updateAddress="updateAddress" @updateShipping="updateShipping" 
                            @updateShippingData="updateShippingData" @updateShippingZones="updateShippingZones"
                        />
                    </panel-section>
                    <panel-section v-if="isGroupBuy && groupBuyForm.has_eoi"
                        :adminSection="true" :sectionCounter="errorsEoi" sectionLabel="E.O.I."
                    >
                        <edit-eoi 
                            :pEoiEndTime="eoiEndTime" :pEoiStartTime="eoiStartTime" :pFormLoaded="formLoaded" :pGotGroupBuy="gotGroupBuy"
                            :pGroupBuy="groupBuy" :pGroupBuyForm="groupBuyForm" :pEoiMaximumOkay="eoiMaximumOkay" :pSortedPricingLevels="sortedPricingLevels"
                            @updateEoi="updateEoi"
                        />
                    </panel-section>
                    <panel-section v-if="!isMemberGiveAway"
                        :adminSection="true" :sectionCounter="errorsGroupBuy" sectionLabel="Group Offer"
                    >
                        <edit-group-buy 
                            :isGiveAway="isGiveAway" :isGroupBuy="isGroupBuy" :isMemberGiveAway="isMemberGiveAway" :pEndTime="endTime" :pEoiEndTime="eoiEndTime" :pFormLoaded="formLoaded" :pGotGroupBuy="gotGroupBuy" :pGroupBuy="groupBuy" 
                            :pGroupBuyForm="groupBuyForm" :pMaximumOkay="maximumOkay" :pQuantityLevels="quantityLevels" :pSortedPricingLevels="sortedPricingLevels" 
                            :pStartTime="startTime"
                            @updateGroupBuy="updateGroupBuy"
                        />
                    </panel-section>
                    <panel-section v-if="isGiveAway && groupBuyForm.secondary_offer"
                        :adminSection="true" :sectionCounter="errorsSecondary" sectionLabel="Secondary Draw"
                    >
                        <edit-secondary-offer
                            :pGroupBuy="groupBuy" :pGroupBuyForm="groupBuyForm" :pSecondaryOfferEndTime="secondaryOfferEndTime" :pSecondaryOfferStartTime="secondaryOfferStartTime" 
                            @updateSecondaryOffer="updateSecondaryOffer"
                        />
                    </panel-section>
                    <panel-section
                        :adminSection="true" sectionLabel="Customisation"
                    >
                        <edit-white-label
                            :groupBuy="groupBuy" :isGiveAway="isGiveAway" :pGroupBuyForm="groupBuyForm"
                            @updateWhiteLabel="updateWhiteLabel" 
                        />
                    </panel-section>
                    <panel-section v-if="!groupBuy.disabled"
                        :adminSection="true" sectionLabel="Sales"
                    >
                        <view-sales
                            :groupBuy="groupBuy" :modalScrollTop="modalScrollTop"
                        />
                    </panel-section>
                    <panel-section v-if="!groupBuy.disabled"
                        :adminSection="true" sectionLabel="Export Sales"
                    >
                        <export-sales
                            :groupBuy="groupBuy" :isGiveAway="isGiveAway"
                        />
                    </panel-section>
                    <panel-section v-if="!groupBuy.disabled && groupBuy.start_time < this.currentUnixTime() && groupBuy.end_time > this.currentUnixTime()"
                        :adminSection="true" sectionLabel="Overtime"
                    >
                        <overtime 
                            :groupBuy="groupBuy" :pEndTime="endTime"
                            @getGroupBuy="getGroupBuy"
                        />
                    </panel-section>
                    <panel-section v-if="!isMemberGiveAway"
                        :adminSection="true" sectionLabel="Create A New A/B Test"
                    >
                        <new-ab-test 
                            @clickCreateNewABTest="clickCreateNewABTest"
                        />
                    </panel-section>
                    <panel-section v-if="groupBuy.ab_tests && !isMemberGiveAway"
                        :adminSection="true" sectionLabel="A/B Tests"
                    >
                        <ab-tests 
                            :groupBuy="groupBuy" :isGiveAway="isGiveAway"
                            @updateABTestGallery="updateABTestGallery" @updateABTestMediaBlocks="updateABTestMediaBlocks" @updateABTestPurchaseMediaBlocks="updateABTestPurchaseMediaBlocks"
                            @updateABTestWhiteLabel="updateABTestWhiteLabel"
                        />
                    </panel-section>
                    <panel-section v-if="isGiveAway"
                        :adminSection="true" sectionLabel="Timed Deals"
                    >
                        <timed-deals 
                            :timedDeals="groupBuyForm.timed_deals"
                            @updateTimedDeals="updateTimedDeals"
                        />
                    </panel-section>
                    <panel-section 
                        :adminSection="true" sectionLabel="Global Discount"
                    >
                        <global-discount 
                            :globalDiscount="groupBuyForm.global_discount"
                            @updateGlobalDiscount="updateGlobalDiscount"
                        />
                    </panel-section>
                    <panel-section 
                        :adminSection="true" sectionLabel="Create A New Discount Code"
                    >
                        <new-discount-code 
                            :discountCodes="discountCodes"
                            @clickCreateNewDiscountCode="clickCreateNewDiscountCode"
                        />
                    </panel-section>
                    <panel-section v-if="discountCodes"
                        :adminSection="true" sectionLabel="Discount Codes"
                    >
                        <discount-codes
                            :discountCodes="discountCodes" :refreshCodes="refreshCodes"
                            @updateDiscountCodes="updateDiscountCodes"
                        />
                    </panel-section>
                </div>
            </div>
            <div class="w-full px-1">
                <div class="w-full white-transparent mt-2 pt-2 pb-4 px-2 mb-20" :class="{ 'desktop-button-box justify-center flex-wrap': isDesktopWidth }">
                    <div @click="clickPreview()" class="alt-button-base mt-2">
                        Preview
                    </div>
                    <div v-if="sellerVendor.white_label" @click="clickPreview(true)" class="alt-button-base" :class="{ 'ml-2 mt-2': isDesktopWidth, 'mt-2': !isDesktopWidth }">
                        White Label Preview
                    </div>
                    <div @click="clickCopy()" class="alt-button-base" :class="{ 'ml-2 mt-2': isDesktopWidth, 'mt-2': !isDesktopWidth }">
                        Make a copy
                    </div>
                    <div v-if="groupBuyForm.has_eoi && !groupBuyForm.eoi" @click="clickPublishEoi()" :class="{ 'ml-2 mt-2': isDesktopWidth, 'mt-2': !isDesktopWidth, 'standard-button-base': publishEoiOkay, 'disabled-button-base': !publishEoiOkay }">
                        Publish E.O.I.
                    </div>
                    <div v-if="groupBuyForm.has_eoi && groupBuyForm.eoi" @click="clickUnpublishEoi()" class="standard-button-base" :class="{ 'ml-2 mt-2': isDesktopWidth, 'mt-2': !isDesktopWidth }">
                        Unpublish E.O.I
                    </div>
                    <div v-if="showEoiEndNow" @click="clickEndEoiNow()" class="alt-button-base" :class="{ 'ml-2 mt-2': isDesktopWidth, 'mt-2': !isDesktopWidth }">
                        End E.O.I. now
                    </div>
                    <div v-if="groupBuyForm.disabled" @click="clickPublish()" :class="{ 'ml-2 mt-2': isDesktopWidth, 'mt-2': !isDesktopWidth, 'standard-button-base': publishOkay, 'disabled-button-base': !publishOkay }">
                        Publish {{ pageData.groupBuy.adminLabelSingular }}
                    </div>
                    <div v-if="!groupBuyForm.disabled && (!groupBuy.current_qty || (groupBuyForm.initial_units_confirmed && groupBuy.current_qty == groupBuyForm.initial_units_confirmed))" @click="clickUnpublish()" class="standard-button-base" :class="{ 'ml-2 mt-2': isDesktopWidth, 'mt-2': !isDesktopWidth }">
                        Unpublish {{ pageData.groupBuy.adminLabelSingular }}
                    </div>
                    <div v-if="showEndNow" @click="clickEndNow()" class="alt-button-base" :class="{ 'ml-2 mt-2': isDesktopWidth, 'mt-2': !isDesktopWidth }">
                        End {{ pageData.groupBuy.adminLabelSingular }} now
                    </div>
                    <div v-if="(groupBuyForm.has_eoi && groupBuyForm.eoi) || !groupBuyForm.disabled" @click="clickPostUpdate()" class="alt-button-base" :class="{ 'ml-2 mt-2': isDesktopWidth, 'mt-2': !isDesktopWidth }">
                        Post an update
                    </div>
                </div>
            </div>
        </template>
    </modal-container>
</template>

<script>
import {debounce} from 'lodash'

import AbTests from './edit-group-buy/components/AbTests'
import Bundles from './edit-group-buy/components/Bundles'
import DiscountCodes from '@/shared/DiscountCodes/DiscountCodes'
import EditDetails from './edit-group-buy/components/EditDetails'
import EditDiscountBreakpoints from './edit-group-buy/components/EditDiscountBreakpoints'
import EditEoi from './edit-group-buy/components/EditEoi'
import EditGroupBuy from './edit-group-buy/components/EditGroupBuy'
import EditOptions from './edit-group-buy/components/EditOptions'
import EditProducts from './edit-group-buy/components/EditProducts'
import EditSecondaryOffer from './edit-group-buy/components/EditSecondaryOffer'
import EditShipping from './edit-group-buy/components/EditShipping'
import EditWhiteLabel from './edit-group-buy/components/EditWhiteLabel'
import EligibleCountries from './edit-group-buy/components/EligibleCountries'
import ExportSales from './edit-group-buy/components/ExportSales'
import GlobalDiscount from './edit-group-buy/components/GlobalDiscount'
import MediaBlocks from './edit-group-buy/components/MediaBlocks'
import NewAbTest from './edit-group-buy/components/NewAbTest'
import NewDiscountCode from '@/shared/DiscountCodes/NewDiscountCode'
import Overtime from './edit-group-buy/components/Overtime'
import PurchaseMediaBlocks from './edit-group-buy/components/PurchaseMediaBlocks'
import Tiers from './edit-group-buy/components/Tiers'
import TimedDeals from './edit-group-buy/components/TimedDeals'
import ViewSales from './edit-group-buy/components/ViewSales'

export default {
    components: {
        AbTests,
        Bundles,
        DiscountCodes,
        EditDetails,
        EditDiscountBreakpoints,
        EditEoi,
        EditGroupBuy,
        EditOptions,
        EditProducts,
        EditSecondaryOffer,
        EditShipping,
        EditWhiteLabel,
        EligibleCountries,
        ExportSales,
        GlobalDiscount,
        MediaBlocks,
        NewAbTest,
        NewDiscountCode,
        Overtime,
        PurchaseMediaBlocks,
        Tiers,
        TimedDeals,
        ViewSales
    },
    data() {
        return {
            address: null,
            bundles: null,
            customRates: null,
            endTime: 0,
            eoiEndTime: 0,
            eoiStartTime: 0,
            eta: 0,
            formLoaded: false,
            gallery: [],
            geoloc: null,
            gotGroupBuy: false,
            groupBuy: null,
            groupBuyForm: {
                ab_tests: null,
                buildz_group_id: null,
                delivery_days: null,
                disabled: false,
                discount_codes: null,
                dollars_graphic: false,
                dollars_percentage_text: null,
                dollars_tracker: false,
                end_time: null,
                eoi: null,
                eoi_end_time: null,
                eoi_max_quantity: 0,
                eoi_start_time: null,
                eta: null,
                global_discount: null,
                has_eoi: false,
                hide_build_category: false,
                hide_eoi_maximum_allowed: false,
                hide_info_bar: false,
                hide_maximum_allowed: false,
                hide_minimum_required: false,
                hide_offer_conditions: false,
                hide_offer_conditions_text_one: false,
                hide_offer_conditions_text_two: false,
                hide_offer_details_text: null,
                hide_offer_description: false,
                hide_products_description: false,
                hide_products_title_text: false,
                hide_share_button: false,
                hide_shipping_eta: false,
                hide_shipping_location: false,
                hide_tracker_bar: false,
                hide_units_confirmed: false,
                immediate_payment: false,
                included_countries: null,
                initial_units_confirmed: null,
                join_button_text: null,
                keywords: null,
                live_now: false,
                live_url: null,
                local_pickup: null,
                location: null,
                max_dollars: 0,
                max_quantity: 1,
                maximum_allowed_text: null,
                media_blocks: null,
                min_order_qty: 1,
                minimum_required_text: null,
                name: null,
                no_breakpoints: false,
                no_eoi_maximum: false,
                no_maximum: false,
                no_maximum_dollars: true,
                offer_conditions_text_one: null,
                offer_conditions_text_two: null,
                percentage_tracker: false,
                private: false,
                products: null,
                products_title_text: null,
                purchase_confirmation_email_text: null,
                purchase_media_blocks: null,
                quantity_levels: null,
                register_button_extra_text: null,
                register_button_text: null,
                secondary_offer: false,
                secondary_offer_amount: null,
                secondary_offer_end_time: null,
                secondary_offer_label: null,
                secondary_offer_recurring: false,
                secondary_offer_start_time: null,
                set_initial_units: false,
                share_button_text: null,
                shipping: [],
                shipping_model: null,
                show_offer_details_text: null,
                start_time: null,
                ticket_email_text: null,
                timed_deals: null,
                units_confirmed_text: null,
                unset_initial_units: false,
                white_label_only: false
            },
            modalScrollTop: 0,
            optionsOpen: [],
            products: null,
            quantityLevels: [],
            refreshCodes: false,
            secondaryOfferEndTime: 0,
            secondaryOfferStartTime: 0,
            shipping: [],
            shippingZones: null,
            startTime: 0
        }
    },
    computed: {
        breakpointsOkay() {
            return this.isGiveAway || this.groupBuyForm.no_breakpoints || (this.sortedPricingLevels && this.sortedPricingLevels.length)
        },
        buildType() {
            let myBuildType = Object.values(this.buildTypes).filter((buildType) => buildType.id == this.groupBuyForm.buildz_group_id)
            return myBuildType.length ? myBuildType[0]  : null
        },
        bundlesOkay() {
            return !this.isGiveAway || !this.isMemberGiveAway || (this.bundles && this.bundles.length)
        },
        discountCodes() {
            return this.groupBuyForm.discount_codes
        },
        eoiMaximumOkay() {
            if (this.groupBuyForm.no_breakpoints || this.groupBuyForm.no_eoi_maximum) {
                return true
            }

            if (!this.sortedPricingLevels || !this.sortedPricingLevels.length) {
                return false
            }

            return this.groupBuyForm.eoi_max_quantity >= this.sortedPricingLevels[this.sortedPricingLevels.length - 1].qty
        },
        eoiOkay() {
            return this.eoiStartTime && this.eoiEndTime && this.eoiEndTime > this.eoiStartTime
        },
        errorsBreakpoints() {
            let errorCount = 0

            if (this.isGroupBuy) {
                if (!this.groupBuyForm.no_breakpoints && (!this.quantityLevels || this.objLength(this.quantityLevels) < 1)) {
                    errorCount++
                }
            }

            return errorCount
        },
        errorsDetail() {
            let errorCount = 0

            if (!this.isMemberGiveAway) {
                if (this.groupBuyForm.min_order_qty < 1) {
                    errorCount++
                }
            }

            return errorCount
        },
        errorsEoi() {
            let errorCount = 0

            if (this.isGroupBuy) {
                if (!this.eoiStartTime) {
                    errorCount++
                }

                if (!this.eoiEndTime) {
                    errorCount++
                }

                if (this.eoiStartTime && this.eoiEndTime && this.eoiEndTime <= this.eoiStartTime) {
                    errorCount++
                }

                if (!this.groupBuyForm.no_eoi_maximum && this.groupBuyForm.eoi_max_quantity < 1) {
                    errorCount++
                }

                if (!this.eoiMaximumOkay) {
                    errorCount++
                }
            }

            return errorCount
        },
        errorsGroupBuy() {
            let errorCount = 0

            if (!this.isMemberGiveAway) {
                if (!this.startTime) {
                    errorCount++
                }

                if (!this.endTime) {
                    errorCount++
                }

                if (this.startTime && this.endTime && this.endTime <= this.startTime) {
                    errorCount++
                }

                if (this.groupBuyForm.has_eoi && this.startTime && this.eoiEndTime && this.startTime < this.eoiEndTime) {
                    errorCount++
                }

                if (!this.groupBuyForm.no_maximum && this.groupBuyForm.max_quantity < 1) {
                    errorCount++
                }

                if (!this.groupBuyForm.no_maximum && this.groupBuyForm.initial_units_confirmed >= this.groupBuyForm.max_quantity) {
                    errorCount++
                }

                if (!this.maximumOkay) {
                    errorCount++
                }
            }

            return errorCount
        },
        errorsProducts() {
            let errorCount = 0

            if (this.isGroupBuy) {
                if (!this.products || this.objLength(this.products) < 1) {
                    errorCount++
                }
            }

            return errorCount
        },
        errorsSecondary() {
            let errorCount = 0

            if (this.isGiveAway) {
                if (!this.secondaryOfferStartTime) {
                    errorCount++
                }

                if (!this.secondaryOfferEndTime) {
                    errorCount++
                }

                if (this.secondaryOfferStartTime && this.secondaryOfferEndTime && this.secondaryOfferEndTime <= this.secondaryOfferStartTime) {
                    errorCount++
                }

                if (!this.groupBuyForm.secondary_offer_amount || this.groupBuyForm.secondary_offer_amount < 1) {
                    errorCount++
                }

                if (!this.groupBuyForm.secondary_offer_label) {
                    errorCount++
                }
            }

            return errorCount
        },
        errorsShipping() {
            let errorCount = 0

            if (!this.isMemberGiveAway) {
                if (!this.groupBuyForm.location || this.groupBuyForm.location == "DELETE") {
                    errorCount++
                }

                if (!this.eta) {
                    errorCount++
                }

                if (this.endTime && this.eta && this.eta <= this.endTime) {
                    errorCount++
                }

                if (!this.isGiveAway && !this.groupBuyForm.shipping_model) {
                    errorCount++
                }

                if (!this.isGiveAway && this.groupBuyForm.shipping_model && !this.shippingCheckValidModel(this.shippingZones, this.groupBuyForm.shipping_model)) {
                    errorCount++
                }
            }

            return errorCount
        },
        groupBuyImage() {
            return this?.groupBuy?.gallery && this.groupBuy.gallery.length ? this.groupBuy.gallery[0].url : null
        },
        isGiveAway() {
            return this.groupBuy.type == "giveAway"
        },
        isGroupBuy() {
            return this.groupBuy.type == "groupBuy"
        },
        isMemberGiveAway() {
            return this.groupBuy.type == "member"
        },
        maximumOkay() {
            if (this.groupBuyForm.no_breakpoints || this.groupBuyForm.no_maximum) {
                return true
            }

            if (!this.sortedPricingLevels || !this.sortedPricingLevels.length) {
                return false
            }

            return this.groupBuyForm.max_quantity >= this.sortedPricingLevels[this.sortedPricingLevels.length - 1].qty
        },
        productsOkay() {
            return this.isGiveAway || this.isMemberGiveAway || (this.products && this.products.length)
        },
        publishEoiOkay() {
            return this.eoiOkay && this.productsOkay && this.breakpointsOkay && this.eoiMaximumOkay && this.shippingOkay && this.groupBuyForm.disabled && this.errorsEoi == 0
        },
        publishOkay() {
            return (!this.groupBuyForm.eoi || this.eoiOkay) && this.bundlesOkay && this.productsOkay && this.breakpointsOkay && this.shippingOkay && this.maximumOkay && this.errorsGroupBuy == 0
        },
        shippingOkay() {
            if (this.isMemberGiveAway) {
                return true
            }

            if (this.isGiveAway) {
                return Boolean(this.groupBuyForm.included_countries && this.groupBuyForm.included_countries.length && this.groupBuyForm.location && this.eta && this.eta > this.endTime)
            }

            let myZones = !this.shippingZones ? null : this.shippingZones.filter((myZone) => myZone.rates && myZone.rates.length)

            return myZones && this.groupBuyForm.location && this.eta && this.eta > this.endTime
        },
        showEndNow() {
            return !this.groupBuyForm.disabled && this.groupBuy.end_time > this.currentUnixTime()
        },
        showEoiEndNow() {
            return this.groupBuyForm.has_eoi && this.groupBuyForm.eoi && this.groupBuy.eoi_end_time > this.currentUnixTime()
        },
        sortedPricingLevels() {
            return this.quantityLevels
                .sort((a, b) => a.qty < b.qty ? -1 : 1)
        }
    },
    watch: {
        sellerVendorData: {
            immediate: true,
            handler(val) {
                if (val?.defaultGroupBuyCategory && !this.groupBuyForm.buildz_group_id) {
                    this.groupBuyForm.buildz_group_id = val.defaultGroupBuyCategory
                }
            }
        }
    },
    mounted() {
        this.getGroupBuy()
    },
    methods: {
        clickCopy() {
            if (confirm(`You wish to make a copy of this ${this.pageData.groupBuy.adminLabelSingular}?`)) {
                this.openSpinner()

                buildzAxios?.post(this.getRoute('groupOfferCopy', this.groupBuy.id))
                    .then((response) => {
                        if (response.status == 200 && response.data) {
                            this.closeEditGroupBuyModal()

                            this.setStateData([
                                { vendorGroupBuys: null }
                            ])

                            this.setStateData([
                                { vendorGroupBuys: this.objCopy(response.data.groupBuys) }
                            ])
                            
                            setTimeout(() => {
                                this.openEditGroupBuyModal(this.objCopy(response.data.groupBuy))
                            }, 200)
                        }

                        this.closeSpinner()
                    })
                    .catch((error) => {
                        this.closeSpinner()
                    })
            }
        },
        clickCreateNewABTest(inData) {
            this.openSpinner()

            buildzAxios?.put(this.getRoute('groupOfferABTest', this.groupBuy.id), inData)
                .then((response) => {
                    if (response.status == 200 && response.data) {
                        this.groupBuy.ab_tests = response.data.ab_tests
                        alert('New A/B test created')
                    }

                    this.closeSpinner()
                })
                .catch((error) => {
                    this.closeSpinner()
                })
        },
        clickCreateNewDiscountCode(inForm) {
            let myNewCode = {
                label: inForm.label,
                percentage: inForm.percentage,
                enabled: inForm.enabled,
                code: inForm.code,
                multiple: inForm.multiple
            }

            if (!this.groupBuyForm.discount_codes) {
                this.groupBuyForm.discount_codes = []
            }

            this.groupBuyForm.discount_codes.push(myNewCode)
            this.save()
            this.refreshCodes = true

            setTimeout(() => {
                this.refreshCodes = false
            }, 500)
        },
        clickEndEoiNow() {

        },
        clickEndNow() {

        },
        clickPostUpdate() {
            this.openWriteActivity(null, 'groupBuyUpdate', this.groupBuy)
        },
        clickPreview(inWhiteLabel) {
            this.openGroupBuy(this.groupBuy, inWhiteLabel)
            
            if (!inWhiteLabel) {
                this.closeEditGroupBuyModal()
            }
        },
        clickPublish() {
            if (this.publishOkay) {
                if (confirm(`You wish to publish and lock this ${this.pageData.groupBuy.adminLabelSingular}?`)) {
                    this.groupBuyForm.disabled = false

                    if (this.groupBuyForm.initial_units_confirmed) {
                        this.groupBuyForm.set_initial_units = true
                    }

                    this.updateGroupBuy(this.groupBuyForm, true, this.endTime, this.startTime)
                }
            }
            else {
                if (!this.breakpointsOkay) {
                    alert(`You wont be able to publish this ${this.pageData.groupBuy.adminLabelSingular} without adding at least one breakpoint.`)
                }
                else if (!this.productsOkay) {
                    alert(`You wont be able to publish this ${this.pageData.groupBuy.adminLabelSingular} without adding at least one product.`)
                }
                else if (!this.bundlesOkay) {
                    alert(`You wont be able to publish this ${this.pageData.groupBuy.adminLabelSingular} without adding at least one bundle.`)
                }
                else if (!this.shippingOkay) {
                    alert(`Shipping details are incomplete. You wont be able to publish this ${this.pageData.groupBuy.adminLabelSingular} until you fix this problem.`)
                }
            }
        },
        clickPublishEoi() {
            if (this.publishEoiOkay) {
                if (confirm("You wish to publish and lock this E.O.I.?")) {
                    this.groupBuyForm.eoi = true
                    this.updateEoi(this.groupBuyForm, true, this.eoiEndTime, this.eoiStartTime)
                }
            }
            else {
                if (!this.sortedPricingLevels.length) {
                    alert("You wont be able to publish this E.O.I. without adding at least one breakpoint.")
                }
                else if (!this.productsOkay) {
                    alert("You wont be able to publish this E.O.I. without adding at least one product.")
                }
            }
        },
        clickUnpublish() {
            if (confirm(`You wish to unpublish and unlock this ${this.pageData.groupBuy.adminLabelSingular}?`)) {
                this.groupBuyForm.disabled = true

                if (this.groupBuyForm.initial_units_confirmed) {
                    this.groupBuyForm.unset_initial_units = true
                }

                this.updateGroupBuy(this.groupBuyForm, true, this.endTime, this.startTime)
            }
        },
        clickUnpublishEoi() {
            if (confirm("You wish to unpublish and unlock this E.O.I.?")) {
                this.groupBuyForm.eoi = false
                this.updateEoi(this.groupBuyForm, true, this.eoiEndTime, this.eoiStartTime)
            }
        },
        getGroupBuy() {
            buildzAxios?.get(this.getRoute('groupOffer', this.groupBuyToEdit.id))
                .then((response) => {
                    if (response.status == 200 && (response?.data?.groupBuy ?? false)) {
                        let saveDates = false

                        this.groupBuy = this.objCopy(response.data.groupBuy)
                        this.groupBuyForm.ab_tests = this.groupBuy.ab_tests
                        this.groupBuyForm.buildz_group_id = this.groupBuy.buildz_group_id
                        this.bundles = this.groupBuy.bundles
                        this.groupBuyForm.delivery_days = this.groupBuy.delivery_days
                        this.groupBuyForm.disabled = this.groupBuy.disabled
                        this.groupBuyForm.discount_codes = this.groupBuy.discount_codes
                        this.groupBuyForm.dollars_graphic = this.groupBuy?.white_label_data?.dollars_graphic ?? false
                        this.groupBuyForm.dollars_percentage_text = this.groupBuy?.white_label_data?.dollars_percentage_text ?? this.defaultText.groupBuy.dollarsPercentageText
                        this.groupBuyForm.dollars_tracker = this.groupBuy?.white_label_data?.dollars_tracker ?? false
                        this.groupBuyForm.end_time = this.groupBuy.end_time
                        this.groupBuyForm.eoi = this.groupBuy.eoi
                        this.groupBuyForm.eoi_end_time = this.groupBuy.eoi_end_time
                        this.groupBuyForm.eoi_max_quantity = this.groupBuy.eoi_max_quantity ?? 1
                        this.groupBuyForm.eoi_start_time = this.groupBuy.eoi_start_time
                        this.groupBuyForm.eta = this.groupBuy.eta
                        this.groupBuyForm.global_discount = this.groupBuy.global_discount
                        this.groupBuyForm.has_eoi = this.groupBuy.has_eoi
                        this.groupBuyForm.hide_build_category = this.groupBuy?.white_label_data?.hide_build_category ?? false
                        this.groupBuyForm.hide_info_bar = this.groupBuy?.white_label_data?.hide_info_bar ?? false
                        this.groupBuyForm.hide_minimum_required = this.groupBuy?.white_label_data?.hide_minimum_required ?? false
                        this.groupBuyForm.hide_maximum_allowed = this.groupBuy?.white_label_data?.hide_maximum_allowed ?? false
                        this.groupBuyForm.hide_offer_conditions = this.groupBuy?.white_label_data?.hide_offer_conditions ?? false
                        this.groupBuyForm.hide_offer_conditions_text_one = this.groupBuy?.white_label_data?.hide_offer_conditions_text_one ?? false
                        this.groupBuyForm.hide_offer_conditions_text_two = this.groupBuy?.white_label_data?.hide_offer_conditions_text_two ?? false
                        this.groupBuyForm.hide_offer_description = this.groupBuy?.white_label_data?.hide_offer_description ?? false
                        this.groupBuyForm.hide_offer_details_text = this.groupBuy?.white_label_data?.hide_offer_details_text ?? this.defaultText.groupBuy.hideOfferDetailsText
                        this.groupBuyForm.hide_products_description = this.groupBuy?.white_label_data?.hide_products_description ?? false
                        this.groupBuyForm.hide_products_title_text = this.groupBuy?.white_label_data?.hide_products_title_text ?? false
                        this.groupBuyForm.hide_share_button = this.groupBuy?.white_label_data?.hide_share_button ?? false
                        this.groupBuyForm.hide_shipping_eta = this.groupBuy?.white_label_data?.hide_shipping_eta ?? false
                        this.groupBuyForm.hide_shipping_location = this.groupBuy?.white_label_data?.hide_shipping_location ?? false
                        this.groupBuyForm.hide_tracker_bar = this.groupBuy?.white_label_data?.hide_tracker_bar ?? false
                        this.groupBuyForm.hide_units_confirmed = this.groupBuy?.white_label_data?.hide_units_confirmed ?? false
                        this.groupBuyForm.immediate_payment = this.groupBuy.immediate_payment ?? false
                        this.groupBuyForm.included_countries = this?.groupBuy?.included_countries ?? []
                        this.groupBuyForm.initial_units_confirmed = this.groupBuy.initial_units_confirmed ?? 0
                        this.groupBuyForm.join_button_text = this.groupBuy?.white_label_data?.join_button_text ?? this.defaultText.groupBuy.joinButtonText
                        this.groupBuyForm.keywords = this.groupBuy.keywords
                        this.groupBuyForm.live_now = this.groupBuy.live_now ?? false
                        this.groupBuyForm.live_url = this.groupBuy.live_url
                        this.groupBuyForm.local_pickup = this.groupBuy.local_pickup
                        this.groupBuyForm.location = this.groupBuy.location
                        this.groupBuyForm.max_dollars = this.groupBuy.max_dollars ?? 0
                        this.groupBuyForm.max_quantity = this.groupBuy.max_quantity ?? 1
                        this.groupBuyForm.maximum_allowed_text = this.groupBuy?.white_label_data?.maximum_allowed_text ?? this.defaultText.groupBuy.maximumAllowedText
                        this.groupBuyForm.media_blocks = this.groupBuy.media_blocks
                        this.groupBuyForm.min_order_qty = this.groupBuy.min_order_qty ?? 1
                        this.groupBuyForm.minimum_required_text = this.groupBuy?.white_label_data?.minimum_required_text ?? this.defaultText.groupBuy.minimumRequiredText
                        this.groupBuyForm.name = this.groupBuy.name
                        this.groupBuyForm.no_breakpoints = this.groupBuy.no_breakpoints ?? false
                        this.groupBuyForm.no_eoi_maximum = this.groupBuy.no_eoi_maximum ?? false
                        this.groupBuyForm.no_maximum = this.groupBuy.no_maximum ?? false
                        this.groupBuyForm.no_maximum_dollars = this.groupBuy.no_maximum_dollars ?? true
                        this.groupBuyForm.offer_conditions_text_one = this.groupBuy?.white_label_data?.offer_conditions_text_one ?? this.defaultText.groupBuy.offerConditionsTextLineOne
                        this.groupBuyForm.offer_conditions_text_two = this.groupBuy?.white_label_data?.offer_conditions_text_two ?? this.defaultText.groupBuy.offerConditionsTextLineTwo
                        this.groupBuyForm.percentage_tracker = this.groupBuy?.white_label_data?.percentage_tracker ?? false
                        this.groupBuyForm.private = this.groupBuy.private ?? false
                        this.groupBuyForm.products = this.groupBuy.products
                        this.groupBuyForm.products_title_text = this.groupBuy?.white_label_data?.products_title_text ?? this.defaultText.groupBuy.productsTitleText
                        this.groupBuyForm.purchase_confirmation_email_text = this.groupBuy.purchase_confirmation_email_text
                        this.groupBuyForm.purchase_media_blocks = this.groupBuy.purchase_media_blocks
                        this.groupBuyForm.quantity_levels = this.groupBuy.quantity_levels
                        this.groupBuyForm.register_button_extra_text = this.groupBuy?.white_label_data?.register_button_extra_text ?? null
                        this.groupBuyForm.register_button_text = this.groupBuy?.white_label_data?.register_button_text ?? this.defaultText.groupBuy.registerButtonText
                        this.groupBuyForm.secondary_offer = this.groupBuy.secondary_offer ?? false
                        this.groupBuyForm.secondary_offer_amount = this.groupBuy.secondary_offer_amount
                        this.groupBuyForm.secondary_offer_label = this.groupBuy.secondary_offer_label
                        this.groupBuyForm.secondary_offer_recurring = this.groupBuy.secondary_offer_recurring ?? false
                        this.groupBuyForm.share_button_text = this.groupBuy?.white_label_data?.share_button_text ?? this.defaultText.groupBuy.shareButtonText
                        this.groupBuyForm.shipping = this.groupBuy.shipping
                        this.groupBuyForm.shipping_model = this.groupBuy.shipping_model ?? false
                        this.groupBuyForm.show_offer_details_text = this.groupBuy?.white_label_data?.show_offer_details_text ?? this.defaultText.groupBuy.showOfferDetailsText
                        this.groupBuyForm.start_time = this.groupBuy.start_time
                        this.groupBuyForm.ticket_email_text = this.groupBuy.ticket_email_text
                        this.groupBuyForm.timed_deals = this.groupBuy.timed_deals
                        this.groupBuyForm.units_confirmed_text = this.groupBuy?.white_label_data?.units_confirmed_text ?? this.defaultText.groupBuy.unitsConfirmedText
                        this.groupBuyForm.white_label_only = this.groupBuy.white_label_only ?? false
                        
                        this.gallery = this.groupBuy.gallery ?? []
                        this.products = this.groupBuy.products ?? []
                        this.quantityLevels = this.groupBuy.quantity_levels ?? []
                        this.shipping = this.objCopy(this.groupBuy.shipping) ?? []

                        if (this?.groupBuy?.shipping?.shippingZones ?? false) {
                            this.shippingZones = this.groupBuy.shipping.shippingZones
                        }
                        else {
                            this.shippingZones = this?.sellerVendorData?.shippingZones ?? [
                                {
                                    id: 1,
                                    name: 'Default zone',
                                    rates: []
                                }
                            ]
                        }

                        if (this?.groupBuy?.shipping?.customRates ?? false) {
                            this.customRates = this.groupBuy.shipping.customRates
                        }
                        else {
                            this.customRates = this.objCopy(this?.sellerVendorData?.customRates ?? [
                                'New rate'
                            ])
                        }

                        let myExcludedCountries = []

                        if (this?.groupBuy?.shipping?.excludedCountries ?? false) {
                            myExcludedCountries = this.groupBuy.shipping.excludedCountries
                        }
                        else {
                            myExcludedCountries = this.objCopy(this?.sellerVendorData?.excludedCountries ?? [])
                        }

                        this.shipping = {
                            shippingZones: this.shippingZones,
                            customRates: this.customRates,
                            excludedCountries: myExcludedCountries
                        }

                        if (this.groupBuy.start_time) {
                            this.startTime = this.groupBuy.start_time * 1000
                        }
                        else {
                            this.startTime = this.currentUnixTime() * 1000 - 1000
                        }

                        if (this.groupBuy.end_time) {
                            this.endTime = this.groupBuy.end_time * 1000
                        }
                        else {
                            this.endTime = this.currentUnixTime() * 1000
                        }

                        if (this.groupBuy.eoi_start_time) {
                            this.eoiStartTime = this.groupBuy.eoi_start_time * 1000
                        }
                        else {
                            this.eoiStartTime = this.currentUnixTime() * 1000 - 3000
                        }

                        if (this.groupBuy.eoi_end_time) {
                            this.eoiEndTime = this.groupBuy.eoi_end_time * 1000
                        }
                        else {
                            this.eoiEndTime = this.currentUnixTime() * 1000 - 2000
                        }

                        if (this.groupBuy.eta) {
                            this.eta = this.groupBuy.eta * 1000
                        }
                        else {
                            this.eta = this.currentUnixTime() * 1000 + 1000
                        }

                        if (this.groupBuy.secondary_offer_start_time) {
                            this.secondaryOfferStartTime = this.groupBuy.secondary_offer_start_time * 1000
                        }
                        else {
                            this.secondaryOfferStartTime = this.currentUnixTime() * 1000
                        }

                        if (this.groupBuy.secondary_offer_end_time) {
                            this.secondaryOfferEndTime = this.groupBuy.secondary_offer_end_time * 1000
                        }
                        else {
                            this.secondaryOfferEndTime = this.currentUnixTime() * 1000 + 1000
                        }

                        this.formLoaded = true

                        setTimeout(() => {
                            this.gotGroupBuy = true
                        }, 1000)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        save() {
            if (this.formLoaded && this.gotGroupBuy) {
                this.groupBuyForm.start_time = Number.isInteger(this.dateTimeToUnixTime(this.startTime)) ? this.dateTimeToUnixTime(this.startTime) : this.startTime / 1000
                this.groupBuyForm.end_time = Number.isInteger(this.dateTimeToUnixTime(this.endTime)) ? this.dateTimeToUnixTime(this.endTime) : this.endTime / 1000
                this.groupBuyForm.eoi_start_time = Number.isInteger(this.dateTimeToUnixTime(this.eoiStartTime)) ? this.dateTimeToUnixTime(this.eoiStartTime) : this.eoiStartTime / 1000
                this.groupBuyForm.eoi_end_time = Number.isInteger(this.dateTimeToUnixTime(this.eoiEndTime)) ? this.dateTimeToUnixTime(this.eoiEndTime) : this.eoiEndTime / 1000
                this.groupBuyForm.eta = Number.isInteger(this.dateTimeToUnixTime(this.eta)) ? this.dateTimeToUnixTime(this.eta) : this.eta / 1000
                this.groupBuyForm.secondary_offer_start_time = Number.isInteger(this.dateTimeToUnixTime(this.secondaryOfferStartTime)) ? this.dateTimeToUnixTime(this.secondaryOfferStartTime) : this.secondaryOfferStartTime / 1000
                this.groupBuyForm.secondary_offer_end_time = Number.isInteger(this.dateTimeToUnixTime(this.secondaryOfferEndTime)) ? this.dateTimeToUnixTime(this.secondaryOfferEndTime) : this.secondaryOfferEndTime / 1000
                this.groupBuyForm.shipping = this.objCopy(this.shipping)
                
                if (this.geoloc && this.address) {
                    let myLocation = {
                        geoloc: this.geoloc,
                        address: this.address
                    }

                    this.groupBuyForm.location = myLocation
                }

                this.saveGroupBuy(this.groupBuy.id, this.groupBuyForm)

                if (this.groupBuyForm.location == "DELETE") {
                    this.groupBuyForm.location = null
                }

                this.groupBuyForm.set_initial_units = false
                this.groupBuyForm.unset_initial_units = false
            }
        },
        saveDebounce: debounce(function() {
            this.save()
        }, 2000),
        async saveLocalGallery(inGallery) {
            this.gallery = inGallery
            await this.postGallery('groupOfferGallery', inGallery, this.groupBuy.id)
        },
        updateABTestGallery(inGallery, inAbIdentifier) {
            this.groupBuyForm.ab_tests[inAbIdentifier].gallery = inGallery
            this.save()
        },
        updateABTestMediaBlocks(inForm, inAbIdentifier) {
            this.groupBuyForm.ab_tests[inAbIdentifier].media_blocks = inForm.media_blocks
            this.save()
        },
        updateABTestPurchaseMediaBlocks(inForm, inAbIdentifier) {
            this.groupBuyForm.ab_tests[inAbIdentifier].purchase_media_blocks = inForm.purchase_media_blocks
            this.save()
        },
        updateABTestWhiteLabel(inForm, inSaveImmediate, inAbIdentifier) {
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.dollars_graphic = inForm.dollars_graphic
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.dollars_percentage_text = inForm.dollars_percentage_text
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.dollars_tracker = inForm.dollars_tracker
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.hide_info_bar = inForm.hide_info_bar
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.hide_offer_conditions = inForm.hide_offer_conditions
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.hide_offer_conditions_text_one = inForm.hide_offer_conditions_text_one
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.hide_offer_conditions_text_two = inForm.hide_offer_conditions_text_two
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.hide_offer_details_text = inForm.hide_offer_details_text
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.hide_products_description = inForm.hide_products_description
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.hide_products_title_text = inForm.hide_products_title_text
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.hide_share_button = inForm.hide_share_button
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.hide_tracker_bar = inForm.hide_tracker_bar
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.hide_units_confirmed = inForm.hide_units_confirmed
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.join_button_text = inForm.join_button_text
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.maximum_allowed_text = inForm.maximum_allowed_text
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.minimum_required_text = inForm.minimum_required_text
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.offer_conditions_text_one = inForm.offer_conditions_text_one
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.offer_conditions_text_two = inForm.offer_conditions_text_two
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.percentage_tracker = inForm.percentage_tracker
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.products_title_text = inForm.products_title_text
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.register_button_extra_text = inForm.register_button_extra_text
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.register_button_text = inForm.register_button_text
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.share_button_text = inForm.share_button_text
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.show_offer_details_text = inForm.show_offer_details_text
            this.groupBuyForm.ab_tests[inAbIdentifier].white_label_data.units_confirmed_text = inForm.units_confirmed_text
            
            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updateAddress(inAddress, inGeoloc) {
            this.address = inAddress
            this.geoloc = inGeoloc
            this.save()
        },
        updateBreakpoints(inBreakpoints) {
            this.quantityLevels = this.objCopy(inBreakpoints)
            this.groupBuyForm.quantity_levels = this.objCopy(this.quantityLevels)
            this.save()
        },
        updateBundles(inBundles) {
            this.bundles = inBundles
        },
        updateDetails(inForm, inSaveImmediate) {
            this.groupBuyForm.buildz_group_id = inForm.buildz_group_id
            this.groupBuyForm.hide_build_category = inForm.hide_build_category
            this.groupBuyForm.hide_offer_description = inForm.hide_offer_description
            this.groupBuyForm.keywords = inForm.keywords
            this.groupBuyForm.live_now = inForm.live_now
            this.groupBuyForm.live_url = inForm.live_url
            this.groupBuyForm.min_order_qty = Number.isInteger(inForm.min_order_qty) ? inForm.min_order_qty : 0
            this.groupBuyForm.no_breakpoints = inForm.no_breakpoints
            this.groupBuyForm.purchase_confirmation_email_text = inForm.purchase_confirmation_email_text
            this.groupBuyForm.secondary_offer = inForm.secondary_offer
            this.groupBuyForm.ticket_email_text = inForm.ticket_email_text
            
            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updateDiscountCodes(inForm, inSaveImmediate) {
            this.groupBuyForm.discount_codes = inForm.codes
            
            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updateEligibleCountries(inCountries) {
            this.groupBuyForm.included_countries = inCountries
            this.saveDebounce()
        },
        updateEoi(inForm, inSaveImmediate, inEoiEndTime, inEoiStartTime) {
            this.groupBuyForm.eoi_end_time = inForm.eoi_end_time
            this.groupBuyForm.eoi_max_quantity = Number.isInteger(inForm.eoi_max_quantity) ? inForm.eoi_max_quantity : 0
            this.groupBuyForm.eoi_start_time = inForm.eoi_start_time
            this.groupBuyForm.no_eoi_maximum = inForm.no_eoi_maximum

            this.eoiEndTime = inEoiEndTime
            this.eoiStartTime = inEoiStartTime

            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updateGlobalDiscount(inForm, inSaveImmediate) {
            this.groupBuyForm.global_discount = inForm
            
            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updateGroupBuy(inForm, inSaveImmediate, inEndTime, inStartTime) {
            this.groupBuyForm.dollars_graphic = inForm.dollars_graphic
            this.groupBuyForm.dollars_tracker = inForm.dollars_tracker
            this.groupBuyForm.end_time = inForm.end_time
            this.groupBuyForm.hide_maximum_allowed = inForm.hide_maximum_allowed
            this.groupBuyForm.hide_minimum_required = inForm.hide_minimum_required
            this.groupBuyForm.initial_units_confirmed = inForm.initial_units_confirmed
            this.groupBuyForm.max_dollars = Number.isFinite(Number.parseFloat(inForm.max_dollars)) ? Number.parseFloat(inForm.max_dollars) : 0
            this.groupBuyForm.max_quantity = Number.isInteger(inForm.max_quantity) ? inForm.max_quantity : 0
            this.groupBuyForm.no_maximum_dollars = inForm.no_maximum_dollars
            this.groupBuyForm.no_maximum = inForm.no_maximum
            this.groupBuyForm.percentage_tracker = inForm.percentage_tracker
            this.groupBuyForm.start_time = inForm.start_time
            
            this.endTime = inEndTime
            this.startTime = inStartTime

            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updateMediaBlocks(inForm, inSaveImmediate) {
            this.groupBuyForm.media_blocks = inForm.media_blocks

            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updateModalScrollTop(inScrollTop) {
            this.modalScrollTop = inScrollTop
        },
        async updateOfflineMedia(inGallery) {
            this.gallery = inGallery
            let myTempGallery = await this.buildOfflineTempGallery(inGallery, `groupBuy_temp${this.groupBuy.id}`)
            
            setTimeout(() => {
                this.saveGalleryPrep(myTempGallery, 'groupBuyGalleryUpdate', this.groupBuy.id, 'groupOfferGallery')
            }, 100)
        },
        updateOptions(inForm, inSaveImmediate) {
            this.groupBuyForm.has_eoi = inForm.has_eoi
            this.groupBuyForm.immediate_payment = inForm.immediate_payment
            this.groupBuyForm.no_breakpoints = inForm.no_breakpoints
            this.groupBuyForm.private = inForm.private
            this.groupBuyForm.secondary_offer = inForm.secondary_offer
            this.groupBuyForm.white_label_only = inForm.white_label_only
            
            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updateProducts(inForm) {
            this.groupBuyForm.products = this.objCopy(inForm.products)
            this.products = this.objCopy(inForm.products)
            this.save()
        },
        updatePurchaseMediaBlocks(inForm, inSaveImmediate) {
            this.groupBuyForm.purchase_media_blocks = inForm.purchase_media_blocks

            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updateSecondaryOffer(inForm, inSaveImmediate, inSecondaryOfferEndTime, inSecondaryOfferStartTime) {
            this.groupBuyForm.secondary_offer_amount = Number.isInteger(inForm.secondary_offer_amount) ? inForm.secondary_offer_amount : 0
            this.groupBuyForm.secondary_offer_label = inForm.secondary_offer_label
            this.groupBuyForm.secondary_offer_end_time = inForm.secondary_offer_end_time
            this.groupBuyForm.secondary_offer_recurring = inForm.secondary_offer_recurring
            this.groupBuyForm.secondary_offer_start_time = inForm.secondary_offer_start_time
            
            this.secondaryOfferEndTime = inSecondaryOfferEndTime
            this.secondaryOfferStartTime = inSecondaryOfferStartTime

            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updateShipping(inForm, inSaveImmediate, inEta) {
            this.groupBuyForm.eta = inForm.eta
            this.groupBuyForm.hide_shipping_eta = inForm.hide_shipping_eta
            this.groupBuyForm.hide_shipping_location = inForm.hide_shipping_location
            this.groupBuyForm.local_pickup = inForm.local_pickup
            this.groupBuyForm.location = inForm?.location ?? 'DELETE'
            this.groupBuyForm.shipping_model = inForm.shipping_model
            
            this.eta = inEta

            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updateShippingData(inShipping) {
            this.shipping = this.objCopy(inShipping)
            this.saveDebounce()
        },
        updateShippingZones(inShippingZones, inSaveImmediate) {
            this.shippingZones = this.objCopy(inShippingZones)
            
            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updateTimedDeals(inForm, inSaveImmediate) {
            this.groupBuyForm.timed_deals = inForm
            
            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updateWhiteLabel(inForm, inSaveImmediate) {
            this.groupBuyForm.dollars_graphic = inForm.dollars_graphic
            this.groupBuyForm.dollars_percentage_text = inForm.dollars_percentage_text
            this.groupBuyForm.dollars_tracker = inForm.dollars_tracker
            this.groupBuyForm.hide_info_bar = inForm.hide_info_bar
            this.groupBuyForm.hide_offer_conditions = inForm.hide_offer_conditions
            this.groupBuyForm.hide_offer_conditions_text_one = inForm.hide_offer_conditions_text_one
            this.groupBuyForm.hide_offer_conditions_text_two = inForm.hide_offer_conditions_text_two
            this.groupBuyForm.hide_offer_details_text = inForm.hide_offer_details_text
            this.groupBuyForm.hide_products_description = inForm.hide_products_description
            this.groupBuyForm.hide_products_title_text = inForm.hide_products_title_text
            this.groupBuyForm.hide_share_button = inForm.hide_share_button
            this.groupBuyForm.hide_tracker_bar = inForm.hide_tracker_bar
            this.groupBuyForm.hide_units_confirmed = inForm.hide_units_confirmed
            this.groupBuyForm.join_button_text = inForm.join_button_text
            this.groupBuyForm.maximum_allowed_text = inForm.maximum_allowed_text
            this.groupBuyForm.minimum_required_text = inForm.minimum_required_text
            this.groupBuyForm.offer_conditions_text_one = inForm.offer_conditions_text_one
            this.groupBuyForm.offer_conditions_text_two = inForm.offer_conditions_text_two
            this.groupBuyForm.percentage_tracker = inForm.percentage_tracker
            this.groupBuyForm.products_title_text = inForm.products_title_text
            this.groupBuyForm.register_button_extra_text = inForm.register_button_extra_text
            this.groupBuyForm.register_button_text = inForm.register_button_text
            this.groupBuyForm.share_button_text = inForm.share_button_text
            this.groupBuyForm.show_offer_details_text = inForm.show_offer_details_text
            this.groupBuyForm.units_confirmed_text = inForm.units_confirmed_text
            
            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        }
    }
}
</script>