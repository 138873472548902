<template>
    <div v-if="formLoaded" class="w-full">
        <input-panel 
            fieldFormName="label" fieldLabel="Title" fieldPlaceholder="Title" :fieldRequired="true" fieldType="text" :fieldValue="bundleForm.label" 
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(bundleForm.label)" 
                message="Label must be entered"
            />
        </input-panel>
        <input-panel 
            fieldFormName="price" fieldLabel="Price" fieldPlaceholder="Price" :fieldRequired="true" fieldType="number" 
            :fieldValue="bundleForm.price" 
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(bundleForm.price)" 
                message="Price must be entered"
            />
        </input-panel>
        <input-panel 
            fieldFormName="charityPercentage" fieldLabel="Charity Percentage" fieldPlaceholder="Charity Percentage" :fieldRequired="true" fieldType="number" 
            :fieldValue="bundleForm.charityPercentage" 
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(bundleForm.charityPercentage)" 
                message="Charity Percentage must be entered"
            />
        </input-panel>
        <input-panel 
            fieldFormName="prizePercentage" fieldLabel="Prize Percentage" fieldPlaceholder="Prize Percentage" :fieldRequired="true" fieldType="number" 
            :fieldValue="bundleForm.prizePercentage" 
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(bundleForm.prizePercentage)" 
                message="Prize Percentage must be entered"
            />
        </input-panel>
        <rich-text-panel v-if="!hideMediaPanel"
            fieldFormName="description" fieldLabel="Description" :fieldValue="bundleForm.description"
            @fieldInput="updateFormValue"
        />
        <media-panel v-if="!hideMediaPanel"
            fieldLabel="Image" :hideSpinner="true" :imagesArray="[]" :imagesOnly="true" :maxFiles="1" :multiple="false"
            @saveMedia="saveLocalImage" @updateOfflineMedia="updateOfflineMedia"
        />
        <div class="w-full white-transparent mt-px py-4 px-2" :class="{ 'desktop-button-box justify-center': isDesktopWidth }">
            <div @click="clickCreateBundle()" class="w-full" :class=" { 'standard-button-base': allOkay, 'disabled-button-base': !allOkay } ">
                Create Tier
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'groupBuy',
        'lastId'
    ],
    data() {
        return {
            bundleForm: {
                charityPercentage: null,
                description: null,
                enabled: false,
                label: null,
                milestones: null,
                price: null,
                prizePercentage: null,
                tempImage: null
            },
            formLoaded: false,
            hideMediaPanel: false,
            newImageGallery: null,
            validating: false
        }
    },
    computed: {
        allOkay() {
            return Boolean(this.bundleForm.label && this.newImageGallery && this.bundleForm.price && this.bundleForm.charityPercentage && this.bundleForm.prizePercentage)
        }
    },
    mounted() {
        this.formLoaded = true
    },
    methods: {
        async clickCreateBundle() {
            if (this.allOkay) {
                this.validating = false
                
                let myId = this.lastId + 1

                if (this.newImageGallery) {
                    let myTempGallery = await this.buildOfflineTempGallery(this.newImageGallery, `bundle_temp${this.groupBuy.id}_${myId}}`)
                
                    let myOtherData = {
                        bundleId: myId
                    }

                    setTimeout(() => {
                        this.saveGalleryPrep(myTempGallery, 'groupBuyBundleGalleryUpdate', this.groupBuy.id, 'groupOfferBundleGallery', myOtherData)
                    }, 100)

                    this.bundleForm.tempImage = this.newImageGallery[0]
                }

                this.$emit('clickCreateNewBundle', this.objCopy(this.bundleForm))
                this.pageMessage('success', "Tier created")
                this.bundleForm.charityPercentage = null
                this.bundleForm.description = null
                this.bundleForm.label = null
                this.bundleForm.price = null
                this.bundleForm.prizePercentage = null
                this.newImageGallery = null
                this.hideMediaPanel = true

                setTimeout(() => {
                    this.hideMediaPanel = false
                }, 100)
            }
            else {
                this.validating = true
            }
        },
        saveLocalImage(inGallery) {
            this.newImageGallery = inGallery
        },
        updateFormValue(inField, inValue) {
            this.bundleForm[inField] = inValue
        },
        updateOfflineMedia(inGallery) {
            this.newImageGallery = inGallery
        }
    }
}
</script>