<template>
    <wizard-table-container v-if="trait"
        :childTable="true" :hideHeader="true"
    >
        <template #records>
            <table-record>
                <template #record>
                    <div class="flex items-center justify-start w-full pl-2">
                        <child-line-prefix />
                        <div>
                            Add-Ons
                        </div>
                    </div>
                    <div v-if="!hideIcons" class="wizard-icon-container">
                        <div @click="clickToggleShowAddOns()" v-show="!showMe" class="cursor-pointer">
                            <fa-icon icon="chevron-left" type="fas" class="h-5 w-5" />
                        </div>
                        <div @click="clickToggleShowAddOns()" v-show="showMe" class="cursor-pointer">
                            <fa-icon icon="chevron-down" type="fas" class="h-5 w-5" />
                        </div>
                        <div v-if="sortedUnselectedAddOns.length" @click="clickNewAddOn()" class="cursor-pointer ml-2">
                            <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                </template>
                <template #extra>
                    <div v-if="showMe" class="w-full">
                        <wizard-table-container
                            :childTable="true" :hideHeader="true"
                        >
                            <template #records>
                                <div v-if="addingAddOn">
                                    <edit-table-record
                                        :fieldData="sortedUnselectedAddOns" fieldDataLabel="label" fieldDataValue="id" fieldFormName="id" fieldType="select" 
                                        :fieldValue="addAddOnForm.id" fieldValueErrorMessage="Add-On must be selected" recordClasses="pt-3 pl-2" :showChildLinePrefix="true" 
                                        :validatingForm="validatingForm"
                                        @clickCancel="clickCancelNewAddOn" @clickSave="clickSaveAddOn" @updateFieldValue="updateAddAddOnFormValue"
                                    />
                                </div>
                                <template v-for="addOn in addOns" :key="id">
                                    <div v-if="isAddOnSelected(addOn.id)" class="w-full">
                                        <table-record>
                                            <template #record>
                                                <div class="flex items-center justify-start w-full pl-4 relative">
                                                    <child-line-prefix />
                                                    <div>
                                                        {{ addOn.label }}
                                                    </div>
                                                    <div v-if="!hideIcons && isAddOnOpen(addOn.id)" class="absolute -bottom-3 right-1 flex items-center justify-end text-xxs text-center">
                                                        <div class="w-12">
                                                            ENABLED
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="!hideIcons" class="wizard-icon-container">
                                                    <div @click="clickToggleShowAddOn(addOn.id)" v-show="!isAddOnOpen(addOn.id)" class="cursor-pointer">
                                                        <fa-icon icon="chevron-left" type="fas" class="h-5 w-5" />
                                                    </div>
                                                    <div @click="clickToggleShowAddOn(addOn.id)" v-show="isAddOnOpen(addOn.id)" class="cursor-pointer">
                                                        <fa-icon icon="chevron-down" type="fas" class="h-5 w-5" />
                                                    </div>
                                                    <div @click="clickDeleteAddOn(addOn.id, addOn.label)" class="cursor-pointer ml-2">
                                                        <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                                    </div>
                                                </div>
                                            </template>
                                            <template #extra>
                                                <div v-if="isAddOnOpen(addOn.id)" class="w-full">
                                                    <wizard-table-container
                                                        :childTable="true" :hideHeader="true" :showNew="false"
                                                    >
                                                        <template #records>
                                                            <div v-for="value in addOn.values">
                                                                <edit-table-record v-if="editingAddOnId == addOn.id && editingValueId == value.id"
                                                                    fieldFormName="priceVariance" fieldId="inputEditValue" fieldPlaceholder=" " fieldType="number" 
                                                                    :fieldValue="editValueForm.priceVariance" fieldValueErrorMessage="Price Variance must be entered" :prefixText="`${value.label} Price Variance`" 
                                                                    recordClasses="pl-4 pt-3" :showChildLinePrefix="true" :validatingForm="validatingForm"
                                                                    @clickCancel="clickCancelEditValue" @clickSave="clickSaveValue" @updateFieldValue="updateEditValueFormValue"
                                                                />
                                                                <table-record v-if="!editingAddOnId || editingAddOnId != addOn.id || editingValueId != value.id">
                                                                    <template #record>
                                                                        <div class="flex items-center justify-between w-full pl-6">
                                                                            <div class="flex items-center justify-start w-full">
                                                                                <child-line-prefix />
                                                                                <div>
                                                                                    {{ value.label }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="w-24 text-right mr-12">
                                                                                {{ getPriceVariance(addOn.id, value.id) }}
                                                                            </div>
                                                                            <div class="w-12 text-center">
                                                                                <input @change="clickToggleValueSelected(addOn.id, value.id)" :checked="isValueSelected(addOn.id, value.id)" type="checkbox" class="w-4 h-4 text-orange-500 border-gray-300 rounded edit-field-text-color" :class="{ 'background-orange-500': isValueSelected(addOn.id, value.id) }">
                                                                            </div>
                                                                        </div>
                                                                        <div class="wizard-icon-container w-20">
                                                                             <div v-if="!hideIcons" @click="clickEditValue(addOn.id, value.id)" class="cursor-pointer ml-3">
                                                                                <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                                                                            </div>
                                                                            <div v-else class="ml-3 w-5">
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                </table-record>
                                                            </div>
                                                        </template>
                                                    </wizard-table-container>
                                                </div>
                                            </template>
                                        </table-record>
                                    </div>
                                </template>
                            </template>
                        </wizard-table-container>
                    </div>
                </template>
            </table-record>
        </template>
    </wizard-table-container>
</template>

<script>
import EditTableRecord from '@/wizards/components/EditTableRecord'
import TableRecord from '@/wizards/components/TableRecord'

export default {
    props: [
        'addOns',
        'hideIcons',
        'traitId',
        'traits'
    ],
    components: {
        EditTableRecord,
        TableRecord
    },
    data() {
        return {
            addingAddOn: false,
            addAddOnForm: {
                id: null,
                values: []
            },
            addOnsOpen: [],
            editingAddOnId: null,
            editingValueId: null,
            editValueForm: {
                priceVariance: null
            },
            showMe: false,
            trait: null,
            validatingForm: false
        }
    },
    computed: {
        sortedUnselectedAddOns() {
            if (!this.addOns) {
                return []
            }

            return this.addOns
                .filter((myAddOn) => !(this.trait.addOns
                    .find((myTAddOn) => myTAddOn.id == myAddOn.id))
                )
        }
    },
    mounted() {
        this.trait = this.objCopy(this.traits.find((myTrait) => myTrait.id == this.traitId))
        let myOriginalTrait = this.objCopy(this.trait)
        
        if (this?.trait?.addOns ?? false) {
            this.addOns.forEach((myAddOn) => {
                let myTraitAddOn = this.trait.addOns.find((myTAddOn) => myTAddOn.id == myAddOn.id)
                let myValues = []
                
                if (myTraitAddOn) {
                    myAddOn.values.forEach((myValue) => {
                        let myNewValue = {
                            id: myValue.id,
                            priceVariance: null,
                            selected: true
                        }

                        let myTraitValue = myTraitAddOn.values.find((myTValue) => myTValue.id == myValue.id)

                        if (myTraitValue) {
                            myNewValue.priceVariance = myTraitValue.priceVariance
                            myNewValue.selected = myTraitValue.selected
                        }

                        myValues.push(myNewValue)
                    })
                    
                    myTraitAddOn.values = myValues
                }
            })
        }
        else {
            this.trait.addOns = []
        }

        if (JSON.stringify(myOriginalTrait) != JSON.stringify(this.trait)) {
            this.$emit('updateTrait', this.trait)
        }
    },
    methods: {
        clickCancelEditValue() {
            this.editValueForm.priceVariance = null
            this.editingAddOnId = null
            this.editingValueId = null
            this.$emit('switchHideIcons', false)
        },
        clickCancelNewAddOn() {
            this.addAddOnForm.id = null
            this.addAddOnForm.values = []
            this.addingAddOn = false
            this.$emit('switchHideIcons', false)
        },
        clickDeleteAddOn(inId, inLabel) {
            if (confirm(`Confirm you wish to delete ${inLabel}?`)) {
                this.trait.addOns = this.trait.addOns.filter((myAddOn) => myAddOn.id != inId)
                this.$emit('updateTrait', this.trait)
            }
        },
        clickEditValue(inAddOnId, inId) {
            let myAddOn = this.trait.addOns.find((myAdd) => myAdd.id == inAddOnId)
            let myValue = myAddOn.values.find(((myVal) => myVal.id == inId))
            
            this.editValueForm.priceVariance = myValue.priceVariance ? this.fixProductPrice(this.sellerVendorData.defaultCurrency, myValue.priceVariance) : null
            this.editingAddOnId = inAddOnId
            this.editingValueId = inId

            this.$nextTick(() => {
                this.elGet('inputEditValue').focus()
            })

            this.$emit('switchHideIcons', true)
        },
        clickNewAddOn() {
            this.addingAddOn = true
            this.showMe = true
            this.$emit('switchHideIcons', true)
        },
        clickSaveAddOn() {
            if (this.addAddOnForm.id) {
                this.validatingForm = false
                let myAddOn = this.addOns.find((myAdd) => myAdd.id == this.addAddOnForm.id)

                myAddOn.values.forEach((myValue) => {
                    let myNewValue = {
                        id: myValue.id,
                        selected: true
                    }

                    this.addAddOnForm.values.push(myNewValue)
                })

                this.trait.addOns.push(this.objCopy(this.addAddOnForm))
                this.$emit('updateTrait', this.trait)
                this.addAddOnForm.id = null
                this.addAddOnForm.values = []

                if (!this.sortedUnselectedAddOns.length) {
                    this.addingAddOn = false
                    this.$emit('switchHideIcons', false)
                }
            }
            else {
                this.validatingForm = true
            }
        },
        clickSaveValue() {
            let myAddOn = this.trait.addOns.find((myAdd) => myAdd.id == this.editingAddOnId)
            myAddOn.values.find(((myVal) => myVal.id == this.editingValueId)).priceVariance = this.editValueForm.priceVariance
            this.$emit('updateTrait', this.trait)
            this.$emit('switchHideIcons', false)
            this.editingAddOnId = null
            this.editingValueId = null
            this.editValueForm.priceVariance = null
        },
        clickToggleShowAddOn(inId) {
            if (this.addOnsOpen.includes(inId)) {
                this.addOnsOpen = this.addOnsOpen.filter((myAddOn) => myAddOn != inId)
            }
            else {
                this.addOnsOpen.push(inId)
            }
        },
        clickToggleShowAddOns() {
            this.showMe = !this.showMe
        },
        clickToggleValueSelected(inAddOnId, inId, inSelected) {
            console.log(inSelected)
            let myAddOn = this.trait.addOns.find((myAdd) => myAdd.id == inAddOnId)
            myAddOn.values.find(((myVal) => myVal.id == inId)).selected = !this.isValueSelected(inAddOnId, inId)
            this.$emit('updateTrait', this.trait)
        },
        getPriceVariance(inAddOnId, inId) {
            let myAddOn = this.trait.addOns.find((myMod) => myMod.id == inAddOnId)
            let myValue = myAddOn.values.find(((myVal) => myVal.id == inId))
            
            return this.displayPrice(this.sellerVendorData.defaultCurrency, myValue.priceVariance && !isNaN(myValue.priceVariance) ? myValue.priceVariance : 0)
        },
        isAddOnOpen(inId) {
            return this.addOnsOpen.includes(inId)
        },
        isAddOnSelected(inId) {
            return Boolean(this.trait.addOns.find((myAddOn) => myAddOn.id == inId))
        },
        isValueSelected(inAddOnId, inId) {
            let myAddOn = this.trait.addOns.find((myAdd) => myAdd.id == inAddOnId)
            return Boolean(myAddOn.values.find((myValue) => myValue.id == inId && myValue.selected))
        },
        updateAddAddOnFormValue(inField, inValue) {
            this.addAddOnForm[inField] = inValue
        },
        updateEditValueFormValue(inField, inValue) {
            this.editValueForm[inField] = inValue
        }
    }
}
</script>