<template>
    <wizard-table-container v-if="groups"
        :childTable="true" :hideHeader="true"
    >
        <template #records>
            <edit-table-record v-if="addingGroup && addingGroupId == null"
                fieldFormName="label" fieldType="text" :fieldValue="addGroupForm.label" recordClasses="pt-3 pl-2" :showChildLinePrefix="true" 
                :validatingForm="validatingForm"
                @clickCancel="clickCancelNewGroup" @clickSave="clickSaveGroup" @updateFieldValue="updateAddGroupFormValue"
            />
            <draggable 
                animation="250" easing="cubic-bezier(1, 0, 0, 1)" group="groups" handle=".handle-media-group" item-key="index" :list="groups" tag="div" 
                @change="saveOrder()" @end="dragEnd()" @start="dragStart()"
            >
                <template #item="{element, index}">
                    <div class="w-full">
                        <table-record>
                            <template #record>
                                <div class="flex items-center justify-between w-full pl-4">
                                    <div class="flex items-center justify-start w-full">
                                        <child-line-prefix />
                                        <div>
                                            {{ element.label }}
                                        </div>
                                    </div>
                                    <div class="w-20 min-w-20 text-center">
                                        <input @change="clickToggleGroupExpandable(element.id)" :checked="isGroupExpandable(element.id)" type="checkbox" class="w-4 h-4 text-orange-500 border-gray-300 rounded edit-field-text-color" :class="{ 'background-orange-500': isGroupExpandable(element.id) }">
                                    </div>
                                    <div class="w-20 min-w-20 text-center">
                                        <input @change="clickToggleGroupEnabled(element.id)" :checked="isGroupEnabled(element.id)" type="checkbox" class="w-4 h-4 text-orange-500 border-gray-300 rounded edit-field-text-color" :class="{ 'background-orange-500': isGroupEnabled(element.id) }">
                                    </div>
                                </div>
                                <div v-if="!hideIcons" class="wizard-icon-container w-34">
                                    <div class="cursor-move handle-media-group">
                                        <fa-icon icon="up-down-left-right" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div @click="clickToggleShowGroupBlocks(element.id)" v-show="!isGroupOpen(element.id)" class="cursor-pointer ml-2">
                                        <fa-icon icon="chevron-left" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div @click="clickToggleShowGroupBlocks(element.id)" v-show="isGroupOpen(element.id)" class="cursor-pointer ml-2">
                                        <fa-icon icon="chevron-down" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div @click="clickAddGroup(index + 1)" class="cursor-pointer ml-2">
                                        <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div @click="clickEditGroup(index)" class="cursor-pointer ml-2">
                                        <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div v-if="groups.length > 1" @click="clickDeleteGroup(index)" class="cursor-pointer ml-2">
                                        <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                    </div>
                                </div>
                            </template>
                            <template #extra>
                                <edit-detail-blocks v-if="isGroupOpen(element.id)"
                                    :addingBlockIndex="addingGroupId == index ? addingBlockIndex : ''" :addTopBlock="addTopBlock" :groupIndex="index" :hideIcons="hideIcons" 
                                    :inBlocks="mediaBlocks.groups[index].blocks" :traitId="traitId"
                                    @clickEditBlock="clickEditBlock" @clickNewBlock="clickNewBlock" @resetAddTopBlock="resetAddTopBlock" @saveOrder="saveOrder" 
                                    @updateAddingBlockIndex="updateAddingBlockIndex" @updateBlocks="updateBlocks"
                                />
                            </template>
                        </table-record>
                    </div>
                </template>
            </draggable>
        </template>
    </wizard-table-container>
</template>

<script>
import draggable from 'vuedraggable'

import EditDetailBlocks from './EditDetailBlocks'
import EditTableRecord from '@/wizards/components/EditTableRecord'
import TableRecord from '@/wizards/components/TableRecord'

export default {
    props: [
        'addingBlockIndex',
        'addingGroupId',
        'addTopGroup',
        'hideIcons',
        'inMediaBlocks',
        'traitId'
    ],
    components: {
        draggable,
        EditDetailBlocks,
        EditTableRecord,
        TableRecord
    },
    data() {
        return {
            addGroupForm: {
                id: null,
                enabled: false,
                expandable: false,
                label: null
            },
            addingGroup: false,
            addTopBlock: false,
            dragging: false,
            groups: null,
            mediaBlocks: null,
            showGroups: [],
            validatingForm: false
        }
    },
    watch: {
        addTopGroup: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.clickAddGroup(null)
                    this.$emit('resetAddTopGroup')
                }
            }
        },
        inMediaBlocks: {
            handler(val) {
                this.mediaBlocks = this.objCopy(this.inMediaBlocks)
                this.groups = this.mediaBlocks.groups
            }
        }
    },
    mounted() {
        if (!this.inMediaBlocks.groups) {
            this.mediaBlocks = {
                groups: [
                    {
                        blocks: [],
                        enabled: true,
                        expandable: false,
                        id: 1,
                        label: "Default Group"
                    }
                ]
            }
        }
        else {
            this.mediaBlocks = this.objCopy(this.inMediaBlocks)
        }

        this.groups = this.mediaBlocks.groups
        this.$emit('updateMediaBlocks', this.mediaBlocks)
    },
    methods: {
        clickAddGroup(inId) {
            this.addingGroup = true
            this.updateAddingGroupId(inId, this.traitId)
            this.$emit('switchHideIcons', true)
        },
        clickCancelNewGroup() {
            this.addingGroup = false
            this.addGroupForm.label = null
            this.updateAddingGroupId(null, null)
            this.$emit('switchHideIcons', false)
        },
        clickDeleteGroup(inIndex) {

        },
        clickEditGroup(inIndex) {

        },
        clickSaveGroup() {
            
        },
        clickToggleGroupEnabled(inId) {
            let myGroup = this.groups.find((myG) => myG.id == inId)
            myGroup.enabled = !myGroup.enabled
            this.$emit('updateGroup', myGroup)
        },
        clickToggleGroupExpandable(inId) {
            let myGroup = this.groups.find((myG) => myG.id == inId)
            myGroup.expandable = !myGroup.expandable
            this.$emit('updateGroup', myGroup)
        },
        clickToggleShowGroupBlocks(inId) {
            if (this.showGroups.includes(inId)) {
                this.showGroups = this.showGroups.filter((myGroup) => myGroup != inId)
            }
            else {
                this.showGroups.push(inId)
            }
        },
        dragEnd() {

        },
        dragStart() {

        },
        isGroupEnabled(inId) {
            return this.groups.find((myGroup) => myGroup.id == inId).enabled
        },
        isGroupExpandable(inId) {
            return this.groups.find((myGroup) => myGroup.id == inId).expandable
        },
        isGroupOpen(inId) {
            return this.showGroups.includes(inId)
        },
        resetAddTopBlock() {
            this.addTopBlock = false
        },
        saveOrder() {

        },
        updateAddGroupFormValue(inField, inValue) {
            this.addGroupForm[inField] = inValue
        },
        updateAddingBlockIndex(inIndex, inGroupId, inTraitId) {
            this.$emit('updateAddingBlockIndex', inIndex, inGroupId, inTraitId)
        },
        updateAddingGroupId(inId, inTraitId) {
            this.$emit('updateAddingGroupId', inId, inTraitId)
        },
        updateBlocks(inBlocks, inGroupId) {
            this.$emit('updateBlocks', inBlocks, inGroupId)
        }
    }
}
</script>