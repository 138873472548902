<template>
    <div class="pl-2 pr-1 text-gray-700">
        <fa-icon icon="arrow-right" type="fas" class="h-2 w-2" />
    </div>
</template>

<script>
export default {
    
}
</script>