<template>
    <div class="w-full">
        <select-panel 
            :fieldData="themeData" fieldFormName="theme" fieldLabel="Theme" fieldLabelClasses="text-gray-800" :fieldRequired="true" 
            :fieldValue="sellerWhiteLabelDataForm.theme" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateSellerWhiteLabelDataFormSelectValue"
        >
            <validation-message v-if="validating && !sellerWhiteLabelDataForm.theme" 
                message="Theme must be selected"
            />
        </select-panel>
        <color-picker-panel 
            fieldFormName="backgroundColor" fieldLabel="Background Color" fieldLabelClasses="text-gray-800" :fieldRequired="true" 
            :fieldValue="sellerWhiteLabelDataForm.backgroundColor"
            @fieldChange="updateSellerWhiteLabelDataFormValue"
        >
            <validation-message v-if="validating && sellerWhiteLabelDataForm.backgroundColor" 
                message="Background Color must be selected"
            />
        </color-picker-panel>
        <color-picker-panel 
            fieldFormName="primaryColor" fieldLabel="Text Color" fieldLabelClasses="text-gray-800" :fieldRequired="true" 
            :fieldValue="sellerWhiteLabelDataForm.primaryColor"
            @fieldChange="updateSellerWhiteLabelDataFormValue"
        >
            <validation-message v-if="validating && sellerWhiteLabelDataForm.primaryColor" 
                message="Text Color must be selected"
            />
        </color-picker-panel>
        <color-picker-panel 
            fieldFormName="secondaryColor" fieldLabel="Button & Link Color" fieldLabelClasses="text-gray-800" :fieldRequired="true" 
            :fieldValue="sellerWhiteLabelDataForm.secondaryColor"
            @fieldChange="updateSellerWhiteLabelDataFormValue"
        >
            <validation-message v-if="validating && sellerWhiteLabelDataForm.secondaryColor" 
                message="Button & Link Colors must be selected"
            />
        </color-picker-panel>
        <color-picker-panel 
            fieldFormName="secondaryTextColor" fieldLabel="Button Text Color" fieldLabelClasses="text-gray-800" :fieldRequired="true" 
            :fieldValue="sellerWhiteLabelDataForm.secondaryTextColor"
            @fieldChange="updateSellerWhiteLabelDataFormValue"
        >
            <validation-message v-if="validating && sellerWhiteLabelDataForm.secondaryTextColor" 
                message="Button Text Color must be selected"
            />
        </color-picker-panel>
        <color-picker-panel 
            fieldFormName="headerColor" fieldLabel="Header Color" fieldLabelClasses="text-gray-800" :fieldRequired="true" 
            :fieldValue="sellerWhiteLabelDataForm.headerColor"
            @fieldChange="updateSellerWhiteLabelDataFormValue"
        >
            <validation-message v-if="validating && sellerWhiteLabelDataForm.headerColor" 
                message="Header Color must be selected"
            />
        </color-picker-panel>
        <color-picker-panel 
            fieldFormName="headerTextColor" fieldLabel="Header Text Color" fieldLabelClasses="text-gray-800" :fieldRequired="true" 
            :fieldValue="sellerWhiteLabelDataForm.headerTextColor"
            @fieldChange="updateSellerWhiteLabelDataFormValue"
        >
            <validation-message v-if="validating && sellerWhiteLabelDataForm.headerTextColor" 
                message="Header Text Color must be selected"
            />
        </color-picker-panel>
        <color-picker-panel 
            fieldFormName="headerLinkColor" fieldLabel="Header Link Color" fieldLabelClasses="text-gray-800" :fieldRequired="true" 
            :fieldValue="sellerWhiteLabelDataForm.headerLinkColor"
            @fieldChange="updateSellerWhiteLabelDataFormValue"
        >
            <validation-message v-if="validating && sellerWhiteLabelDataForm.headerLinkColor" 
                message="Header Link Color must be selected"
            />
        </color-picker-panel>
    </div>
</template>

<script>
export default {
    props: [
        'sellerWhiteLabelDataForm',
        'validating'
    ],
    computed: {
        themeData() {
            return {
                Light: "light",
                Dark: "dark"
            }
        }
    },
    methods: {
        updateSellerWhiteLabelDataFormSelectValue(inField, inValue) {
            this.$emit('updateSellerWhiteLabelDataFormSelectValue', inField, inValue)
        },
        updateSellerWhiteLabelDataFormValue(inField, inValue) {
            this.$emit('updateSellerWhiteLabelDataFormValue', inField, inValue)
        }
    }
}
</script>