<template>
    <wizard-container
        :openConditions="wizardData && sellerWhiteLabelDataForm"
        @closeWizard="closeWhiteLabelWizard"
    >
        <template #main>
            <wizard-page v-if="formLoaded && sellerReady"
                :checkNonValidated="checkNonValidated" :currentPage="currentPage" :hideLogo="false" :hidePrevious="false" :hideProgress="false" 
                :ignorePages="ignorePages" :introPages="introPages" :myPage="currentPage" :outroPages="outroPages" :pages="pages" :startPage="startPage" 
                :wizardData="wizardData"
                @jumpToPage="jumpToPage" @nextPage="nextPage" @previousPage="previousPage" @resetCheckNonValidated="resetCheckNonValidated"
            >
                <template #main>
                    <page01 v-if="currentPage == 1"
                        :logoArray="logoArray" :validating="validating"
                        @logoSavedOkay="logoSavedOkay" @saveLocalLogo="saveLocalLogo" @updateOfflineLogo="updateOfflineLogo"
                    />
                    <page02 v-if="currentPage == 2"
                        :favIconArray="favIconArray" :validating="validating"
                        @favIconSavedOkay="favIconSavedOkay" @saveLocalFavIcon="saveLocalFavIcon" @updateOfflineFavIcon="updateOfflineFavIcon"
                    />
                    <page03 v-if="currentPage == 3"
                        :sellerWhiteLabelDataForm="sellerWhiteLabelDataForm" :validating="validating"
                        @updateSellerWhiteLabelDataFormValue="updateSellerWhiteLabelDataFormValue"
                    />
                    <page04 v-if="currentPage == 4"
                        :sellerWhiteLabelDataForm="sellerWhiteLabelDataForm" :validating="validating"
                        @updateSellerWhiteLabelDataFormSelectValue="updateSellerWhiteLabelDataFormSelectValue" @updateSellerWhiteLabelDataFormValue="updateSellerWhiteLabelDataFormValue"
                    />     
                </template>
                <template #popups>
                </template>
            </wizard-page>
        </template>
    </wizard-container>
</template>

<script>
import {debounce} from 'lodash'

import Page01 from './pages/Page01'
import Page02 from './pages/Page02'
import Page03 from './pages/Page03'
import Page04 from './pages/Page04'
import WizardPage from '../WizardPage'

export default {
    components: {
        Page01,
        Page02,
        Page03,
        Page04,
        WizardPage
    },
    data() {
        return {
            checkNonValidated: false,
            currentPage: 1,
            favIconGallery: [],
            favIcon: null,
            formLoaded: false,
            ignorePages: [],
            introPages: 0,
            logoGallery: [],
            logoImage: null,
            outroPages: 0,
            sellerWhiteLabelDataForm: {
                backgroundColor: null,
                headerColor: null,
                headerLinkColor: null,
                headerTextColor: null,
                linked_domain: null,
                primaryColor: null,
                secondaryColor: null,
                secondaryTextColor: null,
                theme: null
            },
            sellerId: null,
            sellerReady: false,
            startPage: null,
            validating: false,
            wizardData: null
        }
    },
    computed: {
        favIconArray() {
            if (this.favIconGallery && this.favIconGallery.length) {
                let myGallery = []

                this.favIconGallery.forEach((myImage) => {
                    myGallery.push(myImage)
                })

                return myGallery
            }
            else {
                return this.favIcon ? [ 
                    {
                        url: this.favIcon,
                        type: 'image'
                    }
                ] : []
            }
        },
        finalPageButtonText() {
            return this.wizardEditingTempSeller ? "Make Your New Seller Live" : "Close"
        },
        logoArray() {
            if (this.logoGallery && this.logoGallery.length) {
                let myGallery = []

                this.logoGallery.forEach((myImage) => {
                    myGallery.push(myImage)
                })

                return myGallery
            }
            else {
                return this.logoImage ? [ 
                    {
                        url: this.logoImage,
                        type: 'image'
                    }
                ] : []
            }
        },
        pages() {
            return {
                1: {
                    next: 2,
                    previous: false,
                    validation: Boolean(this.logoArray && this.logoArray.length)
                },
                2: {
                    next: 3,
                    previous: false,
                    validation: Boolean(this.favIconArray && this.favIconArray.length)
                },
                3: {
                    next: 4,
                    previous: 2,
                    validation: true
                },
                4: {
                    next: 4,
                    nextText: "Close",
                    previous: 3,
                    validation: this.sellerWhiteLabelDataForm.backgroundColor != null && this.sellerWhiteLabelDataForm.headerColor != null && this.sellerWhiteLabelDataForm.headerLinkColor != null && this.sellerWhiteLabelDataForm.headerTextColor != null && this.sellerWhiteLabelDataForm.primaryColor != null && this.sellerWhiteLabelDataForm.secondaryColor != null && this.sellerWhiteLabelDataForm.secondaryTextColor != null && this.sellerWhiteLabelDataForm.theme != null
                }
            }
        }
    },
    watch: {
        sellerVendor: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.setStateData([
                        { tempSellerVendorData: this.sellerVendorData },
                        { tempSellerVendor: this.sellerVendor },
                        { tempSellerVendorWhiteLabelData: this.sellerVendorWhiteLabelData }
                    ])

                    this.sellerReady = true
                }
            }
        },
        tempSellerVendorWhiteLabelData: {
            immediate: true,
            handler(val) {
                if (!this.sellerId) {
                    if (val) {
                        this.sellerId = this.tempSellerVendor.id
                        this.sellerWhiteLabelDataForm.backgroundColor = this?.tempSellerVendorWhiteLabelData?.backgroundColor ?? null
                        this.favIcon = this?.tempSellerVendorWhiteLabelData?.favIcon ?? null
                        this.favIconGallery = this?.tempSellerVendorWhiteLabelData?.favIconGallery ?? []
                        this.logoImage = this?.tempSellerVendorWhiteLabelData?.logo ?? null
                        this.logoGallery = this?.tempSellerVendorWhiteLabelData?.logoGallery ?? []
                        this.sellerWhiteLabelDataForm.linked_domain = this.tempSellerVendor.linked_domain
                        this.sellerWhiteLabelDataForm.headerColor = this?.tempSellerVendorWhiteLabelData?.headerColor ?? null
                        this.sellerWhiteLabelDataForm.headerLinkColor = this?.tempSellerVendorWhiteLabelData?.headerLinkColor ?? null
                        this.sellerWhiteLabelDataForm.headerTextColor = this?.tempSellerVendorWhiteLabelData?.headerTextColor ?? null
                        this.sellerWhiteLabelDataForm.primaryColor = this?.tempSellerVendorWhiteLabelData?.primaryColor ?? null
                        this.sellerWhiteLabelDataForm.secondaryColor = this?.tempSellerVendorWhiteLabelData?.secondaryColor ?? null
                        this.sellerWhiteLabelDataForm.secondaryTextColor = this?.tempSellerVendorWhiteLabelData?.secondaryTextColor ?? null
                        this.sellerWhiteLabelDataForm.theme = this?.tempSellerVendorWhiteLabelData?.theme ?? null
                        
                    }
                    else {
                        this.sellerId = null
                        this.favIcon = null
                        this.favIconGallery = []
                        this.logoImage = null
                        this.logoGallery = []
                        this.sellerWhiteLabelDataForm.linked_domain = null
                        this.sellerWhiteLabelDataForm.backgroundColor = null
                        this.sellerWhiteLabelDataForm.headerColor = null
                        this.sellerWhiteLabelDataForm.headerLinkColor = null
                        this.sellerWhiteLabelDataForm.headerTextColor = null
                        this.sellerWhiteLabelDataForm.primaryColor = null
                        this.sellerWhiteLabelDataForm.secondaryColor = null
                        this.sellerWhiteLabelDataForm.secondaryTextColor = null
                        this.sellerWhiteLabelDataForm.theme = null
                    }
                }
            }
        },
        wizardWhiteLabelData: {
            immediate: true,
            handler(val) {
                this.wizardData = this.wizardWhiteLabelData
            }
        }
    },
    mounted() {
        if (this.wizardWhiteLabelStartPage) {
            this.startPage = this.wizardWhiteLabelStartPage
        }

        this.formLoaded = true
    },
    beforeUnmount() {
        this.setStateData([
            { wizardEditingTempSeller: false },
            { wizardWhiteLabelStartPage: null },
            { tempSellerVendorData: null },
            { tempSellerVendor: null },
            { tempSellerVendorWhiteLabelData: null }
        ])
    },
    methods: {
        checkValidation() {
            this.checkNonValidated = true
        },
        favIconSavedOkay(inImage, inIndex) {
            this.favIconGallery[inIndex] = inImage
            this.favIcon = inImage.url
        },
        logoSavedOkay(inImage, inIndex) {
            this.logoGallery[inIndex] = inImage
        },
        async getTempSeller() {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.get(this.getRoute('seller'), 
                {
                    params: {
                        vendor_id: this.loggedUser.temp_vendor_id
                    }
                })
                .then((response) => {
                    this.setStateData([
                        { tempSellerVendorData: response.data.marketplace_vendor_data },
                        { tempSellerVendor: response.data.marketplace_vendor },
                        { tempSellerVendorWhiteLabelData: response.data.marketplace_vendor_white_label_data }
                    ])

                    this.sellerReady = true
                    resolve(true)
                })
                .catch((error) => {
                    console.log('error getting temp seller')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        jumpToPage(inPageNumber) {
            this.currentPage = inPageNumber
        },
        nextPage() {
            if (this.pages[this.currentPage].validation) {
                this.validating = false

                if (this.currentPage == 4) {
                    this.closeWhiteLabelWizard()
                }
                else {
                    this.currentPage = this.pages[this.currentPage].next
                }
            }
            else {
                this.validating = true
            }
        },
        previousPage() {
            this.currentPage = this.pages[this.currentPage].previous
        },
        resetCheckNonValidated() {
            this.checkNonValidated = false
        },
        save() {
            this.saveStart()
            this.checkValidation()

            let myData = this.sellerWhiteLabelDataForm
            myData.vendor_id = this.sellerId

            buildzAxios?.post(this.getRoute('seller'), this.objCopy(myData))
                .then((response) => {
                    this.saveDone()

                    this.setStateData([
                        { sellerVendor: response.data.marketplace_vendor },
                        { sellerVendorData: response.data.marketplace_vendor_data },
                        { sellerVendorWhiteLabelData: response.data.marketplace_vendor_white_label_data }
                    ])
                })
                .catch((error) => {
                    console.log(error)
                    this.saveDone()
                })
        },
        saveDebounce: debounce(function() {
            this.save()
        }, 2000),
        async saveLocalFavIcon(inGallery) {
            this.favIcon = null
            this.favIconGallery = inGallery
            await this.postGallery('sellerFavIcon', inGallery, this.sellerId)
        },
        async saveLocalLogo(inGallery) {
            this.logoImage = null
            this.logoGallery = inGallery
            await this.postGallery('sellerLogo', inGallery, this.sellerId)
        },
        async updateOfflineFavIcon(inGallery) {
            this.favIcon = null
            this.favIconGallery = inGallery
            let myTempGallery = await this.buildOfflineTempGallery(inGallery, `sellerFavIcon_temp${this.sellerId}`)
            this.tempFavIconGallery = myTempGallery
            
            setTimeout(() => {
                this.saveGalleryPrep(this.tempFavIconGallery, 'sellerFavIconUpdate', this.sellerId, 'sellerFavIcon')
            }, 100)
        },
        async updateOfflineLogo(inGallery) {
            this.logoImage = null
            this.logoGallery = inGallery
            let myTempGallery = await this.buildOfflineTempGallery(inGallery, `sellerLogo_temp${this.sellerId}`)
            this.tempLogoGallery = this.objCopy(myTempGallery)
            
            setTimeout(() => {
                this.saveGalleryPrep(this.tempLogoGallery, 'sellerLogoUpdate', this.sellerId, 'sellerLogo')
            }, 100)
        },
        updateSellerWhiteLabelDataFormSelectValue(inField, inValue) {
            this.sellerWhiteLabelDataForm[inField] = inValue
            this.save()
        },
        updateSellerWhiteLabelDataFormValue(inField, inValue) {
            this.sellerWhiteLabelDataForm[inField] = inValue
            this.saveDebounce()
        }
    }
}
</script>