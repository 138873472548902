<template>
    <div class="w-full white-transparent mt-px" :class="{ 'py-3 px-2': !fieldNude }">
        <div v-if="!hideLabel" class="w-full">
            <panel-label 
                :fieldLabel="fieldLabel" :fieldLabelClasses="fieldLabelClasses" :fieldRequired="fieldRequired" :forceMobile="true"
            />
        </div>
        <div class="flex text-center items-center justify-center w-full text-black">
            <ColorPicker
                :alpha-channel="fieldShowAlpha ? 'show' : 'hide'"
                :color="fieldModel"
                :visible-formats="['rgb', 'hex']"
                @color-change="fieldChange"
            />
        </div>
        <slot /> 
    </div>
</template>

<script>
import { ColorPicker } from 'vue-accessible-color-picker'

export default {
    props: [
        'fieldFormName', 
        'fieldFormSubObjectName', 
        'fieldLabel',
        'fieldLabelClasses',
        'fieldNude', 
        'fieldRequired', 
        'fieldShowAlpha',
        'fieldValue',
        'hideLabel'
    ],
    components: {
        ColorPicker
    },
    data() {
        return {
            fieldModel: null,
            formLoaded: false
        }
    },
    mounted() {
        this.fieldModel = this.fieldValue
        this.formLoaded = true
    },
    methods: {
        fieldChange(eventData) {
            if (eventData.cssColor != this.fieldValue) {
                this.$emit('fieldChange', this.fieldFormName, eventData.cssColor, this.fieldFormSubObjectName)
            }
        }
    }
}
</script>