import moment from 'moment'

const buildzMixins = {
    computed: {
        bzLogo() {
            return this.buildzLogo
        },
        imSpecial() {
            return this.loggedUser && (this.loggedUser.name == 'Mike test' || this.loggedUser.name == 'Glenn Test' || this.loggedUser.name == 'Mike Laverick' || this.loggedUser.name == 'Glenn Hodges')
        },
        isAppLaunchUrlReady() {
            return this.isApp && this.isLoggedIn && this.isRealGlobalData
        },
        isDesktopWidth() {
            return this.windowWidth > 800
        },
        tabLeft() {
            return this.mainPartWidth < this.desktopMaxWidth + 1 ? '0px' : parseInt((this.mainPartWidth - this.desktopMaxWidth) / 2) + 'px'
        },
        tabRight() {
            return this.mainPartWidth < this.desktopMaxWidth + 1 ? '0px' : parseInt((this.mainPartWidth - this.desktopMaxWidth) / 2) + 'px'
        }      
    },
    methods: {
        addOption(inSelect, inValue, inText, inSelected = false, inIndex = null) {
            var newOption = document.createElement('option')
            var optionText = document.createTextNode(inText)
            newOption.appendChild(optionText)
            newOption.setAttribute('value', inValue)

            if (inIndex != null) {
                newOption.setAttribute('data-index', inIndex)
            }

            if (inSelected) {
                newOption.selected = true
            }

            inSelect.appendChild(newOption)
        },
        capitalizeFirstLetter(inString) {
            return inString.charAt(0).toUpperCase() + inString.slice(1)
        },
        capitalizeWordsFirstLetter(inString) {
            let myNewString = ""
            let myAr = inString.split(" ")
            let myNewAr = []

            myAr.forEach((myWord) => {
                myNewAr.push(myWord.charAt(0).toUpperCase() + myWord.slice(1))
            })

            myNewString = myNewAr.join(" ")
            myAr = myNewString.split("-")
            myNewAr = []

            myAr.forEach((myWord) => {
                myNewAr.push(myWord.charAt(0).toUpperCase() + myWord.slice(1))
            })

            myNewString = myNewAr.join("-")

            return myNewString
        },
        clearSelected(inSelect) {
            var selectArray = []

            if (Array.isArray(inSelect)) {
                for (var i = 0, len = inSelect.length; i < len; i++) {
                    if (this.elGet(inSelect[i])) {
                        selectArray.push(this.elGet(inSelect[i]))
                    }
                }
            }
            else {
                selectArray.push(inSelect)
            }

            for (var i = 0, len = selectArray.length; i < len; i++) {
                for (var op = 0; op < selectArray[i].options.length; op++) {
                    selectArray[i].options.selected = false
                }
            }
        },
        closeAllModals() {
            this.setStateData([
                { showBadgeModal: false },
                { showBadgePopup: false },
                { showBadgeUnlockModal: false },
                // { showMembershipModal: false },
                { showNewBuildModal: false },
                { showNewChatModal: false },
                // { showNewEventModal: false },
                { showNewGroupModal: false },
                { showOnboardingSubCategoriesModal: false },
                { showShareIntentModal: false },
                { showSpinner: false },
                { showWriteActivity: false },
                { showWritePostReaction: false }
            ])
        },
        elGet(inId) {
            return document.getElementById(inId)
        },
        getCookie(inCookieName) {
            let name = `${inCookieName}=`
            let decodedCookie = decodeURIComponent(document.cookie)
            let ca = decodedCookie.split(';')

            for (let i = 0; i <ca.length; i++) {
              let c = ca[i]

              while (c.charAt(0) == ' ') {
                c = c.substring(1)
              }

              if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length)
              }
            }

            return ""
        },
        getFileFromUrl(inFileUrl, callback) {
            var xhr = new XMLHttpRequest()

            xhr.onload = function () {
                callback(xhr.response)
            }

            xhr.open('GET', inFileUrl)
            xhr.responseType = 'blob'
            xhr.send()
        },   
        getFirstArrayKey(inArray) {
            return inArray && inArray.length > 0 ? inArray[0] : null
        },
        getFirstObjectKey(inObject) {
            return inObject && Object.keys(inObject) && Object.keys(inObject).length > 0 ? Object.keys(inObject)[0] : null
        },
        getLastArrayKey(inArray) {
            return inArray && inArray.length > 0 ? inArray[inArray.length - 1] : null
        },
        getLastObjectKey(inObject) {
            return inObject && Object.keys(inObject) && Object.keys(inObject).length > 0 ? Object.keys(inObject)[Object.keys(inObject).length - 1] : null
        },
        getRandomString(inLength) {
            var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()'
            var charLength = chars.length
            var result = ''

            for ( var i = 0; i < inLength; i++ ) {
                result += chars.charAt(Math.floor(Math.random() * charLength))
            }

            return result
        },
        getRect(inId) {
            return this.elGet(inId) ? this.elGet(inId).getBoundingClientRect() : new DOMRect(0, 0, 0, 0)
        },
        getRectEl(inElement) {
            return inElement ? inElement.getBoundingClientRect() : new DOMRect(0, 0, 0, 0)
        },
        hasRecords(inArray) {
            return inArray && inArray.length
        },
        isTestServer() {
            let url = window.location.href

            if (url.includes('apptest.buildz') || url.includes('whitetest.buildz') || url.includes('localhost')) {
                return true
            }

            return false
        },
        isValidEmailAddress(inAddress) {
            let mailformat = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

            return inAddress.match(mailformat)
        },
        isValidURL(inString) {
            var pattern = new RegExp('^(https?:\\/\\/)'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;:&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[.-a-z\\d_]*)?$','i'); // fragment locator
                
            return !!pattern.test(inString)
        },
        nlToBr(inString) {
            return (inString).split('\n')
                .map(item => `${item}<br/>`)
                .join('')
        },
        nlToBrSpace(inString) {
            return (inString).split('\n')
                .map(item => `${item} <br/> `)
                .join('')
        },
        nlToP(inString) {
            return (inString).split('\n')
                .filter(item => item.length > 0)
                .map(item => `<p>${item}</p>`)
                .join('')
        },
        objCopy(inObject) {
            try{
                return JSON.parse(JSON.stringify(inObject))
            }
            catch(e){
                return null
            }
            
        },
        objLength(inObject) {
            return inObject ? (Array.isArray(inObject) ? inObject.length : Object.keys(inObject).length) : 0
        },
        openNewTabLink(inURL) {
            window.open(inURL, '_blank')
            // Object.assign(document.createElement('a'), { target: '_blank', href: inURL }).click()
        },
        pageMessage(inLevel, inResult, inHelp = null) {
            this.setStateData([
                { 
                    systemMessage: 
                    {
                        'level': inLevel,
                        'result': inResult,
                        'help': inHelp
                    }
                }
            ])
        },
        removeOptions(inSelect, inKeepFirst, inKeepSecond) {
            var myMin = inKeepSecond? 2 : (inKeepFirst ? 1 : 0)
            var selectArray = []

            if (Array.isArray(inSelect)) {
                for (var i = 0, len = inSelect.length; i < len; i++) {
                    if (this.elGet(inSelect[i])) {
                        selectArray.push(this.elGet(inSelect[i]))
                    }
                }
            }
            else {
                selectArray.push(inSelect)
            }

            for (var i = 0, len = selectArray.length; i < len; i++) {
                while (selectArray[i].options && selectArray[i].options.length > myMin) {
                    selectArray[i].remove(myMin)
                }
            }
        },
        back() {
            window.history.back()
        },
        forward() {
            window.history.forward()
        },
        signupEvent(inType) {
            this.gaEventPush({
                'event': `NewSignup`,
                'label': inType,
                'value': inType
            })
        },
		stringToDate(dateString) {
			return moment.utc(dateString).local().format("DD/MM/YYYY HH:mm")
		},
        stripString(inString) {
            return inString.replace(/[\W_]+/g,"")
        },
        unixTimeToDate(dateString) {
            return moment.unix(dateString).format("DD/MM/YYYY");
		}
    }
}

export default buildzMixins