<template>
    <div class="w-full">
        <select-panel
            :fieldData="yesNoData" fieldFormName="has_variants" fieldLabel="Does This Product Have Variants?" fieldLabelClasses="text-gray-800" 
            :fieldRequired="true" :fieldValue="productForm.has_variants" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateProductFormSelectValue"
        >
            <validation-message v-if="validating && productForm.has_variants == null" 
                message="Select yes or no"
            />
        </select-panel>
        <div v-if="productForm.has_variants" class="w-full px-2 text-sm">
            <div class="w-full bg-orange-200">
                <div class="flex items-center justfy-between w-full py-2">
                    <div class="pl-2 text-left font-semibold w-full">
                        Modifiers
                    </div>
                    <div v-if="sortedVendorVariants && vendorVariants && sortedVendorVariants.length != vendorVariants.length" class="flex items-center justify-end text-gray-600 pr-2">
                        <div @click="clickNewVariant()" class="cursor-pointer">
                            <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                </div>
                <div v-for="vendorVariant in sortedVendorVariants" class="mt-px w-full orange-transparent">
                    <div class="flex justify-between items-center w-full py-3">
                        <div @click="clickToggleShowVendorVariant(vendorVariant.identifier)" class="pl-2">
                            {{ vendorVariant.label }}
                        </div>
                        <div class="wizard-icon-container">
                            <div @click="clickToggleShowVendorVariant(vendorVariant.identifier)" v-show="!isVendorVariantOpen(vendorVariant.identifier)" class="cursor-pointer">
                                <fa-icon icon="chevron-left" type="fas" class="h-5 w-5" />
                            </div>
                            <div @click="clickToggleShowVendorVariant(vendorVariant.identifier)" v-show="isVendorVariantOpen(vendorVariant.identifier)" class="cursor-pointer">
                                <fa-icon icon="chevron-down" type="fas" class="h-5 w-5" />
                            </div>
                            <div @click="clickEditVariant(vendorVariant.identifier)" class="cursor-pointer ml-2">
                                <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                            </div>
                            <div @click="clickDeleteVariant(vendorVariant.identifier, vendorVariant.label)" class="cursor-pointer ml-2">
                                <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                            </div>
                        </div>
                    </div>
                    <div v-if="isVendorVariantOpen(vendorVariant.identifier)" class="w-full">
                        <draggable
                            animation="250" easing="cubic-bezier(1, 0, 0, 1)" handle=".handle-value" item-key="index" :list="getRealVariantValues(vendorVariant.identifier)" tag="div" 
                            @change="saveOrder()"
                        >
                            <template #item="{element, index}">
                                <div class="flex items-center justify-between w-full orange-transparent py-2 mt-px">
                                    <div class="flex items-center justify-start w-full pl-2">
                                        <child-line-prefix />
                                        <div>
                                            {{ element.label }}
                                        </div>
                                    </div>
                                    <div class="wizard-icon-container">
                                        <div class="cursor-move handle-value">
                                            <fa-icon icon="up-down-left-right" type="fas" class="h-5 w-5" />
                                        </div>
                                        <div @click="clickDeleteValue(element.identifier, element.label, vendorVariant.identifier)" class="cursor-pointer ml-2">
                                            <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </draggable>
                    </div>
                </div>
            </div>
            <validation-message v-if="validating && !sortedVendorVariants.length" 
                message="You must create at least one Modifier"
            />
            <div v-if="productVariants.theVariants && productVariants.theVariants.length" class="w-full bg-orange-200 mt-2">
                <div class="w-full">
                    <div class="flex items-center justify-between w-full font-semibold py-2">
                        <div class="flex items-center justify-between w-full">
                            <div class="pl-2 text-left">
                                Details
                            </div>
                            <div v-if="productForm.track_quantity" class="w-20 text-center">
                                Stock
                            </div>
                        </div>
                        <div class="w-24 text-center">
                            Price
                        </div>
                        <div class="w-6 mx-2">
                        </div>
                    </div>
                    <div v-for="variantVariant in sortedTheVariants" class="flex items-center justify-between w-full mt-px py-3" :class="{ 'orange-transparent': variantVariant.enabled, 'red-transparent': !variantVariant.enabled }">
                        <div class="flex items-center justify-between w-full">
                            <div class="pl-2 text-left">
                                <div>
                                    {{ getTheVariantLabel(variantVariant) }}
                                </div>
                                <div v-if="variantVariant.sku">
                                    {{ variantVariant.sku }}
                                </div>
                            </div>
                            <div v-if="productForm.track_quantity" class="w-20 text-center">
                                {{ variantVariant.qty }}
                            </div>
                        </div>
                        <div class="w-24 text-center">
                            {{ displayPrice(sellerVendorData.defaultCurrency, variantVariant.overridePrice ?? getTheVariantPrice(variantVariant)) }}
                        </div>
                        <div @click="clickEditTheVariant(variantVariant.identifier, getTheVariantPrice(variantVariant))" class="cursor-pointer mx-2 text-gray-600">
                            <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    props: [
        'productForm',
        'productVariants',
        'refreshVariantValues',
        'validating'
    ],
    components: {
        draggable
    },
    data() {
        return {
            formLoaded: false,
            variantValues: null,
            vendorVariants: null,
            vendorVariantsOpen: []
        }
    },
    computed: {
        sortedTheVariants() {
            if (!this.productVariants.theVariants) {
                return []
            }

            return this.productVariants.theVariants.sort((a, b) => a.identifier < b.identifier ? -1 : 1)
        },
        sortedVendorVariants() {
            if (!this.vendorVariants) {
                return []
            }

            return this.vendorVariants.filter((myVariant) => myVariant.selected)
        }
    },
    watch: {
        refreshVariantValues: {
            handler(val) {
                if (val) {
                    this.variantValues = this.objCopy(this.productVariants.variantValues)
                    this.$emit('resetRefreshVariantValues')
                }
            }
        }
    },
    mounted() {
        let myVendorVariants = []

        this.sellerVendor.product_variants.forEach((mySellerVariant) => {
            let mySelected = false

            if (this.productVariants && this.productVariants.vendorVariants) {
                let myVariant = this.productVariants.vendorVariants.find((myVariant) => myVariant.identifier == mySellerVariant.identifier)
                mySelected = myVariant?.selected ?? false
            }

            let myNewVariant = {
                identifier: mySellerVariant.identifier,
                label: mySellerVariant.label,
                selected: mySelected
            }

            myVendorVariants.push(myNewVariant)
        })

        this.vendorVariants = myVendorVariants
        this.variantValues = this.objCopy(this.productVariants.variantValues)
        this.formLoaded = true
        this.$emit('updateVendorVariants', this.vendorVariants)
    },
    methods: {
        clickDeleteValue(inIdentifier, inLabel, inVariantIdentifier) {
            if (confirm(`Confirm you wish to delete ${inLabel}?`)) {
                let myVariantValues = this.variantValues.find((myValues) => myValues.identifier == inVariantIdentifier)

                if (myVariantValues) {
                    myVariantValues.values = myVariantValues.values.filter((myValue) => myValue.identifier != inIdentifier)
                    this.$emit('updateVariantValues', this.variantValues, true)
                }
            }
        },
        clickDeleteVariant(inIdentifier, inLabel) {
            if (confirm(`Confirm you wish to delete ${inLabel}?`)) {
                this.$emit('clickDeleteVariant', inIdentifier)
            }
        },
        clickEditTheVariant(inIdentifier, inPrice) {
            this.$emit('clickEditTheVariant', inIdentifier, inPrice)
        },
        clickEditVariant(inIdentifier) {
            this.$emit('clickEditVariant', inIdentifier)
        },
        clickNewVariant() {
            this.$emit('clickNewVariant')
        },
        clickToggleShowVendorVariant(inIdentifier) {
            if (!this.vendorVariantsOpen.includes(inIdentifier)) {
                this.vendorVariantsOpen.push(inIdentifier)
            }
            else {
                this.vendorVariantsOpen = this.vendorVariantsOpen.filter((myVariant) => myVariant != inIdentifier)
            }
        },
        getRealVariantValues(inIdentifier) {
            if (this.variantValues) {
                let myVariantValues = this.variantValues.find((myValues) => myValues.identifier == inIdentifier)

                if (myVariantValues) {
                    return this.variantValues.find((myValues) => myValues.identifier == inIdentifier).values
                }
            }

            return []
        },
        getTheVariantPrice(inTheVariant) {
            let myValues = inTheVariant.identifier.split("|")
            let myVariants = inTheVariant.variants.split("|")
            let myPrice = parseFloat(this?.productForm.price ?? 0)

            myVariants.forEach((myVariant, index) => {
                let myVariantValue = this.variantValues.find((vValue) => vValue.identifier == myVariant)
                let myValue = myVariantValue.values.find((vvValue) => vvValue.identifier == myValues[index])

                myPrice = myPrice + (parseFloat(myValue?.priceVariance ?? 0))
            })

            return myPrice
        },
        getTheVariantLabel(inTheVariant) {
            let myValues = inTheVariant.identifier.split("|")
            let myVariants = inTheVariant.variants.split("|")
            let myLabel = ""

            myVariants.forEach((myVariant, index) => {
                let myVariantValue = this.variantValues.find((vValue) => vValue.identifier == myVariant)
                let myValue = myVariantValue.values.find((vvValue) => vvValue.identifier == myValues[index])

                if (index > 0) {
                    myLabel = `${myLabel}-`
                }

                myLabel = `${myLabel}${myValue?.label ?? ''}`
            })

            return myLabel
        },
        isVendorVariantOpen(inIdentifier) {
            return this.vendorVariantsOpen.includes(inIdentifier)
        },
        saveOrder() {
            this.$emit('updateVariantValues', this.variantValues, false)
        },
        updateProductFormSelectValue(inField, inValue) {
            this.$emit('updateProductFormSelectValue', inField, inValue)
        },
        vendorVariantValues(inIdentifier) {
            return this.variantValues
                .find((myValue) => myValue.identifier == inIdentifier).values
        }
    }
}
</script>