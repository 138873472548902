<template>
    <div v-if="formLoaded" class="w-full">
        <select-panel
            :fieldData="yesNoData" fieldFormName="hasBulkDiscounts" fieldLabel="Does This Product Have Bulk Discounts?" fieldLabelClasses="text-gray-800" 
            :fieldRequired="true" :fieldValue="productDataForm.hasBulkDiscounts" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateProductDataFormSelectValue"
        >
            <validation-message v-if="validating && productDataForm.hasBulkDiscounts == null" 
                message="Select yes or no"
            />
        </select-panel>
        <div v-if="productDataForm.hasBulkDiscounts" class="w-full px-2">
            <div class="w-full bg-orange-200 text-sm">
                <div class="flex justify-between items-center w-full py-2">
                    <div class="w-full grid grid-cols-3 text-center font-semibold">
                        <div>
                            Quantity
                        </div>
                        <div>
                            Discount
                        </div>
                        <div>
                            Enabled
                        </div>
                    </div>
                    <div class="flex items-center justify-end w-20 text-gray-600 pr-2">
                        <div @click="clickNewBulkDiscount()" class="cursor-pointer">
                            <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                </div>
                <div v-for="discount in sortedDiscounts" class="mt-px flex justify-between items-center w-full py-3" :class="{ 'orange-transparent': discount.enabled, 'red-transparent': !discount.enabled }">
                    <div class="w-full grid grid-cols-3 text-center items-center">
                        <div>
                            {{ discount.qty }}
                        </div>
                        <div>
                            {{ discount.percentage }}%
                        </div>
                        <div>
                            <input @change="clickToggleBulkDiscountEnabled(discount.id, discount.enabled)" :checked="discount.enabled" type="checkbox" class="w-4 h-4 text-orange-500 border-gray-300 rounded edit-field-text-color" :class="{ 'background-orange-500': discount.enabled }">
                        </div>
                    </div>
                    <div class="flex items-center justify-end w-20 text-gray-600 px-2">
                        <div @click="clickEditBulkDiscount(discount.id)" class="cursor-pointer">
                            <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                        </div>
                        <div @click="clickDeleteBulkDiscount(discount.id)" class="cursor-pointer ml-2">
                            <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <validation-message v-if="validating && !sortedDiscounts.length" 
            message="You must create at least one Bulk Discount"
        />
    </div>
</template>

<script>
export default {
    props: [
        'productBulkDiscounts',
        'productDataForm',
        'validating'
    ],
    data() {
        return {
            formLoaded: false
        }
    },
    computed: {
        sortedDiscounts() {
            if (this?.productBulkDiscounts?.discounts ?? false) {
                return this.productBulkDiscounts.discounts
                    .sort((a, b) => parseInt(a.qty) < parseInt(b.qty) ? -1 : 1)
            }

            return null
        }
    },
    mounted() {
        this.formLoaded = true
    },
    methods: {
        clickDeleteBulkDiscount(inId) {
            if (confirm('You wish to delete this Bulk Discount?')) {
                this.$emit('clickDeleteBulkDiscount', inId)
            }
        },
        clickEditBulkDiscount(inId) {
            this.$emit('clickEditBulkDiscount', inId)
        },
        clickNewBulkDiscount() {
            this.$emit('clickNewBulkDiscount')
        },
        clickToggleBulkDiscountEnabled(inId, inEnabled) {
            this.$emit('clickToggleBulkDiscountEnabled', inId, !inEnabled)
        },
        updateProductDataFormSelectValue(inField, inValue) {
            this.$emit('updateProductDataFormSelectValue', inField, inValue)
        }
    }
}
</script>