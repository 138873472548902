<template>
    <div class="w-full">
        <select-panel
            :fieldData="yesNoData" fieldFormName="has_add_ons" fieldLabel="Does This Product Have Add-Ons?" fieldLabelClasses="text-gray-800" 
            :fieldRequired="true" :fieldValue="productForm.has_add_ons" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateProductFormSelectValue"
        >
            <validation-message v-if="validating && productForm.has_add_ons == null" 
                message="Select yes or no"
            />
        </select-panel>
        <div v-if="productForm.has_add_ons" class="w-full px-2 text-sm">
            <div class="w-full bg-orange-200">
                <div class="flex items-center justfy-between w-full py-2">
                    <div class="pl-2 text-left font-semibold w-full">
                        Add-Ons
                    </div>
                    <div v-if="sortedVendorAddOns && vendorAddOns && sortedVendorAddOns.length != vendorAddOns.length" class="flex items-center justify-end text-gray-600 pr-2">
                        <div @click="clickNewAddOn()" class="cursor-pointer">
                            <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                </div>
                <div v-for="vendorAddOn in sortedVendorAddOns" class="mt-px w-full orange-transparent">
                    <div class="flex justify-between items-center w-full py-3">
                        <div @click="clickToggleShowVendorAddOn(vendorAddOn.identifier)" class="pl-2">
                            {{ vendorAddOn.label }}
                        </div>
                        <div class="wizard-icon-container">
                            <div @click="clickToggleShowVendorAddOn(vendorAddOn.identifier)" v-show="!isVendorAddOnOpen(vendorAddOn.identifier)" class="cursor-pointer">
                                <fa-icon icon="chevron-left" type="fas" class="h-5 w-5" />
                            </div>
                            <div @click="clickToggleShowVendorAddOn(vendorAddOn.identifier)" v-show="isVendorAddOnOpen(vendorAddOn.identifier)" class="cursor-pointer">
                                <fa-icon icon="chevron-down" type="fas" class="h-5 w-5" />
                            </div>
                            <div @click="clickEditAddOn(vendorAddOn.identifier)" class="cursor-pointer ml-2">
                                <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                            </div>
                            <div @click="clickDeleteAddOn(vendorAddOn.identifier, vendorAddOn.label)" class="cursor-pointer ml-2">
                                <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                            </div>
                        </div>
                    </div>
                    <div v-if="isVendorAddOnOpen(vendorAddOn.identifier)" class="w-full">
                        <draggable
                            animation="250" easing="cubic-bezier(1, 0, 0, 1)" handle=".handle-value" item-key="index" :list="getRealAddOnValues(vendorAddOn.identifier)" tag="div" 
                            @change="saveOrder()"
                        >
                            <template #item="{element, index}">
                                <div class="flex items-center justify-between w-full orange-transparent py-3 mt-px">
                                    <div class="flex items-center justify-start w-full pl-2">
                                        <child-line-prefix />
                                        <div>
                                            {{ element.label }}
                                        </div>
                                    </div>
                                    <div class="wizard-icon-container">
                                        <div class="cursor-move handle-value">
                                            <fa-icon icon="up-down-left-right" type="fas" class="h-5 w-5" />
                                        </div>
                                        <div @click="clickDeleteValue(element.identifier, element.label, vendorAddOn.identifier)" class="cursor-pointer ml-2">
                                            <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </draggable>
                    </div>
                </div>
            </div>
            <validation-message v-if="validating && !sortedVendorAddOns.length" 
                message="You must create at least one Add-On"
            />
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    props: [
        'productAddOns',
        'productForm',
        'refreshAddOnValues',
        'validating'
    ],
    components: {
        draggable
    },
    data() {
        return {
            addOnValues: null,
            formLoaded: false,
            vendorAddOns: null,
            vendorAddOnsOpen: []
        }
    },
    computed: {
        sortedVendorAddOns() {
            if (!this.vendorAddOns) {
                return []
            }

            return this.vendorAddOns.filter((myAddOn) => myAddOn.selected)
        }
    },
    watch: {
        refreshAddOnValues: {
            handler(val) {
                if (val) {
                    this.addOnValues = this.objCopy(this.productAddOns.addOnValues)
                    this.$emit('resetRefreshAddOnValues')
                }
            }
        }
    },
    mounted() {
        let myVendorAddOns = []

        this.sellerVendor.product_add_ons.forEach((mySellerAddOn) => {
            let mySelected = false

            if (this.productAddOns && this.productAddOns.vendorAddOns) {
                let myAddOn = this.productAddOns.vendorAddOns.find((myAddOn) => myAddOn.identifier == mySellerAddOn.identifier)
                mySelected = myAddOn?.selected ?? false
            }

            let myNewAddOn = {
                identifier: mySellerAddOn.identifier,
                label: mySellerAddOn.label,
                selected: mySelected
            }

            myVendorAddOns.push(myNewAddOn)
        })

        this.vendorAddOns = myVendorAddOns

        if (this.productAddOns && this.productAddOns.addOnValues) {
            this.addOnValues = this.objCopy(this.productAddOns.addOnValues)
        }

        this.formLoaded = true
        this.$emit('updateVendorAddOns', this.vendorAddOns)
    },
    methods: {
        clickDeleteAddOn(inIdentifier, inLabel) {
            if (confirm(`Confirm you wish to delete ${inLabel}?`)) {
                this.$emit('clickDeleteAddOn', inIdentifier)
            }
        },
        clickDeleteValue(inIdentifier, inLabel, inAddOnIdentifier) {
            if (confirm(`Confirm you wish to delete ${inLabel}?`)) {
                let myAddOnValues = this.addOnValues.find((myValues) => myValues.identifier == inAddOnIdentifier)

                if (myAddOnValues) {
                    myAddOnValues.values = myAddOnValues.values.filter((myValue) => myValue.identifier != inIdentifier)
                    this.$emit('updateAddOnValues', this.addOnValues, true)
                }
            }
        },
        clickEditAddOn(inIdentifier) {
            this.$emit('clickEditAddOn', inIdentifier)
        },
        clickNewAddOn() {
            this.$emit('clickNewAddOn')
        },
        clickToggleShowVendorAddOn(inIdentifier) {
            if (!this.vendorAddOnsOpen.includes(inIdentifier)) {
                this.vendorAddOnsOpen.push(inIdentifier)
            }
            else {
                this.vendorAddOnsOpen = this.vendorAddOnsOpen.filter((myAddOn) => myAddOn != inIdentifier)
            }
        },
        getRealAddOnValues(inIdentifier) {
            if (this.addOnValues) {
                let myAddOnValues = this.addOnValues.find((myValues) => myValues.identifier == inIdentifier)

                if (myAddOnValues) {
                    return this.addOnValues.find((myValues) => myValues.identifier == inIdentifier).values
                }
            }

            return []
        },
        isVendorAddOnOpen(inIdentifier) {
            return this.vendorAddOnsOpen.includes(inIdentifier)
        },
        saveOrder() {
            this.$emit('updateAddOnValues', this.addOnValues, false)
        },
        updateProductFormSelectValue(inField, inValue) {
            this.$emit('updateProductFormSelectValue', inField, inValue)
        }
    }
}
</script>