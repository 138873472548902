<template>
    <modal-container v-if="showEditCustomerSegmentModal && segment"
       :openConditions="showEditCustomerSegmentModal"
       @closeModal="closeEditSegmentModal" @updateModalScrollTop="updateModalScrollTop"
    >
        <template #main>
            <div v-if="formLoaded" class="w-full mt-4 px-1">
                <h3 class="section-heading mb-3 text-center">
                    Edit Customer Segment
                </h3>
                <div class="max-width-box">
                    <div class="w-full mb-1">
                        <div class="w-full">
                            <input @input="saveDebounce()" placeholder="Label" type="text" v-model="segmentForm.label" class="form-input edit-field-text-color bg-white py-1 w-full text-center" />
                        </div>
                        <validation-message v-if="!segment.label" 
                            message="Label must be entered"
                        />
                    </div>
                    <div v-for="filter in filters" class="w-full flex items-center justify-between white-transparent mb-1 p-2">
                        <div :class="{ 'flex items-center justify-start': filter.type != 'state' }">
                            <div class="whitespace-nowrap">
                                {{ filterOptions[filter.type] }}
                            </div>
                            <div v-if="filter.type == 'country'" class="ml-2">
                                <select-panel
                                    :fieldData="filteredCountryList" fieldDataLabel="name" fieldDataValue="code" fieldFormName="country" :fieldNude="true" :fieldValue="country"
                                    :forceMobile="true" :hideLabel="true"
                                    @fieldChange="updateCountry"
                                />
                            </div>
                            <div v-if="filter.type == 'createdAge'" class="ml-2 flex items-center">
                                <input-panel
                                    fieldFormName="createdAge" :fieldNude="true" fieldType="number" :fieldValue="createdAge" :forceMobile="true" :hideLabel="true"
                                    @fieldInput="updateCreatedAge"
                                />
                            </div>
                            <div v-if="filter.type == 'createdDate'" class="ml-2 flex items-center">
                                <date-picker-panel
                                    fieldFormName="createdFrom" :fieldNude="true" :fieldValue="createdFrom" :forceMobile="true" :hideLabel="true"
                                    @fieldChange="updateCreatedFromDateValue"
                                />
                                <div class="px-2">
                                    -
                                </div>
                                <date-picker-panel
                                    fieldFormName="createdTo" :fieldNude="true" :fieldValue="createdTo" :forceMobile="true" :hideLabel="true"
                                    @fieldChange="updateCreatedToDateValue"
                                />
                            </div>
                            <div v-if="filter.type == 'groupOffer'" class="ml-2 flex items-center justify-start">
                                <select-panel
                                    :fieldData="vendorGroupBuys" fieldDataLabel="name" fieldDataValue="id" fieldFormName="groupOffer" :fieldNude="true" :fieldValue="groupOffer"
                                    :forceMobile="true" :hideLabel="true"
                                    @fieldChange="updateGroupOffer"
                                />
                                <div class="ml-2 w-full">
                                    <select-panel
                                        :fieldData="groupOfferTypesData" fieldFormName="groupOfferType" :fieldNude="true" :fieldValue="groupOfferType"
                                        :forceMobile="true" :hideLabel="true" :isLabelAndValue="true"
                                        @fieldChange="updateGroupIncludeOrExclude"
                                    />
                                </div>
                            </div>
                            <div v-if="filter.type == 'lastSaleAge'" class="ml-2 flex items-center justify-start">
                                <input-panel
                                    fieldFormName="lastSaleAge" :fieldNude="true" fieldType="number" :fieldValue="lastSaleAge" :forceMobile="true" :hideLabel="true"
                                    @fieldInput="updateLastSaleAge"
                                />
                                <div class="ml-2 w-full">
                                    <select-panel
                                        :fieldData="lastSaleAgeTypesData" fieldFormName="lastSaleAgeType" :fieldNude="true" :fieldValue="lastSaleAgeType"
                                        :forceMobile="true" :hideLabel="true" :isLabelAndValue="true"
                                        @fieldChange="updateLastSaleAgeDuringOrBefore"
                                    />
                                </div>
                            </div>
                            <div v-if="filter.type == 'lastSaleDate'" class="ml-2 flex items-center">
                                <date-picker-panel
                                    fieldFormName="lastSaleFrom" :fieldNude="true" :fieldValue="lastSaleFrom" :forceMobile="true" :hideLabel="true"
                                    @fieldChange="updateLastSaleFromDateValue"
                                />
                                <div class="px-2">
                                    -
                                </div>
                                <date-picker-panel
                                    fieldFormName="lastSaleTo" :fieldNude="true" :fieldValue="lastSaleTo" :forceMobile="true" :hideLabel="true"
                                    @fieldChange="updateLastSaleToDateValue"
                                />
                            </div>
                            <div v-if="filter.type == 'spend'" class="ml-2 flex items-center">
                                <input-panel
                                    fieldFormName="spendFrom" :fieldNude="true" fieldType="number" :fieldValue="spendFrom" :forceMobile="true" :hideLabel="true"
                                    @fieldInput="updateSpendFrom"
                                />
                                <div class="px-2">
                                    -
                                </div>
                                <input-panel
                                    fieldFormName="spendTo" :fieldNude="true" fieldType="number" :fieldValue="spendTo" :forceMobile="true" :hideLabel="true"
                                    @fieldInput="updateSpendTo"
                                />
                            </div>
                            <div v-if="filter.type == 'state'">
                                <div v-if="countryStates && countryStates.length" class="round-tabs-container px-1 mt-1">
                                    <template v-for="(state) in countryStates" :key="key">
                                        <div @click="toggleState(state)" class="round-tab flex items-center" :class="{ 'round-tab-off': !includedState(state), 'round-tab-on': includedState(state) }">
                                            {{ state }}
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div @click="deleteFilter(filter.type)" class="ml-2 cursor-pointer">
                            <fa-icon icon="trash-can" type="fas" class="h-6 w-6" />
                        </div>
                    </div>
                    <div v-if="filteredFilters && objLength(filteredFilters)" class="flex items-center w-full justify-between mt-2 white-transparent p-2">
                        <div class="w-full">
                            <select v-model="newFilterType" class="form-input edit-field-text-color bg-white py-1 w-full">
                                <option v-for="(key, value) in filteredFilters" :value="value">{{ key }}</option>
                            </select>
                        </div>
                        <div @click="addFilter()" class="ml-2 text-orange-500 cursor-pointer">
                            <fa-icon icon="circle-plus" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                </div>
                <div class="w-full flex items-start flex-wrap mt-2">
                    <div class="px-px" :class="{ 'w-1/4': isDesktopWidth, 'w-full mb-2': !isDesktopWidth }">
                        <div class="white-transparent p-2 text-center h-32 relative">
                            <div class="mb-3 text-xs">
                                Marketing Email Audience
                            </div>
                            <div class="w-full flex items-center justify-center">
                                <div v-if="audienceCountME != null">
                                    {{ audienceCountME }}
                                </div>
                                 <div v-if="gettingME" class="ml-2">
                                    <fa-icon icon="spinner" type="fas" class="spin h-5 w-5" />
                                 </div>
                            </div>
                            <div v-if="!gettingME" class="absolute left-0 px-2 bottom-2 w-full">
                                <div @click="clickGetCount('email', 'marketing')" class="standard-button-base w-full">
                                    Get Count
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-px" :class="{ 'w-1/4': isDesktopWidth, 'w-full mb-2': !isDesktopWidth }">
                        <div class="white-transparent p-2 text-center h-32 relative">
                            <div class="mb-3 text-xs">
                                Marketing SMS Audience
                            </div>
                            <div class="w-full flex items-center justify-center">
                                <div v-if="audienceCountMS != null">
                                    {{ audienceCountMS }}
                                </div>
                                 <div v-if="gettingMS" class="ml-2">
                                    <fa-icon icon="spinner" type="fas" class="spin h-5 w-5" />
                                 </div>
                            </div>
                            <div v-if="!gettingMS" class="absolute left-0 px-2 bottom-2 w-full">
                                <div @click="clickGetCount('sms', 'marketing')" class="standard-button-base w-full">
                                    Get Count
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-px" :class="{ 'w-1/4': isDesktopWidth, 'w-full mb-2': !isDesktopWidth }">
                        <div class="white-transparent p-2 text-center h-32 relative">
                            <div class="mb-3 text-xs">
                                Potential Email Audience
                            </div>
                            <div class="w-full flex items-center justify-center">
                                <div v-if="audienceCountPE != null">
                                    {{ audienceCountPE }}
                                </div>
                                 <div v-if="gettingPE" class="ml-2">
                                    <fa-icon icon="spinner" type="fas" class="spin h-5 w-5" />
                                 </div>
                            </div>
                            <div v-if="!gettingPE" class="absolute left-0 px-2 bottom-2 w-full">
                                <div @click="clickGetCount('email', 'potential')" class="standard-button-base w-full">
                                    Get Count
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-px" :class="{ 'w-1/4': isDesktopWidth, 'w-full mb-2': !isDesktopWidth }">
                        <div class="white-transparent p-2 text-center h-32 relative">
                            <div class="mb-3 text-xs">
                                Potential SMS Audience
                            </div>
                            <div class="w-full flex items-center justify-center">
                                <div v-if="audienceCountPS != null">
                                    {{ audienceCountPS }}
                                </div>
                                 <div v-if="gettingPS" class="ml-2">
                                    <fa-icon icon="spinner" type="fas" class="spin h-5 w-5" />
                                 </div>
                            </div>
                            <div v-if="!gettingPS" class="absolute left-0 px-2 bottom-2 w-full">
                                <div @click="clickGetCount('sms', 'potential')" class="standard-button-base w-full">
                                    Get Count
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </template>
   </modal-container>
</template>

<script>
import {debounce} from 'lodash'

export default {
    data() {
        return {
            audienceCountME: null,
            audienceCountMS: null,
            audienceCountPE: null,
            audienceCountPS: null,
            country: null,
            createdAge: null,
            createdFrom: null,
            createdTo: null,
            filterOptions: {
                "country": "Country",
                "createdAge": "Created Age (Days)",
                "createdDate": "Created Time",
                "groupOffer": "Group Offer",
                "lastSaleAge": "Sale Age (Days)",
                "lastSaleDate": "Sale Time",
                "state": "States",
                "spend": "Total Spend"
            },
            formLoaded: false,
            gettingME: false,
            gettingMS: false,
            gettingPE: false,
            gettingPS: false,
            groupOffer: null,
            groupOfferType: null,
            lastSaleAge: null,
            lastSaleAgeType: null,
            lastSaleFrom: null,
            lastSaleTo: null,            
            modalScrollTop: null,
            newFilterType: null,
            segment: null,
            segmentForm: {
                data: null,
                label: null
            },
            spendFrom: null,
            spendTo: null,
            states: [],
            usedFilters: [],
            vendorCountries: [],
            vendorStates: []
        }
    },
    computed: {
        allOkay() {
            return Boolean(this.segmentForm.label)
        },
        countryStates() {
            if (!this.country) {
                return []
            }

            if (this.vendorStates[this.country] ?? false) {
                return this.vendorStates[this.country]
                    .sort((a, b) => a < b ? -1 : 1)
            }

            return []
        },
        filteredCountryList() {
            if (!this.vendorCountries) {
                return []
            }

            return this.countryList.filter((myCountry) => this.vendorCountries.includes(myCountry.code))
        },
        filteredFilters() {
            let myFilters = {}
            
            Object.keys(this.filterOptions).forEach((myFilter) => {
                if (!this.usedFilters.includes(myFilter)) {
                    myFilters[myFilter] = this.filterOptions[myFilter]
                }
            })

            return myFilters
        },
        filters() {
            return this?.segmentForm?.data?.filters ?? []
        },
        groupOfferTypesData() {
            return {
                Include: "include",
                Exclude: "exclude"
            }
        },
        lastSaleAgeTypesData() {
            return {
                During: "during",
                Before: "before"
            }
        }
    },
    watch: {
        customerSegmentCounts: {
            handler(val) {
                if (this.customerSegmentCounts[this.segment.id]) {
                    if (this.gettingME) {
                        if (this.customerSegmentCounts[this.segment.id]["ME"]) {
                            if (!this.audienceCountME || this.audienceCountME <= this.customerSegmentCounts[this.segment.id]["ME"].count) {
                                this.audienceCountME = this.customerSegmentCounts[this.segment.id]["ME"].count
                                this.gettingME = !this.customerSegmentCounts[this.segment.id]["ME"].complete
                            }
                        }
                    }

                    if (this.gettingMS) {
                        if (this.customerSegmentCounts[this.segment.id]["MS"]) {
                            if (!this.audienceCountMS || this.audienceCountMS <= this.customerSegmentCounts[this.segment.id]["MS"].count) {
                                this.audienceCountMS = this.customerSegmentCounts[this.segment.id]["MS"].count
                                this.gettingMS = !this.customerSegmentCounts[this.segment.id]["MS"].complete
                            }
                        }
                    }

                    if (this.gettingPE) {
                        if (this.customerSegmentCounts[this.segment.id]["PE"]) {
                            if (!this.audienceCountPE || this.audienceCountPE <= this.customerSegmentCounts[this.segment.id]["PE"].count) {
                                this.audienceCountPE = this.customerSegmentCounts[this.segment.id]["PE"].count
                                this.gettingPE = !this.customerSegmentCounts[this.segment.id]["PE"].complete
                            }
                        }
                    }

                    if (this.gettingPS) {
                        if (this.customerSegmentCounts[this.segment.id]["PS"]) {
                            if (!this.audienceCountPS || this.audienceCountPS <= this.customerSegmentCounts[this.segment.id]["PS"].count) {
                                this.audienceCountPS = this.customerSegmentCounts[this.segment.id]["PS"].count
                                this.gettingPS = !this.customerSegmentCounts[this.segment.id]["PS"].complete
                            }
                        }
                    }
                }
            }
        }
    },
    mounted() {
        this.formLoaded = true
        this.getSegment()
    },
    methods: {
        addFilter() {
            if (!this.newFilterType) {
                return false
            }

            let myFilter = {}

            if (this.newFilterType == "country") {
                myFilter = {
                    type: "country",
                    value: null
                }
            }
            else if (this.newFilterType == "createdAge") {
                myFilter = {
                    type: "createdAge",
                    age: null
                }
            }
            else if (this.newFilterType == "createdDate") {
                myFilter = {
                    type: "createdDate",
                    from: null,
                    to: null
                }
            }
            else if (this.newFilterType == "groupOffer") {
                myFilter = {
                    type: "groupOffer",
                    value: null,
                    includeOrExclude: null
                }
            }
            else if (this.newFilterType == "lastSaleAge") {
                myFilter = {
                    type: "lastSaleAge",
                    age: null,
                    duringOrBefore: null
                }
            }
            else if (this.newFilterType == "lastSaleDate") {
                myFilter = {
                    type: "lastSaleDate",
                    from: null,
                    to: null
                }
            }
            else if (this.newFilterType == "spend") {
                myFilter = {
                    type: "spend",
                    from: null,
                    to: null
                }
            }
            else if (this.newFilterType == "state") {
                myFilter = {
                    type: "state",
                    values: []
                }
            }

            let myData = this?.segmentForm?.data ?? {}
            let myFilters = myData.filters ?? []
            myFilters.push(myFilter)
            myData.filters = myFilters
            this.segmentForm.data = myData
            this.save()
            this.usedFilters.push(this.newFilterType)
        },
        clickGetCount(inType, inDataSource) {
            let myIdentifier = null

            if (inType == 'email' && inDataSource == 'marketing') {
                this.audienceCountME = null
                this.gettingME = true
                myIdentifier = "ME"
            }
            else if (inType == 'sms' && inDataSource == 'marketing') {
                this.audienceCountMS = null
                this.gettingMS = true
                myIdentifier = "MS"
            }
            else if (inType == 'email' && inDataSource == 'potential') {
                this.audienceCountPE = null
                this.gettingPE = true
                myIdentifier = "PE"
            }
            else if (inType == 'sms' && inDataSource == 'potential') {
                this.audienceCountPS = null
                this.gettingPS = true
                myIdentifier = "PS"
            }

            let { [`${this.segment.id}`]: mySegment, ...theOtherSegments } = this.objCopy(this.customerSegmentCounts)
            
            if (!mySegment) {
                mySegment = {
                    [`${myIdentifier}`]: {}
                }
            }
            else {
                let { [`${myIdentifier}`]: myCount, ...theOtherCounts } = this.objCopy(mySegment)
                mySegment = { ...theOtherCounts, [`${myIdentifier}`]: {} }
            }

            let myNewSegments = { ...theOtherSegments, [`${this.segment.id}`]: mySegment }

            this.setStateData([
                { customerSegmentCounts: myNewSegments }
            ])

            buildzAxios?.post(this.getRoute('customerSegmentCount', this.segment.id),
                {
                    dataSource: inDataSource,
                    type: inType,
                    identifier: myIdentifier
                }
            )
            .then((response) => {
                
            })
            .catch((error) => {
                console.log(error)
                this.pageMessage('error', 'Error getting count')
            })
        },
        deleteFilter(inFilter) {
            if (confirm("You wish to delete this filter?")) {
                let myData = this?.segmentForm?.data ?? {}
                let myFilters = this.objCopy(myData.filters)
                myFilters = myFilters.filter((myFilter) => myFilter.type != inFilter)
                myData.filters = myFilters
                this.segmentForm.data = myData
                this.save()

                this.usedFilters = this.usedFilters.filter((myFilter) => myFilter != inFilter)

                if (inFilter == "country") {
                    this.country = null
                }
                else if (inFilter == "createdAge") {
                    this.createdAge = null
                }
                else if (inFilter == "createdDate") {
                    this.createdFrom = null
                    this.createdTo = null
                }
                else if (inFilter == "groupOffer") {
                    this.groupOffer = null
                    this.groupOfferType = null
                }
                else if (inFilter == "lastSaleAge") {
                    this.lastSaleAge = null
                    this.lastSaleAgeType = null
                }
                else if (inFilter == "lastSaleDate") {
                    this.lastSaleFrom = null
                    this.lastSaleTo = null
                }
                else if (inFilter == "spend") {
                    this.spendFrom = null
                    this.spendTo = null
                }
                else if (inFilter == "state") {
                    this.states = null
                    this.states = []
                }
            }
        },
        getSegment() {
            buildzAxios?.get(this.getRoute('customerSegment', this.customerSegmentToEdit.id))
                .then((response) => {
                    if (response.status == 200 && (response?.data?.segment ?? false)) {
                        this.segment = this.objCopy(response.data.segment)
                        this.segmentForm.data = this.segment.data
                        this.segmentForm.label = this.segment.label

                        if (this?.segmentForm?.data?.filters ?? false) {
                            this.segmentForm.data.filters.forEach((myFilter) => {
                                if (myFilter.type == "country") {
                                    if (myFilter?.value ?? false) {
                                        this.country = myFilter.value
                                    }
                                }
                                else if (myFilter.type == "createdAge") {
                                    if (myFilter?.age ?? false) {
                                        this.createdAge = myFilter.age
                                    }
                                }
                                else if (myFilter.type == "createdDate") {
                                    if (myFilter?.from ?? false) {
                                        this.createdFrom = myFilter.from * 1000
                                    }

                                    if (myFilter?.to ?? false) {
                                        this.createdTo = myFilter.to * 1000
                                    }
                                }
                                else if (myFilter.type == "groupOffer") {
                                    if (myFilter?.value ?? false) {
                                        this.groupOffer = myFilter.value
                                        this.groupOfferType = myFilter.includeOrExclude ?? "include"
                                    }
                                }
                                else if (myFilter.type == "lastSaleAge") {
                                    if (myFilter?.age ?? false) {
                                        this.lastSaleAge = myFilter.age
                                        this.lastSaleAgeType = myFilter.duringOrBefore ?? "during"
                                    }
                                }
                                else if (myFilter.type == "lastSaleDate") {
                                    if (myFilter?.from ?? false) {
                                        this.lastSaleFrom = myFilter.from * 1000
                                    }

                                    if (myFilter?.to ?? false) {
                                        this.lastSaleTo = myFilter.to * 1000
                                    }
                                }
                                else if (myFilter.type == "spend") {
                                    if (myFilter?.from ?? false) {
                                        this.spendFrom = myFilter.from
                                    }

                                    if (myFilter?.to ?? false) {
                                        this.spendTo = myFilter.to
                                    }
                                }
                                else if (myFilter.type == "state") {
                                    if (myFilter?.values ?? false) {
                                        this.states = myFilter.values
                                    }
                                }

                                this.usedFilters.push(myFilter.type)
                            })
                        }

                        this.vendorCountries = response.data.countries
                        this.vendorStates = response.data.states
                        this.formLoaded = true
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        includedState(inState) {
            return this.states.includes(inState)
        },
        save() {
            if (this.formLoaded) {
                this.saveSegment(this.segment.id, this.segmentForm)
            }
        },
        saveDebounce: debounce(function() {
            this.save()
        }, 2000),
        toggleState(inState) {
            if (this.states.includes(inState)) {
                let myIncludedStates = this.objCopy(this.states)
                myIncludedStates = myIncludedStates.filter((myState) => myState != inState)
                this.states = this.objCopy(myIncludedStates)
            }
            else {
                this.states.push(inState)
            }

            this.segmentForm.data.filters.forEach((myFilter) => {
                if (myFilter.type == "state") {
                    myFilter.values = this.states
                }
            }) 

            this.save()
        },
        updateCountry(inField, inValue) {
            this.segmentForm.data.filters.forEach((myFilter) => {
                if (myFilter.type == "country") {
                    myFilter.value = inValue
                }
            }) 

            this.country = inValue
            this.save()
        },
        updateCreatedAge(inField, inValue) {
            this.segmentForm.data.filters.forEach((myFilter) => {
                if (myFilter.type == "createdAge") {
                    myFilter.age = inValue
                }
            }) 

            this.saveDebounce()
        },
        updateCreatedFromDateValue(inField, inValue) {
            let originalValue = Number.isInteger(this.dateTimeToUnixTime(this.createdFrom)) ? this.dateTimeToUnixTime(this.createdFrom) : this.createdFrom / 1000
            let newValue = Number.isInteger(this.dateTimeToUnixTime(inValue)) ? this.dateTimeToUnixTime(inValue) : inValue / 1000

            if (originalValue != newValue) {
                this.createdFrom = inValue

                this.segmentForm.data.filters.forEach((myFilter) => {
                    if (myFilter.type == "createdDate") {
                        myFilter.from = Number.isInteger(this.dateTimeToUnixTime(this.createdFrom)) ? this.dateTimeToUnixTime(this.createdFrom) : this.createdFrom / 1000
                    }
                }) 

                this.save()
            }
        },
        updateCreatedToDateValue(inField, inValue) {
            let originalValue = Number.isInteger(this.dateTimeToUnixTime(this.createdTo)) ? this.dateTimeToUnixTime(this.createdTo) : this.createdTo / 1000
            let newValue = Number.isInteger(this.dateTimeToUnixTime(inValue)) ? this.dateTimeToUnixTime(inValue) : inValue / 1000

            if (originalValue != newValue) {
                this.createdTo = inValue

                this.segmentForm.data.filters.forEach((myFilter) => {
                    if (myFilter.type == "createdDate") {
                        myFilter.to = Number.isInteger(this.dateTimeToUnixTime(this.createdTo)) ? this.dateTimeToUnixTime(this.createdTo) : this.createdTo / 1000
                    }
                })

                this.save()
            }
        },
        updateGroupOffer(inField, inValue) {
            this.segmentForm.data.filters.forEach((myFilter) => {
                if (myFilter.type == "groupOffer") {
                    myFilter.value = inValue
                }
            }) 

            this.save()
        },
        updateGroupIncludeOrExclude(inField, inValue) {
            this.segmentForm.data.filters.forEach((myFilter) => {
                if (myFilter.type == "groupOffer") {
                    myFilter.includeOrExclude = inValue
                }
            }) 

            this.save()
        },
        updateLastSaleAge(inField, inValue) {
            this.segmentForm.data.filters.forEach((myFilter) => {
                if (myFilter.type == "lastSaleAge") {
                    myFilter.age = inValue
                }
            }) 

            this.saveDebounce()
        },
        updateLastSaleAgeDuringOrBefore(inField, inValue) {
            this.segmentForm.data.filters.forEach((myFilter) => {
                if (myFilter.type == "lastSaleAge") {
                    myFilter.duringOrBefore = inValue
                }
            }) 

            this.save()
        },
        updateLastSaleFromDateValue(inField, inValue) {
            let originalValue = Number.isInteger(this.dateTimeToUnixTime(this.lastSaleFrom)) ? this.dateTimeToUnixTime(this.lastSaleFrom) : this.lastSaleFrom / 1000
            let newValue = Number.isInteger(this.dateTimeToUnixTime(inValue)) ? this.dateTimeToUnixTime(inValue) : inValue / 1000

            if (originalValue != newValue) {
                this.lastSaleFrom = inValue

                this.segmentForm.data.filters.forEach((myFilter) => {
                    if (myFilter.type == "lastSaleDate") {
                        myFilter.from = Number.isInteger(this.dateTimeToUnixTime(this.lastSaleFrom)) ? this.dateTimeToUnixTime(this.lastSaleFrom) : this.lastSaleFrom / 1000
                    }
                }) 

                this.save()
            }
        },
        updateLastSaleToDateValue(inField, inValue) {
            let originalValue = Number.isInteger(this.dateTimeToUnixTime(this.LastSaleTo)) ? this.dateTimeToUnixTime(this.LastSaleTo) : this.LastSaleTo / 1000
            let newValue = Number.isInteger(this.dateTimeToUnixTime(inValue)) ? this.dateTimeToUnixTime(inValue) : inValue / 1000

            if (originalValue != newValue) {
                this.LastSaleTo = inValue

                this.segmentForm.data.filters.forEach((myFilter) => {
                    if (myFilter.type == "lastSaleDate") {
                        myFilter.to = Number.isInteger(this.dateTimeToUnixTime(this.LastSaleTo)) ? this.dateTimeToUnixTime(this.LastSaleTo) : this.LastSaleTo / 1000
                    }
                })

                this.save()
            }
        },
        updateSpendFrom(inField, inValue) {
            this.segmentForm.data.filters.forEach((myFilter) => {
                if (myFilter.type == "spend") {
                    myFilter.from = inValue
                }
            }) 

            this.saveDebounce()
        },
        updateSpendTo(inField, inValue) {
            this.segmentForm.data.filters.forEach((myFilter) => {
                if (myFilter.type == "spend") {
                    myFilter.to = inValue
                }
            }) 

            this.saveDebounce()
        },
        updateModalScrollTop(inScrollTop) {
            this.modalScrollTop = inScrollTop
        }
    }
}
</script>