<template>
    <table-record
        :noVerticalPadding="true"
    >
        <template #record>
            <wizard-table-container
                :bigVerticalPadding="true" :childTable="true" :headingText="ratesHeading" :nonBoldHeading="true" :showNew="!editingRateId && !editingZoneId"
                @clickNewRecord="clickNewZoneRate"
            >
                <template #records>
                    <zone-rate v-for="(rate, index) in rates" 
                        :defaultCurrency="defaultCurrency" :editingRateId="editingRateId" :editingRateZoneId="editingRateZoneId" :editingZoneId="editingZoneId" :rate="rate" 
                        :showName="showName(rate, index)" :type="type" :zone="zone"
                        @clickDeleteRate="clickDeleteRate" @clickEditRate="clickEditRate" 
                    />
                </template>
            </wizard-table-container>
        </template>
    </table-record>
</template>

<script>
import TableRecord from '@/wizards/components/TableRecord'
import ZoneRate from './ZoneRate'

export default {
    props: [
        'defaultCurrency',
        'editingRateId',
        'editingRateZoneId',
        'editingZoneId',
        'rates',    
        'type',
        'zone'
    ],
    components: {
        TableRecord,
        ZoneRate
    },
    computed: {
        ratesHeading() {
            if (this.type == 'general') {
                return "Flat Rates"
            }
            else if (this.type == 'unit') {
                return "Per Unit Rates"
            }
            else if (this.type == 'price') {
                return "Price Based Rates"
            }
            else if (this.type == 'weight') {
                return "Weight Based Rates"
            }
        }
    },
    methods: {
        clickDeleteRate(inRate) {
            this.$emit('clickDeleteRate', inRate)
        },
        clickEditRate(inRate) {
            this.$emit('clickEditRate', inRate)
        },
        clickNewZoneRate() {
            this.$emit('clickNewZoneRate')
        },
        showName(inRate, inIndex) {
            if ((this.type != 'weight' && this.type != 'price') || inIndex == 0) {
                return true
            }

            if (this.rates[inIndex - 1].customRateName != inRate.customRateName) {
                return true
            }

            return false
        }
    }
}
</script>