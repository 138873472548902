<template>
    <div class="absolute top-0 left-0 w-full h-full flex items-center justify-center black-less-transparent">
        <div class="border border-gray-800 bg-gray-100 rounded-md text-gray-800 relative" style="max-width: 95%;" :style="panelWidth ? { 'width': panelWidth } : {}">
            <div class="w-full text-center">
                <div class="bg-orange-400 py-4 px-12 font-bold rounded-t-md border-b border-gray-800 relative">
                    <div class="w-full">
                        {{ headingText }}
                    </div>
                    <div v-if="!hideClose" @click.stop="$emit('closeWizardPopup')" class="absolute top-1 left-1 font-normal cursor-pointer text-gray-800">
                        <fa-icon icon="xmark" type="fas" class="h-5 w-5" />
                    </div>
                </div>
                <div class="w-full p-1 overflow-scroll wizard-popup-panel text-sm">
                    <slot name="main"></slot>
                </div>
            </div>
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'headingText',
        'hideClose',
        'panelWidth'
    ]
}
</script>