<template>
    <div class="w-full white-transparent mt-px" :class="{ 'py-3 px-2': !fieldNude }">
        <div v-if="!hideLabel" class="w-full">
            <panel-label 
                :fieldLabel="fieldLabel" :fieldLabelClasses="fieldLabelClasses" :fieldRequired="fieldRequired && !fieldNonEdit" :forceMobile="true"
            />
        </div>
        <div class="w-full bg-white rounded-md flex items-center justify-center pr-8">
            <offline-media-new 
                :autoBrowse="autoBrowse" :boxNude="fieldNude" :boxStyle="`min-height: 8rem;`" dropPanelClass="bg-orange-200 border rounded-md border-orange-400" :hideSpinner="hideSpinner" :imagesOnly="imagesOnly" :inFiles="imagesArray" :maxFiles="maxFiles" :multiple="multiple"
                @saveMedia="saveMedia" @updateMedia="updateOfflineMedia" 
            />
        </div>
        <slot /> 
    </div>
</template>

<script>
import OfflineMediaNew from '@/shared/OfflineMediaNew'

export default {
    props: [
        'autoBrowse',
        'fieldLabel',
        'fieldLabelClasses',
        'fieldNude',
        'fieldRequired',
        'hideLabel',
        'hideSpinner',
        'imagesArray', 
        'imagesOnly', 
        'maxFiles',
        'multiple'
    ],
    components: {
        OfflineMediaNew
    },
    methods: {
        saveMedia(inGallery) {
            this.$emit('saveMedia', inGallery)
        },
        updateOfflineMedia(inGallery) {
            this.$emit('updateOfflineMedia', inGallery)
        }
    }
}
</script>