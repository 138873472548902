<template>
    <div v-if="formLoaded" class="w-full mt-px" :class="{ 'px-2 py-3': (!isDesktopWidth || forceMobile) && !fieldNude, 'white-transparent': !fieldNude }">
        <div class="w-full" :class="{ 'flex items-stretch': isDesktopWidth && !forceMobile }">
            <div v-if="!hideLabel" :class="{ 'w-1/2 py-3 pl-2 pr-1': isDesktopWidth && !forceMobile, 'w-full': !isDesktopWidth || forceMobile }">
                <panel-label 
                    :fieldLabel="fieldLabel" :fieldLabelClasses="fieldLabelClasses" :fieldRequired="fieldRequired && !fieldNonEdit" :forceMobile="forceMobile" :hide="hideFieldModel"
                />
            </div>
            <div v-if="!fieldNonEdit" :class="{ 'w-1/2 pr-2 py-3': isDesktopWidth && !forceMobile, 'w-full': !isDesktopWidth || forceMobile }">
                <date-picker
                    v-model="fieldModel"
                    :mode="selectMode"
                    :masks="masks"
                    :popover="{ visibility: 'click' }"
                >
                    <template v-slot="{ inputValue, inputEvents }">
                        <div class="relative flex-grow">
                            <svg
                                class="absolute w-4 h-full mx-2 text-gray-600 pointer-events-none"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                ></path>
                            </svg>
                            <input
                                type="text"
                                class="flex-grow block w-full pr-2 edit-field-text-color form-input-date py-1 text-center"
                                :class="{ 'pr-11': hideIcon, 'bg-white': !hideFieldModel, 'bg-gray-400 border-gray-400': hideFieldModel }"
                                :value="inputValue"
                                v-on="inputEvents"
                                autocomplete="off"
                                :placeholder="fieldPlaceholder"
                            />
                            <div v-if="hideIcon" class="absolute top-0 right-0 bg-orange-500 text-white w-12 rounded-r-md flex items-center justify-center cursor-pointer" style="height: 34px;">
                                <div v-if="hideFieldModel" @click="toggleHide()" class="cursor-pointer flex items-center">
                                    <fa-icon icon="eye-slash" type="fas" class="h-6 w-6" />
                                </div>
                                <div v-if="!hideFieldModel" @click="toggleHide()" class="cursor-pointer flex items-center">
                                    <fa-icon icon="eye" type="fas" class="h-6 w-6" />
                                </div>
                            </div>
                        </div>
                    </template>
                </date-picker>
            </div>
            <div v-else :class="{ 'w-1/2 pr-2 py-3': isDesktopWidth && !forceMobile, 'w-full': !isDesktopWidth || forceMobile }">
                <div class="form-input edit-field-text-color bg-gray-400 border-gray-400 border py-1">
                    {{ getGroupBuyTime(fieldDisplayValue) }}
                </div>
            </div>
        </div>
        <div v-if="fieldSubLabel && !fieldNonEdit" class="text-sm text-center p-2">
            {{ fieldSubLabel }}
        </div>
        <slot /> 
    </div>
</template>

<script>
import { Calendar, DatePicker } from 'v-calendar'

export default {
    props: [
        'fieldDisplayValue',
        'fieldFormName', 
        'fieldFormSubObjectName', 
        'fieldLabel', 
        'fieldLabelClasses',
        'fieldNonEdit',
        'fieldNude',
        'fieldPlaceholder',
        'fieldRequired', 
        'fieldSelectMode',
        'fieldSubLabel',
        'fieldValue',
        'forceMobile',
        'hideFieldFormName',
        'hideFieldValue',
        'hideIcon',
        'hideLabel'
    ],
    components: {
        DatePicker
    },
    data() {
        return {
            fieldModel: null,
            formLoaded: false,
            hideFieldModel: null,
            masks: {
                input: 'DD/MM/YYYY',
                inputDateTime: 'DD/MM/YYYY h:mm A',
                inputTime: 'h:mm A'
            },
            selectMode: null
        }
    },
    watch: {
        fieldModel: {
            handler(val) {
                if (this.formLoaded) {
                    this.$emit('fieldChange', this.fieldFormName, this.fieldModel, this.fieldFormSubObjectName)
                }
            }
        },
        // fieldValue: {
        //     handler(val) {
        //         this.fieldModel = val
        //     }
        // },
        hideFieldValue: {
            handler(val) {
                this.hideFieldModel = val
            }
        }
    },
    mounted() {
        this.fieldModel = this.fieldValue
        this.hideFieldModel = this.hideFieldValue
        this.selectMode = this.fieldSelectMode ?? "dateTime"
        this.formLoaded = true
    },
    methods: {
        toggleHide() {
            this.hideFieldModel = !this.hideFieldModel
            this.$emit('fieldInput', this.hideFieldFormName, this.hideFieldModel)
        }
    }
}
</script>