<template>
    <div v-if="featureListRecord">
        <div class="flex items-stretch w-full orange-transparent rounded-md mt-px" style="min-height: 5rem;">
            <div @click="clickEditFeatureListRecord()" class="flex items-stretch w-full">
                <div class="py-1 handle-record cursor-move white-transparent px-2 flex items-center mr-px">
                    <fa-icon icon="up-down-left-right" type="fas" class="h-6 w-6" />
                </div>
                <div class="white-transparent p-1 w-full flex items-center cursor-pointer">
                    <div v-if="featureListRecord.gallery && featureListRecord.gallery.length" class="relative w-20 h-full flex items-center mr-1" style="min-width: 5rem; min-height: 5rem;">
                        <offline-media-card 
                            :element="featureListRecord.gallery[0]" :filesCount="1" :hideDelete="true" :hideSpinner="true" :index="0"
                        />
                    </div>
                    <div class="text-left">
                        <div class="text-sm font-semibold leading-5 break-words line-clamp-2" v-html="featureListRecord.textOne">
                        </div>
                        <div class="text-xs leading-5 break-words line-clamp-2" v-html="featureListRecord.textTwo">
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex items-stretch justify-end">
                <div class="w-full white-transparent text-gray-600">
                    <div @click="clickDeleteFeatureListRecord()" class="cursor-pointer p-2 flex items-center">
                        <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                    </div>
                    <div @click="clickEditFeatureListRecord()" class="cursor-pointer p-2 flex items-center">
                        <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OfflineMediaCard from '@/shared/Cards/OfflineMediaCard'

export default {
    props: [
        'featureListRecord'
    ],
    components: {
        OfflineMediaCard
    },
    methods: {
        clickDeleteFeatureListRecord() {
            if (confirm("Confirm you wish to delete this line?")) {
                this.$emit('deleteFeatureListRecord', this.featureListRecord.id)
            }
        },
        clickEditFeatureListRecord() {
            this.$emit('editFeatureListRecord', this.featureListRecord.id)
        }
    }
}
</script>