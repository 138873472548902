<template>
    <div v-if="formLoaded" class="w-full">
        <select-panel
            :fieldData="noYesData" fieldFormName="disabled" fieldLabel="Activate This Product?" fieldLabelClasses="text-gray-800" 
            :fieldRequired="true" :fieldValue="productForm.disabled" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateProductFormSelectValue"
        >
            <validation-message v-if="validating && productForm.disabled == null" 
                message="Select yes or no"
            />
        </select-panel>
    </div>
</template>

<script>
export default {
    props: [
        'productForm',
        'validating'
    ],
    data() {
        return {
            formLoaded: false
        }
    },
    mounted() {
        this.formLoaded = true
    },
    methods: {
        updateProductFormSelectValue(inField, inValue) {
            this.$emit('updateProductFormSelectValue', inField, inValue)
        }
    }
}
</script>