<template>
    <div class="w-full mt-px" :class="{ 'px-2 py-3': (!isDesktopWidth || forceMobile) && !fieldNude, 'white-transparent': !fieldNude }">
        <div class="w-full" :class="{ 'flex items-stretch': isDesktopWidth && !forceMobile }">
            <div v-if="!hideLabel" :class="{ 'w-1/2 py-3 pl-2 pr-1': isDesktopWidth && !forceMobile, 'w-full': !isDesktopWidth || forceMobile }">
                <panel-label 
                    :fieldLabel="fieldLabel" :fieldRequired="fieldRequired && !fieldNonEdit" :forceMobile="forceMobile" :hide="hideFieldModel" :fieldLabelClasses="fieldLabelClasses"
                />
            </div>
            <div class="relative" :class="{ 'w-1/2 pr-2 py-3': isDesktopWidth && !forceMobile, 'w-full': !isDesktopWidth || forceMobile }">
                <div v-if="!fieldNonEdit && !isSingleValue && !isLabelAndValue && !isReverseLabelAndValue" :class="{ 'pr-11': hideIcon }">
                    <select v-model="fieldModel" @change="fieldChange()" @keyup.enter="hitEnter()" @keyup.escape="hitEscape()" class="form-input edit-field-text-color w-full pr-8 text-center py-2 sm:py-1" :class="{ 'bg-white': !hideFieldModel, 'bg-gray-400 border-gray-400': hideFieldModel, [fieldClasses]: fieldClasses }">
                        <template v-for="(record, index) in fieldData" :key="index">
                            <option :value="record[fieldDataValue]">{{ record[fieldDataLabel] }}</option>
                        </template>
                    </select>
                </div>
                <div v-if="!fieldNonEdit && isSingleValue" :class="{ 'pr-11': hideIcon }">
                    <select v-model="fieldModel" @change="fieldChange()" @keyup.enter="hitEnter()" @keyup.escape="hitEscape()" class="form-input edit-field-text-color w-full pr-8 text-center py-2 sm:py-1" :class="{ 'bg-white': !hideFieldModel, 'bg-gray-400 border-gray-400': hideFieldModel, [fieldClasses]: fieldClasses }">
                        <template v-for="(value, index) in fieldData" :key="index">
                            <option :value="value">{{ value }}</option>
                        </template>
                    </select>
                </div>
                <div v-if="!fieldNonEdit && isLabelAndValue" :class="{ 'pr-11': hideIcon }">
                    <select v-model="fieldModel" @change="fieldChange()" @keyup.enter="hitEnter()" @keyup.escape="hitEscape()" class="form-input edit-field-text-color w-full pr-8 text-center py-2 sm:py-1" :class="{ 'bg-white': !hideFieldModel, 'bg-gray-400 border-gray-400': hideFieldModel, [fieldClasses]: fieldClasses }">
                        <template v-for="(value, name) in fieldData" :key="value">
                            <option :value="value">{{ name }}</option>
                        </template>
                    </select>
                </div>
                <div v-if="!fieldNonEdit && isReverseLabelAndValue" :class="{ 'pr-11': hideIcon }">
                    <select v-model="fieldModel" @change="fieldChange()" @keyup.enter="hitEnter()" @keyup.escape="hitEscape()" class="form-input edit-field-text-color w-full pr-8 text-center py-2 sm:py-1" :class="{ 'bg-white': !hideFieldModel, 'bg-gray-400 border-gray-400': hideFieldModel, [fieldClasses]: fieldClasses }">
                        <template v-for="(value, name) in fieldData" :key="value">
                            <option :value="name">{{ value }}</option>
                        </template>
                    </select>
                </div>
                <div v-if="fieldNonEdit" class="form-input edit-field-text-color bg-gray-400 border-gray-400 border py-1 w-full">
                    {{ fieldNonEditValue }}
                </div>
                <div v-if="!fieldNonEdit && hideIcon" class="absolute right-2 bg-orange-500 text-white w-12 rounded-r-md flex items-center justify-center cursor-pointer" :class="{ 'top-3': isDesktopWidth, 'top-0': !isDesktopWidth }" style="height: 34px;">
                    <div v-if="hideFieldModel" @click="toggleHide()" class="cursor-pointer flex items-center">
                        <fa-icon icon="eye-slash" type="fas" class="h-6 w-6" />
                    </div>
                    <div v-if="!hideFieldModel" @click="toggleHide()" class="cursor-pointer flex items-center">
                        <fa-icon icon="eye" type="fas" class="h-6 w-6" />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="fieldSubLabel" class="text-sm text-center px-2 pb-2">
            {{ fieldSubLabel }}
        </div>
        <slot /> 
    </div>
</template>

<script>
export default {
    props: [
        'fieldClasses',
        'fieldData', 
        'fieldDataLabel', 
        'fieldDataValue', 
        'fieldFormName', 
        'fieldFormSubObjectName', 
        'fieldLabel',
        'fieldLabelClasses', 
        'fieldNonEdit',
        'fieldNonEditValue',
        'fieldNude',
        'fieldRequired', 
        'fieldSubLabel',
        'fieldValue',
        'forceMobile',
        'hideFieldFormName',
        'hideFieldValue',
        'hideIcon',
        'hideLabel',
        'isLabelAndValue',
        'isReverseLabelAndValue',
        'isSingleValue'
    ],
    data() {
        return {
            fieldModel: null,
            hideFieldModel: null
        }
    },
    watch: {
        fieldValue: {
            handler(val) {
                this.fieldModel = val
            }
        },
        hideFieldValue: {
            handler(val) {
                this.hideFieldModel = val
            }
        }
    },
    mounted() {
        this.fieldModel = this.fieldValue
        this.hideFieldModel = this.hideFieldValue
    },
    methods: {
        fieldChange() {
            this.$emit('fieldChange', this.fieldFormName, this.fieldModel, this.fieldFormSubObjectName)
        },
        hitEnter() {
            this.$emit('hitEnter')
        },
        hitEscape() {
            this.$emit('hitEscape')
        },
        toggleHide() {
            this.hideFieldModel = !this.hideFieldModel
            this.$emit('fieldChange', this.hideFieldFormName, this.hideFieldModel, this.fieldFormSubObjectName)
        }
    }
}
</script>