<template>
    <wizard-table-container v-if="trait"
        :childTable="true" :hideHeader="true"
    >
        <template #records>
            <table-record>
                <template #record>
                    <div class="flex items-center justify-start w-full pl-2 relative">
                        <child-line-prefix />
                        <div>
                            Detail Blocks
                        </div>
                        <div v-if="!hideIcons && showMe" class="absolute -bottom-3 right-0 flex items-center justify-end text-xxs text-center">
                            <div class="w-20 min-w-20">
                                EXPANDABLE
                            </div>
                            <div class="w-20 min-w-20 mr-2">
                                ENABLED
                            </div>
                        </div>
                    </div>
                    <div v-if="!hideIcons" class="wizard-icon-container w-34">
                        <div @click="clickToggleShowDetailBlockGroups()" v-show="!showMe" class="cursor-pointer">
                            <fa-icon icon="chevron-left" type="fas" class="h-5 w-5" />
                        </div>
                        <div @click="clickToggleShowDetailBlockGroups()" v-show="showMe" class="cursor-pointer">
                            <fa-icon icon="chevron-down" type="fas" class="h-5 w-5" />
                        </div>
                        <div v-if="editingBlocks" @click="clickAddGroup()" class="cursor-pointer ml-2">
                            <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                        </div>
                        <div v-if="!editingBlocks" @click="clickEditBlocks()" class="cursor-pointer ml-2">
                            <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                        </div>
                        <div v-if="editingBlocks" @click="clickEditBlocks()" class="cursor-pointer ml-2">
                            <fa-icon icon="eye" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                </template>
                <template #extra>
                    <div v-if="showMe" class="w-full">
                        <view-detail-blocks v-if="!editingBlocks"
                            :mediaBlocks="trait.mediaBlocks"
                        />
                        <edit-detail-block-groups v-else
                            :addingBlockIndex="addingBlockIndex" :addingGroupId="addingGroupId" :addTopGroup="addTopGroup" :hideIcons="hideIcons" 
                            :inMediaBlocks="trait.mediaBlocks" :traitId="traitId"
                            @clickEditBlock="clickEditBlock" @clickNewBlock="clickNewBlock" @resetAddTopGroup="resetAddTopGroup" @saveOrder="saveOrder" 
                            @switchHideIcons="switchHideIcons" @updateAddingBlockIndex="updateAddingBlockIndex" @updateAddingGroupId="updateAddingGroupId" 
                            @updateBlocks="updateBlocks" @updateGroup="updateGroup" @updateMediaBlocks="updateMediaBlocks"
                        />
                    </div>
                </template>
            </table-record>
        </template>
    </wizard-table-container>
</template>

<script>
import EditDetailBlockGroups from './EditDetailBlockGroups'
import TableRecord from '@/wizards/components/TableRecord'
import ViewDetailBlocks from './ViewDetailBlocks'

export default {
    props: [
        'addingBlockIndex',
        'addingGroupId',
        'hideIcons',
        'traitId',
        'traits'
    ],
    components: {
        EditDetailBlockGroups,
        TableRecord,
        ViewDetailBlocks
    },
    data() {
        return {
            addTopGroup: false,
            editingBlocks: true,
            showMe: false,
            trait: null
        }
    },
    watch: {
        traits: {
            handler(val) {
                this.trait = this.objCopy(this.traits.find((myTrait) => myTrait.id == this.traitId))
            }
        }
    },
    mounted() {
        this.trait = this.objCopy(this.traits.find((myTrait) => myTrait.id == this.traitId))
    },
    methods: {
        clickAddGroup() {
            this.showMe = true
            this.addTopGroup = true
        },
        clickEditBlocks() {
            this.showMe = true
            this.editingBlocks = !this.editingBlocks
        },
        clickEditBlock(inIndex, inType) {
            this.$emit('clickEditBlock', inIndex, this.traitId, inType)
        },
        clickNewBlock(inType) {
            this.$emit('clickNewBlock', inType)
        },
        clickToggleShowDetailBlockGroups() {
            this.showMe = !this.showMe
        },
        resetAddTopGroup() {
            this.addTopGroup = false
        },
        switchHideIcons(inSwitch) {
            this.$emit('switchHideIcons', inSwitch)
        },
        updateAddingBlockIndex(inIndex, inGroupId, inTraitId) {
            this.$emit('updateAddingBlockIndex', inIndex, inGroupId, inTraitId)
        },
        updateAddingGroupId(inId, inTraitId) {
            this.$emit('updateAddingGroupId', inId, inTraitId)
        },
        updateBlocks(inBlocks, inGroupId) {
            let myGroup = this.trait.mediaBlocks.groups.find((myG) => myG.id == inGroupId)
            myGroup.blocks = inBlocks
            this.$emit('updateTrait', this.trait)
        },
        updateGroup(inGroup) {
            console.log('g', inGroup)
            let myGroup = this.trait.mediaBlocks.groups.find((myGroup) => myGroup.id == inGroup.id)
            myGroup.label = inGroup.label
            myGroup.expandable = inGroup.expandable
            console.log('mg', myGroup)
            console.log('t', this.trait)
            this.$emit('updateTrait', this.trait)
        },
        updateMediaBlocks(inMediaBlocks) {
            this.trait.mediaBlocks = inMediaBlocks
            this.$emit('updateTrait', this.trait)
        }
    }
}
</script>