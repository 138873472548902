import { createStore } from 'vuex'

const store = createStore({
    state: {
        abGroupBuys: {},
        activatingNewSeller: false,
        activityEntity: null,
        activityToEdit: null,
        activityToShow: null,
        activityType: null,
        afterRegisterRoute: null,
        algoliaAppId: "KL228HWQFU",
        algoliaPosition: null,
        algoliaPublicKey: "2dfb201324d5d5992083848029344974",
        algoliaQueryId: null,
        algoliaSearchClient: null,
        alreadyShownOnboardingModalsThisSession: false,
        appsFlyerConnected: false,
        appsFlyerOneLink: 'https://buildzpro.onelink.me/Zwh2/b8xiaicb',
        appSounds: [
            {
                id: "commonBadge",
                file: "Game_Coin_Common.mp3"
            },
            {
                id: "specialBadge",
                file: "Game_Success_Special.mp3"
            },
            {
                id: "rareBadge",
                file: "Game_Win_Rare.mp3"
            },
            {
                id: "legendaryBadge",
                file: "Game_Award_Legendary.mp3"
            }
        ],
        appStoreProducts: {
            productBuildUnlock: null,
            productLootboxOne: null,
            productLootboxFive: null,
            productLootboxTen: null,
            productVmProComboUnlock: null,
            productVmEntryUnlock: null
        },
        appUrl: "https://buildz.pro/",
        backgroundPostReaction: null,
        badgeAwarded: null,
        badgePurchaseBadge: null,
        badgePurchaseCategory: null,
        buildsMenuOpen: false,
        buildTemplates: null,
        buildTypes: null,
        buildzLogo: "https://res.cloudinary.com/dnnzvmbis/image/upload/v1660055566/183675517-04802167-3622-4cfb-929c-d4833424c8a2_evequo.png",
        cachedHomeFeed: null,
        campaignToEdit: null,
        cart: null,
        chatActivities: null,
        chatEditForm: null,
        chatFeed: null,
        chatFeeds: [],
        chats: [],
        checkoutBuyNowQty: null,
        checkoutCart: null,
        checkoutCustomerDetails: null,
        checkoutEOIProducts: null,
        checkoutFailedSale: null,
        checkoutGroupBuy: null,
        checkoutProduct: null,
        checkoutShippingDetails: null,
        checkoutType: null,
        cloudinaryFolder: "buildz_pro_public",
        cloudinaryName: "dnnzvmbis",
        cloudinaryPreset: "hf4n8toj",
        communitiesModalData: null,
        communityLists: null,
        countryCode: null,
        countryData: [
            {
                name: "Afghanistan",
                dial_code: "+93",
                code: "AF",
                alt_code: "AFG"
            },
            {
                name: "Aland Islands",
                dial_code: "+358",
                code: "AX",
                alt_code: "ALA"
            },
            {
                name: "Albania",
                dial_code: "+355",
                code: "AL",
                alt_code: "ALB"
            },
            {
                name: "Algeria",
                dial_code: "+213",
                code: "DZ",
                alt_code: "DZA"
            },
            {
                name: "American Samoa",
                dial_code: "+1684",
                code: "AS",
                alt_code: "ASM"
            },
            {
                name: "Andorra",
                dial_code: "+376",
                code: "AD",
                alt_code: "AND"
            },
            {
                name: "Angola",
                dial_code: "+244",
                code: "AO",
                alt_code: "AGO"
            },
            {
                name: "Anguilla",
                dial_code: "+1264",
                code: "AI",
                alt_code: "AIA"
            },
            {
                name: "Antarctica",
                dial_code: "+672",
                code: "AQ",
                alt_code: "ATA"
            },
            {
                name: "Antigua and Barbuda",
                dial_code: "+1268",
                code: "AG",
                alt_code: "ATG"
            },
            {
                name: "Argentina",
                dial_code: "+54",
                code: "AR",
                alt_code: "ARG"
            },
            {
                name: "Armenia",
                dial_code: "+374",
                code: "AM",
                alt_code: "ARM"
            },
            {
                name: "Aruba",
                dial_code: "+297",
                code: "AW",
                alt_code: "ABW"
            },
            {
                name: "Australia",
                dial_code: "+61",
                code: "AU",
                alt_code: "AUS",
                google_places: {
                    city: ['locality'],
                    line1: ['subpremise', '/', 'street_number', ' ', 'route'],
                    line2: [],
                    postal_code: ['postal_code'],
                    state: ['administrative_area_level_1']
                },
                default_currency: "AUD",
                default_tax_label: "GST"
            },
            {
                name: "Austria",
                dial_code: "+43",
                code: "AT",
                alt_code: "AUT"
            },
            {
                name: "Azerbaijan",
                dial_code: "+994",
                code: "AZ",
                alt_code: "AZE"
            },
            {
                name: "Bahamas",
                dial_code: "+1242",
                code: "BS",
                alt_code: "BHS"
            },
            {
                name: "Bahrain",
                dial_code: "+973",
                code: "BH",
                alt_code: "BHR"
            },
            {
                name: "Bangladesh",
                dial_code: "+880",
                code: "BD",
                alt_code: "BGD"
            },
            {
                name: "Barbados",
                dial_code: "+1246",
                code: "BB",
                alt_code: "BRB"
            },
            {
                name: "Belarus",
                dial_code: "+375",
                code: "BY",
                alt_code: "BLR"
            },
            {
                name: "Belgium",
                dial_code: "+32",
                code: "BE",
                alt_code: "BEL"
            },
            {
                name: "Belize",
                dial_code: "+501",
                code: "BZ",
                alt_code: "BLZ"
            },
            {
                name: "Benin",
                dial_code: "+229",
                code: "BJ",
                alt_code: "BEN"
            },
            {
                name: "Bermuda",
                dial_code: "+1441",
                code: "BM",
                alt_code: "BMU"
            },
            {
                name: "Bhutan",
                dial_code: "+975",
                code: "BT",
                alt_code: "BTN"
            },
            {
                name: "Bolivia, Plurinational State of",
                dial_code: "+591",
                code: "BO",
                alt_code: "BOL"
            },
            {
                name: "Bosnia and Herzegovina",
                dial_code: "+387",
                code: "BA",
                alt_code: "BIH"
            },
            {
                name: "Botswana",
                dial_code: "+267",
                code: "BW",
                alt_code: "BWA"
            },
            {
                name: "Bouvet Island",
                dial_code: "+47",
                code: "BV",
                alt_code: "BVT"
            },
            {
                name: "Brazil",
                dial_code: "+55",
                code: "BR",
                alt_code: "BRA"
            },
            {
                name: "British Indian Ocean Territory",
                dial_code: "+246",
                code: "IO",
                alt_code: "IOT"
            },
            {
                name: "Brunei Darussalam",
                dial_code: "+673",
                code: "BN",
                alt_code: "BRN"
            },
            {
                name: "Bulgaria",
                dial_code: "+359",
                code: "BG",
                alt_code: "BGR"
            },
            {
                name: "Burkina Faso",
                dial_code: "+226",
                code: "BF",
                alt_code: "BFA"
            },
            {
                name: "Burundi",
                dial_code: "+257",
                code: "BI",
                alt_code: "BDI"
            },
            {
                name: "Cambodia",
                dial_code: "+855",
                code: "KH",
                alt_code: "KHM"
            },
            {
                name: "Cameroon",
                dial_code: "+237",
                code: "CM",
                alt_code: "CMR"
            },
            {
                name: "Canada",
                dial_code: "+1",
                code: "CA",
                alt_code: "CAN",
                google_places: {
                    city: ['locality'],
                    line1: ['subpremise', '/', 'street_number', ' ', 'route'],
                    line2: [],
                    postal_code: ['postal_code'],
                    state: ['administrative_area_level_1']
                }
            },
            {
                name: "Cape Verde",
                dial_code: "+238",
                code: "CV",
                alt_code: "CPV"
            },
            {
                name: "Bonaire, Sint Eustatius and Saba",
                dial_code: "+599",
                code: "BQ",
                alt_code: "BES"
            },
            {
                name: "Cayman Islands",
                dial_code: "+345",
                code: "KY",
                alt_code: "CYM"
            },
            {
                name: "Central African Republic",
                dial_code: "+236",
                code: "CF",
                alt_code: "CAF"
            },
            {
                name: "Chad",
                dial_code: "+235",
                code: "TD",
                alt_code: "TCD"
            },
            {
                name: "Chile",
                dial_code: "+56",
                code: "CL",
                alt_code: "CHL"
            },
            {
                name: "China",
                dial_code: "+86",
                code: "CN",
                alt_code: "CHN"
            },
            {
                name: "Christmas Island",
                dial_code: "+61",
                code: "CX",
                alt_code: "CXR"
            },
            {
                name: "Cocos (Keeling) Islands",
                dial_code: "+61",
                code: "CC",
                alt_code: "CCK"
            },
            {
                name: "Colombia",
                dial_code: "+57",
                code: "CO",
                alt_code: "COL"
            },
            {
                name: "Comoros",
                dial_code: "+269",
                code: "KM",
                alt_code: "COM"
            },
            {
                name: "Congo",
                dial_code: "+242",
                code: "CG",
                alt_code: "COG"
            },
            {
                name: "Congo, The Democratic Republic of the Congo",
                dial_code: "+243",
                code: "CD",
                alt_code: "COD"
            },
            {
                name: "Cook Islands",
                dial_code: "+682",
                code: "CK",
                alt_code: "COK"
            },
            {
                name: "Costa Rica",
                dial_code: "+506",
                code: "CR",
                alt_code: "CRI"
            },
            {
                name: "Cote d'Ivoire",
                dial_code: "+225",
                code: "CI",
                alt_code: "CIV"
            },
            {
                name: "Croatia",
                dial_code: "+385",
                code: "HR",
                alt_code: "HRV"
            },
            {
                name: "Cuba",
                dial_code: "+53",
                code: "CU",
                alt_code: "CUB"
            },
            {
                name: "Curaçao",
                dial_code: "+599",
                code: "CW",
                alt_code: "CUW"
            },
            {
                name: "Cyprus",
                dial_code: "+357",
                code: "CY",
                alt_code: "CYP"
            },
            {
                name: "Czech Republic",
                dial_code: "+420",
                code: "CZ",
                alt_code: "CZE"
            },
            {
                name: "Denmark",
                dial_code: "+45",
                code: "DK",
                alt_code: "DNK"
            },
            {
                name: "Djibouti",
                dial_code: "+253",
                code: "DJ",
                alt_code: "DJI"
            },
            {
                name: "Dominica",
                dial_code: "+1767",
                code: "DM",
                alt_code: "DMA"
            },
            {
                name: "Dominican Republic",
                dial_code: "+1849",
                code: "DO",
                alt_code: "DOM"
            },
            {
                name: "Ecuador",
                dial_code: "+593",
                code: "EC",
                alt_code: "ECU"
            },
            {
                name: "Egypt",
                dial_code: "+20",
                code: "EG",
                alt_code: "EGY"
            },
            {
                name: "El Salvador",
                dial_code: "+503",
                code: "SV",
                alt_code: "SLV"
            },
            {
                name: "Equatorial Guinea",
                dial_code: "+240",
                code: "GQ",
                alt_code: "GNQ"
            },
            {
                name: "Eritrea",
                dial_code: "+291",
                code: "ER",
                alt_code: "ERI"
            },
            {
                name: "Estonia",
                dial_code: "+372",
                code: "EE",
                alt_code: "EST"
            },
            {
                name: "Ethiopia",
                dial_code: "+251",
                code: "ET",
                alt_code: "ETH"
            },
            {
                name: "Falkland Islands (Malvinas)",
                dial_code: "+500",
                code: "FK",
                alt_code: "FLK"
            },
            {
                name: "Faroe Islands",
                dial_code: "+298",
                code: "FO",
                alt_code: "FRO"
            },
            {
                name: "Fiji",
                dial_code: "+679",
                code: "FJ",
                alt_code: "FJI"
            },
            {
                name: "Finland",
                dial_code: "+358",
                code: "FI",
                alt_code: "FIN"
            },
            {
                name: "France",
                dial_code: "+33",
                code: "FR",
                alt_code: "FRA"
            },
            {
                name: "French Guiana",
                dial_code: "+594",
                code: "GF",
                alt_code: "GUF"
            },
            {
                name: "French Polynesia",
                dial_code: "+689",
                code: "PF",
                alt_code: "PYF"
            },
            {
                name: "French Southern Territories",
                dial_code: "+262",
                code: "TF",
                alt_code: "ATF"
            },
            {
                name: "Gabon",
                dial_code: "+241",
                code: "GA",
                alt_code: "GAB"
            },
            {
                name: "Gambia",
                dial_code: "+220",
                code: "GM",
                alt_code: "GMB"
            },
            {
                name: "Georgia",
                dial_code: "+995",
                code: "GE",
                alt_code: "GEO"
            },
            {
                name: "Germany",
                dial_code: "+49",
                code: "DE",
                alt_code: "DEU",
                google_places: {
                    city: ['locality'],
                    line1: ['route', ' ', 'subpremise', '/', 'street_number'],
                    line2: [],
                    postal_code: ['postal_code'],
                    state: []
                }
            },
            {
                name: "Ghana",
                dial_code: "+233",
                code: "GH",
                alt_code: "GHA"
            },
            {
                name: "Gibraltar",
                dial_code: "+350",
                code: "GI",
                alt_code: "GIB"
            },
            {
                name: "Greece",
                dial_code: "+30",
                code: "GR",
                alt_code: "GRC"
            },
            {
                name: "Greenland",
                dial_code: "+299",
                code: "GL",
                alt_code: "GRL"
            },
            {
                name: "Grenada",
                dial_code: "+1473",
                code: "GD",
                alt_code: "GRD"
            },
            {
                name: "Guadeloupe",
                dial_code: "+590",
                code: "GP",
                alt_code: "GLP"
            },
            {
                name: "Guam",
                dial_code: "+1671",
                code: "GU",
                alt_code: "GUM"
            },
            {
                name: "Guatemala",
                dial_code: "+502",
                code: "GT",
                alt_code: "GTM"
            },
            {
                name: "Guernsey",
                dial_code: "+44",
                code: "GG",
                alt_code: "GGY"
            },
            {
                name: "Guinea",
                dial_code: "+224",
                code: "GN",
                alt_code: "GIN"
            },
            {
                name: "Guinea-Bissau",
                dial_code: "+245",
                code: "GW",
                alt_code: "GNB"
            },
            {
                name: "Guyana",
                dial_code: "+595",
                code: "GY",
                alt_code: "GUY"
            },
            {
                name: "Haiti",
                dial_code: "+509",
                code: "HT",
                alt_code: "HTI"
            },
            {
                name: "Heard Island and McDonald Islands",
                dial_code: "+672",
                code: "HM",
                alt_code: "HMD"
            },
            {
                name: "Holy See (Vatican City State)",
                dial_code: "+379",
                code: "VA",
                alt_code: "VAT"
            },
            {
                name: "Honduras",
                dial_code: "+504",
                code: "HN",
                alt_code: "HND"
            },
            {
                name: "Hong Kong",
                dial_code: "+852",
                code: "HK",
                alt_code: "HKG"
            },
            {
                name: "Hungary",
                dial_code: "+36",
                code: "HU",
                alt_code: "HUN"
            },
            {
                name: "Iceland",
                dial_code: "+354",
                code: "IS",
                alt_code: "ISL"
            },
            {
                name: "India",
                dial_code: "+91",
                code: "IN",
                alt_code: "IND"
            },
            {
                name: "Indonesia",
                dial_code: "+62",
                code: "ID",
                alt_code: "IDN",
                google_places: {
                    city: ['administrative_area_level_2'],
                    line1: ['route', ' No.', 'street_number'],
                    line2: ['administrative_area_level_4', ', ', 'administrative_area_level_3'],
                    postal_code: ['postal_code'],
                    state: ['administrative_area_level_1']
                },
                default_currency: "IDR",
                default_tax_label: "PPN"
            },
            {
                name: "Iran, Islamic Republic of Persian Gulf",
                dial_code: "+98",
                code: "IR",
                alt_code: "IRN"
            },
            {
                name: "Iraq",
                dial_code: "+964",
                code: "IQ",
                alt_code: "IRQ"
            },
            {
                name: "Ireland",
                dial_code: "+353",
                code: "IE",
                alt_code: "IRL"
            },
            {
                name: "Isle of Man",
                dial_code: "+44",
                code: "IM",
                alt_code: "IMN"
            },
            {
                name: "Israel",
                dial_code: "+972",
                code: "IL",
                alt_code: "ISR"
            },
            {
                name: "Italy",
                dial_code: "+39",
                code: "IT",
                alt_code: "ITA"
            },
            {
                name: "Jamaica",
                dial_code: "+1876",
                code: "JM",
                alt_code: "JAM"
            },
            {
                name: "Japan",
                dial_code: "+81",
                code: "JP",
                alt_code: "JPN"
            },
            {
                name: "Jersey",
                dial_code: "+44",
                code: "JE",
                alt_code: "JEY"
            },
            {
                name: "Jordan",
                dial_code: "+962",
                code: "JO",
                alt_code: "JOR"
            },
            {
                name: "Kazakhstan",
                dial_code: "+77",
                code: "KZ",
                alt_code: "KAZ"
            },
            {
                name: "Kenya",
                dial_code: "+254",
                code: "KE",
                alt_code: "KEN"
            },
            {
                name: "Kiribati",
                dial_code: "+686",
                code: "KI",
                alt_code: "KIR"
            },
            {
                name: "Korea, Democratic People's Republic of Korea",
                dial_code: "+850",
                code: "KP",
                alt_code: "PRK"
            },
            {
                name: "Korea, Republic of South Korea",
                dial_code: "+82",
                code: "KR",
                alt_code: "KOR"
            },
            {
                name: "Kuwait",
                dial_code: "+965",
                code: "KW",
                alt_code: "KWT"
            },
            {
                name: "Kyrgyzstan",
                dial_code: "+996",
                code: "KG",
                alt_code: "KGZ"
            },
            {
                name: "Laos",
                dial_code: "+856",
                code: "LA",
                alt_code: "LAO"
            },
            {
                name: "Latvia",
                dial_code: "+371",
                code: "LV",
                alt_code: "LVA"
            },
            {
                name: "Lebanon",
                dial_code: "+961",
                code: "LB",
                alt_code: "LBN"
            },
            {
                name: "Lesotho",
                dial_code: "+266",
                code: "LS",
                alt_code: "LSO"
            },
            {
                name: "Liberia",
                dial_code: "+231",
                code: "LR",
                alt_code: "LBR"
            },
            {
                name: "Libyan Arab Jamahiriya",
                dial_code: "+218",
                code: "LY",
                alt_code: "LBY"
            },
            {
                name: "Liechtenstein",
                dial_code: "+423",
                code: "LI",
                alt_code: "LIE"
            },
            {
                name: "Lithuania",
                dial_code: "+370",
                code: "LT",
                alt_code: "LTU"
            },
            {
                name: "Luxembourg",
                dial_code: "+352",
                code: "LU",
                alt_code: "LUX"
            },
            {
                name: "Macao",
                dial_code: "+853",
                code: "MO",
                alt_code: "MAC"
            },
            {
                name: "Macedonia",
                dial_code: "+389",
                code: "MK",
                alt_code: "MKD"
            },
            {
                name: "Madagascar",
                dial_code: "+261",
                code: "MG",
                alt_code: "MDG"
            },
            {
                name: "Malawi",
                dial_code: "+265",
                code: "MW",
                alt_code: "MWI"
            },
            {
                name: "Malaysia",
                dial_code: "+60",
                code: "MY",
                alt_code: "MYS",
                google_places: {
                    city: ['locality'],
                    line1: ['subpremise', '/', 'street_number', ' ', 'route'],
                    line2: ['sublocality_level_1'],
                    postal_code: ['postal_code'],
                    state: ['administrative_area_level_1']
                }
            },
            {
                name: "Maldives",
                dial_code: "+960",
                code: "MV",
                alt_code: "MDV"
            },
            {
                name: "Mali",
                dial_code: "+223",
                code: "ML",
                alt_code: "MLI"
            },
            {
                name: "Malta",
                dial_code: "+356",
                code: "MT",
                alt_code: "MLT"
            },
            {
                name: "Marshall Islands",
                dial_code: "+692",
                code: "MH",
                alt_code: "MHL"
            },
            {
                name: "Martinique",
                dial_code: "+596",
                code: "MQ",
                alt_code: "MTQ"
            },
            {
                name: "Mauritania",
                dial_code: "+222",
                code: "MR",
                alt_code: "MRT"
            },
            {
                name: "Mauritius",
                dial_code: "+230",
                code: "MU",
                alt_code: "MUS"
            },
            {
                name: "Mayotte",
                dial_code: "+262",
                code: "YT",
                alt_code: "MYT"
            },
            {
                name: "Mexico",
                dial_code: "+52",
                code: "MX",
                alt_code: "MEX"
            },
            {
                name: "Micronesia, Federated States of Micronesia",
                dial_code: "+691",
                code: "FM",
                alt_code: "FSM"
            },
            {
                name: "Moldova",
                dial_code: "+373",
                code: "MD",
                alt_code: "MDA"
            },
            {
                name: "Monaco",
                dial_code: "+377",
                code: "MC",
                alt_code: "MCO"
            },
            {
                name: "Mongolia",
                dial_code: "+976",
                code: "MN",
                alt_code: "MNG"
            },
            {
                name: "Montenegro",
                dial_code: "+382",
                code: "ME",
                alt_code: "MNE"
            },
            {
                name: "Montserrat",
                dial_code: "+1664",
                code: "MS",
                alt_code: "MSR"
            },
            {
                name: "Morocco",
                dial_code: "+212",
                code: "MA",
                alt_code: "MAR"
            },
            {
                name: "Mozambique",
                dial_code: "+258",
                code: "MZ",
                alt_code: "MOZ"
            },
            {
                name: "Myanmar",
                dial_code: "+95",
                code: "MM",
                alt_code: "MMR"
            },
            {
                name: "Namibia",
                dial_code: "+264",
                code: "NA",
                alt_code: "NAM"
            },
            {
                name: "Nauru",
                dial_code: "+674",
                code: "NR",
                alt_code: "NRU"
            },
            {
                name: "Nepal",
                dial_code: "+977",
                code: "NP",
                alt_code: "NPL"
            },
            {
                name: "Netherlands",
                dial_code: "+31",
                code: "NL",
                alt_code: "NLD"
            },
            {
                name: "New Caledonia",
                dial_code: "+687",
                code: "NC",
                alt_code: "NCL"
            },
            {
                name: "New Zealand",
                dial_code: "+64",
                code: "NZ",
                alt_code: "NZL",
                google_places: {
                    city: ['locality'],
                    line1: ['subpremise', '/', 'street_number', ' ', 'route'],
                    line2: ['sublocality_level_1'],
                    postal_code: ['postal_code'],
                    state: []
                }
            },
            {
                name: "Nicaragua",
                dial_code: "+505",
                code: "NI",
                alt_code: "NIC"
            },
            {
                name: "Niger",
                dial_code: "+227",
                code: "NE",
                alt_code: "NER"
            },
            {
                name: "Nigeria",
                dial_code: "+234",
                code: "NG",
                alt_code: "NGA"
            },
            {
                name: "Niue",
                dial_code: "+683",
                code: "NU",
                alt_code: "NIU"
            },
            {
                name: "Norfolk Island",
                dial_code: "+672",
                code: "NF",
                alt_code: "NFK"
            },
            {
                name: "Northern Mariana Islands",
                dial_code: "+1670",
                code: "MP",
                alt_code: "MNP"
            },
            {
                name: "Norway",
                dial_code: "+47",
                code: "NO",
                alt_code: "NOR"
            },
            {
                name: "Oman",
                dial_code: "+968",
                code: "OM",
                alt_code: "OMN"
            },
            {
                name: "Pakistan",
                dial_code: "+92",
                code: "PK",
                alt_code: "PAK"
            },
            {
                name: "Palau",
                dial_code: "+680",
                code: "PW",
                alt_code: "PLW"
            },
            {
                name: "Palestinian Territory, Occupied",
                dial_code: "+970",
                code: "PS",
                alt_code: "PSE"
            },
            {
                name: "Panama",
                dial_code: "+507",
                code: "PA",
                alt_code: "PAN"
            },
            {
                name: "Papua New Guinea",
                dial_code: "+675",
                code: "PG",
                alt_code: "PNG"
            },
            {
                name: "Paraguay",
                dial_code: "+595",
                code: "PY",
                alt_code: "PRY"
            },
            {
                name: "Peru",
                dial_code: "+51",
                code: "PE",
                alt_code: "PER"
            },
            {
                name: "Philippines",
                dial_code: "+63",
                code: "PH",
                alt_code: "PHL"
            },
            {
                name: "Pitcairn",
                dial_code: "+872",
                code: "PN",
                alt_code: "PCN"
            },
            {
                name: "Poland",
                dial_code: "+48",
                code: "PL",
                alt_code: "POL"
            },
            {
                name: "Portugal",
                dial_code: "+351",
                code: "PT",
                alt_code: "PRT"
            },
            {
                name: "Puerto Rico",
                dial_code: "+1939",
                code: "PR",
                alt_code: "PRI"
            },
            {
                name: "Qatar",
                dial_code: "+974",
                code: "QA",
                alt_code: "QAT"
            },
            {
                name: "Romania",
                dial_code: "+40",
                code: "RO",
                alt_code: "ROU"
            },
            {
                name: "Russia",
                dial_code: "+7",
                code: "RU",
                alt_code: "RUS"
            },
            {
                name: "Rwanda",
                dial_code: "+250",
                code: "RW",
                alt_code: "RWA"
            },
            {
                name: "Reunion",
                dial_code: "+262",
                code: "RE",
                alt_code: "REU"
            },
            {
                name: "Saint Barthelemy",
                dial_code: "+590",
                code: "BL",
                alt_code: "BLM"
            },
            {
                name: "Saint Helena, Ascension and Tristan Da Cunha",
                dial_code: "+290",
                code: "SH",
                alt_code: "SHN"
            },
            {
                name: "Saint Kitts and Nevis",
                dial_code: "+1869",
                code: "KN",
                alt_code: "KNA"
            },
            {
                name: "Saint Lucia",
                dial_code: "+1758",
                code: "LC",
                alt_code: "LCA"
            },
            {
                name: "Saint Martin",
                dial_code: "+590",
                code: "MF",
                alt_code: "MAF"
            },
            {
                name: "Saint Pierre and Miquelon",
                dial_code: "+508",
                code: "PM",
                alt_code: "SPM"
            },
            {
                name: "Saint Vincent and the Grenadines",
                dial_code: "+1784",
                code: "VC",
                alt_code: "VCT"
            },
            {
                name: "Samoa",
                dial_code: "+685",
                code: "WS",
                alt_code: "WSM"
            },
            {
                name: "San Marino",
                dial_code: "+378",
                code: "SM",
                alt_code: "SMR"
            },
            {
                name: "Sao Tome and Principe",
                dial_code: "+239",
                code: "ST",
                alt_code: "STP"
            },
            {
                name: "Saudi Arabia",
                dial_code: "+966",
                code: "SA",
                alt_code: "SAU"
            },
            {
                name: "Senegal",
                dial_code: "+221",
                code: "SN",
                alt_code: "SEN"
            },
            {
                name: "Serbia",
                dial_code: "+381",
                code: "RS",
                alt_code: "SRB"
            },
            {
                name: "Seychelles",
                dial_code: "+248",
                code: "SC",
                alt_code: "SYC"
            },
            {
                name: "Sierra Leone",
                dial_code: "+232",
                code: "SL",
                alt_code: "SLE"
            },
            {
                name: "Singapore",
                dial_code: "+65",
                code: "SG",
                alt_code: "SGP"
            },
            {
                name: "Sint Maarten",
                dial_code: "+1721",
                code: "SX",
                alt_code: "SXM"
            },
            {
                name: "Slovakia",
                dial_code: "+421",
                code: "SK",
                alt_code: "SVK"
            },
            {
                name: "Slovenia",
                dial_code: "+386",
                code: "SI",
                alt_code: "SVN"
            },
            {
                name: "Solomon Islands",
                dial_code: "+677",
                code: "SB",
                alt_code: "SLB"
            },
            {
                name: "Somalia",
                dial_code: "+252",
                code: "SO",
                alt_code: "SOM"
            },
            {
                name: "South Africa",
                dial_code: "+27",
                code: "ZA",
                alt_code: "ZAF"
            },
            {
                name: "South Sudan",
                dial_code: "+211",
                code: "SS",
                alt_code: "SSD"
            },
            {
                name: "South Georgia and the South Sandwich Islands",
                dial_code: "+500",
                code: "GS",
                alt_code: "SGS"
            },
            {
                name: "Spain",
                dial_code: "+34",
                code: "ES",
                alt_code: "ESP"
            },
            {
                name: "Sri Lanka",
                dial_code: "+94",
                code: "LK",
                alt_code: "LKA"
            },
            {
                name: "Sudan",
                dial_code: "+249",
                code: "SD",
                alt_code: "SDN"
            },
            {
                name: "Suriname",
                dial_code: "+597",
                code: "SR",
                alt_code: "SUR"
            },
            {
                name: "Svalbard and Jan Mayen",
                dial_code: "+47",
                code: "SJ",
                alt_code: "SJM"
            },
            {
                name: "Swaziland",
                dial_code: "+268",
                code: "SZ",
                alt_code: "SWZ"
            },
            {
                name: "Sweden",
                dial_code: "+46",
                code: "SE",
                alt_code: "SWE"
            },
            {
                name: "Switzerland",
                dial_code: "+41",
                code: "CH",
                alt_code: "CHE"
            },
            {
                name: "Syrian Arab Republic",
                dial_code: "+963",
                code: "SY",
                alt_code: "SYR"
            },
            {
                name: "Taiwan",
                dial_code: "+886",
                code: "TW",
                alt_code: "TWN"
            },
            {
                name: "Tajikistan",
                dial_code: "+992",
                code: "TJ",
                alt_code: "TJK"
            },
            {
                name: "Tanzania, United Republic of Tanzania",
                dial_code: "+255",
                code: "TZ",
                alt_code: "TZA"
            },
            {
                name: "Thailand",
                dial_code: "+66",
                code: "TH",
                alt_code: "THA",
                default_currency: "THB"
            },
            {
                name: "Timor-Leste",
                dial_code: "+670",
                code: "TL",
                alt_code: "TLS"
            },
            {
                name: "Togo",
                dial_code: "+228",
                code: "TG",
                alt_code: "TGO"
            },
            {
                name: "Tokelau",
                dial_code: "+690",
                code: "TK",
                alt_code: "TKL"
            },
            {
                name: "Tonga",
                dial_code: "+676",
                code: "TO",
                alt_code: "TON"
            },
            {
                name: "Trinidad and Tobago",
                dial_code: "+1868",
                code: "TT",
                alt_code: "TTO"
            },
            {
                name: "Tunisia",
                dial_code: "+216",
                code: "TN",
                alt_code: "TUN"
            },
            {
                name: "Turkey",
                dial_code: "+90",
                code: "TR",
                alt_code: "TUR"
            },
            {
                name: "Turkmenistan",
                dial_code: "+993",
                code: "TM",
                alt_code: "TKM"
            },
            {
                name: "Turks and Caicos Islands",
                dial_code: "+1649",
                code: "TC",
                alt_code: "TCA"
            },
            {
                name: "Tuvalu",
                dial_code: "+688",
                code: "TV",
                alt_code: "TUV"
            },
            {
                name: "Uganda",
                dial_code: "+256",
                code: "UG",
                alt_code: "UGA"
            },
            {
                name: "Ukraine",
                dial_code: "+380",
                code: "UA",
                alt_code: "UKR"
            },
            {
                name: "United Arab Emirates",
                dial_code: "+971",
                code: "AE",
                alt_code: "ARE"
            },
            {
                name: "United Kingdom",
                dial_code: "+44",
                code: "GB",
                alt_code: "GBR",
                google_places: {
                    city: ['administrative_area_level_2'],
                    line1: ['subpremise', '/', 'street_number', ' ', 'route'],
                    line2: [],
                    postal_code: ['postal_code'],
                    state: ['administrative_area_level_1']
                }
            },
            {
                name: "United States",
                dial_code: "+1",
                code: "US",
                alt_code: "USA",
                google_places: {
                    city: ['locality'],
                    line1: ['subpremise', '/', 'street_number', ' ', 'route'],
                    line2: [],
                    postal_code: ['postal_code'],
                    state: ['administrative_area_level_1']
                }
            },
            {
                name: "United States Minor Outlying Islands",
                dial_code: "+1",
                code: "UM",
                alt_code: "UMI"
            },
            {
                name: "Uruguay",
                dial_code: "+598",
                code: "UY",
                alt_code: "URY"
            },
            {
                name: "Uzbekistan",
                dial_code: "+998",
                code: "UZ",
                alt_code: "UZB"
            },
            {
                name: "Vanuatu",
                dial_code: "+678",
                code: "VU",
                alt_code: "VUT"
            },
            {
                name: "Venezuela, Bolivarian Republic of Venezuela",
                dial_code: "+58",
                code: "VE",
                alt_code: "VEN"
            },
            {
                name: "Vietnam",
                dial_code: "+84",
                code: "VN",
                alt_code: "VNM",
                google_places: {
                    city: ['administrative_area_level_2'],
                    line1: ['subpremise', '/', 'street_number', ' ', 'route'],
                    line2: [],
                    postal_code: ['postal_code'],
                    state: ['administrative_area_level_1']
                }
            },
            {
                name: "Virgin Islands, British",
                dial_code: "+1284",
                code: "VG",
                alt_code: "VGB"
            },
            {
                name: "Virgin Islands, U.S.",
                dial_code: "+1340",
                code: "VI",
                alt_code: "VIR"
            },
            {
                name: "Wallis and Futuna",
                dial_code: "+681",
                code: "WF",
                alt_code: "WLF"
            },
            {
                name: "Western Sahara",
                dial_code: "+212",
                code: "EH",
                alt_code: "ESH"
            },
            {
                name: "Yemen",
                dial_code: "+967",
                code: "YE",
                alt_code: "YEM"
            },
            {
                name: "Zambia",
                dial_code: "+260",
                code: "ZM",
                alt_code: "ZMB"
            },
            {
                name: "Zimbabwe",
                dial_code: "+263",
                code: "ZW",
                alt_code: "ZWE"
            }
        ],
        countryTimeZones: {
            "Europe/Andorra": "AD",
            "Asia/Dubai": "AE",
            "Asia/Kabul": "AF",
            "Europe/Tirane": "AL",
            "Asia/Yerevan": "AM",
            "Antarctica/Casey": "AQ",
            "Antarctica/Davis": "AQ",
            "Antarctica/Mawson": "AQ",
            "Antarctica/Palmer": "AQ",
            "Antarctica/Rothera": "AQ",
            "Antarctica/Troll": "AQ",
            "Antarctica/Vostok": "AQ",
            "America/Argentina/Buenos_Aires": "AR",
            "America/Argentina/Cordoba": "AR",
            "America/Argentina/Salta": "AR",
            "America/Argentina/Jujuy": "AR",
            "America/Argentina/Tucuman": "AR",
            "America/Argentina/Catamarca": "AR",
            "America/Argentina/La_Rioja": "AR",
            "America/Argentina/San_Juan": "AR",
            "America/Argentina/Mendoza": "AR",
            "America/Argentina/San_Luis": "AR",
            "America/Argentina/Rio_Gallegos": "AR",
            "America/Argentina/Ushuaia": "AR",
            "Pacific/Pago_Pago": "AS",
            "Europe/Vienna": "AT",
            "Australia/Lord_Howe": "AU",
            "Antarctica/Macquarie": "AU",
            "Australia/Hobart": "AU",
            "Australia/Melbourne": "AU",
            "Australia/Sydney": "AU",
            "Australia/Broken_Hill": "AU",
            "Australia/Brisbane": "AU",
            "Australia/Lindeman": "AU",
            "Australia/Adelaide": "AU",
            "Australia/Darwin": "AU",
            "Australia/Perth": "AU",
            "Australia/Eucla": "AU",
            "Asia/Baku": "AZ",
            "America/Barbados": "BB",
            "Asia/Dhaka": "BD",
            "Europe/Brussels": "BE",
            "Europe/Sofia": "BG",
            "Atlantic/Bermuda": "BM",
            "Asia/Brunei": "BN",
            "America/La_Paz": "BO",
            "America/Noronha": "BR",
            "America/Belem": "BR",
            "America/Fortaleza": "BR",
            "America/Recife": "BR",
            "America/Araguaina": "BR",
            "America/Maceio": "BR",
            "America/Bahia": "BR",
            "America/Sao_Paulo": "BR",
            "America/Campo_Grande": "BR",
            "America/Cuiaba": "BR",
            "America/Santarem": "BR",
            "America/Porto_Velho": "BR",
            "America/Boa_Vista": "BR",
            "America/Manaus": "BR",
            "America/Eirunepe": "BR",
            "America/Rio_Branco": "BR",
            "Asia/Thimphu": "BT",
            "Europe/Minsk": "BY",
            "America/Belize": "BZ",
            "America/St_Johns": "CA",
            "America/Halifax": "CA",
            "America/Glace_Bay": "CA",
            "America/Moncton": "CA",
            "America/Goose_Bay": "CA",
            "America/Toronto": "CA",
            "America/Nipigon": "CA",
            "America/Thunder_Bay": "CA",
            "America/Iqaluit": "CA",
            "America/Pangnirtung": "CA",
            "America/Winnipeg": "CA",
            "America/Rainy_River": "CA",
            "America/Resolute": "CA",
            "America/Rankin_Inlet": "CA",
            "America/Regina": "CA",
            "America/Swift_Current": "CA",
            "America/Edmonton": "CA",
            "America/Cambridge_Bay": "CA",
            "America/Yellowknife": "CA",
            "America/Inuvik": "CA",
            "America/Dawson_Creek": "CA",
            "America/Fort_Nelson": "CA",
            "America/Whitehorse": "CA",
            "America/Dawson": "CA",
            "America/Vancouver": "CA",
            "Indian/Cocos": "CC",
            "Europe/Zurich": "CH",
            "Africa/Abidjan": "CI",
            "Pacific/Rarotonga": "CK",
            "America/Santiago": "CL",
            "America/Punta_Arenas": "CL",
            "Pacific/Easter": "CL",
            "Asia/Shanghai": "CN",
            "Asia/Urumqi": "CN",
            "America/Bogota": "CO",
            "America/Costa_Rica": "CR",
            "America/Havana": "CU",
            "Atlantic/Cape_Verde": "CV",
            "Indian/Christmas": "CX",
            "Asia/Nicosia": "CY",
            "Asia/Famagusta": "CY",
            "Europe/Prague": "CZ",
            "Europe/Berlin": "DE",
            "Europe/Copenhagen": "DK",
            "America/Santo_Domingo": "DO",
            "Africa/Algiers": "DZ",
            "America/Guayaquil": "EC",
            "Pacific/Galapagos": "EC",
            "Europe/Tallinn": "EE",
            "Africa/Cairo": "EG",
            "Africa/El_Aaiun": "EH",
            "Europe/Madrid": "ES",
            "Africa/Ceuta": "ES",
            "Atlantic/Canary": "ES",
            "Europe/Helsinki": "FI",
            "Pacific/Fiji": "FJ",
            "Atlantic/Stanley": "FK",
            "Pacific/Chuuk": "FM",
            "Pacific/Pohnpei": "FM",
            "Pacific/Kosrae": "FM",
            "Atlantic/Faroe": "FO",
            "Europe/Paris": "FR",
            "Europe/London": "GB",
            "Asia/Tbilisi": "GE",
            "America/Cayenne": "GF",
            "Europe/Gibraltar": "GI",
            "America/Nuuk": "GL",
            "America/Danmarkshavn": "GL",
            "America/Scoresbysund": "GL",
            "America/Thule": "GL",
            "Europe/Athens": "GR",
            "Atlantic/South_Georgia": "GS",
            "America/Guatemala": "GT",
            "Pacific/Guam": "GU",
            "Africa/Bissau": "GW",
            "America/Guyana": "GY",
            "Asia/Hong_Kong": "HK",
            "America/Tegucigalpa": "HN",
            "America/Port-au-Prince": "HT",
            "Europe/Budapest": "HU",
            "Asia/Jakarta": "ID",
            "Asia/Pontianak": "ID",
            "Asia/Makassar": "ID",
            "Asia/Jayapura": "ID",
            "Europe/Dublin": "IE",
            "Asia/Jerusalem": "IL",
            "Asia/Kolkata": "IN",
            "Asia/Calcutta": "IN",
            "Indian/Chagos": "IO",
            "Asia/Baghdad": "IQ",
            "Asia/Tehran": "IR",
            "Atlantic/Reykjavik": "IS",
            "Europe/Rome": "IT",
            "America/Jamaica": "JM",
            "Asia/Amman": "JO",
            "Asia/Tokyo": "JP",
            "Africa/Nairobi": "KE",
            "Asia/Bishkek": "KG",
            "Pacific/Tarawa": "KI",
            "Pacific/Kanton": "KI",
            "Pacific/Kiritimati": "KI",
            "Asia/Pyongyang": "KP",
            "Asia/Seoul": "KR",
            "Asia/Almaty": "KZ",
            "Asia/Qyzylorda": "KZ",
            "Asia/Qostanay": "KZ",
            "Asia/Aqtobe": "KZ",
            "Asia/Aqtau": "KZ",
            "Asia/Atyrau": "KZ",
            "Asia/Oral": "KZ",
            "Asia/Beirut": "LB",
            "Asia/Colombo": "LK",
            "Africa/Monrovia": "LR",
            "Europe/Vilnius": "LT",
            "Europe/Luxembourg": "LU",
            "Europe/Riga": "LV",
            "Africa/Tripoli": "LY",
            "Africa/Casablanca": "MA",
            "Europe/Monaco": "MC",
            "Europe/Chisinau": "MD",
            "Pacific/Majuro": "MH",
            "Pacific/Kwajalein": "MH",
            "Asia/Yangon": "MM",
            "Asia/Ulaanbaatar": "MN",
            "Asia/Hovd": "MN",
            "Asia/Choibalsan": "MN",
            "Asia/Macau": "CN",
            "America/Martinique": "MQ",
            "Europe/Malta": "MT",
            "Indian/Mauritius": "MU",
            "Indian/Maldives": "MV",
            "America/Mexico_City": "MX",
            "America/Cancun": "MX",
            "America/Merida": "MX",
            "America/Monterrey": "MX",
            "America/Matamoros": "MX",
            "America/Mazatlan": "MX",
            "America/Chihuahua": "MX",
            "America/Ojinaga": "MX",
            "America/Hermosillo": "MX",
            "America/Tijuana": "MX",
            "America/Bahia_Banderas": "MX",
            "Asia/Kuala_Lumpur": "MY",
            "Asia/Kuching": "MY",
            "Africa/Maputo": "MZ",
            "Africa/Windhoek": "NA",
            "Pacific/Noumea": "NC",
            "Pacific/Norfolk": "NF",
            "Africa/Lagos": "NG",
            "America/Managua": "NI",
            "Europe/Amsterdam": "NL",
            "Europe/Oslo": "NO",
            "Asia/Kathmandu": "NP",
            "Pacific/Nauru": "NR",
            "Pacific/Niue": "NU",
            "Pacific/Auckland": "NZ",
            "Pacific/Chatham": "NZ",
            "America/Panama": "PA",
            "America/Lima": "PE",
            "Pacific/Tahiti": "PF",
            "Pacific/Marquesas": "PF",
            "Pacific/Gambier": "PF",
            "Pacific/Port_Moresby": "PG",
            "Pacific/Bougainville": "PG",
            "Asia/Manila": "PH",
            "Asia/Karachi": "PK",
            "Europe/Warsaw": "PL",
            "America/Miquelon": "PM",
            "Pacific/Pitcairn": "PN",
            "America/Puerto_Rico": "PR",
            "Asia/Gaza": "PS",
            "Asia/Hebron": "PS",
            "Europe/Lisbon": "PT",
            "Atlantic/Madeira": "PT",
            "Atlantic/Azores": "PT",
            "Pacific/Palau": "PW",
            "America/Asuncion": "PY",
            "Asia/Qatar": "QA",
            "Indian/Reunion": "RE",
            "Europe/Bucharest": "RO",
            "Europe/Belgrade": "RS",
            "Europe/Kaliningrad": "RU",
            "Europe/Moscow": "RU",
            "Europe/Simferopol": "RU",
            "Europe/Kirov": "RU",
            "Europe/Volgograd": "RU",
            "Europe/Astrakhan": "RU",
            "Europe/Saratov": "RU",
            "Europe/Ulyanovsk": "RU",
            "Europe/Samara": "RU",
            "Asia/Yekaterinburg": "RU",
            "Asia/Omsk": "RU",
            "Asia/Novosibirsk": "RU",
            "Asia/Barnaul": "RU",
            "Asia/Tomsk": "RU",
            "Asia/Novokuznetsk": "RU",
            "Asia/Krasnoyarsk": "RU",
            "Asia/Irkutsk": "RU",
            "Asia/Chita": "RU",
            "Asia/Yakutsk": "RU",
            "Asia/Khandyga": "RU",
            "Asia/Vladivostok": "RU",
            "Asia/Ust-Nera": "RU",
            "Asia/Magadan": "RU",
            "Asia/Sakhalin": "RU",
            "Asia/Srednekolymsk": "RU",
            "Asia/Kamchatka": "RU",
            "Asia/Anadyr": "RU",
            "Asia/Riyadh": "SA",
            "Pacific/Guadalcanal": "SB",
            "Indian/Mahe": "SC",
            "Africa/Khartoum": "SD",
            "Europe/Stockholm": "SE",
            "Asia/Singapore": "SG",
            "America/Paramaribo": "SR",
            "Africa/Juba": "SS",
            "Africa/Sao_Tome": "ST",
            "America/El_Salvador": "SV",
            "Asia/Damascus": "SY",
            "America/Grand_Turk": "TC",
            "Africa/Ndjamena": "TD",
            "Indian/Kerguelen": "TF",
            "Asia/Bangkok": "TH",
            "Asia/Dushanbe": "TJ",
            "Pacific/Fakaofo": "TK",
            "Asia/Dili": "TL",
            "Asia/Ashgabat": "TM",
            "Africa/Tunis": "TN",
            "Pacific/Tongatapu": "TO",
            "Europe/Istanbul": "TR",
            "Pacific/Funafuti": "TV",
            "Asia/Taipei": "TW",
            "Europe/Kiev": "UA",
            "Europe/Uzhgorod": "UA",
            "Europe/Zaporozhye": "UA",
            "Pacific/Wake": "UM",
            "America/New_York": "US",
            "America/Detroit": "US",
            "America/Kentucky/Louisville": "US",
            "America/Kentucky/Monticello": "US",
            "America/Indiana/Indianapolis": "US",
            "America/Indiana/Vincennes": "US",
            "America/Indiana/Winamac": "US",
            "America/Indiana/Marengo": "US",
            "America/Indiana/Petersburg": "US",
            "America/Indiana/Vevay": "US",
            "America/Chicago": "US",
            "America/Indiana/Tell_City": "US",
            "America/Indiana/Knox": "US",
            "America/Menominee": "US",
            "America/North_Dakota/Center": "US",
            "America/North_Dakota/New_Salem": "US",
            "America/North_Dakota/Beulah": "US",
            "America/Denver": "US",
            "America/Boise": "US",
            "America/Phoenix": "US",
            "America/Los_Angeles": "US",
            "America/Anchorage": "US",
            "America/Juneau": "US",
            "America/Sitka": "US",
            "America/Metlakatla": "US",
            "America/Yakutat": "US",
            "America/Nome": "US",
            "America/Adak": "US",
            "Pacific/Honolulu": "US",
            "America/Montevideo": "UY",
            "Asia/Samarkand": "UZ",
            "Asia/Tashkent": "UZ",
            "America/Caracas": "VE",
            "Asia/Ho_Chi_Minh": "VN",
            "Asia/Saigon": "VN",
            "Pacific/Efate": "VU",
            "Pacific/Wallis": "WF",
            "Pacific/Apia": "WS",
            "Africa/Johannesburg": "ZA",
            "America/Antigua": "AG",
            "America/Anguilla": "AI",
            "Africa/Luanda": "AO",
            "Antarctica/McMurdo": "AQ",
            "Antarctica/DumontDUrville": "AQ",
            "Antarctica/Syowa": "AQ",
            "America/Aruba": "AW",
            "Europe/Mariehamn": "AX",
            "Europe/Sarajevo": "BA",
            "Africa/Ouagadougou": "BF",
            "Asia/Bahrain": "BH",
            "Africa/Bujumbura": "BI",
            "Africa/Porto-Novo": "BJ",
            "America/St_Barthelemy": "BL",
            "America/Kralendijk": "BQ",
            "America/Nassau": "BS",
            "Africa/Gaborone": "BW",
            "America/Blanc-Sablon": "CA",
            "America/Atikokan": "CA",
            "America/Creston": "CA",
            "Africa/Kinshasa": "CD",
            "Africa/Lubumbashi": "CD",
            "Africa/Bangui": "CF",
            "Africa/Brazzaville": "CG",
            "Africa/Douala": "CM",
            "America/Curacao": "CW",
            "Europe/Busingen": "DE",
            "Africa/Djibouti": "DJ",
            "America/Dominica": "DM",
            "Africa/Asmara": "ER",
            "Africa/Addis_Ababa": "ET",
            "Africa/Libreville": "GA",
            "America/Grenada": "GD",
            "Europe/Guernsey": "GG",
            "Africa/Accra": "GH",
            "Africa/Banjul": "GM",
            "Africa/Conakry": "GN",
            "America/Guadeloupe": "GP",
            "Africa/Malabo": "GQ",
            "Europe/Zagreb": "HR",
            "Europe/Isle_of_Man": "IM",
            "Europe/Jersey": "JE",
            "Asia/Phnom_Penh": "KH",
            "Indian/Comoro": "KM",
            "America/St_Kitts": "KN",
            "Asia/Kuwait": "KW",
            "America/Cayman": "KY",
            "Asia/Vientiane": "LA",
            "America/St_Lucia": "LC",
            "Europe/Vaduz": "LI",
            "Africa/Maseru": "LS",
            "Europe/Podgorica": "ME",
            "America/Marigot": "MF",
            "Indian/Antananarivo": "MG",
            "Europe/Skopje": "MK",
            "Africa/Bamako": "ML",
            "Pacific/Saipan": "MP",
            "Africa/Nouakchott": "MR",
            "America/Montserrat": "MS",
            "Africa/Blantyre": "MW",
            "Africa/Niamey": "NE",
            "Asia/Muscat": "OM",
            "Africa/Kigali": "RW",
            "Atlantic/St_Helena": "SH",
            "Europe/Ljubljana": "SI",
            "Arctic/Longyearbyen": "SJ",
            "Europe/Bratislava": "SK",
            "Africa/Freetown": "SL",
            "Europe/San_Marino": "SM",
            "Africa/Dakar": "SN",
            "Africa/Mogadishu": "SO",
            "America/Lower_Princes": "SX",
            "Africa/Mbabane": "SZ",
            "Africa/Lome": "TG",
            "America/Port_of_Spain": "TT",
            "Africa/Dar_es_Salaam": "TZ",
            "Africa/Kampala": "UG",
            "Pacific/Midway": "UM",
            "Europe/Vatican": "VA",
            "America/St_Vincent": "VC",
            "America/Tortola": "VG",
            "America/St_Thomas": "VI",
            "Asia/Aden": "YE",
            "Indian/Mayotte": "YT",
            "Africa/Lusaka": "ZM",
            "Africa/Harare": "ZW"
        },
        currencies: {
            "USD": {
                "symbol": "US$",
                "name": "US Dollar",
                "symbol_native": "$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "USD",
                "name_plural": "US dollars"
            },
            "CAD": {
                "symbol": "CA$",
                "name": "Canadian Dollar",
                "symbol_native": "$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "CAD",
                "name_plural": "Canadian dollars"
            },
            "EUR": {
                "symbol": "€",
                "name": "Euro",
                "symbol_native": "€",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "EUR",
                "name_plural": "Euros"
            },
            "AED": {
                "symbol": "AED",
                "name": "United Arab Emirates Dirham",
                "symbol_native": "د.إ.‏",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "AED",
                "name_plural": "UAE dirhams"
            },
            "AFN": {
                "symbol": "Af",
                "name": "Afghan Afghani",
                "symbol_native": "؋",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "AFN",
                "name_plural": "Afghan Afghanis",
                "amex": false
            },
            "ALL": {
                "symbol": "ALL",
                "name": "Albanian Lek",
                "symbol_native": "Lek",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "ALL",
                "name_plural": "Albanian lekë"
            },
            "AMD": {
                "symbol": "AMD",
                "name": "Armenian Dram",
                "symbol_native": "դր.",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "AMD",
                "name_plural": "Armenian drams"
            },
            "ARS": {
                "symbol": "AR$",
                "name": "Argentine Peso",
                "symbol_native": "$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "ARS",
                "name_plural": "Argentine pesos",
                "amex": false
            },
            "AUD": {
                "symbol": "AU$",
                "name": "Australian Dollar",
                "symbol_native": "$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "AUD",
                "name_plural": "Australian dollars"
            },
            "AZN": {
                "symbol": "man.",
                "name": "Azerbaijani Manat",
                "symbol_native": "ман.",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "AZN",
                "name_plural": "Azerbaijani manats"
            },
            "BAM": {
                "symbol": "KM",
                "name": "Bosnia-Herzegovina Convertible Mark",
                "symbol_native": "KM",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "BAM",
                "name_plural": "Bosnia-Herzegovina convertible marks"
            },
            "BDT": {
                "symbol": "Tk",
                "name": "Bangladeshi Taka",
                "symbol_native": "৳",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "BDT",
                "name_plural": "Bangladeshi takas"
            },
            "BGN": {
                "symbol": "BGN",
                "name": "Bulgarian Lev",
                "symbol_native": "лв.",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "BGN",
                "name_plural": "Bulgarian leva"
            },
            "BIF": {
                "symbol": "FBu",
                "name": "Burundian Franc",
                "symbol_native": "FBu",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "BIF",
                "name_plural": "Burundian francs"
            },
            "BND": {
                "symbol": "BN$",
                "name": "Brunei Dollar",
                "symbol_native": "$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "BND",
                "name_plural": "Brunei dollars"
            },
            "BOB": {
                "symbol": "Bs",
                "name": "Bolivian Boliviano",
                "symbol_native": "Bs",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "BOB",
                "name_plural": "Bolivian bolivianos",
                "amex": false
            },
            "BRL": {
                "symbol": "R$",
                "name": "Brazilian Real",
                "symbol_native": "R$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "BRL",
                "name_plural": "Brazilian reals",
                "amex": false
            },
            "BWP": {
                "symbol": "BWP",
                "name": "Botswanan Pula",
                "symbol_native": "P",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "BWP",
                "name_plural": "Botswanan pulas"
            },
            "BYN": {
                "symbol": "Br",
                "name": "Belarusian Ruble",
                "symbol_native": "руб.",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "BYN",
                "name_plural": "Belarusian rubles"
            },
            "BZD": {
                "symbol": "BZ$",
                "name": "Belize Dollar",
                "symbol_native": "$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "BZD",
                "name_plural": "Belize dollars"
            },
            "CDF": {
                "symbol": "CDF",
                "name": "Congolese Franc",
                "symbol_native": "FrCD",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "CDF",
                "name_plural": "Congolese francs"
            },
            "CHF": {
                "symbol": "CHF",
                "name": "Swiss Franc",
                "symbol_native": "CHF",
                "decimal_digits": 2,
                "rounding": 0.05,
                "code": "CHF",
                "name_plural": "Swiss francs"
            },
            "CLP": {
                "symbol": "CL$",
                "name": "Chilean Peso",
                "symbol_native": "$",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "CLP",
                "name_plural": "Chilean pesos",
                "amex": false
            },
            "CNY": {
                "symbol": "CN¥",
                "name": "Chinese Yuan",
                "symbol_native": "CN¥",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "CNY",
                "name_plural": "Chinese yuan"
            },
            "COP": {
                "symbol": "CO$",
                "name": "Colombian Peso",
                "symbol_native": "$",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "COP",
                "name_plural": "Colombian pesos",
                "amex": false
            },
            "CRC": {
                "symbol": "₡",
                "name": "Costa Rican Colón",
                "symbol_native": "₡",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "CRC",
                "name_plural": "Costa Rican colóns",
                "amex": false
            },
            "CVE": {
                "symbol": "CV$",
                "name": "Cape Verdean Escudo",
                "symbol_native": "CV$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "CVE",
                "name_plural": "Cape Verdean escudos",
                "amex": false
            },
            "CZK": {
                "symbol": "Kč",
                "name": "Czech Republic Koruna",
                "symbol_native": "Kč",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "CZK",
                "name_plural": "Czech Republic korunas"
            },
            "DJF": {
                "symbol": "Fdj",
                "name": "Djiboutian Franc",
                "symbol_native": "Fdj",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "DJF",
                "name_plural": "Djiboutian francs",
                "amex": false
            },
            "DKK": {
                "symbol": "Dkr",
                "name": "Danish Krone",
                "symbol_native": "kr",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "DKK",
                "name_plural": "Danish kroner"
            },
            "DOP": {
                "symbol": "RD$",
                "name": "Dominican Peso",
                "symbol_native": "RD$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "DOP",
                "name_plural": "Dominican pesos"
            },
            "DZD": {
                "symbol": "DA",
                "name": "Algerian Dinar",
                "symbol_native": "د.ج.‏",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "DZD",
                "name_plural": "Algerian dinars"
            },
            "EGP": {
                "symbol": "EGP",
                "name": "Egyptian Pound",
                "symbol_native": "ج.م.‏",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "EGP",
                "name_plural": "Egyptian pounds"
            },
            "ETB": {
                "symbol": "Br",
                "name": "Ethiopian Birr",
                "symbol_native": "Br",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "ETB",
                "name_plural": "Ethiopian birrs"
            },
            "GBP": {
                "symbol": "£",
                "name": "British Pound Sterling",
                "symbol_native": "£",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "GBP",
                "name_plural": "British pounds sterling"
            },
            "GEL": {
                "symbol": "GEL",
                "name": "Georgian Lari",
                "symbol_native": "GEL",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "GEL",
                "name_plural": "Georgian laris"
            },
            "GNF": {
                "symbol": "FG",
                "name": "Guinean Franc",
                "symbol_native": "FG",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "GNF",
                "name_plural": "Guinean francs",
                "amex": false
            },
            "GTQ": {
                "symbol": "GTQ",
                "name": "Guatemalan Quetzal",
                "symbol_native": "Q",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "GTQ",
                "name_plural": "Guatemalan quetzals",
                "amex": false
            },
            "HKD": {
                "symbol": "HK$",
                "name": "Hong Kong Dollar",
                "symbol_native": "$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "HKD",
                "name_plural": "Hong Kong dollars"
            },
            "HNL": {
                "symbol": "HNL",
                "name": "Honduran Lempira",
                "symbol_native": "L",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "HNL",
                "name_plural": "Honduran lempiras",
                "amex": false
            },
            "HRK": {
                "symbol": "kn",
                "name": "Croatian Kuna",
                "symbol_native": "kn",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "HRK",
                "name_plural": "Croatian kunas"
            },
            "HUF": {
                "symbol": "Ft",
                "name": "Hungarian Forint",
                "symbol_native": "Ft",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "HUF",
                "name_plural": "Hungarian forints"
            },
            "IDR": {
                "symbol": "Rp",
                "name": "Indonesian Rupiah",
                "symbol_native": "Rp",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "IDR",
                "name_plural": "Indonesian rupiahs"
            },
            "ILS": {
                "symbol": "₪",
                "name": "Israeli New Sheqel",
                "symbol_native": "₪",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "ILS",
                "name_plural": "Israeli new sheqels"
            },
            "INR": {
                "symbol": "Rs",
                "name": "Indian Rupee",
                "symbol_native": "টকা",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "INR",
                "name_plural": "Indian rupees"
            },
            "ISK": {
                "symbol": "Ikr",
                "name": "Icelandic Króna",
                "symbol_native": "kr",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "ISK",
                "name_plural": "Icelandic krónur"
            },
            "JMD": {
                "symbol": "J$",
                "name": "Jamaican Dollar",
                "symbol_native": "$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "JMD",
                "name_plural": "Jamaican dollars"
            },
            "JPY": {
                "symbol": "¥",
                "name": "Japanese Yen",
                "symbol_native": "￥",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "JPY",
                "name_plural": "Japanese yen"
            },
            "KES": {
                "symbol": "Ksh",
                "name": "Kenyan Shilling",
                "symbol_native": "Ksh",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "KES",
                "name_plural": "Kenyan shillings"
            },
            "KHR": {
                "symbol": "KHR",
                "name": "Cambodian Riel",
                "symbol_native": "៛",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "KHR",
                "name_plural": "Cambodian riels"
            },
            "KMF": {
                "symbol": "CF",
                "name": "Comorian Franc",
                "symbol_native": "FC",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "KMF",
                "name_plural": "Comorian francs"
            },
            "KRW": {
                "symbol": "₩",
                "name": "South Korean Won",
                "symbol_native": "₩",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "KRW",
                "name_plural": "South Korean won"
            },
            "KZT": {
                "symbol": "KZT",
                "name": "Kazakhstani Tenge",
                "symbol_native": "тңг.",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "KZT",
                "name_plural": "Kazakhstani tenges"
            },
            "LBP": {
                "symbol": "L.L.",
                "name": "Lebanese Pound",
                "symbol_native": "ل.ل.‏",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "LBP",
                "name_plural": "Lebanese pounds"
            },
            "LKR": {
                "symbol": "SLRs",
                "name": "Sri Lankan Rupee",
                "symbol_native": "SL Re",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "LKR",
                "name_plural": "Sri Lankan rupees"
            },
            "MAD": {
                "symbol": "MAD",
                "name": "Moroccan Dirham",
                "symbol_native": "د.م.‏",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "MAD",
                "name_plural": "Moroccan dirhams"
            },
            "MDL": {
                "symbol": "MDL",
                "name": "Moldovan Leu",
                "symbol_native": "MDL",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "MDL",
                "name_plural": "Moldovan lei"
            },
            "MGA": {
                "symbol": "MGA",
                "name": "Malagasy Ariary",
                "symbol_native": "MGA",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "MGA",
                "name_plural": "Malagasy Ariaries"
            },
            "MKD": {
                "symbol": "MKD",
                "name": "Macedonian Denar",
                "symbol_native": "MKD",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "MKD",
                "name_plural": "Macedonian denari"
            },
            "MMK": {
                "symbol": "MMK",
                "name": "Myanma Kyat",
                "symbol_native": "K",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "MMK",
                "name_plural": "Myanma kyats"
            },
            "MOP": {
                "symbol": "MOP$",
                "name": "Macanese Pataca",
                "symbol_native": "MOP$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "MOP",
                "name_plural": "Macanese patacas"
            },
            "MUR": {
                "symbol": "MURs",
                "name": "Mauritian Rupee",
                "symbol_native": "MURs",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "MUR",
                "name_plural": "Mauritian rupees",
                "amex": false
            },
            "MXN": {
                "symbol": "MX$",
                "name": "Mexican Peso",
                "symbol_native": "$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "MXN",
                "name_plural": "Mexican pesos"
            },
            "MYR": {
                "symbol": "RM",
                "name": "Malaysian Ringgit",
                "symbol_native": "RM",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "MYR",
                "name_plural": "Malaysian ringgits"
            },
            "MZN": {
                "symbol": "MTn",
                "name": "Mozambican Metical",
                "symbol_native": "MTn",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "MZN",
                "name_plural": "Mozambican meticals"
            },
            "NAD": {
                "symbol": "N$",
                "name": "Namibian Dollar",
                "symbol_native": "N$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "NAD",
                "name_plural": "Namibian dollars"
            },
            "NGN": {
                "symbol": "₦",
                "name": "Nigerian Naira",
                "symbol_native": "₦",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "NGN",
                "name_plural": "Nigerian nairas"
            },
            "NIO": {
                "symbol": "C$",
                "name": "Nicaraguan Córdoba",
                "symbol_native": "C$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "NIO",
                "name_plural": "Nicaraguan córdobas",
                "amex": false
            },
            "NOK": {
                "symbol": "Nkr",
                "name": "Norwegian Krone",
                "symbol_native": "kr",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "NOK",
                "name_plural": "Norwegian kroner"
            },
            "NPR": {
                "symbol": "NPRs",
                "name": "Nepalese Rupee",
                "symbol_native": "नेरू",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "NPR",
                "name_plural": "Nepalese rupees"
            },
            "NZD": {
                "symbol": "NZ$",
                "name": "New Zealand Dollar",
                "symbol_native": "$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "NZD",
                "name_plural": "New Zealand dollars"
            },
            "PAB": {
                "symbol": "B/.",
                "name": "Panamanian Balboa",
                "symbol_native": "B/.",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "PAB",
                "name_plural": "Panamanian balboas",
                "amex": false
            },
            "PEN": {
                "symbol": "S/.",
                "name": "Peruvian Nuevo Sol",
                "symbol_native": "S/.",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "PEN",
                "name_plural": "Peruvian nuevos soles",
                "amex": false
            },
            "PHP": {
                "symbol": "₱",
                "name": "Philippine Peso",
                "symbol_native": "₱",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "PHP",
                "name_plural": "Philippine pesos"
            },
            "PKR": {
                "symbol": "PKRs",
                "name": "Pakistani Rupee",
                "symbol_native": "₨",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "PKR",
                "name_plural": "Pakistani rupees"
            },
            "PLN": {
                "symbol": "zł",
                "name": "Polish Zloty",
                "symbol_native": "zł",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "PLN",
                "name_plural": "Polish zlotys"
            },
            "PYG": {
                "symbol": "₲",
                "name": "Paraguayan Guarani",
                "symbol_native": "₲",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "PYG",
                "name_plural": "Paraguayan guaranis",
                "amex": false
            },
            "QAR": {
                "symbol": "QR",
                "name": "Qatari Rial",
                "symbol_native": "ر.ق.‏",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "QAR",
                "name_plural": "Qatari rials"
            },
            "RON": {
                "symbol": "RON",
                "name": "Romanian Leu",
                "symbol_native": "RON",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "RON",
                "name_plural": "Romanian lei"
            },
            "RSD": {
                "symbol": "din.",
                "name": "Serbian Dinar",
                "symbol_native": "дин.",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "RSD",
                "name_plural": "Serbian dinars"
            },
            "RUB": {
                "symbol": "RUB",
                "name": "Russian Ruble",
                "symbol_native": "₽.",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "RUB",
                "name_plural": "Russian rubles"
            },
            "RWF": {
                "symbol": "RWF",
                "name": "Rwandan Franc",
                "symbol_native": "FR",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "RWF",
                "name_plural": "Rwandan francs"
            },
            "SAR": {
                "symbol": "SR",
                "name": "Saudi Riyal",
                "symbol_native": "ر.س.‏",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "SAR",
                "name_plural": "Saudi riyals"
            },
            "SEK": {
                "symbol": "Skr",
                "name": "Swedish Krona",
                "symbol_native": "kr",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "SEK",
                "name_plural": "Swedish kronor"
            },
            "SGD": {
                "symbol": "S$",
                "name": "Singapore Dollar",
                "symbol_native": "$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "SGD",
                "name_plural": "Singapore dollars"
            },
            "SOS": {
                "symbol": "Ssh",
                "name": "Somali Shilling",
                "symbol_native": "Ssh",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "SOS",
                "name_plural": "Somali shillings"
            },
            "THB": {
                "symbol": "฿",
                "name": "Thai Baht",
                "symbol_native": "฿",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "THB",
                "name_plural": "Thai baht"
            },
            "TOP": {
                "symbol": "T$",
                "name": "Tongan Paʻanga",
                "symbol_native": "T$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "TOP",
                "name_plural": "Tongan paʻanga"
            },
            "TRY": {
                "symbol": "TL",
                "name": "Turkish Lira",
                "symbol_native": "TL",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "TRY",
                "name_plural": "Turkish Lira"
            },
            "TTD": {
                "symbol": "TT$",
                "name": "Trinidad and Tobago Dollar",
                "symbol_native": "$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "TTD",
                "name_plural": "Trinidad and Tobago dollars"
            },
            "TWD": {
                "symbol": "NT$",
                "name": "New Taiwan Dollar",
                "symbol_native": "NT$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "TWD",
                "name_plural": "New Taiwan dollars"
            },
            "TZS": {
                "symbol": "TSh",
                "name": "Tanzanian Shilling",
                "symbol_native": "TSh",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "TZS",
                "name_plural": "Tanzanian shillings"
            },
            "UAH": {
                "symbol": "₴",
                "name": "Ukrainian Hryvnia",
                "symbol_native": "₴",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "UAH",
                "name_plural": "Ukrainian hryvnias"
            },
            "UGX": {
                "symbol": "USh",
                "name": "Ugandan Shilling",
                "symbol_native": "USh",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "UGX",
                "name_plural": "Ugandan shillings"
            },
            "UYU": {
                "symbol": "$U",
                "name": "Uruguayan Peso",
                "symbol_native": "$",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "UYU",
                "name_plural": "Uruguayan pesos",
                "amex": false
            },
            "UZS": {
                "symbol": "UZS",
                "name": "Uzbekistan Som",
                "symbol_native": "UZS",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "UZS",
                "name_plural": "Uzbekistan som"
            },
            "VND": {
                "symbol": "₫",
                "name": "Vietnamese Dong",
                "symbol_native": "₫",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "VND",
                "name_plural": "Vietnamese dong"
            },
            "XAF": {
                "symbol": "FCFA",
                "name": "CFA Franc BEAC",
                "symbol_native": "FCFA",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "XAF",
                "name_plural": "CFA francs BEAC"
            },
            "XOF": {
                "symbol": "CFA",
                "name": "CFA Franc BCEAO",
                "symbol_native": "CFA",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "XOF",
                "name_plural": "CFA francs BCEAO",
                "amex": false
            },
            "YER": {
                "symbol": "YR",
                "name": "Yemeni Rial",
                "symbol_native": "ر.ي.‏",
                "decimal_digits": 0,
                "rounding": 0,
                "code": "YER",
                "name_plural": "Yemeni rials"
            },
            "ZAR": {
                "symbol": "R",
                "name": "South African Rand",
                "symbol_native": "R",
                "decimal_digits": 2,
                "rounding": 0,
                "code": "ZAR",
                "name_plural": "South African rand"
            }
        },
        currentAppVersion: "6.3.16",
        currentBuildHandle: null,
        currentEventId: null,
        currentEventName: null,
        currentGroupHandle: null,
        currentGroupId: null,
        currentGroupName: null,
        customerSegmentCounts: {},
        customerSegmentToEdit: null,
        defaultHero: "https://res.cloudinary.com/dnnzvmbis/image/upload/v1638831982/Feature_Image_x4r7b6.jpg",
        defaultShippingRateLabels: [
            'Standard (2 to 8 business days)',
            'Express (1 to 4 business days)'
        ],
        defaultText: {
            groupBuy: {
                dollarsPercentageText: "Of Maximum Amount",
                hideOfferDetailsText: "Hide {labelSingular} Details",
                joinButtonText: "Join {labelSingular}",
                maximumAllowedText: "Maximum Allowed",
                minimumRequiredText: "Minimum Required",
                offerConditionsTextLineOne: "All or Nothing, this {labelSingular} will only be funded if it reaches it's goal by {dateTime}",
                offerConditionsTextLineTwo: "{labelSingular} Tiers discounts will be calculated and applied on this date.",
                productsTitleText: "Products in {labelSingular}",
                registerButtonText: "Register your interest",
                shareButtonText: "Share {labelSingular}",
                showOfferDetailsText: "Show {labelSingular} Details",
                unitsConfirmedText: "Units Confirmed"
            }
        },
        desktopMaxWidth: 1600,
        deviceId: null,
        deviceInfo: null,
        editingEvent: null,
        editingGroup: null,
        eventEditForm: null,
        eventHasNewPosts: [],
        eventLists: null,
        eventStatus: null,
        feedActivities: null,
        feeds: [],
        firebaseConnected: false,
        flasherPage: null,
        galleryInitialSlide: 0,
        galleryMedia: [],
        getStreamKey: 'jpjh3bwp9gz5',
        getStreamAppId: '93287',
        globalHubData: null,
        groupBuyCurrentDollars: {},
        groupBuyCurrentQty: {},
        groupBuyNewProduct: null,
        groupBuyStreaming: {},
        groupBuyToEdit: null,
        groupEditForm: null,
        groupOfferSuggested: null,
        gtmId: {
            // 'jdm-giveaways.buildz.store': "GTM-T79WVCS",
            // 'jdmgiveaways.com.au': "GTM-T79WVCS",
            // 'safety-drive.buildz.store': "GTM-T79WVCS"
        },
        guestEmailAddress: null,
        homeSuggested: [],
        impersonatingOriginalUserId: null,
        impersonatingUser: false,
        isGlobalData: false,
        isLoggedIn: !!localStorage.getItem('token') && localStorage.getItem('token') != "undefined",
        isRealGlobalData: false,
        keyEscape: false,
        lastURL: null,
        latestSaleGroupOfferId: null,
        latestSales: {},
        launchUrlInitFired: false,
        localHostServer: "staging.buildz.pro",
        loggedUser: null,
        loginEmail: null,
        mainMenuOpen: false,
        mainPartWidth: 0,
        maintenance: false,
        marketplaceEOIToShow: null,
        marketplaceLive: true,
        marketplaceSaleInProgress: false,
        marketplaceSalePayPalResponse: null,
        mediaInProgress: false,
        mediaProgress: 0,
        mediaProgressTotal: 0,
        menu: {
            // events: {
            //     class: "",
            //     description: null,
            //     externalLink: false,
            //     flasher: "events",
            //     function: false,
            //     icon: "calendar-days",
            //     iconClass: "h-5 w-5",
            //     iconType: "fas",
            //     label: "Events",
            //     method: "get",
            //     name: "events",
            //     preserveScroll: true,
            //     route: 'EventsUpcoming',
            //     sort: 3,
            //     url: null
            // },
            // event: {
            //     class: "",
            //     description: null,
            //     externalLink: false,
            //     flasher: null,
            //     function: false,
            //     icon: "calendar-days",
            //     iconClass: "h-5 w-5",
            //     iconType: "fas",
            //     label: "Event",
            //     method: "get",
            //     name: "event",
            //     preserveScroll: true,
            //     route: 'event',
            //     sort: 3,
            //     url: null
            // },
            communities: {
                class: "",
                description: null,
                externalLink: false,
                flasher: "communities",
                function: false,
                icon: "users",
                iconClass: "h-5 w-5",
                iconType: "fas",
                label: "Communities",
                method: "get",
                name: "communities",
                preserveScroll: true,
                route: 'CommunitiesSearch',
                sort: 4,
                url: null
            },
            community: {
                class: "",
                description: null,
                externalLink: false,
                flasher: null,
                function: false,
                icon: "users",
                iconClass: "h-5 w-5",
                iconType: "fas",
                label: "Community",
                method: "get",
                name: "community",
                preserveScroll: true,
                route: 'Group',
                sort: 4,
                url: null
            },
            chat: {
                class: "",
                description: null,
                externalLink: false,
                flasher: null,
                function: false,
                icon: "comments",
                iconClass: "h-5 w-5",
                iconType: "fas",
                label: "Chat",
                method: "get",
                name: "chat",
                preserveScroll: true,
                route: 'Chats',
                sort: 5,
                url: null
            },
            tags: {
                class: "",
                description: null,
                externalLink: false,
                flasher: "tags",
                function: false,
                icon: "nfc-symbol",
                iconClass: "h-5 w-5",
                iconType: "fab",
                label: "Smart Tags",
                method: "get",
                name: "tags",
                preserveScroll: true,
                route: 'Devices',
                sort: 6,
                url: null
            },
            settings: {
                class: "",
                description: null,
                externalLink: false,
                flasher: "settings",
                function: false,
                icon: "gears",
                iconClass: "h-5 w-5",
                iconType: "fas",
                label: "Settings",
                method: "get",
                name: "settings",
                preserveScroll: true,
                route: 'Settings',
                sort: 9,
                url: null
            },
            signout: {
                class: "",
                description: null,
                externalLink: false,
                flasher: null,
                function: false,
                icon: "right-from-bracket",
                iconClass: "h-5 w-5",
                iconType: "fas",
                label: "Sign Out",
                method: "post",
                name: "signout",
                preserveScroll: false,
                route: 'Logout',
                sort: 10,
                url: null
            },
            help: {
                class: "",
                description: null,
                externalLink: false,
                flasher: null,
                function: true,
                icon: "life-ring",
                iconClass: "h-5 w-5",
                iconType: "fas",
                label: "Chat with BZ Staff",
                method: null,
                name: "help",
                preserveScroll: true,
                route: null,
                sort: 11,
                url: null
            },
            marketplace: {
                class: "",
                description: null,
                externalLink: false,
                flasher: null,
                function: false,
                icon: "shop",
                iconClass: "h-5 w-5",
                iconType: "fas",
                label: "Marketplace",
                method: null,
                name: "marketplace",
                preserveScroll: false,
                route: 'MarketplaceCustomer',
                sort: 1,
                url: null
            },
            seller: {
                class: "",
                description: null,
                externalLink: false,
                flasher: null,
                function: false,
                icon: "sack-dollar",
                iconClass: "h-5 w-5",
                iconType: "fas",
                label: "Seller",
                method: null,
                name: "seller",
                preserveScroll: false,
                route: 'MarketplaceSeller',
                sort: 2,
                url: null
            },
            becomeSeller: {
                class: "",
                description: null,
                externalLink: false,
                flasher: null,
                function: false,
                icon: "sack-dollar",
                iconClass: "h-5 w-5",
                iconType: "fas",
                label: "Become a Seller",
                method: null,
                name: "becomeSeller",
                preserveScroll: false,
                route: 'MarketplaceSeller',
                sort: 2,
                url: null
            },
            impersonate: {
                class: "",
                description: null,
                externalLink: false,
                flasher: null,
                function: false,
                icon: "user-secret",
                iconClass: "h-5 w-5",
                iconType: "fas",
                label: "Impersonate User",
                method: null,
                name: "impersonateUser",
                preserveScroll: false,
                route: 'Impersonate',
                sort: 12,
                url: null
            }
        },
        menuSelected: 'home',
        metaPixelTrackPurchaseValue: true,
        newActivityShareURL: null,
        newGroupBuyFeedPost: false,
        notificationActivities: null,
        offlineMedia: [],
        offlineMediaFiles: null,
        oneSignalAppId: 'a5c17c0e-ecc2-42f8-9785-f944a3e7a119',
        pageCache: {},
        pageData: {
            groupBuy: {
                adminLabelPlural: "Group Offers",
                adminLabelSingular: "Group Offer",
                labelPlural: "Limited Time Group Offers",
                labelSingular: "Limited Time Group Offer",
                types: {
                    giveAway: {
                        id: "giveAway",
                        labelPlural: "Giveaways",
                        labelSingular: "Giveaway",
                    },
                    groupBuy: {
                        id: "groupBuy",
                        labelPlural: "Group Buys",
                        labelSingular: "Group Buy",
                    },
                    member: {
                        id: "member",
                        labelPlural: "Member Giveaways",
                        labelSingular: "Member Giveaway",
                    }
                }
            }
        },
        pageDataBuilds: {},
        pageTabs: {
            build: {
                mods: {
                    alertCondition: "mods",
                    class: "",
                    disabledCondition: null,
                    displayCondition: "mods",
                    hideFrom: [],
                    icon: "screwdriver-wrench",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Mods",
                    name: "mods",
                    sort: 2,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 1
                },
                feed: {
                    alertCondition: "feed",
                    class: "",
                    disabledCondition: "feed",
                    displayCondition: "feed",
                    hideFrom: [],
                    icon: "message",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Feed",
                    name: "feed",
                    sort: 1,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 2
                },
                // gallery: {
                //     alertCondition: null,
                //     class: "",
                //     disabledCondition: "gallery",
                //     displayCondition: "gallery",
                //     hideFrom: [],
                //     icon: "photo-film",
                //     iconClass: "h-5 w-5",
                //     iconType: "fas",
                //     imageNumber: null,
                //     label: "Gallery",
                //     name: "gallery",
                //     sort: 4,
                //     staffOnly: false,
                //     tab: true,
                //     textNumber: null,
                //     value: 3
                // },
                badges: {
                    alertCondition: "badges",
                    class: "",
                    disabledCondition: null,
                    displayCondition: "badges",
                    hideFrom: [],
                    icon: "gift",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Badges",
                    name: "badges",
                    sort: 3,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 4
                },
                team: {
                    alertCondition: "team",
                    class: "",
                    disabledCondition: null,
                    displayCondition: "team",
                    hideFrom: [],
                    icon: "info",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Team",
                    name: "team",
                    sort: 5,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 6
                },
                settings: {
                    class: "",
                    disabledCondition: null,
                    displayCondition: "settings",
                    hideFrom: [],
                    icon: "gear",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Settings",
                    name: "settings",
                    sort: 7,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 5
                },
                tokens: {
                    class: "",
                    disabledCondition: null,
                    displayCondition: "tokens",
                    hideFrom: [],
                    icon: "coins",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Tokens",
                    name: "tokens",
                    sort: 6,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 7
                }
            },
            garage: {
                feed: {
                    class: "",
                    disabledCondition: "feed",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "message",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Feed",
                    name: "feed",
                    sort: 1,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 1
                },
                builds: {
                    class: "",
                    disabledCondition: null,
                    displayCondition: "things",
                    hideFrom: [],
                    icon: "car",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Buildz",
                    name: "builds",
                    sort: 2,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 2
                },
                // gallery: {
                //     class: "",
                //     disabledCondition: "gallery",
                //     displayCondition: "gallery",
                //     hideFrom: [],
                //     icon: "photo-film",
                //     iconClass: "h-5 w-5",
                //     iconType: "fas",
                //     imageNumber: null,
                //     label: "Gallery",
                //     name: "gallery",
                //     sort: 3,
                //     staffOnly: false,
                //     tab: true,
                //     textNumber: null,
                //     value: 3
                // }
            },
            event: {
                about: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "info",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "About",
                    name: "About",
                    sort: 1,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 1
                },
                builds: {
                    class: "",
                    displayCondition: "builds",
                    hideFrom: [],
                    icon: "car",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "My Buildz",
                    name: "builds",
                    sort: 2,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 2
                },
                posts: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "message",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Posts",
                    name: "posts",
                    sort: 3,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 3
                },
                chat: {
                    class: "",
                    displayCondition: "chat",
                    hideFrom: [],
                    icon: "comments",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Chat",
                    name: "chat",
                    sort: 4,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 4
                },
                going: {
                    class: "",
                    displayCondition: "going",
                    hideFrom: [],
                    icon: "users",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Going",
                    name: "going",
                    sort: 5,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 5
                },
                preliminaries: {
                    class: "",
                    displayCondition: "preliminaries",
                    hideFrom: [],
                    icon: "list",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Preliminaries",
                    name: "preliminaries",
                    sort: 6,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 6
                },
                leaderboards: {
                    class: "",
                    displayCondition: "leaderboards",
                    hideFrom: [],
                    icon: "list-ol",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Leaderboards",
                    name: "leaderboards",
                    sort: 7,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 7
                },
                champion: {
                    class: "",
                    displayCondition: "champion",
                    hideFrom: [],
                    icon: "trophy",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Grand Champion",
                    name: "champion",
                    sort: 8,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 8
                },
                invite: {
                    class: "",
                    displayCondition: "invite",
                    hideFrom: [],
                    icon: "envelope",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Invite",
                    name: "invite",
                    sort: 9,
                    staffOnly: false,
                    tab: false,
                    textNumber: null,
                    value: "invite"
                },
                award: {
                    class: "",
                    displayCondition: "award",
                    hideFrom: [],
                    icon: "award",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Award Badges",
                    name: "award",
                    sort: 10,
                    staffOnly: false,
                    tab: false,
                    textNumber: null,
                    value: "award"
                },
                upcoming: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "calendar",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Back to All Events",
                    name: "upcoming",
                    sort: 11,
                    staffOnly: false,
                    tab: false,
                    textNumber: null,
                    value: "upcoming"
                },

            },
            search: {
                home: {
                    class: "",
                    displayCondition: null,
                    flasher: null,
                    hideFrom: [],
                    icon: "house",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Home",
                    name: "home",
                    sort: 1,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 6
                },
                buildz: {
                    class: "",
                    displayCondition: null,
                    flasher: null,
                    hideFrom: [],
                    icon: "car",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Buildz",
                    name: "buildz",
                    sort: 5,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 1
                },
                posts: {
                    class: "",
                    displayCondition: null,
                    flasher: null,
                    hideFrom: [],
                    icon: "message",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Posts",
                    name: "posts",
                    sort: 7,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 3
                },
                media: {
                    class: "",
                    displayCondition: null,
                    flasher: null,
                    hideFrom: [],
                    icon: "photo-film",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Media",
                    name: "media",
                    sort: 6,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 4
                },
                products: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "bag-shopping",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Products",
                    name: "products",
                    sort: 2,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 5
                },
                vendors: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "user-tag",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Vendors",
                    name: "vendors",
                    sort: 4,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 7
                },
                groupBuys: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "people-group",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Group Buys",
                    name: "groupBuys",
                    sort: 3,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 8
                }
            },
            seller: {
                products: {
                    class: "",
                    disabledCondition: "products",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "bag-shopping",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Products",
                    name: "products",
                    sort: 1,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 3
                },
                feed: {
                    class: "",
                    disabledCondition: "feed",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "message",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Feed",
                    name: "feed",
                    sort: 2,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 1
                },
                builds: {
                    class: "",
                    disabledCondition: null,
                    displayCondition: "builds",
                    hideFrom: [],
                    icon: "car",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Buildz",
                    name: "builds",
                    sort: 3,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 2
                }
            },
            settings: {
                account: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "user-pen",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Account",
                    name: "account",
                    sort: 1,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 1
                },
                password: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "user-shield",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Password",
                    name: "password",
                    sort: 2,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 2
                },
                preferences: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "user-gear",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Preferences",
                    name: "preferences",
                    sort: 3,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 3
                },
                nft: {
                    class: "",
                    displayCondition: "nft",
                    hideFrom: [],
                    icon: "coins",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Nft",
                    name: "nft",
                    sort: 4,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 4
                }
            },
            smartTags: {
                tags: {
                    class: "",
                    displayCondition: "tags",
                    hideFrom: [],
                    icon: "nfc-symbol",
                    iconClass: "h-5 w-5",
                    iconType: "fab",
                    imageNumber: null,
                    label: "Tags",
                    name: "tags",
                    sort: 1,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 1
                },
                logs: {
                    class: "",
                    displayCondition: "logs",
                    hideFrom: [],
                    icon: "list",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Logs",
                    name: "logs",
                    sort: 2,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 2
                }
            },
            events: {
                top: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "fire",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Top Events",
                    name: "top",
                    sort: 1,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 1
                },
                soon: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "calendar-week",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Coming Soon",
                    name: "soon",
                    sort: 2,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 2
                }
            },
            myEvents: {
                hosting: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "user",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Hosting",
                    name: "hosting",
                    sort: 1,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 1
                },
                interested: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "question",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Interested",
                    name: "interested",
                    sort: 2,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 2
                },
                going: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "check",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Attending",
                    name: "going",
                    sort: 3,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 3
                },
                invited: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "envelope",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Invited",
                    name: "invited",
                    sort: 4,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 4
                },
                notGoing: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "xmark",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Not Attending",
                    name: "notGoing",
                    sort: 5,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 5
                }
            },
            pastEvents: {
                hosting: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "user",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Hosted",
                    name: "hosting",
                    sort: 1,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 1
                },
                interested: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "question",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Interested",
                    name: "interested",
                    sort: 2,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 2
                },
                going: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "check",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Attended",
                    name: "going",
                    sort: 3,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 3
                },
                invited: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "envelope",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Invited",
                    name: "invited",
                    sort: 4,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 4
                },
                notGoing: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "xmark",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Didn't Attend",
                    name: "notGoing",
                    sort: 5,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 5
                }
            },
            eventsNav: {
                upcoming: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "calendar",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Upcoming Events",
                    name: "upcoming",
                    sort: 1,
                    staffOnly: false,
                    tab: false,
                    textNumber: null,
                    value: "upcoming"
                },
                mine: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "user",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "My Events",
                    name: "mine",
                    sort: 2,
                    staffOnly: false,
                    tab: false,
                    textNumber: null,
                    value: "mine"
                },
                past: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "clock-rotate-left",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Past Events",
                    name: "past",
                    sort: 3,
                    staffOnly: false,
                    tab: false,
                    textNumber: null,
                    value: "past"
                },
                new: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "calendar-plus",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "New Event",
                    name: "new",
                    sort: 4,
                    staffOnly: false,
                    tab: false,
                    textNumber: null,
                    value: "new"
                }
            },
            hub: {
                // main: {
                //     class: "",
                //     displayCondition: null,
                //     hideFrom: [],
                //     icon: "trophy",
                //     iconClass: "h-5 w-5",
                //     iconType: "fas",
                //     imageNumber: null,
                //     label: "Hub",
                //     name: "main",
                //     sort: 1,
                //     staffOnly: false,
                //     tab: true,
                //     textNumber: null,
                //     value: 1
                // },
                badges: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "gift",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Collectable Build Badges",
                    name: "badges",
                    sort: 1,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 2
                },
                // links: {
                //     class: "",
                //     displayCondition: "links",
                //     hideFrom: [],
                //     icon: "link",
                //     iconClass: "h-5 w-5",
                //     iconType: "fas",
                //     imageNumber: null,
                //     label: "Member Links",
                //     name: "links",
                //     sort: 6,
                //     staffOnly: false,
                //     tab: true,
                //     textNumber: null,
                //     value: 5
                // },
                // analytics: {
                //     class: "",
                //     displayCondition: "analytics",
                //     hideFrom: [],
                //     icon: "chart-line",
                //     iconClass: "h-5 w-5",
                //     iconType: "fas",
                //     imageNumber: null,
                //     label: "Member Analytics",
                //     name: "analytics",
                //     sort: 7,
                //     staffOnly: false,
                //     tab: true,
                //     textNumber: null,
                //     value: 6
                // }
            },
            groupsNav: {
                search: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "magnifying-glass",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Find Communities",
                    name: "search",
                    sort: 1,
                    staffOnly: false,
                    tab: false,
                    textNumber: null,
                    value: "search"
                },
                mine: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "user",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "My Communities",
                    name: "mine",
                    sort: 2,
                    staffOnly: false,
                    tab: false,
                    textNumber: null,
                    value: "mine"
                },
                new: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "plus",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "New Community",
                    name: "new",
                    sort: 3,
                    staffOnly: false,
                    tab: false,
                    textNumber: null,
                    value: "new"
                }
            },
            group: {
                posts: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "message",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Posts",
                    name: "posts",
                    sort: 1,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 1
                },
                members: {
                    class: "",
                    displayCondition: "members",
                    hideFrom: [],
                    icon: "user-group",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Members",
                    name: "members",
                    sort: 2,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 2
                },
                builds: {
                    class: "",
                    displayCondition: "builds",
                    hideFrom: [],
                    icon: "car",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "My Builds",
                    name: "builds",
                    sort: 3,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 3
                },
                invite: {
                    class: "",
                    displayCondition: "invite",
                    hideFrom: [],
                    icon: "envelope",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Invite Builders",
                    name: "invite",
                    sort: 4,
                    staffOnly: false,
                    tab: false,
                    textNumber: null,
                    value: "invite"
                },
                search: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "users",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Back to Communities",
                    name: "search",
                    sort: 5,
                    staffOnly: false,
                    tab: false,
                    textNumber: null,
                    value: "search"
                },
            },
            wallet: {
                // products: {
                //     class: "",
                //     displayCondition: null,
                //     hideFrom: [],
                //     icon: "bag-shopping",
                //     iconClass: "h-5 w-5",
                //     iconType: "fas",
                //     imageNumber: null,
                //     label: "Products",
                //     name: "products",
                //     sort: 2,
                //     staffOnly: false,
                //     tab: true,
                //     textNumber: null,
                //     value: 7
                // },
            },
            shopify: {
                import: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "file-import",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Import",
                    name: "import",
                    sort: 1,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 1
                },
                push: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "upload",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Push",
                    name: "push",
                    sort: 2,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 2
                }
            },
            marketplaceCustomer: {
                orders: {
                    class: "",
                    displayCondition: "orders",
                    hideFrom: [],
                    icon: "credit-card",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Orders",
                    name: "orders",
                    sort: 1,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 1
                },
                account: {
                    class: "",
                    displayCondition: null,
                    hideFrom: [],
                    icon: "user",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Account",
                    name: "account",
                    sort: 2,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 2
                }
            },
            marketplaceSeller: {
                sales: {
                    class: "",
                    displayCondition: "sales",
                    hideFrom: [],
                    icon: "sack-dollar",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Sales",
                    name: "sales",
                    sort: 1,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 1
                },
                account: {
                    class: "",
                    displayCondition: "account",
                    hideFrom: [],
                    icon: "user",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Account",
                    name: "account",
                    sort: 2,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 2
                },
                listings: {
                    class: "",
                    displayCondition: "listings",
                    hideFrom: [],
                    icon: "list",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Listings",
                    name: "listings",
                    sort: 3,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 3
                },
                groupBuys: {
                    class: "",
                    displayCondition: "groupBuys",
                    hideFrom: [],
                    icon: "clock",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Limited Time Offers",
                    name: "groupBuys",
                    sort: 4,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 4
                },
                campaigns: {
                    class: "",
                    displayCondition: "campaigns",
                    hideFrom: [],
                    icon: "envelope",
                    iconClass: "h-5 w-5",
                    iconType: "fas",
                    imageNumber: null,
                    label: "Campaigns",
                    name: "campaigns",
                    sort: 5,
                    staffOnly: false,
                    tab: true,
                    textNumber: null,
                    value: 5
                }
            },
        },
        paymentMethods: {
            afterpay: {
                countries: {
                    AU: {
                        currencies: [
                            'AUD'
                        ],
                        customers: [
                            'AU'
                        ]
                    },
                    CA: {
                        currencies: [
                            'CAD'
                        ],
                        customers: [
                            'CA'
                        ]
                    },
                    GB: {
                        currencies: [
                            'GBP'
                        ],
                        customers: [
                            'GB'
                        ]
                    },
                    NZ: {
                        currencies: [
                            'NZD'
                        ],
                        customers: [
                            'NZ'
                        ]
                    },
                    US: {
                        currencies: [
                            'USD'
                        ],
                        customers: [
                            'US'
                        ]
                    }
                },
                currencies: {
                    AUD: {
                        min: 1,
                        max: 2000
                    },
                    CAD: {
                        min: 1,
                        max: 2000
                    },
                    GBP: {
                        min: 1,
                        max: 1200
                    },
                    NZD: {
                        min: 1,
                        max: 2000
                    },
                    USD: {
                        min: 1,
                        max: 4000
                    }
                }
            },
            klarna: {
                countries: {
                    AU: {
                        currencies: [
                            'AUD'
                        ],
                        customers: [
                            'AU'
                        ]
                    },
                    CA: {
                        currencies: [
                            'CAD'
                        ],
                        customers: [
                            'CA'
                        ]
                    },
                    NZ: {
                        currencies: [
                            'NZD'
                        ],
                        customers: [
                            'NZ'
                        ]
                    },
                    US: {
                        currencies: [
                            'USD'
                        ],
                        customers: [
                            'US'
                        ]
                    }
                },
                currencies: {
                    AUD: {
                        min: 10,
                        max: 2000
                    },
                    CAD: {
                        min: 10,
                        max: 2000
                    },
                    NZD: {
                        min: 10,
                        max: 2000
                    },
                    USD: {
                        min: 10,
                        max: 2000
                    }
                }
            },
            zip: {
                countries: {
                    AU: {
                        currencies: [
                            'AUD'
                        ],
                        customers: [
                            'AU'
                        ]
                    },
                    US: {
                        currencies: [
                            'USD'
                        ],
                        customers: [
                            'US'
                        ]
                    }
                },
                currencies: {
                    AUD: {
                        min: 1,
                        max: 50000
                    },
                    USD: {
                        min: 35,
                        max: 1500
                    }
                }
            }
        },
        paypalAppClientId: 'AX-nKkAk0yWWdrqq-yV1v44YoP35jkw5EXnaG4vyRTvMq5hcVTfKbozbTD-FDiuju_wT6M0Cd59U-X_A',
        paypalCheckingStatus: false,
        paypalMerchantOkay: false,
        popups: [],
        postingActivity: false,
        postingComment: false,
        postingReaction: false,
        postQueue: [],
        postQueueId: 0,
        postQueueProcessed: [],
        postQueueProcessing: false,
        postReactionToEdit: null,
        presaleCode: null,
        presaleId: null,
        productEditing: null,
        productGroupTiles: null,
        productSuggested: null,
        productToEdit: null,
        publicSocketOpen: false,
        queueImagesUpdated : {},
        reactions: {},
        reloadBuildId: null,
        reloadGarageId: null,
        routes: {
            campaign: "api/campaign/{id}",
            campaignCancel: "api/campaign/{id}/cancel",
            campaigns: "api/campaigns",
            campaignSend: "api/campaign/{id}/send",
            campaignTest: "api/campaign/{id}/test",
            cart: "api/cart",
            cartProduct: "api/cart/product/{id}",
            customerEoi: "api/customerEoi/{id}",
            customerEois: "api/customer/eois",
            customerGroupOffer: "api/customerGroupOffer/{id}",
            customerGroupOffers: "api/customerGroupOffers",
            customerSegment: "api/customerSegment/{id}",
            customerSegmentCount: "api/customerSegment/{id}/count",
            customerSegments: "api/customerSegments",
            eoiConfirmation: "api/eoi/{id}/confirmation",
            eoiRegisterInterest: "api/eoi/{id}/register",
            giveawayCheckSession: "api/giveaway/checkSession",
            giveawayClearTimedDealsData: "api/giveaway/clearTimedDealsData",
            giveawayTimedDealsData: "api/giveaway/timedDealsData",
            global: "api/global",
            groupOffer: "api/groupOffer/{id}",
            groupOfferABTest: "api/groupOffer/{id}/ab",
            groupOfferABTestGallery: "api/groupOffer/{id}/abTestGallery",
            groupOfferABTestMBFeatureGallery: "api/groupOffer/{id}/abTestMBFeatureGallery",
            groupOfferABTestMBGallery: "api/groupOffer/{id}/abTestMBGallery",
            groupOfferABTestMBSliderGallery: "api/groupOffer/{id}/abTestMBSliderGallery",
            groupOfferABTestPMBGallery: "api/groupOffer/{id}/abTestPMBGallery",
            groupOfferArchive: "api/groupOffer/{id}/archive",
            groupOfferBundle: "api/groupOffer/{id}/bundle",
            groupOfferBundleDoubleOfferGallery: "api/groupOffer/{id}/bundleDoubleOfferGallery",
            groupOfferBundleGallery: "api/groupOffer/{id}/bundleGallery",
            groupOfferBundleOrder: "api/groupOffer/{id}/bundleOrder",
            groupOfferCopy: "api/groupOffer/{id}/copy",
            groupOfferCustomer: "api/groupOffer/customer/{id}",
            groupOfferGallery: "api/groupOffer/{id}/gallery",
            groupOfferMBFeatureGallery: "api/groupOffer/{id}/mbFeatureGallery",
            groupOfferMBGallery: "api/groupOffer/{id}/mbGallery",
            groupOfferMBSliderGallery: "api/groupOffer/{id}/mbSliderGallery",
            groupOfferOvertime: "api/groupOffer/{id}/overtime",
            groupOfferPMBGallery: "api/groupOffer/{id}/pmbGallery",
            groupOfferPreJoin: "api/groupOffer/{id}/prejoin",
            groupOfferRestore: "api/groupOffer/{id}/restore",
            groupOffers: "api/groupOffers",
            groupOfferSwitchLiveStream: "api/groupOffer/{id}/switchStream",
            marketingSubscribe: "api/marketing/signup",
            membershipOfferCancel: "api/membershipOffer/{id}/cancel",
            membershipOfferUpdatePaymentMethod: "api/membershipOffer/{id}/updatePaymentMethod",
            membershipOfferUpdateTier: "api/membershipOffer/{id}/updateTier",
            presale: "api/presale",
            product: "api/product/{id}",
            productArchive: "api/product/{id}/archive",
            productCategories: "api/productCategories",
            productCopy: "api/product/{id}/copy",
            productGallery: "api/product/{id}/gallery",
            productMBFeatureGallery: "api/product/{id}/mbFeatureGallery",
            productMBGallery: "api/product/{id}/mbGallery",
            productRestore: "api/product/{id}/restore",
            products: "api/products/{id}",
            purchase: "api/purchase/{id}",
            purchaseCancel: "api/purchase/{id}/cancel",
            purchaseComplete: "api/purchase/complete",
            purchaseConfirmation: "api/purchase/{id}/confirmation",
            purchaseFailed: "api/purchase/failed",
            purchasePrepPayment: "api/purchase/prepPayment",
            purchases: "api/purchases",
            sale: "api/sale/{id}",
            salePaymentReminder: "api/sale/{id}/paymentReminder",
            saleProcessDate: "api/sale/{id}/processDate",
            saleRefund: "api/sale/{id}/refund",
            sales: "api/sales",
            salesExport: "api/sales/export",
            saleStatus: "api/sale/{id}/status",
            seller: "api/seller",
            stripeAccount: "api/stripe/account",
            stripeAccountLinks: "api/stripe/account/links",
            stripeAccountLoginLink: "api/stripe/account/link/login",
            stripeCustomerSubscription: "api/stripe/customer/subscription",
            user: "api/user",
            validateEmail: "api/validateEmail",
            validationError: "api/validation/error",
            vendor: "api/vendor/{id}",
            vendorLatestGroupOffer: "api/vendor/{id}/latestGroupOffer",
            vendorProfile: "api/vendor/{id}/profile",
            verifyEmailCode: "api/verifyEmailCode",
            whiteLabel: "api/whiteLabel",
            



            activityCreate: "api/activity",
            activityData: "api/activity/{id}/data",
            activityDestroy: "api/activity/{id}",
            activityLike: "api/activity/like/{id}",
            activityPin: "api/activity/pin/{id}",
            activitySold: "api/activity/sold/{id}",
            activityUnpin: "api/activity/unpin/{id}",
            activityUpdate: "api/activity/{id}",
            attendeeBuild: "api/attendee/{id}/build",
            attendeeInvite: "api/attendee/{id}/invite",
            attendeeInviteAll: "api/attendee/{id}/inviteAll",
            attendeeUpdate: "api/attendee/{id}/update",
            buildCreate: "api/build",
            buildData: "api/build/{id}/data",
            buildDelete: "api/build/{id}",
            buildEquipBadge: "api/build/equipBadge",
            buildGalleryUpdate: "api/build/{id}/gallery",
            buildListAddShareMod: "api/build/{id}/mod/add",
            buildListAddTemplate: "api/build/{id}/lists/template",
            buildListDeleteCategory: "api/build/{id}/category/delete",
            buildListDeleteList: "api/build/{id}/list/delete",
            buildListDeleteMod: "api/build/{id}/mod/delete",
            buildListImport: "api/build/{id}/lists/import",
            buildListUpdate: "api/build/{id}/lists",
            buildListUpdateMod: "api/build/{id}/mod",
            buildNfts: "api/build/{id}/nfts",
            buildSaveSettings: "api/build/{id}/settings",
            buildSpecPrefill: "/api/prefill/{id}",
            buildTransferApprove: "api/buildTransfer/{id}/approve",
            buildTransferStore: "api/buildTransfer/{id}/create",
            buildUpdate: "api/build/{id}",
            chatCheckChat: "api/chat/userCheck",
            chatDelete: "api/chat/{id}",
            chatMemberInvite: "api/chatMember/{id}/invite",
            chatMemberUpdate: "api/chatMember/{id}/update",
            chatMessage: "api/chat/{id}/message",
            chatStore: "api/chat",
            chatUpdate: "api/chat/{id}",
            chatUpdateRead: "api/chat/{id}/updateRead",
            chatUserChats: "api/chat/userChats",
            codeCreate: "api/smartDevices",
            codeData: "api/smartDevices/{id}/data",
            codeUpdate: "api/smartDevices",
            collaborationDestroy: "api/collaboration/{id}",
            collaborationInvite: "api/collaboration/{id}",
            collaborationUpdate: "api/collaboration/{id}",
            commentCreate: "api/comment",
            communitiesData: "api/communities/data",
            communityData: "api/community/{id}/data",
            communityDelete: "api/community/{id}",
            communityMemberBuild: "api/communityMember/{id}/build",
            communityMemberInvite: "api/communityMember/{id}/invite",
            communityMemberInviteAll: "api/communityMember/{id}/inviteAll", 
            communityMembers: "api/community/{id}/members",
            communityMemberUpdate: "api/communityMember/{id}/update",
            communityMyBuilds: "api/community/{id}/myBuilds",
            communitySearch: "api/community/search",
            communityStore: "api/community",
            communityUpdate: "api/community/{id}",
            customerSubscriptions: "api/customer/subscriptions",
            eventAwardBadges: "api/event/{id}/awardBadges",
            eventData: "api/event/{id}/data",
            eventDelete: "api/event/{id}",
            eventGoing: "api/event/{id}/going",
            eventLeaderboards: "api/event/{id}/leaderboards",
            eventMyBuilds: "api/event/{id}/myBuilds",
            eventsData: "api/events/data",
            eventStore: "api/event",
            eventUpdate: "api/event/{id}",
            fbConversionsEvent: "api/conversions/event",
            fbConversionsTest: "api/conversions/test",
            flagStore: "api/flag",
            garageData: "api/garage/{id}/data",
            garageGalleryUpdate: "api/garage/{id}/gallery",
            garageUpdate: "api/garage/{id}",
            impersonateUser: "api/user/impersonate",
            impersonateUserStop: "api/user/impersonate/stop",
            leaderboardBuild: "api/leaderboard/{id}/build",
            leaderboardStopVoting: "api/leaderboard/{id}/stopVoting",
            leaderboardVote: "api/leaderboard/{id}/vote",
            leaderboardWildcard: "api/leaderboard/{id}/wildcard",
            linkOg: "api/link/og",
            login: "api/login",
            loginDesktopGoogle: "api/login/google/desktop",
            loginMobileApple: "api/login/apple/mobile",
            loginMobileFacebook: "api/login/facebook/mobile",
            loginMobileGoogle: "api/login/google/mobile",
            modGalleryUpdate: "api/build/{id}/mod/gallery",
            modSearch: "api/mod/search",
            nftAddBuildToCollectionContract: "api/nft/contract/collection/build/{id}",
            nftContractCollectionCurrent: "api/nft/contract/collection/current",
            onboardingAwardReviewBadge: "api/onboarding/awardReviewBadge",
            onboardingLevelTwo: "api/onboarding/levelTwo",
            onboardingLoggedIn: "api/onboarding/loggedIn",
            onboardingSeenHelp: "api/onboarding/seenHelp",
            onboardingSwipedHelp: "api/onboarding/swipedHelp",
            onboardingUserCategories: "api/onboarding/userCategories",
            onboardingUserCommunities: "api/onboarding/userCommunities",
            passwordReset: "api/password/reset",
            passwordResetVerification: "api/password/resetVerification",
            paypalCheckOnboardingStatus: "api/paypal/checkOnboardingStatus",
            paypalCompleteOnboarding: "api/paypal/completeOnboarding",
            postReactionCreate: "api/postReaction",
            postReactionLike: "api/postReaction/like/{id}",
            postReactionUpdate: "api/postReaction/{id}",
            register: "api/register",
            registerPlayerId: "api/registerPlayerId",
            sellerAvatar: "api/seller/avatar",
            sellerFavIcon: "api/seller/favIcon",
            sellerGallery: "api/seller/gallery",
            sellerLogo: "api/seller/logo",
            sellerPlans: "api/seller/plans",
            sellerTraitMBGallery: "api/seller/trait/mbGallery",
            sellerUploadFiles: "api/seller/upload/files",
            settingsAvatar: "api/settings/avatar",
            settingsDelete: "api/settings",
            settingsUpdate: "api/settings",
            shopifyProductImport: "api/shopify/products/import",
            shopifyProductUpload: "api/shopify/products/upload",
            shopifyScrapeTest: "api/shopify/scrapetest",
            smartCode: "api/smartDevices/code",
            smartCodes: "api/smartDevices/codes",
            smartLogs: "api/smartDevices/logs",
            stripeCheckoutSession: "api/stripe/checkoutSession",
            stripeCustomer: "api/stripe/customer",
            stripePaymentIntent: "api/stripe/paymentIntent",
            stripePaymentMethod: "api/stripe/paymentMethod",
            stripeResetBranding: "api/stripe/resetBranding",
            stripeSetupIntent: "api/stripe/setupIntent",
            testEvent: "api/conversions/test",
            unsubscribe: "api/unsubscribe",
            userBlock: "api/users/{id}/block",
            userConnections: "api/userConnections",
            userFollow: "api/users/{id}/follow",
            userUnblock: "api/users/{id}/unblock",
            userUnfollow: "api/users/{id}/unfollow",
            userUpdateShippingAddress: "api/users/{id}/shipping",
            verifyEmail: "api/reverify",
            visit: "api/visit"
        },
        saved: false,
        saving: false,
        savingError: false,
        savingTotal: 0,
        scrollToCommentId: null,
        scrollTop: 0,
        scrollToReactionId: null,
        searchForProducts: null,
        searchForText: "",
        sellerCommissions: null,
        sellerDefaultCommission: 10,
        sellerVendor: null,
        sellerVendorData: null,
        sellerVendorWhiteLabelData: null,
        serverConnected: true,
        shareIntentExternal: false,
        shareIntentProduct: null,
        shareIntentProductId: null,
        shareIntentURL: null,
        shippingModels: {
            general: 'Flat rate',
            unit: 'Per unit',
            price: 'Price',
            weight: 'Weight'
        },
        showActivityModal: false,
        showBadgeModal: false,
        showBadgePopup: false,
        showBadgeUnlockModal: false,
        showBuildUnlockModal: false,
        showEditCampaignModal: false,
        showEditCustomerSegmentModal: false,
        showEditGroupBuyModal: false,
        showEditListingModal: false,
        showEoiRegisterCompleteModal: false,
        showGlobalIcons: true,
        showMembershipModal: false,
        showMoreClicked: false,
        showNewBuildModal: false,
        showNewChatModal: false,
        showNewEventModal: false,
        showNewGroupModal: false,
        showNewListingModal: false,
        shownNotifications: false,
        showOnboardingSubCategoriesModal: false,
        showShareIntentModal: false,
        showSpinner: false,
        showTutorialGallery: false,
        showWizardDiscountCode: false,
        showWizardListing: false,
        showWizardProduct: false,
        showWizardSeller: false,
        showWizardWhiteLabel: false,
        showWriteActivity: false,
        showWritePostReaction: false,
        soundsLoaded: false,
        storedActivities: {},
        storedBuildz: {},
        storedCustomerEois: {},
        storedCustomerEoisList: null,
        storedCustomerGroupBuys: {},
        storedCustomerGroupBuysList: null,
        storedCustomerPurchasesList: null,
        storedEvents: {},
        storedGarages: {},
        storedGroupBuys: {},
        storedGroups: {},
        storedProducts: {},
        storedSellers: {},
        streamClient: null,
        streamToken: null,
        stripe: null,
        stripeAccount: null,
        stripeAccountChecked: false,
        stripeAccountComplete: false,
        stripeCheckoutElement: null,
        stripeCustomer: null,
        stripeLiveKey: "pk_live_51Ll0izLZWwHIBzXGTbVGAUo6220SNB0CkBzPkAApp6dD4ffCxY5umPzQsdh6krapNPWja1QHCp0BbYqLMsemHyrY003OpYRVeh",
        stripePaymentElement: null,
        stripeTempAccount: null,
        stripeTempAccountChecked: false,
        stripeTempAccountComplete: false,
        stripeTestKey: "pk_test_51Ll0izLZWwHIBzXGmVE3vz98Xp8NYyR0gL2xojdaQxa00XJ47Pee1dzHpEy3LT51I4I4LnGvuwCzPELb0thCZrSr00vWBzs9YH",
        subscribedChats: [],
        subscribedFeeds: [],
        suggestions: null,
        systemMessage: null,
        tabScrollTimeout: 10,
        tempGuestEmail: null,
        tempGroupBuyConversion: 0.66,
        tempGroupBuyId: 101,
        tempSellerVendor: null,
        tempSellerVendorData: null,
        tempSellerVendorWhiteLabelData: null,
        termsData: [],
        thankyouSuggested: null,
        transitTimes: {
            standard: 'Standard (2 to 8 business days)',
            express: 'Express (1 to 4 business days)',
            custom: 'Custom transit time'
        },
        tutorialGallery: null,
        tutorialName: null,
        unseen: 0,
        updatedEvent: null,
        uploadImages: [],
        userFunctionsFired: false,
        userQueue: null,
        userQueueId: 1,
        userSocketOpen: false,
        userTutorials: null,
        utcLocal: null,
        utcServer: null,
        utcServerCheck: null,
        vendorCampaigns: null,
        vendorCustomerSegments: null,
        vendorGroupBuys: null,
        vendorPaymentTypes: null,
        vendorPlans: null,
        vendorProductFiles: null,
        vendorProductCategories: null,
        vendorProductGroups: null,
        vendorProducts: null,
        whiteLabel: false,
        whiteLabelData: null,
        windowWidth: 0,
        wizardDiscountCodeData: null,
        wizardEditingProduct: null,
        wizardEditingTempSeller: false,
        wizardListingData: null,
        wizardListingStartPage: null,
        wizardProductData: null,
        wizardProductStartPage: null,
        wizardSellerData: null,
        wizardSellerStartPage: null,
        wizardWhiteLabelData: null,
        wizardWhiteLabelStartPage: null
    },
    mutations: {
        mergeStateData(state, data) {
            for (const [a, b] of Object.entries(data)) {
                for (const [x, y] of Object.entries(b)) {
                    state[x] = { ...state[x], ...y }

                    if (Object.entries(y)) {
                        for (const [c, d] of Object.entries(y)) {
                            if (Object.entries(d)) {
                                for (const [e, f] of Object.entries(d)) {
                                    if (!state[x][c][e]) {
                                        state[x] = { ...state[x], ...{ ...state[x][c], ...f } }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        setStateData(state, data) {
            for (const [a, b] of Object.entries(data)) {
                for (const [x, y] of Object.entries(b)) {
                    state[x] = y === Object(y) ? { ...state[x], ...y } : y
                }
            }
        },
        replaceStateData(state, data) {
            for (const [a, b] of Object.entries(data)) {
                for (const [x, y] of Object.entries(b)) {
                    ({ ...state[x] } = y)
                }
            }
        },
        replaceStateDataArray(state, data) {
            for (const [a, b] of Object.entries(data)) {
                for (const [x, y] of Object.entries(b)) {
                    ([...state[x]] = y)
                }
            }
        },
        setStreamClient(state, inClient) {
            state.streamClient = inClient
        }
    }
})

store.allState = Reflect.ownKeys(store.state)
// store.allGetters = Reflect.ownKeys(getters)
store.allMutations = Reflect.ownKeys(store._mutations)

export default store