<template>
    <wizard-popup-container v-if="formLoaded"
       :headingText="headingText" panelWidth="600px"
       @closeWizardPopup="clickCloseDiscountCodePopup"
    >
        <template #main>
            <div class="w-full">
                <div v-if="currentSlide == 1" class="w-full">
                    <input-panel 
                        fieldFormName="label" fieldLabel="Label" fieldLabelClasses="text-gray-800" fieldPlaceholder="Label" :fieldRequired="true" fieldType="text" 
                        :fieldValue="form.label" :forceMobile="true"
                        @fieldInput="updateFormValue"
                    >
                        <validation-message v-if="validating && !Boolean(form.label)" 
                            message="Label must be entered"
                        />
                    </input-panel>
                    <input-panel 
                        fieldFormName="code" fieldLabel="Code" fieldLabelClasses="text-gray-800" fieldPlaceholder="Code" :fieldRequired="true" fieldType="text" 
                        fieldSubLabel="This is the code that will be given to customers to enter during checkout." :fieldValue="form.code" :forceMobile="true"
                        @fieldInput="updateFormValue"
                    >
                        <validation-message v-if="validating && !Boolean(form.code)" 
                            message="Code must be entered"
                        />
                        <validation-message v-if="validating  && uniqueDiscountCode == false" 
                            message="Duplicate Code"
                        />
                    </input-panel>
                    <input-panel 
                        fieldFormName="percentage" fieldLabel="Discount Percentage" fieldLabelClasses="text-gray-800" fieldPlaceholder="Discount Percentage" 
                        :fieldRequired="true" fieldType="number" :fieldValue="form.percentage" :forceMobile="true"
                        @fieldInput="updateFormValue"
                    >
                        <validation-message v-if="validating && !Boolean(form.percentage)" 
                            message="Discount Percentage must be entered"
                        />
                    </input-panel>
                    <input-panel 
                        fieldFormName="maxUses" fieldLabel="How Many Times Can This Code Be Used?" fieldLabelClasses="text-gray-800" fieldPlaceholder="How Many Times Can This Code Be Used?" 
                        fieldSubLabel="Leave blank or enter 0 for unlimited use." fieldType="number" :fieldValue="form.maxUses" :forceMobile="true"
                        @fieldInput="updateFormValue"
                    />
                    <date-picker-panel
                        :fieldDisplayValue="form.startTime" fieldFormName="startTime" fieldLabel="Valid From" fieldLabelClasses="text-gray-800"
                        fieldSubLabel="Leave blank if this code is valid immediately." :fieldValue="dates.startTime" :forceMobile="true"
                        @fieldChange="updateFormDateValue"
                    />
                    <date-picker-panel
                        :fieldDisplayValue="form.endTime" fieldFormName="endTime" fieldLabel="Valid Until" fieldLabelClasses="text-gray-800"
                        fieldSubLabel="Leave blank if this code is valid indefinitely." :fieldValue="dates.endTime" :forceMobile="true"
                        @fieldChange="updateFormDateValue"
                    />
                    <select-panel 
                        :fieldData="yesNoData" fieldFormName="enabled" fieldLabel="Enabled" fieldLabelClasses="text-gray-800" :fieldRequired="true" :fieldValue="form.enabled" 
                        :forceMobile="true" :isLabelAndValue="true"
                        @fieldChange="updateFormValue"
                    >
                        <validation-message v-if="validating && form.enabled == null" 
                            message="Please select Yes or No"
                        />
                    </select-panel>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="w-full flex items-center bg-gray-100 border-t border-gray-800 py-2 h-12 rounded-b-md">
                <div class="w-1/2">
                    <div v-if="!hideClose " @click="clickCloseDiscountCodePopup()" class="secondary-button-base mx-2">
                        Close Without Saving
                    </div>
                </div>
                <div class="w-1/2">
                    <div v-if="!hideSave" @click="clickSaveDiscountCode()" class="standard-button-base mx-2">
                        Save And Close
                    </div>
                </div>
            </div>
        </template>
    </wizard-popup-container>
</template>

<script>
export default {
    props: [
        'discountCodes',
        'editingDiscountCode'
    ],
    data() {
        return {
            currentSlide: 1,
            dates: {
                endTime: null,
                startTime: null
            },
            form: {
                code: null,
                enabled: null,
                endTime: null,
                label: null,
                maxUses: null,
                multiple: null,
                percentage: null,
                startTime: null
            },
            formLoaded: false,
            originalForm: null,
            uniqueDiscountCode: false,
            validating: false
        }
    },
    computed: { 
        headingText() {
            return this.slides[this.currentSlide].heading
        },
        hideClose() {
            return !this.slides[this.currentSlide].close
        },
        hideNext() {
            return !this.slides[this.currentSlide].next
        },
        hidePrevious() {
            return !this.slides[this.currentSlide].previous
        },
        hideSave() {
            return !this.slides[this.currentSlide].save
        },
        slides() {
            return {
                1: {
                    close: true,
                    heading: `${this.editingDiscountCode ? 'Edit' : 'New'} Discount Code`,
                    next: false,
                    previous: false,
                    save: true,
                    validation: true
                }
            }
        }
    },
    mounted() {
        if (this.editingDiscountCode) {
            this.dates.endTime = this.editingDiscountCode.endTime ? this.editingDiscountCode.endTime * 1000 : null
            this.dates.startTime = this.editingDiscountCode.startTime ? this.editingDiscountCode.startTime * 1000 : null

            this.form.code = this?.editingDiscountCode?.code ?? null
            this.form.enabled = this?.editingDiscountCode?.enabled ?? null
            this.form.endTime = this?.editingDiscountCode?.endTime ?? null
            this.form.label = this?.editingDiscountCode?.label ?? null
            this.form.maxUses = this?.editingDiscountCode?.maxUses ?? null
            this.form.multiple = this?.editingDiscountCode?.multiple ?? null
            this.form.percentage = this?.editingDiscountCode?.percentage ?? null
            this.form.startTime = this?.editingDiscountCode?.startTime ?? null
        }

        this.originalForm = this.objCopy(this.form)
        this.formLoaded = true
    },
    methods: {
        clickCloseDiscountCodePopup() {
            if (JSON.stringify(this.originalForm) == JSON.stringify(this.form) || confirm("Confirm you wish to close without saving your changes?")) {
                this.$emit('clickCloseDiscountCodePopup')
            }
        },
        clickSaveDiscountCode() {
            if (!this.form.label || !this.form.code || !this.form.percentage || this.form.multiple == null || this.form.enabled == null) {
                this.validatiing = true
            }
            else {
                if (this.form.startTime) {
                    this.form.startTime = Number.isInteger(this.dateTimeToUnixTime(this.form.startTime)) ? this.dateTimeToUnixTime(this.form.startTime) : this.form.startTime / 1000
                }

                if (this.form.endTime) {
                    this.form.endTime = Number.isInteger(this.dateTimeToUnixTime(this.form.endTime)) ? this.dateTimeToUnixTime(this.form.endTime) : this.form.endTime / 1000
                }

                if (this.isDiscountCodeUnique()) {
                    this.validating = false
                    this.$emit('clickSaveDiscountCode', this.form)
                }
                else {
                    this.validating = true
                }
            }
        },
        isDiscountCodeUnique() {
            let checkCode = null
            
            if (!this.editingDiscountCode || this.form.code != this.originalForm.code) {
                checkCode = Boolean(this.discountCodes.find((myCode) => myCode.code == this.form.code))
            }

            this.uniqueDiscountCode = !checkCode
            return !checkCode
        },
        updateFormDateValue(inField, inValue) {
            let originalValue = Number.isInteger(this.dateTimeToUnixTime(this.dates[inField])) ? this.dateTimeToUnixTime(this.dates[inField]) : this.dates[inField] / 1000
            let newValue = Number.isInteger(this.dateTimeToUnixTime(inValue)) ? this.dateTimeToUnixTime(inValue) : inValue / 1000

            if (originalValue != newValue) {
                this.dates[inField] = inValue
                this.form[inField] = inValue
            }
        },
        updateFormValue(inField, inValue) {
            this.form[inField] = inValue

            if (inField == 'maxUses') {
                this.form.multiple == inValue && inValue > 1
            }
        }
    }
}
</script>