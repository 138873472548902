<template>
    <div v-if="formLoaded" class="w-full">
        <select-panel
            :fieldData="yesNoData" fieldFormName="hasModifiers" fieldLabel="Do Any Of Your Products Have Modifiers?" fieldLabelClasses="text-gray-800" 
            :fieldRequired="true" :fieldValue="sellerDataForm.hasModifiers" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateSellerDataFormSelectValue"
        >
            <validation-message v-if="validating && sellerDataForm.hasModifiers == null" 
                message="Select yes or no"
            />
        </select-panel>
        <div v-if="sellerDataForm.hasModifiers" class="w-full text-sm">
            <wizard-table-container
                headingText="Modifiers" :showNew="showIcons"
                @clickNewRecord="clickNewModifier"
            >
                <template #records>
                    <edit-table-record v-if="addingModifier"
                        :duplicateValueError="duplicateModifierNameError" fieldFormName="label" fieldId="inputNewModifier" fieldType="text" :fieldValue="addModifierForm.label"
                        :validatingForm="validatingForm"
                        @clickCancel="clickCancelNewModifier" @clickSave="clickSaveModifier" @updateFieldValue="updateAddModifierFormValue"
                    />
                    <draggable
                        animation="250" easing="cubic-bezier(1, 0, 0, 1)" handle=".handle-modifier" item-key="id" :list="modifiers" tag="div" 
                        @change="saveOrder()"
                    >
                        <template #item="{element, index}">
                            <div class="w-full">
                                <table-record v-if="editingValueId || editingModifierId != element.id">
                                    <template #record>
                                        <div @click="clickToggleShowModifier(element.id)" class="pl-2">
                                            {{ element.label }}
                                        </div>
                                        <div v-if="showIcons" class="wizard-icon-container">
                                            <div @click="clickToggleShowModifier(element.id)" v-show="!isModifierOpen(element.id)" class="cursor-pointer">
                                                <fa-icon icon="chevron-left" type="fas" class="h-5 w-5" />
                                            </div>
                                            <div @click="clickToggleShowModifier(element.id)" v-show="isModifierOpen(element.id)" class="cursor-pointer">
                                                <fa-icon icon="chevron-down" type="fas" class="h-5 w-5" />
                                            </div>
                                            <div class="cursor-move handle-modifier ml-2">
                                                <fa-icon icon="up-down-left-right" type="fas" class="h-5 w-5" />
                                            </div>
                                            <div @click="clickNewValue(element.id)" class="cursor-pointer ml-2">
                                                <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                                            </div>
                                            <div @click="clickEditModifier(element.id, element.label)" class="cursor-pointer ml-2">
                                                <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                                            </div>
                                            <div @click="clickDeleteModifier(element.id, element.label)" class="cursor-pointer ml-2">
                                                <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                            </div>
                                        </div>
                                    </template>
                                    <template #extra>
                                        <div v-if="isModifierOpen(element.id)" class="w-full">
                                            <wizard-table-container
                                                :childTable="true" :hideHeader="true" :showNew="false"
                                            >
                                                <template #records>
                                                    <edit-table-record v-if="addingModifierId && addingModifierId == element.id"
                                                        :duplicateValueError="duplicateValueNameError" fieldFormName="label" fieldId="inputNewValue" fieldType="text" :fieldValue="addValueForm.label"
                                                        :validatingForm="validatingForm"
                                                        @clickCancel="clickCancelNewValue" @clickSave="clickSaveValue" @updateFieldValue="updateAddValueFormValue"
                                                    />
                                                    <draggable-modifier-values
                                                        :duplicateValueNameError="duplicateValueNameError" :editingModifierId="editingModifierId" :editValueForm="editValueForm" :editingValueId="editingValueId"
                                                        :modifier="element" :showIcons="showIcons" :validatingForm="validatingForm"
                                                        @clickCancelEditValue="clickCancelEditValue" @clickDeleteValue="clickDeleteValue" @clickEditValue="clickEditValue" @clickSaveValue="clickSaveValue"
                                                        @saveOrder="saveOrder" @updateEditValueFormValue="updateEditValueFormValue"
                                                    />
                                                </template>
                                            </wizard-table-container>
                                        </div>
                                    </template>
                                </table-record>
                                <edit-table-record v-if="!editingValueId && editingModifierId == element.id"
                                    :duplicateValueError="duplicateModifierNameError" fieldFormName="label" fieldId="inputEditModifier" fieldType="text" :fieldValue="editModifierForm.label"
                                    :validatingForm="validatingForm"
                                    @clickCancel="clickCancelEditModifier" @clickSave="clickSaveModifier" @updateFieldValue="updateEditModifierFormValue"
                                />
                            </div>
                        </template>
                    </draggable>
                </template>
            </wizard-table-container>
            <validation-message v-if="validating && sellerDataForm.hasModifiers && (!sellerForm.product_variants || !sellerForm.product_variants.length)" 
                 message="You must create at least one Modifier"
            />
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import DraggableModifierValues from '../components/page-09/DraggableModifierValues'
import EditTableRecord from '@/wizards/components/EditTableRecord'
import TableRecord from '@/wizards/components/TableRecord'

export default {
    props: [
        'sellerDataForm',
        'sellerForm',
        'validating'
    ],
    components: {
        draggable,
        DraggableModifierValues,
        EditTableRecord,
        TableRecord
    },
    data() {
        return {
            addingModifier: false,
            addingModifierId: null,
            addModifierForm: {
                id: null,
                label: null,
                values: []
            },
            addValueForm: {
                id: null,
                label: null
            },
            duplicateModifierNameError: false,
            duplicateValueNameError: false,
            editModifierForm: {
                label: null
            },
            editingModifierId: null,
            editingValueId: null,
            editValueForm: {
                label: null
            },
            formLoaded: false,
            modifiers: null,
            modifiersOpen: [],
            validatingForm: false
        }
    },
    computed: {
        showIcons() {
            return !this.addingModifier && !this.addingModifierId && !this.editingModifierId
        }
    },
    mounted() {
        this.modifiers = this.objCopy(this.sellerForm.product_variants)
        this.formLoaded = true
    },
    methods: {
        clickCancelEditModifier() {
            this.editModifierForm.label = null
            this.editingModifierId = null
            this.validatingForm = false
        },
        clickCancelEditValue() {
            this.editValueForm.label = null
            this.editingModifierId = null
            this.editingValueId = null
            this.validatingForm = false
        },
        clickCancelNewModifier() {
            this.addModifierForm.label = null
            this.addModifierForm.id = null
            this.addingModifier = false
            this.validatingForm = false
        },
        clickCancelNewValue() {
            this.addValueForm.label = null
            this.addValueForm.id = null
            this.addingModifierId = null
            this.validatingForm = false
        },
        clickDeleteModifier(inId, inLabel) {
            if (confirm(`Confirm you wish to delete ${inLabel}?`)) {
                this.modifiers = this.modifiers.filter((myModifier) => myModifier.id != inId)
                this.$emit('updateModifiers', this.modifiers)
            }
        },
        clickDeleteValue(inId, inModifierId, inLabel) {
            if (confirm(`Confirm you wish to delete ${inLabel}?`)) {
                let myModifierValues = this.modifiers.find((myValues) => myValues.id == inModifierId)

                if (myModifierValues) {
                    myModifierValues.values = myModifierValues.values.filter((myValue) => myValue.id != inId)
                    this.$emit('updateModifiers', this.modifiers)
                }
            }
        },
        clickEditModifier(inModifierId, inLabel) {
            this.editingModifierId = inModifierId
            this.editModifierForm.label = inLabel

            this.$nextTick(() => {
                this.elGet('inputEditModifier').focus()
            })
        },
        clickEditValue(inId, inModifierId, inLabel) {
            this.editingModifierId = inModifierId
            this.editingValueId = inId
            this.editValueForm.label = inLabel

            this.$nextTick(() => {
                this.elGet('inputEditValue').focus()
            })
        },
        clickNewValue(inModifierId) {
            this.addingModifierId = inModifierId

            if (!this.isModifierOpen(inModifierId)) {
                this.clickToggleShowModifier(inModifierId)
            }

            this.$nextTick(() => {
                this.elGet('inputNewValue').focus()
            })
        },
        clickNewModifier() {
            this.addingModifier = true

            this.$nextTick(() => {
                this.elGet('inputNewModifier').focus()
            })
        },
        clickSaveModifier() {
            if (((this.editingModifierId && this.editModifierForm.label) || this.addModifierForm.label) && this.isModifierNameUnique()) {
                this.validatingForm = false

                if (this.editingModifierId) {
                    let myModifier = this.modifiers.find((myMod) => myMod.id == this.editingModifierId)

                    if (myModifier) {
                        myModifier.label = this.editModifierForm.label
                    }
                }
                else {
                    let myId = 0

                    if (this.modifiers) {
                        this.modifiers.forEach((myModifier) => {
                            if (myModifier.id > myId) {
                                myId = myModifier.id
                            }
                        })
                    }
                    else {
                        this.modifiers = []
                    }

                    this.addModifierForm.id = myId + 1
                    this.modifiers.push(this.objCopy(this.addModifierForm))
                }
                
                this.$emit('updateModifiers', this.modifiers)
                this.addModifierForm.id = null
                this.addModifierForm.label = null
                this.editModifierForm.label = null
                this.editingModifierId = null
            }
            else {
                this.validatingForm = true
            }
        },
        clickSaveValue() {
            if (((this.addingModifierId && this.addValueForm.label) || (this.editingModifierId && this.editValueForm.label)) && this.isValueNameUnique()) {
                this.validatingForm = false
                let myModifier = this.modifiers.find((myMod) => myMod.id == (this.addingModifierId ?? this.editingModifierId))
                
                if (myModifier) {
                    if (this.editingModifierId && myModifier.values && myModifier.values.length) {
                        let myValue = myModifier.values.find((myModifierValues) => myModifierValues.id == this.editingValueId)

                        if (myValue) {
                            myValue.label = this.editValueForm.label
                        }
                    }
                    else if (this.addingModifierId) {
                        if (!myModifier.values) {
                            myModifier.values = []
                        }

                        let myId = 0

                        myModifier.values.forEach((myValue) => {
                            if (myValue.id > myId) {
                                myId = myValue.id
                            }
                        })

                        this.addValueForm.id = myId + 1
                        myModifier.values.push(this.objCopy(this.addValueForm))
                    }

                    this.$emit('updateModifiers', this.modifiers)
                    this.addValueForm.label = null
                    this.addValueForm.id = null
                    this.editValueForm.label = null
                    this.editingModifierId = null
                    this.editingValueId = null
                }
            }
            else {
                this.validatingForm = true
            }
        },
        clickToggleShowModifier(inId) {
            if (!this.modifiersOpen.includes(inId)) {
                this.modifiersOpen.push(inId)
            }
            else {
                this.modifiersOpen = this.modifiersOpen.filter((myModifier) => myModifier != inId)
            }
        },
        isModifierNameUnique() {
            let myDuplicateError = false
            
            if (this.editingModifierId) {
                myDuplicateError = Boolean(this.modifiers.find((myModifier) => myModifier.id != this.editingModifierId && myModifier.label.toUpperCase() == this.editModifierForm.label.toUpperCase()))
            }
            else {
                myDuplicateError = Boolean(this.modifiers.find((myModifier) => myModifier.label.toUpperCase() == this.addModifierForm.label.toUpperCase()))
            }
            
            this.duplicateModifierNameError = myDuplicateError
            return !myDuplicateError
        },
        isModifierOpen(inId) {
            return this.modifiersOpen.includes(inId)
        },
        isValueNameUnique() {
            let myDuplicateError = false
            let myModifier = this.modifiers.find((myMod) => myMod.id == (this.addingModifierId ?? this.editingModifierId))
            let myValues = []

            if (myModifier && myModifier.values && myModifier.values.length) {
                myValues = myModifier.values
            }

            if (this.addingModifierId) {
                myDuplicateError = Boolean(myValues.find((myMod) => myMod.label.toUpperCase() == this.addValueForm.label.toUpperCase()))
            }
            else {
                myDuplicateError = Boolean(myValues.find((myMod) => myMod.id != this.editingValueId && myMod.label.toUpperCase() == this.editValueForm.label.toUpperCase()))
            }
            
            this.duplicateValueNameError = myDuplicateError
            return !myDuplicateError
        },
        saveOrder() {
            this.$emit('updateModifiers', this.modifiers)
        },
        updateAddModifierFormValue(inField, inValue) {
            inValue = this.capitalizeWordsFirstLetter(inValue.toLowerCase())
            this.addModifierForm[inField] = inValue
            this.isModifierNameUnique()
        },
        updateAddValueFormValue(inField, inValue) {
            this.addValueForm[inField] = inValue
            this.isValueNameUnique()
        },
        updateEditModifierFormValue(inField, inValue) {
            inValue = this.capitalizeWordsFirstLetter(inValue.toLowerCase())
            this.editModifierForm[inField] = inValue
            this.isModifierNameUnique()
        },
        updateEditValueFormValue(inField, inValue) {
            this.editValueForm[inField] = inValue
            this.isValueNameUnique()
        },
        updateSellerDataFormSelectValue(inField, inValue) {
            this.$emit('updateSellerDataFormSelectValue', inField, inValue)
        }
    }
}
</script>