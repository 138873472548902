<template>
    <wizard-table-container v-if="trait"
        :childTable="true" :hideHeader="true"
    >
        <template #records>
            <table-record>
                <template #record>
                    <div class="flex items-center justify-start w-full pl-2">
                        <child-line-prefix />
                        <div>
                            Modifiers
                        </div>
                    </div>
                    <div v-if="!hideIcons" class="wizard-icon-container">
                        <div @click="clickToggleShowModifiers()" v-show="!showMe" class="cursor-pointer">
                            <fa-icon icon="chevron-left" type="fas" class="h-5 w-5" />
                        </div>
                        <div @click="clickToggleShowModifiers()" v-show="showMe" class="cursor-pointer">
                            <fa-icon icon="chevron-down" type="fas" class="h-5 w-5" />
                        </div>
                        <div v-if="sortedUnselectedModifiers.length" @click="clickNewModifier()" class="cursor-pointer ml-2">
                            <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                </template>
                <template #extra>
                    <div v-if="showMe" class="w-full">
                        <wizard-table-container
                            :childTable="true" :hideHeader="true"
                        >
                            <template #records>
                                <edit-table-record v-if="addingModifier"
                                    :fieldData="sortedUnselectedModifiers" fieldDataLabel="label" fieldDataValue="id" fieldFormName="id" fieldType="select" 
                                    :fieldValue="addModifierForm.id" fieldValueErrorMessage="Modifier must be selected" recordClasses="pt-3 pl-2" :showChildLinePrefix="true" 
                                    :validatingForm="validatingForm"
                                    @clickCancel="clickCancelNewModifier" @clickSave="clickSaveModifier" @updateFieldValue="updateAddModifierFormValue"
                                />
                                <template v-for="modifier in modifiers" :key="id">
                                    <div v-if="isModifierSelected(modifier.id)" class="w-full">
                                        <table-record>
                                            <template #record>
                                                <div class="flex items-center justify-start w-full pl-4 relative">
                                                    <child-line-prefix />
                                                    <div>
                                                        {{ modifier.label }}
                                                    </div>
                                                    <div v-if="!hideIcons && isModifierOpen(modifier.id)" class="absolute -bottom-3 right-1 flex items-center justify-end text-xxs text-center">
                                                        <div class="w-12">
                                                            ENABLED
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="!hideIcons" class="wizard-icon-container">
                                                    <div @click="clickToggleShowModifier(modifier.id)" v-show="!isModifierOpen(modifier.id)" class="cursor-pointer">
                                                        <fa-icon icon="chevron-left" type="fas" class="h-5 w-5" />
                                                    </div>
                                                    <div @click="clickToggleShowModifier(modifier.id)" v-show="isModifierOpen(modifier.id)" class="cursor-pointer">
                                                        <fa-icon icon="chevron-down" type="fas" class="h-5 w-5" />
                                                    </div>
                                                    <div @click="clickDeleteModifier(modifier.id, modifier.label)" class="cursor-pointer ml-2">
                                                        <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                                    </div>
                                                </div>
                                            </template>
                                            <template #extra>
                                                <div v-if="isModifierOpen(modifier.id)" class="w-full">
                                                    <wizard-table-container
                                                        :childTable="true" :hideHeader="true" :showNew="false"
                                                    >
                                                        <template #records>
                                                            <div v-for="value in modifier.values">
                                                                <edit-table-record v-if="editingModifierId == modifier.id && editingValueId == value.id"
                                                                    fieldFormName="priceVariance" fieldId="inputEditValue" fieldPlaceholder=" " fieldType="number" 
                                                                    :fieldValue="editValueForm.priceVariance" fieldValueErrorMessage="Price Variance must be entered" :prefixText="`${value.label} Price Variance`" 
                                                                    recordClasses="pl-4 pt-3" :showChildLinePrefix="true" :validatingForm="validatingForm"
                                                                    @clickCancel="clickCancelEditValue" @clickSave="clickSaveValue" @updateFieldValue="updateEditValueFormValue"
                                                                />
                                                                <table-record v-if="!editingModifierId || editingModifierId != modifier.id || editingValueId != value.id">
                                                                    <template #record>
                                                                        <div class="flex items-center justify-between w-full pl-6">
                                                                            <div class="flex items-center justify-start w-full">
                                                                                <child-line-prefix />
                                                                                <div>
                                                                                    {{ value.label }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="w-24 text-right mr-12">
                                                                                {{ getPriceVariance(modifier.id, value.id) }}
                                                                            </div>
                                                                            <div class="w-12 text-center">
                                                                                <input @change="clickToggleValueSelected(modifier.id, value.id)" :checked="isValueSelected(modifier.id, value.id)" type="checkbox" class="w-4 h-4 text-orange-500 border-gray-300 rounded edit-field-text-color" :class="{ 'background-orange-500': isValueSelected(modifier.id, value.id) }">
                                                                            </div>
                                                                        </div>
                                                                        <div class="wizard-icon-container w-20">
                                                                             <div v-if="!hideIcons" @click="clickEditValue(modifier.id, value.id)" class="cursor-pointer ml-3">
                                                                                <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                                                                            </div>
                                                                            <div v-else class="ml-3 w-5">
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                </table-record>
                                                            </div>
                                                        </template>
                                                    </wizard-table-container>
                                                </div>
                                            </template>
                                        </table-record>
                                    </div>
                                </template>
                            </template>
                        </wizard-table-container>
                    </div>
                </template>
            </table-record>
        </template>
    </wizard-table-container>
</template>

<script>
import EditTableRecord from '@/wizards/components/EditTableRecord'
import TableRecord from '@/wizards/components/TableRecord'

export default {
    props: [
        'hideIcons',
        'modifiers',
        'traitId',
        'traits'
    ],
    components: {
        EditTableRecord,
        TableRecord
    },
    data() {
        return {
            addingModifier: false,
            addModifierForm: {
                id: null,
                values: []
            },
            editingModifierId: null,
            editingValueId: null,
            editValueForm: {
                priceVariance: null
            },
            modifiersOpen: [],
            showMe: false,
            trait: null,
            validatingForm: false
        }
    },
    computed: {
        sortedUnselectedModifiers() {
            if (!this.modifiers) {
                return []
            }

            return this.modifiers
                .filter((myModifier) => !(this.trait.modifiers
                    .find((myTModifier) => myTModifier.id == myModifier.id))
                )
        }
    },
    mounted() {
        this.trait = this.objCopy(this.traits.find((myTrait) => myTrait.id == this.traitId))
        let myOriginalTrait = this.objCopy(this.trait)
        
        if (this?.trait?.modifiers ?? false) {
            this.modifiers.forEach((myModifier) => {
                let myTraitModifier = this.trait.modifiers.find((myTModifier) => myTModifier.id == myModifier.id)
                let myValues = []
                
                if (myTraitModifier) {
                    myModifier.values.forEach((myValue) => {
                        let myNewValue = {
                            id: myValue.id,
                            priceVariance: null,
                            selected: true
                        }

                        let myTraitValue = myTraitModifier.values.find((myTValue) => myTValue.id == myValue.id)

                        if (myTraitValue) {
                            myNewValue.priceVariance = myTraitValue.priceVariance
                            myNewValue.selected = myTraitValue.selected
                        }

                        myValues.push(myNewValue)
                    })
                    
                    myTraitModifier.values = myValues
                }
            })
        }
        else {
            this.trait.modifiers = []
        }

        if (JSON.stringify(myOriginalTrait) != JSON.stringify(this.trait)) {
            console.log('mod')
            this.$emit('updateTrait', this.trait)
        }
    },
    methods: {
        clickCancelEditValue() {
            this.editValueForm.priceVariance = null
            this.editingModifierId = null
            this.editingValueId = null
            this.validatingForm = false
            this.$emit('switchHideIcons', false)
        },
        clickCancelNewModifier() {
            this.addModifierForm.id = null
            this.addModifierForm.values = []
            this.addingModifier = false
            this.validatingForm = false
            this.$emit('switchHideIcons', false)
        },
        clickDeleteModifier(inId, inLabel) {
            if (confirm(`Confirm you wish to delete ${inLabel}?`)) {
                this.trait.modifiers = this.trait.modifiers.filter((myModifier) => myModifier.id != inId)
                this.$emit('updateTrait', this.trait)
            }
        },
        clickEditValue(inModifierId, inId) {
            let myModifier = this.trait.modifiers.find((myMod) => myMod.id == inModifierId)
            let myValue = myModifier.values.find(((myVal) => myVal.id == inId))
            
            this.editValueForm.priceVariance = myValue.priceVariance ? this.fixProductPrice(this.sellerVendorData.defaultCurrency, myValue.priceVariance) : null
            this.editingModifierId = inModifierId
            this.editingValueId = inId

            this.$nextTick(() => {
                this.elGet('inputEditValue').focus()
            })

            this.$emit('switchHideIcons', true)
        },
        clickNewModifier() {
            this.addingModifier = true
            this.showMe = true
            this.$emit('switchHideIcons', true)
        },
        clickSaveModifier() {
            if (this.addModifierForm.id) {
                this.validatingForm = false
                let myModifier = this.modifiers.find((myMod) => myMod.id == this.addModifierForm.id)

                myModifier.values.forEach((myValue) => {
                    let myNewValue = {
                        id: myValue.id,
                        selected: true
                    }

                    this.addModifierForm.values.push(myNewValue)
                })

                this.trait.modifiers.push(this.objCopy(this.addModifierForm))
                this.$emit('updateTrait', this.trait)
                this.addModifierForm.id = null
                this.addModifierForm.values = []

                if (!this.sortedUnselectedModifiers.length) {
                    this.addingModifier = false
                    this.$emit('switchHideIcons', false)
                }
            }
            else {
                this.validatingForm = true
            }
        },
        clickSaveValue() {
            let myModifier = this.trait.modifiers.find((myMod) => myMod.id == this.editingModifierId)
            myModifier.values.find(((myVal) => myVal.id == this.editingValueId)).priceVariance = this.editValueForm.priceVariance
            this.$emit('updateTrait', this.trait)
            this.$emit('switchHideIcons', false)
            this.editingModifierId = null
            this.editingValueId = null
            this.editValueForm.priceVariance = null
        },
        clickToggleShowModifier(inId) {
            if (this.modifiersOpen.includes(inId)) {
                this.modifiersOpen = this.modifiersOpen.filter((myModifier) => myModifier != inId)
            }
            else {
                this.modifiersOpen.push(inId)
            }
        },
        clickToggleShowModifiers() {
            this.showMe = !this.showMe
        },
        clickToggleValueSelected(inModifierId, inId) {
            let myModifier = this.trait.modifiers.find((myMod) => myMod.id == inModifierId)
            myModifier.values.find(((myVal) => myVal.id == inId)).selected = !this.isValueSelected(inModifierId, inId)
            this.$emit('updateTrait', this.trait)
        },
        getPriceVariance(inModifierId, inId) {
            let myModifier = this.trait.modifiers.find((myMod) => myMod.id == inModifierId)
            let myValue = myModifier.values.find(((myVal) => myVal.id == inId))
            
            return this.displayPrice(this.sellerVendorData.defaultCurrency, myValue.priceVariance && !isNaN(myValue.priceVariance) ? myValue.priceVariance : 0)
        },
        isModifierOpen(inId) {
            return this.modifiersOpen.includes(inId)
        },
        isModifierSelected(inId) {
            return Boolean(this.trait.modifiers.find((myModifier) => myModifier.id == inId))
        },
        isValueSelected(inModifierId, inId) {
            let myModifier = this.trait.modifiers.find((myMod) => myMod.id == inModifierId)
            return Boolean(myModifier.values.find((myValue) => myValue.id == inId && myValue.selected))
        },
        updateAddModifierFormValue(inField, inValue) {
            this.addModifierForm[inField] = inValue
        },
        updateEditValueFormValue(inField, inValue) {
            this.editValueForm[inField] = inValue
        }
    }
}
</script>