<template>
    <div v-if="!whiteLabel || whiteLabelData" id="theMainPart" class="relative min-h-screen" :class="{ 'text-white': !whiteLabel }" :style="whiteLabel ? { 'color': whiteLabelData.primaryColor } : {}">
        <navbar v-if="!whiteLabel" />
        <white-label-header v-if="whiteLabel" />
        <div class="absolute w-full iphonePaddingTop iphonePaddingBottom min-h-full" :class="{ 'bg-gray-800 text-white': !whiteLabel }" :style="whiteLabel ? { 'background-color': whiteLabelData.backgroundColor, 'color': whiteLabelData.primaryColor } : {}">
            <div v-if="!whiteLabel" class="fixed top-0 left-0 w-full h-full">
                <div class="mx-auto w-full h-full">
                    <cached-image imageClasses="object-cover h-full w-full" :imageSrc="transformUrl(defaultHero, ['optimize', 'md', 'q80'])" />
                </div>
            </div>
            <div v-if="!whiteLabel" class="fixed top-0 left-0 h-full w-full">
                <div class="mx-auto w-full h-full black-blur-3">
                </div>
            </div>
            <div class="w-full flex items-start justify-start">
                <div v-if="!whiteLabel && isLoggedIn && loggedUser && isDesktopWidth" class="w-56">
                    <desktop-main-menu />
                </div>
                <router-view v-if="isGlobalData || !isLoggedIn" :key="$route.fullPath" v-show="!cachedHomeFeed || feedExists || $route.name != 'Feed'" />
                <cached-home-feed v-if="!whiteLabel && isLoggedIn && cachedHomeFeed && !feedExists && $route.name == 'Feed'" />
            </div>
        </div>
        <desktop-buttons v-if="!whiteLabel && isDesktopWidth" />
        <desktop-guest-logo v-if="!whiteLabel && isDesktopWidth && !loggedUser" />
        <footer-menu v-if="isLoggedIn && !whiteLabel && !isDesktopWidth && !isChatRoute" />
        <main-menu v-if="!whiteLabel && !isDesktopWidth && mainMenuOpen && isLoggedIn && loggedUser" />
        <builds-menu v-if="!whiteLabel && buildsMenuOpen && isLoggedIn && loggedUser" />
        <page-message />
        <group-offer-latest-sale v-if="latestSaleGroupOfferId && ($route.name == 'Checkout' || $route.name == 'GroupBuy' || $route.name == 'Active')" />
        <new-build-modal v-if="!whiteLabel && loggedUser && showNewBuildModal && buildTypes" 
            :build_types="buildTypes" :build_templates="buildTemplates" 
        />
        <!-- <new-event-modal v-if="loggedUser && showNewEventModal" /> -->
        <new-group-modal v-if="!whiteLabel && loggedUser && showNewGroupModal && buildTypes" />
        <activity-modal v-if="!whiteLabel && showActivityModal" />
        <badge-popup v-if="!whiteLabel && showBadgePopup && badgeAwarded" />
        <badge-modal v-if="!whiteLabel && showBadgeModal && badgeAwarded" />
        <onboarding-communities-modal v-if="!whiteLabel && showOnboardingSubCategoriesModal && loggedUser && buildTypes" />
        <edit-campaign-modal v-if="!whiteLabel && showEditCampaignModal" />
        <edit-customer-segment-modal v-if="!whiteLabel && showEditCustomerSegmentModal" />
        <edit-group-buy-modal v-if="!whiteLabel && showEditGroupBuyModal" />
        <edit-listing-modal v-if="!whiteLabel && showEditListingModal" />
        <new-listing-modal v-if="!whiteLabel && showNewListingModal" />
        <share-intent-modal v-if="!whiteLabel && showShareIntentModal && loggedUser" />
        <seller-wizard v-if="showWizardSeller" />
        <product-wizard v-if="showWizardProduct" />
        <listing-wizard v-if="showWizardListing" />
        <discount-code-wizard v-if="showWizardDiscountCode" />
        <white-label-wizard v-if="showWizardWhiteLabel" />
        <write-activity v-if="!whiteLabel && showWriteActivity" />
        <tutorial-gallery v-if="!whiteLabel && showTutorialGallery && tutorialGallery" 
            :gallery="tutorialGallery" :initialSlide="0" :appsFlyer="true"
            @close="closeTutorialGallery()" 
        />
        <notifications v-if="!whiteLabel && loggedUser && streamToken" 
            :open="shownNotifications" 
        />
        <flashers v-if="!whiteLabel" />
        <spinner v-if="showSpinner || (!isGlobalData && isLoggedIn)" />
    </div>
</template>

<script>
import ActivityModal from '@/modals/ActivityModal'
import BadgeModal from '@/modals/BadgeModal'
import BadgePopup from '@/modals/BadgePopup'
import BuildsMenu from '@/components/BuildsMenu'
import CachedHomeFeed from '@/components/CachedHomeFeed'
import DesktopButtons from '@/components/DesktopButtons'
import DesktopGuestLogo from '@/components/DesktopGuestLogo'
import DesktopMainMenu from '@/components/DesktopMainMenu'
import DiscountCodeWizard from '@/wizards/discount_code/DiscountCodeWizard'
import EditCampaignModal from '@/modals/EditCampaignModal'
import EditCustomerSegmentModal from '@/modals/EditCustomerSegmentModal'
import EditGroupBuyModal from '@/modals/EditGroupBuyModal'
import EditListingModal from '@/modals/EditListingModal'
import Flashers from '@/flashers/Flashers'
import FooterMenu from '@/components/FooterMenu'
import GroupOfferLatestSale from '@/shared/GroupOfferLatestSale'
import ListingWizard from '@/wizards/listing/ListingWizard'
import MainMenu from '@/components/MainMenu'
import Navbar from '@/components/Navbar'
import NewBuildModal from '@/modals/NewBuildModal'
// import NewEventModal from '@/modals/NewEventModal'
import NewGroupModal from '@/modals/NewGroupModal'
import NewListingModal from '@/modals/NewListingModal'
import Notifications from "@/shared/Notifications/Notifications"
import OnboardingCommunitiesModal from '@/modals/OnboardingCommunitiesModal'
import PageMessage from '@/components/PageMessage'
import ProductWizard from '@/wizards/product/ProductWizard'
import SellerWizard from '@/wizards/seller/SellerWizard'
import ShareIntentModal from '@/modals/ShareIntentModal'
import Spinner from '@/modals/Spinner'
import TutorialGallery from '@/shared/NewGallery'
import WhiteLabelHeader from '@/components/WhiteLabelHeader'
import WhiteLabelWizard from '@/wizards/white_label/WhiteLabelWizard'
import WriteActivity from '@/modals/WriteActivity'

export default {
    name: 'App',
    components: {
        ActivityModal,
        BadgeModal,
        BadgePopup,
        BuildsMenu,
        CachedHomeFeed,
        DesktopButtons,
        DesktopGuestLogo,
        DesktopMainMenu,
        DiscountCodeWizard,
        EditCampaignModal,
        EditCustomerSegmentModal,
        EditGroupBuyModal,
        EditListingModal,
        Flashers,
        FooterMenu,
        GroupOfferLatestSale,
        ListingWizard,
        MainMenu,
        Navbar,
        NewBuildModal,
        // NewEventModal,
        NewGroupModal,
        NewListingModal,
        Notifications,
        OnboardingCommunitiesModal,
        PageMessage,
        ProductWizard,
        SellerWizard,
        ShareIntentModal,
        Spinner,
        TutorialGallery,
        WhiteLabelHeader,
        WhiteLabelWizard,
        WriteActivity
    },
    computed: {
        feedExists() {
            return this.feedActivities && this.feedActivities[`timeline:${this.loggedUserId}`] && this.feedActivities[`timeline:${this.loggedUserId}`].length
        }
    },
    mounted() {
        this.appInit()
    }
}
</script>