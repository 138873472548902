<template>
    <div class="text-white">
        <media-panel v-if="favIconArray"
            fieldLabel="Fav Icon" fieldLabelClasses="text-gray-800" :fieldRequired="true" :imagesArray="favIconArray" :imagesOnly="true" :maxFiles="1" :multiple="false"
            @imageSavedOkay="favIconSavedOkay" @saveMedia="saveLocalFavIcon" @updateOfflineMedia="updateOfflineFavIcon"
        >
            <validation-message v-if="validating && (!favIconArray || !favIconArray.length)" 
                message="FavIcon must be selected"
            />
        </media-panel>
    </div>
</template>

<script>
export default {
    props: [
        'favIconArray',
        'validating'
    ],
    methods: {
        favIconSavedOkay(inImage, inIndex) {
            this.$emit('favIconSavedOkay', inImage, inIndex)
        },
        saveLocalFavIcon(inGallery) {
            this.$emit('saveLocalFavIcon', inGallery)
        },
        updateOfflineFavIcon(inGallery) {
            this.$emit('updateOfflineFavIcon', inGallery)
        }
    }
}
</script>