<template>
    <div class="w-full">
        <select-panel
            :fieldData="countryList" fieldDataLabel="name" fieldDataValue="code" fieldFormName="countryCode" fieldLabel="Country" 
            fieldLabelClasses="text-gray-800" :fieldRequired="true" :fieldValue="sellerDataForm.countryCode" :forceMobile="true"
            @fieldChange="changeCountry"
        >
            <validation-message v-if="validating && !sellerDataForm.countryCode" 
                message="Country must be selected"
            />
        </select-panel>
        <select-panel 
            :fieldData="sortedCurrencies" fieldDataLabel="name_plural" fieldDataValue="code" fieldFormName="defaultCurrency" fieldLabel="Currency" 
            fieldLabelClasses="text-gray-800" :fieldNonEdit="!wizardEditingTempSeller" :fieldNonEditValue="selectedCurrency" :fieldRequired="true" 
            :fieldValue="sellerDataForm.defaultCurrency" :forceMobile="true"
            @fieldChange="updateSellerDataFormSelectValue"
        >
            <validation-message v-if="validating && !sellerDataForm.defaultCurrency" 
                message="Currency must be selected"
            />
        </select-panel>
    </div>
</template>

<script>
export default {
    props: [
        'sellerDataForm',
        'validating'
    ],
    computed: {
        selectedCurrency() {
            if (!this.sellerDataForm.defaultCurrency) {
                return null
            }

            return this.currencies[this.sellerDataForm.defaultCurrency].name_plural
        },
        sortedCurrencies() {
            return Object.values(this.currencies)
                .sort((a, b) => a.name_plural < b.name_plural ? -1 : 1)
        }
    },
    mounted() {
        if (!this.sellerDataForm.countryCode) {
            let myCountryCode = this.coGetCountryCodeFromTZ()

            if (myCountryCode) {
                this.changeCountry('countryCode', myCountryCode)
            }
        }
    },
    methods: {
        changeCountry(inField, inValue) {
            this.$emit('changeCountry', inField, inValue)
        }
    }
}
</script>