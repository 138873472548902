<template>
    <div class="w-full">
        <media-panel v-if="imagesArray"
            fieldLabel="Media Gallery" fieldLabelClasses="text-gray-800" :imagesArray="imagesArray" :imagesOnly="false" :maxFiles="10" :multiple="true"
            @imageSavedOkay="galleryImageSavedOkay" @saveMedia="saveLocalGallery" @updateOfflineMedia="updateOfflineGallery"
        />
    </div>
</template>

<script>
export default {
    props: [
        'imagesArray',
        'validating'
    ],
    methods: {
        galleryImageSavedOkay(inImage, inIndex) {
            this.$emit('galleryImageSavedOkay', inImage, inIndex)
        },
        saveLocalGallery(inGallery) {
            this.$emit('saveLocalGallery', inGallery)
        },
        updateOfflineGallery(inGallery) {
            this.$emit('updateOfflineGallery', inGallery)
        }
    }
}
</script>