<template>
    <div class="text-white">
        <media-panel v-if="logoArray"
            fieldLabel="Logo" fieldLabelClasses="text-gray-800" :fieldRequired="true" :imagesArray="logoArray" :imagesOnly="true" :maxFiles="1" :multiple="false"
            @imageSavedOkay="logoSavedOkay" @saveMedia="saveLocalLogo" @updateOfflineMedia="updateOfflineLogo"
        >
            <validation-message v-if="validating && (!logoArray || !logoArray.length)" 
                message="Logo must be selected"
            />
        </media-panel>
    </div>
</template>

<script>
export default {
    props: [
        'logoArray',
        'validating'
    ],
    methods: {
        logoSavedOkay(inImage, inIndex) {
            this.$emit('logoSavedOkay', inImage, inIndex)
        },
        saveLocalLogo(inGallery) {
            this.$emit('saveLocalLogo', inGallery)
        },
        updateOfflineLogo(inGallery) {
            this.$emit('updateOfflineLogo', inGallery)
        }
    }
}
</script>