<template>
    <div v-if="formLoaded" class="w-full">
        <select-panel
            :fieldData="yesNoData" fieldFormName="hasAddOns" fieldLabel="Do Any Of Your Products Have Add-Ons?" fieldLabelClasses="text-gray-800" 
            :fieldRequired="true" :fieldValue="sellerDataForm.hasAddOns" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateSellerDataFormSelectValue"
        >
            <validation-message v-if="validating && sellerDataForm.hasAddOns == null" 
                message="Select yes or no"
            />
        </select-panel>
        <div v-if="sellerDataForm.hasAddOns" class="w-full text-sm">
            <wizard-table-container
                headingText="Add-Ons" :showNew="showIcons"
                @clickNewRecord="clickNewAddOn"
            >
                <template #records>
                    <edit-table-record v-if="addingAddOn"
                        :duplicateValueError="duplicateAddOnNameError" fieldFormName="label" fieldId="inputNewAddOn" fieldType="text" :fieldValue="addAddOnForm.label"
                        :validatingForm="validatingForm"
                        @clickCancel="clickCancelNewAddOn" @clickSave="clickSaveAddOn" @updateFieldValue="updateAddAddOnFormValue"
                    />
                    <draggable
                        animation="250" easing="cubic-bezier(1, 0, 0, 1)" handle=".handle-addOn" item-key="id" :list="addOns" tag="div" 
                        @change="saveOrder()"
                    >
                        <template #item="{element, index}">
                            <div class="w-full">
                                <table-record v-if="editingValueId || editingAddOnId != element.id">
                                    <template #record>
                                        <div @click="clickToggleShowAddOn(element.id)" class="pl-2">
                                            {{ element.label }}
                                        </div>
                                        <div v-if="showIcons" class="wizard-icon-container">
                                            <div @click="clickToggleShowAddOn(element.id)" v-show="!isAddOnOpen(element.id)" class="cursor-pointer">
                                                <fa-icon icon="chevron-left" type="fas" class="h-5 w-5" />
                                            </div>
                                            <div @click="clickToggleShowAddOn(element.id)" v-show="isAddOnOpen(element.id)" class="cursor-pointer">
                                                <fa-icon icon="chevron-down" type="fas" class="h-5 w-5" />
                                            </div>
                                            <div class="cursor-move handle-addOn ml-2">
                                                <fa-icon icon="up-down-left-right" type="fas" class="h-5 w-5" />
                                            </div>
                                            <div @click="clickNewValue(element.id)" class="cursor-pointer ml-2">
                                                <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                                            </div>
                                            <div @click="clickEditAddOn(element.id, element.label)" class="cursor-pointer ml-2">
                                                <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                                            </div>
                                            <div @click="clickDeleteAddOn(element.id, element.label)" class="cursor-pointer ml-2">
                                                <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                            </div>
                                        </div>
                                    </template>
                                    <template #extra>
                                        <div v-if="isAddOnOpen(element.id)" class="w-full">
                                            <wizard-table-container
                                                :childTable="true" :hideHeader="true" :showNew="false"
                                            >
                                                <template #records>
                                                    <edit-table-record v-if="addingAddOnId && addingAddOnId == element.id"
                                                        :duplicateValueError="duplicateValueNameError" fieldFormName="label" fieldId="inputNewValue" fieldType="text" :fieldValue="addValueForm.label"
                                                        :validatingForm="validatingForm"
                                                        @clickCancel="clickCancelNewValue" @clickSave="clickSaveValue" @updateFieldValue="updateAddValueFormValue"
                                                    />
                                                    <draggable-add-on-values
                                                        :addOn="element" :duplicateValueNameError="duplicateValueNameError" :editingAddOnId="editingAddOnId" :editValueForm="editValueForm" 
                                                        :editingValueId="editingValueId" :showIcons="showIcons" :validatingForm="validatingForm"
                                                        @clickCancelEditValue="clickCancelEditValue" @clickDeleteValue="clickDeleteValue" @clickEditValue="clickEditValue" @clickSaveValue="clickSaveValue"
                                                        @saveOrder="saveOrder" @updateEditValueFormValue="updateEditValueFormValue"
                                                    />
                                                </template>
                                            </wizard-table-container>
                                        </div>
                                    </template>
                                </table-record>
                                <edit-table-record v-if="!editingValueId && editingAddOnId == element.id"
                                    :duplicateValueError="duplicateAddOnNameError" fieldFormName="label" fieldId="inputEditAddOn" fieldType="text" :fieldValue="editAddOnForm.label"
                                    :validatingForm="validatingForm"
                                    @clickCancel="clickCancelEditAddOn" @clickSave="clickSaveAddOn" @updateFieldValue="updateEditAddOnFormValue"
                                />
                            </div>
                        </template>
                    </draggable>
                </template>
            </wizard-table-container>
            <validation-message v-if="validating && sellerDataForm.hasAddOns && (!sellerForm.product_add_ons || !sellerForm.product_add_ons.length)" 
                 message="You must create at least one Add-On"
            />
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import DraggableAddOnValues from '../components/page-10/DraggableAddOnValues'
import EditTableRecord from '@/wizards/components/EditTableRecord'
import TableRecord from '@/wizards/components/TableRecord'

export default {
    props: [
        'sellerDataForm',
        'sellerForm',
        'validating'
    ],
    components: {
        draggable,
        DraggableAddOnValues,
        EditTableRecord,
        TableRecord
    },
    data() {
        return {
            addingAddOn: false,
            addingAddOnId: null,
            addAddOnForm: {
                id: null,
                label: null,
                values: []
            },
            addOns: null,
            addOnsOpen: [],
            addValueForm: {
                id: null,
                label: null
            },
            duplicateAddOnNameError: false,
            duplicateValueNameError: false,
            editAddOnForm: {
                label: null
            },
            editingAddOnId: null,
            editingValueId: null,
            editValueForm: {
                label: null
            },
            formLoaded: false,
            validatingForm: false
        }
    },
    computed: {
        showIcons() {
            return !this.addingAddOn && !this.addingAddOnId && !this.editingAddOnId
        }
    },
    mounted() {
        this.addOns = this.objCopy(this.sellerForm.product_add_ons)
        this.formLoaded = true
    },
    methods: {
        clickCancelEditAddOn() {
            this.editAddOnForm.label = null
            this.editingAddOnId = null
            this.validatingForm = false
        },
        clickCancelEditValue() {
            this.editValueForm.label = null
            this.editingAddOnId = null
            this.editingValueId = null
            this.validatingForm = false
        },
        clickCancelNewAddOn() {
            this.addAddOnForm.label = null
            this.addAddOnForm.id = null
            this.addingAddOn = false
            this.validatingForm = false
        },
        clickCancelNewValue() {
            this.addValueForm.label = null
            this.addValueForm.id = null
            this.addingAddOnId = null
            this.validatingForm = false
        },
        clickDeleteValue(inAddOnId, inId, inLabel) {
            if (confirm(`Confirm you wish to delete ${inLabel}?`)) {
                let myAddOnValues = this.addOns.find((myValues) => myValues.id == inAddOnId)

                if (myAddOnValues) {
                    myAddOnValues.values = myAddOnValues.values.filter((myValue) => myValue.id != inId)
                    this.$emit('updateAddOns', this.addOns)
                }
            }
        },
        clickDeleteAddOn(inId, inLabel) {
            if (confirm(`Confirm you wish to delete ${inLabel}?`)) {
                this.addOns = this.addOns.filter((myAddOn) => myAddOn.id != inId)
                this.$emit('updateAddOns', this.addOns)
            }
        },
        clickEditAddOn(inAddOnId, inLabel) {
            this.editingAddOnId = inAddOnId
            this.editAddOnForm.label = inLabel

            this.$nextTick(() => {
                this.elGet('inputEditAddOn').focus()
            })
        },
        clickEditValue(inId, inAddOnId, inLabel) {
            this.editingAddOnId = inAddOnId
            this.editingValueId = inId
            this.editValueForm.label = inLabel

            this.$nextTick(() => {
                this.elGet('inputEditValue').focus()
            })
        },
        clickNewAddOn() {
            this.addingAddOn = true

            this.$nextTick(() => {
                this.elGet('inputNewAddOn').focus()
            })
        },
        clickNewValue(inAddOnId) {
            this.addingAddOnId = inAddOnId

            if (!this.isAddOnOpen(inAddOnId)) {
                this.clickToggleShowAddOn(inAddOnId)
            }

            this.$nextTick(() => {
                this.elGet('inputNewValue').focus()
            })
        },
        clickSaveAddOn() {
            if (((this.editingAddOnId && this.editAddOnForm.label) || this.addAddOnForm.label) && this.isAddOnNameUnique()) {
                this.validatingForm = false

                if (this.editingAddOnId) {
                    let myAddOn = this.addOns.find((myAdd) => myAdd.id == this.editingAddOnId)

                    if (myAddOn) {
                        myAddOn.label = this.editAddOnForm.label
                    }
                }
                else {
                    let myId = 0

                    if (this.addOns) {
                        this.addOns.forEach((myAddOn) => {
                            if (myAddOn.id > myId) {
                                myId = myAddOn.id
                            }
                        })
                    }
                    else {
                        this.addOns = []
                    }

                    this.addAddOnForm.id = myId + 1
                    this.addOns.push(this.objCopy(this.addAddOnForm))
                }
                
                this.$emit('updateAddOns', this.addOns)
                this.addAddOnForm.id = null
                this.addAddOnForm.label = null
                this.editAddOnForm.label = null
                this.editingAddOnId = null
            }
            else {
                this.validatingForm = true
            }
        },
        clickSaveValue() {
            if (((this.addingAddOnId && this.addValueForm.label) || (this.editingAddOnId && this.editValueForm.label)) && this.isValueNameUnique()) {
                this.validatingForm = false
                let myAddOn = this.addOns.find((myAdd) => myAdd.id == (this.addingAddOnId ?? this.editingAddOnId))
                
                if (myAddOn) {
                    if (this.editingAddOnId && myAddOn.values && myAddOn.values.length) {
                        let myValue = myAddOn.values.find((myAddOnValues) => myAddOnValues.id == this.editingValueId)

                        if (myValue) {
                            myValue.label = this.editValueForm.label
                        }
                    }
                    else if (this.addingAddOnId) {
                        if (!myAddOn.values) {
                            myAddOn.values = []
                        }

                        let myId = 0

                        myAddOn.values.forEach((myValue) => {
                            if (myValue.id > myId) {
                                myId = myValue.id
                            }
                        })

                        this.addValueForm.id = myId + 1
                        myAddOn.values.push(this.objCopy(this.addValueForm))
                    }

                    this.$emit('updateAddOns', this.addOns)
                    this.addValueForm.label = null
                    this.addValueForm.id = null
                    this.editValueForm.label = null
                    this.editingAddOnId = null
                    this.editingValueId = null
                }
            }
            else {
                this.validatingForm = true
            }
        },
        clickToggleShowAddOn(inId) {
            if (!this.addOnsOpen.includes(inId)) {
                this.addOnsOpen.push(inId)
            }
            else {
                this.addOnsOpen = this.addOnsOpen.filter((myAddOn) => myAddOn != inId)
            }
        },
        isAddOnNameUnique() {
            let myDuplicateError = false
            
            if (this.editingAddOnId) {
                myDuplicateError = Boolean(this.addOns.find((myAddOn) => myAddOn.id != this.editingAddOnId && myAddOn.label.toUpperCase() == this.editAddOnForm.label.toUpperCase()))
            }
            else {
                myDuplicateError = Boolean(this.addOns.find((myAddOn) => myAddOn.label.toUpperCase() == this.addAddOnForm.label.toUpperCase()))
            }
            
            this.duplicateAddOnNameError = myDuplicateError
            return !myDuplicateError
        },
        isAddOnOpen(inId) {
            return this.addOnsOpen.includes(inId)
        },
        isValueNameUnique() {
            let myDuplicateError = false
            let myAddOn = this.addOns.find((myAdd) => myAdd.id == (this.addingAddOnId ?? this.editingAddOnId))
            let myValues = []

            if (myAddOn && myAddOn.values && myAddOn.values.length) {
                myValues = myAddOn.values
            }

            if (this.addingAddOnId) {
                myDuplicateError = Boolean(myValues.find((myAdd) => myAdd.label.toUpperCase() == this.addValueForm.label.toUpperCase()))
            }
            else {
                myDuplicateError = Boolean(myValues.find((myAdd) => myAdd.id != this.editingValueId && myAdd.label.toUpperCase() == this.editValueForm.label.toUpperCase()))
            }
            
            this.duplicateValueNameError = myDuplicateError
            return !myDuplicateError
        },
        saveOrder() {
            this.$emit('updateAddOns', this.addOns)
        },
        updateAddAddOnFormValue(inField, inValue) {
            inValue = this.capitalizeWordsFirstLetter(inValue.toLowerCase())
            this.addAddOnForm[inField] = inValue
            this.isAddOnNameUnique()
        },
        updateAddValueFormValue(inField, inValue) {
            this.addValueForm[inField] = inValue
            this.isValueNameUnique()
        },
        updateEditAddOnFormValue(inField, inValue) {
            inValue = this.capitalizeWordsFirstLetter(inValue.toLowerCase())
            this.editAddOnForm[inField] = inValue
            this.isAddOnNameUnique()
        },
        updateEditValueFormValue(inField, inValue) {
            this.editValueForm[inField] = inValue
            this.isValueNameUnique()
        },
        updateSellerDataFormSelectValue(inField, inValue) {
            this.$emit('updateSellerDataFormSelectValue', inField, inValue)
        }
    }
}
</script>