const wizardMixins = {
    methods: {
        closeDiscountCodeWizard() {
            this.setStateData([
                { showWizardDiscountCode: false }
            ])
        },
        closeListingWizard() {
            this.setStateData([
                { showWizardListing: false }
            ])
        },
        closeProductWizard() {
            this.setStateData([
                { showWizardProduct: false }
            ])
        },
        closeSellerWizard() {
            this.setStateData([
                { showWizardSeller: false }
            ])
        },
        closeWhiteLabelWizard() {
            this.setStateData([
                { showWizardWhiteLabel: false }
            ])
        },
        openDiscountCodeWizard() {
            this.setStateData([
                { showWizardDiscountCode: true }
            ])
        },
        openListingWizard() {
            this.setStateData([
                { showWizardListing: true }
            ])
        },
        openProductWizard(inListing) {
            this.setStateData([
                { wizardEditingProduct: inListing },
                { showWizardProduct: true }
            ])
        },
        openSellerWizard() {
            this.setStateData([
                { showWizardSeller: true }
            ])
        },
        openWhiteLabelWizard() {
            this.setStateData([
                { showWizardWhiteLabel: true }
            ])
        }
    }
}


export default wizardMixins