<template>
    <div class="w-full">
        <draggable
            animation="250" easing="cubic-bezier(1, 0, 0, 1)" group="newList" handle=".handle-record" item-key="id" :list="featureList" tag="div" 
            @change="saveListOrder()"
        >
            <template #item="{element, index}">
                <feature-list-record class="w-full mb-px"
                    :featureListRecord="element"
                    @deleteFeatureListRecord="deleteFeatureListRecord" @editFeatureListRecord="editFeatureListRecord"
                />
            </template>
        </draggable>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import FeatureListRecord from './FeatureListRecord'

export default {
    props: [
        'featureList'
    ],
    components: {
        draggable,
        FeatureListRecord
    },
    methods: {
        deleteFeatureListRecord(inRecordId) {
            this.$emit('deleteFeatureListRecord', inRecordId)
        },
        editFeatureListRecord(inRecordId) {
            this.$emit('editFeatureListRecord', inRecordId)
        }
    }
}
</script>