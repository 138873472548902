<template>
    <div v-if="formLoaded" class="w-full">
        <select-panel
            :fieldData="yesNoData" fieldFormName="physical" fieldLabel="Do You Sell Products That Require Shipping?" fieldLabelClasses="text-gray-800" 
            :fieldRequired="true" :fieldValue="hasType('physical')" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateListingTypeValue"
        >
            <validation-message v-if="validating && hasType('physical') == null"
                message="Select yes or no"
            />
        </select-panel>
        <select-panel v-if="hasType('physical') != null"
            :fieldData="yesNoData" fieldFormName="digital" fieldLabel="Do You Sell Digital Products?" fieldLabelClasses="text-gray-800" 
            :fieldRequired="true" :fieldValue="hasType('digital')" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateListingTypeValue"
        >
            <validation-message v-if="validating && hasType('digital') == null"
                message="Select yes or no"
            />
        </select-panel>
        <select-panel v-if="hasType('digital') != null && hasType('physical') != null && (hasType('digital') || hasType('physical'))"
            :fieldData="yesNoData" fieldFormName="groupBuy" fieldLabel="Will You Offer Product Group Buys?" fieldLabelClasses="text-gray-800" 
            :fieldRequired="true" :fieldValue="hasType('groupBuy')" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateListingTypeValue"
        >
            <validation-message v-if="validating && hasType('groupBuy') == null"
                message="Select yes or no"
            />
        </select-panel>
        <select-panel v-if="hasType('groupBuy') != null"
            :fieldData="yesNoData" fieldFormName="giveAway" fieldLabel="Will You Offer Giveaways?" fieldLabelClasses="text-gray-800" 
            :fieldRequired="true" :fieldValue="hasType('giveAway')" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateListingTypeValue"
        >
            <validation-message v-if="validating && hasType('giveAway') == null"
                message="Select yes or no"
            />
        </select-panel>
        <select-panel v-if="hasType('giveAway') != null"
            :fieldData="yesNoData" fieldFormName="memberGiveAway" fieldLabel="Will You Offer Member Giveaways?" fieldLabelClasses="text-gray-800" 
            :fieldRequired="true" :fieldValue="hasType('memberGiveAway')" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateListingTypeValue"
        >
            <validation-message v-if="validating && hasType('memberGiveAway') == null"
                message="Select yes or no"
            />
        </select-panel>
    </div>
</template>

<script>
export default {
    props: [
        'sellerDataForm',
        'validating'
    ],
    data() {
        return {
            formLoaded: false
        }
    },
    mounted() {
        this.formLoaded = true
    },
    methods: {
        hasType(inType) {
            let myType = this.sellerDataForm.listingTypes.find((myType) => myType.type == inType)

            return myType ? myType.value : null
        },
        updateListingTypeValue(inField, inValue) {
            let myListingTypes = this.objCopy(this.sellerDataForm.listingTypes)

            if (!myListingTypes.find((myType) => myType.type == inField)) {
                let myNewType = {
                    type: inField,
                    value: inValue
                }

                myListingTypes.push(myNewType)
            }

            myListingTypes.find((myType) => myType.type == inField).value = inValue

            if (inField == 'digital' || inField == 'physical') {
                if (this.hasType('digital') == false && this.hasType('physical') == false) {
                    if (!myListingTypes.find((myType) => myType.type == 'groupBuy')) {
                        let myNewType = {
                            type: 'groupBuy',
                            value: false
                        }

                        myListingTypes.push(myNewType)
                    }

                    myListingTypes.find((myType) => myType.type == 'groupBuy').value = false
                }
            }

            this.$emit('updateSellerDataFormSelectValue', 'listingTypes', myListingTypes)
        }
    }
}
</script>