const cartMixins = {
    methods: {
        addToCart(inProduct, inQty, inCurrency, inVendorName, inStopMessage) {
            if (!localStorage.getItem('cartId')) {
                localStorage.setItem('cartId', this.getRandomString(40))
            }

            let { [`${inProduct.vendor_id}`]: myVendorCart, ...otherCarts } = this.cart ? this.objCopy(this.cart) : {}

            if (!myVendorCart) {
                myVendorCart = {
                    id: inProduct.vendor_id,
                    name: inVendorName,
                    currency: inCurrency,
                    products: {}
                }
            }

            let myLabel = inProduct.id

            if (inProduct.variant_identifier) {
                myLabel = `${myLabel}|${inProduct.variant_identifier}`
            }

            if (inProduct.add_on_identifier) {
                myLabel = `${myLabel}|${inProduct.add_on_identifier}`
            }

            let { [`${myLabel}`]: existingProduct, ...otherProducts } = this.objCopy(myVendorCart.products)

            let myProduct = {
                data: this.objCopy(inProduct),
                qty: inQty
            }

            myVendorCart.products = { ...otherProducts , [`${myLabel}`]: myProduct }
            
            let myCart = { ...otherCarts, [`${inProduct.vendor_id}`]: myVendorCart }

            this.setStateData([
                { cart: this.objCopy(myCart) }
            ])

            this.setStorage('cart', JSON.stringify(myCart))

            if (!inStopMessage) {
                if (!existingProduct) {
                    this.pageMessage('success', "Product added to cart")
                }
                else {
                    this.pageMessage('success', "Cart updated")
                }
            }

            let myGaItems = this.gaBuildItemArray([myProduct])
            let myGaValue = myProduct.qty * this.fixProductPrice(myProduct.data.price.currency, myProduct.data.price.amount)

            this.gaAddToCart(
                inCurrency,
                myGaValue,
                myGaItems
            )

            buildzAxios?.post(this.getRoute('cart', inProduct.id), {
                qty: inQty,
                cartId: localStorage.getItem('cartId'),
                variantId: inProduct.variant_identifier ?? null,
                addOnId: inProduct.add_on_identifier ?? null
            })
        },
        cartCheckout(inCart) {
            this.setStateData([
                { checkoutCart: this.objCopy(inCart) },
                { checkoutType: "cart" },
                { checkoutProduct: null },
                { checkoutGroupBuy: null },
                { checkoutBuyNowQty: null }
            ])

            this.routeTo('/checkout')
        },
        deleteCart(inCartId, inConfirm) {
            if (!inConfirm || confirm("You wish to delete this cart?")) {
                let { [`${inCartId}`]: myVendorCart, ...otherCarts } = this.cart 

                this.setStateData([
                    { cart: null }
                ])

                this.setStateData([
                    { cart: this.objCopy(otherCarts) }
                ])

                buildzAxios?.delete(this.getRoute('cart'), {
                    data: {
                        vendorId: inCartId,
                        cartId: localStorage.getItem('cartId')
                    }
                })
            }
        },
        getCart() {
            if (this.loggedUser || localStorage.getItem('cartId')) {
                buildzAxios?.get(this.getRoute('cart'), {
                    params: {
                        cartId: localStorage.getItem('cartId')
                    }
                })
                .then((response) => {
                    if (response.data.cart) {
                        let myCart = this.objCopy(response.data.cart)
                        let myCartCopy = this.objCopy(response.data.cart)

                        Object.values(myCartCopy).forEach((theCart) => {
                            Object.values(theCart.products)
                                .forEach((myCartProduct) => {
                                    if (myCartProduct.variant_id) {
                                        let myTheVariant = myCartProduct.data.product_variants.theVariants.find((myTheVariant) => myTheVariant.identifier == myCartProduct.variant_id && myTheVariant.enabled) 

                                        if (myTheVariant) {
                                            myCart[theCart.id].products[`${myCartProduct.id}|${myCartProduct.variant_id}`].data.variant_identifier = myCartProduct.variant_id
                                            myCart[theCart.id].products[`${myCartProduct.id}|${myCartProduct.variant_id}`].data.variant_description = this.getVariantDescription(myCartProduct.data, myTheVariant, myCartProduct.variant_id)
                                            myCart[theCart.id].products[`${myCartProduct.id}|${myCartProduct.variant_id}`].data.price.amount = parseFloat(myCart[theCart.id].products[`${myCartProduct.id}|${myCartProduct.variant_id}`].data.price.amount) + this.getVariantPriceAdjustment(myCartProduct.data, myTheVariant, myCartProduct.variant_id)

                                            if (myCart[theCart.id].products[`${myCartProduct.id}|${myCartProduct.variant_id}`].data.compare_at_price) {
                                                myCart[theCart.id].products[`${myCartProduct.id}|${myCartProduct.variant_id}`].data.compare_at_price = parseFloat(myCart[theCart.id].products[`${myCartProduct.id}|${myCartProduct.variant_id}`].data.compare_at_price) + this.getVariantPriceAdjustment(myCartProduct.data, myTheVariant, myCartProduct.variant_id)
                                            }
                                        }
                                    }

                                    if (myCartProduct.add_on_id) {
                                        myCart[theCart.id].products[`${myCartProduct.id}|${myCartProduct.add_on_id}`].data.add_on_identifier = myCartProduct.add_on_id
                                        myCart[theCart.id].products[`${myCartProduct.id}|${myCartProduct.add_on_id}`].data.add_on_description = this.getAddOnDescription(myCartProduct.data, myCartProduct.add_on_id)
                                        myCart[theCart.id].products[`${myCartProduct.id}|${myCartProduct.add_on_id}`].data.price.amount = parseFloat(myCart[theCart.id].products[`${myCartProduct.id}|${myCartProduct.add_on_id}`].data.price.amount) + this.getAddOnPriceAdjustment(myCartProduct.data, myCartProduct.add_on_id)

                                        if (myCart[theCart.id].products[`${myCartProduct.id}|${myCartProduct.add_on_id}`].data.compare_at_price) {
                                            myCart[theCart.id].products[`${myCartProduct.id}|${myCartProduct.add_on_id}`].data.compare_at_price = parseFloat(myCart[theCart.id].products[`${myCartProduct.id}|${myCartProduct.add_on_id}`].data.compare_at_price) + this.getAddOnPriceAdjustment(myCartProduct.data, myCartProduct.add_on_id)
                                        }
                                    }
                                })
                        })

                        this.setStateData([
                            { cart: myCart }
                        ])
                    }
                })
                .catch((error) => {
                    
                })
            }
        },
        removeFromCart(inProduct, inFromCheckout) {
            if (confirm("You wish to remove this item from your cart?")) {
                let { [`${inProduct.vendor_id}`]: myVendorCart, ...otherCarts } = this.cart ? this.objCopy(this.cart) : {}

                if (myVendorCart) {
                    let myLabel = inProduct.id

                    if (inProduct.variant_identifier) {
                        myLabel = `${myLabel}|${inProduct.variant_identifier}`
                    }

                    if (inProduct.add_on_identifier) {
                        myLabel = `${myLabel}|${inProduct.add_on_identifier}`
                    }

                    let { [`${myLabel}`]: existingProduct, ...otherProducts } = this.objCopy(myVendorCart.products)

                    myVendorCart.products = otherProducts
                    
                    let myCart = { ...otherCarts, [`${inProduct.vendor_id}`]: myVendorCart }

                    this.setStateData([
                        { cart: this.objCopy(myCart) }
                    ])

                    this.setStorage('cart', JSON.stringify(myCart))

                    if (inFromCheckout) {
                        let { [`${inProduct.vendor_id}`]: myCheckoutCart, ...otherCheckoutCarts } = this.cart

                        this.setStateData([
                            { checkoutCart: myCheckoutCart }
                        ])
                    }

                    buildzAxios?.delete(this.getRoute('cartProduct', inProduct.id), {
                        data: {
                            cartId: localStorage.getItem('cartId'),
                            variantId: inProduct.variant_identifier ?? null,
                            addOnId: inProduct.add_on_identifier ?? null
                        }
                    })
                }

                this.pageMessage('success', "Product removed from cart")
            }
        }
    }
}

export default cartMixins