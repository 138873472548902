<template>
    <div class="mt-px w-full" :class="{ 'orange-transparent': !noColor, [recordContainerClasses]: recordContainerClasses }">
        <div class="flex justify-between items-center w-full" :class="{ 'py-3': !noVerticalPadding, [recordClasses]: recordClasses }">
            <slot name="record"></slot>
        </div>
        <div :class="{ [extraClasses]: extraClasses }">
            <slot name="extra"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'extraClasses',
        'noColor',
        'noVerticalPadding',
        'recordClasses',
        'recordContainerClasses'
    ]
}
</script>