<template>
    <div class="w-full">
        <input-panel 
            fieldFormName="sku" fieldLabel="SKU" fieldLabelClasses="text-gray-800" fieldPlaceholder="SKU" fieldType="text" 
            :fieldValue="productForm.sku" :forceMobile="true"
            @fieldInput="updateProductFormValue"
        />
        <select-panel
            :fieldData="yesNoData" fieldFormName="track_quantity" fieldLabel="Do You Wish To Track Stock Quantity For This Product?" fieldLabelClasses="text-gray-800" 
            :fieldRequired="true" :fieldValue="productForm.track_quantity" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateProductFormSelectValue"
        >
            <validation-message v-if="validating && productForm.track_quantity == null" 
                message="Select yes or no"
            />
        </select-panel>
        <div v-if="productForm.track_quantity" class="w-full">
            <input-panel 
                fieldFormName="qty" fieldLabel="Current Stock Quantity" fieldLabelClasses="text-gray-800" fieldPlaceholder="Current Stock Quantity" :fieldRequired="true" fieldType="number" 
                :fieldValue="productForm.qty" :forceMobile="true"
                @fieldInput="updateProductFormValue"
            >
                <validation-message v-if="validating && !productForm.qty" 
                    message="Current Stock Quantity must be entered"
                />
            </input-panel>
        </div>
        <select-panel
            :fieldData="yesNoData" fieldFormName="hasMinimumPurchaseQty" fieldLabel="Does This Product Have a Minimum Purchase Quantity?" fieldLabelClasses="text-gray-800" 
            :fieldRequired="true" :fieldValue="productDataForm.hasMinimumPurchaseQty" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateProductDataFormSelectValue"
        >
            <validation-message v-if="validating && productDataForm.hasMinimumPurchaseQty == null" 
                message="Select yes or no"
            />
        </select-panel>
        <input-panel v-if="productDataForm.hasMinimumPurchaseQty"
            fieldFormName="min_order_qty" fieldLabel="Minimum Purchase Quantity" fieldLabelClasses="text-gray-800" fieldPlaceholder="Minimum Purchase Quantity" :fieldRequired="true" fieldType="number" 
            :fieldValue="productForm.min_order_qty" :forceMobile="true"
            @fieldInput="updateProductFormValue"
        >
            <validation-message v-if="validating && !productForm.min_order_qty" 
                message="Minimum Purchase Quantity must be entered"
            />
        </input-panel>
    </div>
</template>

<script>
export default {
    props: [
        'productDataForm',
        'productForm',
        'validating'
    ],
    data() {
        return {
            itemConditionData: [
                'New', 
                'Used'
            ]
        }
    },
    mounted() {
        if (!this.productForm.min_order_qty) {
            this.updateProductFormValue('min_order_qty', 1)
        }
    },
    methods: {
        updateProductDataFormSelectValue(inField, inValue) {
            this.$emit('updateProductDataFormSelectValue', inField, inValue)
        },
        updateProductFormSelectValue(inField, inValue) {
            this.$emit('updateProductFormSelectValue', inField, inValue)
        },
        updateProductFormValue(inField, inValue) {
            this.$emit('updateProductFormValue', inField, inValue)
        }
    }
}
</script>