<template>
    <div v-if="formLoaded" class="w-full">
        <select-panel
            :fieldData="yesNoData" fieldFormName="hasDiscountCodes" fieldLabel="Do You Want To Use Discount Codes?" fieldLabelClasses="text-gray-800" 
            :fieldRequired="true" :fieldValue="sellerDataForm.hasDiscountCodes" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateSellerDataFormSelectValue"
        >
            <validation-message v-if="validating && sellerDataForm.hasDiscountCodes == null" 
                message="Select yes or no"
            />
        </select-panel>
        <div v-if="sellerDataForm.hasDiscountCodes" class="w-full px-2 text-sm">
            <div class="relative bg-orange-200">
                <div class="flex justify-between items-center w-full py-2 font-semibold">
                    <div class="w-full grid grid-cols-2">
                        <div class="text-left pl-2">
                            Label
                        </div>
                        <div class="w-full grid grid-cols-3 text-center">
                            <div>
                                Code
                            </div>
                            <div>
                                Uses
                            </div>
                            <div>
                                Enabled
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center justify-end w-20 text-gray-600 px-2">
                        <div @click="clickNewDiscountCode()" class="cursor-pointer">
                            <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                </div>
                <div v-for="discountCode in discountCodes" class="mt-px w-full" :class="{ 'orange-transparent': isCodeOkay(discountCode), 'red-transparent': !isCodeOkay(discountCode) }">
                    <div class="flex justify-between items-center w-full py-3">
                        <div class="w-full grid grid-cols-2">
                            <div class="text-left pl-2">
                                {{ discountCode.label }}
                            </div>
                            <div class="w-full grid grid-cols-3 text-center">
                                <div>
                                    {{ discountCode.code }}
                                </div>
                                <div>
                                    {{ discountCodeUseCount(discountCode) }}
                                </div>
                                <div>
                                    <input @change="clickToggleDiscountCodeEnabled(discountCode.code, discountCode.enabled)" :checked="discountCode.enabled" type="checkbox" class="w-4 h-4 text-orange-500 border-gray-300 rounded edit-field-text-color" :class="{ 'background-orange-500': discountCode.enabled }">
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center justify-end w-20 text-gray-600 px-2">
                            <div @click="clickEditDiscountCode(discountCode.code)" class="cursor-pointer">
                                <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                            </div>
                            <div @click="clickDeleteDiscountCode(discountCode.code, discountCode.label)" class="cursor-pointer ml-2">
                                <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'sellerDataForm',
        'sellerForm',
        'validating'
    ],
    data() {
        return {
            formLoaded: false,
        }
    },
    computed: {
        discountCodes() {
            return this.objCopy(this.sellerForm.discount_codes)
        }
    },
    mounted() {
        this.formLoaded = true
    },
    methods: {
        clickDeleteDiscountCode(inCode, inLabel) {
            if (confirm(`Confirm you wish to delete ${inLabel}?`)) {
                this.$emit('clickDeleteDiscountCode', inCode)
            }
        },
        clickEditDiscountCode(inCode) {
            this.$emit('clickEditDiscountCode', inCode)
        },
        clickNewDiscountCode() {
            this.$emit('clickNewDiscountCode')
        },
        clickToggleDiscountCodeEnabled(inCode, inEnabled) {
            this.$emit('clickToggleDiscountCodeEnabled', inCode, !inEnabled)
        },
        discountCodeUseCount(inCode) {
            return inCode.useCount ?? 0
        },
        isCodeOkay(inDiscountCode) {
            if (!inDiscountCode.enabled) {
                return false
            }

            if (inDiscountCode.maxUses && inDiscountCode.useCount >= inDiscountCode.maxUses) {
                return false
            }

            if (inDiscountCode.startTime && this.currentUnixTime() < inDiscountCode.startTime) {
                return false
            }


            if (inDiscountCode.endTime && this.currentUnixTime() > inDiscountCode.endTime) {
                return false
            }

            return true
        },
        updateSellerDataFormSelectValue(inField, inValue) {
            this.$emit('updateSellerDataFormSelectValue', inField, inValue)
        }
    }
}
</script>