<template>
    <div class="w-full" :class="{ 'px-2 mb-2': !childTable }">
        <div class="w-full" :class="{ 'mt-2 bg-orange-200': !childTable, [tableClasses]: tableClasses }">
            <div v-if="!hideHeader" class="flex items-center justify-between w-full" :class="{ 'py-2': !noVerticalPadding && !bigVerticalPadding, 'py-3': bigVerticalPadding }">
                <div v-if="columns" class="w-full grid text-center font-semibold" :class="{ [columnHeaderContainerClasses]: columnHeaderContainerClasses }">
                    <slot name="headingColumns"></slot>
                </div>
                <div v-else class="pl-2 w-full text-left" :class="{ 'font-semibold': !nonBoldHeading }">
                    {{ headingText }}
                </div>
                <div v-if="showNew" class="wizard-icon-container" :class="{ [iconContainerClasses]: iconContainerClasses }">
                    <div v-if="!hideNewButton" @click="clickNewRecord()" class="cursor-pointer">
                        <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                    </div>
                </div>
                <div v-if="showOpen" class="wizard-icon-container" :class="{ [iconContainerClasses]: iconContainerClasses }">
                    <div @click="clickOpenRecord()" class="cursor-pointer">
                        <fa-icon icon="chevron-left" type="fas" class="h-5 w-5" />
                    </div>
                </div>
                <div v-if="showClose" class="wizard-icon-container" :class="{ [iconContainerClasses]: iconContainerClasses }">
                    <div @click="clickCloseRecord()" class="cursor-pointer">
                        <fa-icon icon="chevron-down" type="fas" class="h-5 w-5" />
                    </div>
                </div>
            </div>
            <slot name="records"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'bigVerticalPadding',
        'childTable',
        'columnHeaderContainerClasses',
        'columns',
        'headingText',
        'hideHeader',
        'hideNewButton',
        'iconContainerClasses',
        'nonBoldHeading',
        'noVerticalPadding',
        'showClose',
        'showNew',
        'showOpen',
        'tableClasses'
    ],
    methods: {
        clickCloseRecord() {
            this.$emit('clickCloseRecord')
        },
        clickNewRecord() {
            this.$emit('clickNewRecord')
        },
        clickOpenRecord() {
            this.$emit('clickOpenRecord')
        }
    }
}
</script>