const groupBuyMixins = {
    methods: {
        closeEditGroupBuyModal() {
            this.setStateData([
                { showEditGroupBuyModal: false },
                { groupBuyToEdit: null }
            ])
        },
        editGroupBuy(inGroupBuy) {
            this.openEditGroupBuyModal(inGroupBuy)
        },
        eoiPending(inGroupBuy) {
            return (inGroupBuy.eoi && inGroupBuy.eoi_start_time && inGroupBuy.eoi_start_time > this.currentUnixTime())
        },
        eoiUnsuccessful(inGroupBuy) {
            return inGroupBuy.eoi && inGroupBuy.eoi_end_time < this.currentUnixTime() && inGroupBuy.eoi_current_qty < inGroupBuy.eoi_min_quantity
        },
        getGroupBuyDate(inDate) {
            return inDate ? this.getLocalUnixDate(inDate) : "--/--/----"
        },
        getGroupBuyTime(inDate) {
            return inDate ? this.getLocalUnixTime(inDate) : "--/--/----"
        },
        getGroupBuyQty(inQty) {
            return inQty ?? 0
        },
        getGroupBuyTierPrice(inIndex, inPrice, inPricingLevels) {
            if (inPricingLevels && inPricingLevels.length) {
                return inPrice - (inPricingLevels[inIndex].discount / 100 * inPrice)
            }
            
            return null
        },
        async groupBuyPreJoin(inIntentId, inVendorId, inVendorCurrency, inVendorTaxLabel, inGroupBuyId, inProducts, inAmount, inQty, inCommission, inShippingAmount, inShippingDetails, inCustomerDetails, inShippingTaxRate, inCustomerMessage, inImmediatePayment, inPaypal, inSecondaryOfferEntries, inTax, inDiscountAmount, inDiscountCodes, inGlobalDiscount, inTimedDealActive, inBundle, inMarketing) {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.post(this.getRoute('groupOfferPreJoin', inGroupBuyId), {   
                    intentId: inIntentId,
                    vendorId: inVendorId,
                    taxRateLabel: inVendorTaxLabel,
                    products: inProducts,
                    totalAmount: inAmount,
                    totalQty: inQty,
                    currency: inVendorCurrency,
                    commission: inCommission,
                    shippingAmount: inShippingAmount,
                    shippingDetails: inShippingDetails,
                    customerDetails: inCustomerDetails,
                    shippingTaxRate: inShippingTaxRate,
                    currencyData: this.currencies[inVendorCurrency],
                    customerMessage: inCustomerMessage,
                    immediatePayment: inImmediatePayment,
                    paypal: inPaypal,
                    whiteLabel: this.whiteLabel,
                    fbp: this.getCookie('_fbp'),
                    fbc: this.getCookie('_fbc'),
                    secondaryOfferEntries: inSecondaryOfferEntries,
                    discountAmount: inDiscountAmount,
                    discountCodes: inDiscountCodes,
                    globalDiscount: inGlobalDiscount,
                    timedDealActive: inTimedDealActive,
                    newShop: true,
                    bundle: inBundle,
                    marketing: inMarketing,
                    baseCurrencyTotal: this.getBaseCurrencyValue(inVendorCurrency, inAmount),
                    baseCurrencyCommission: this.getBaseCurrencyValue(inVendorCurrency, inCommission)
                })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    console.log(error)
                    // alert(error)
                    reject(error)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {
                    myReturn = e
                })
        
            return myReturn
        },
        groupBuyType(inType) {
            return this.pageData.groupBuy.types[inType]
        },
        isEoiCurrent(inGroupBuy) {
            return this.currentUnixTime() >= inGroupBuy.eoi_start_time && this.currentUnixTime() < inGroupBuy.eoi_end_time
        },
        openEditGroupBuyModal(inGroupBuy) {
            this.setStateData([
                { showEditGroupBuyModal: true },
                { groupBuyToEdit: inGroupBuy }
            ])
        },
        openGroupBuy(inGroupBuy, inWhiteLabel) {
            if (inWhiteLabel) {
                this.openNewTabLink(`https://${this.sellerVendor.sub_domain}.buildz.store/groupBuy/${inGroupBuy.id}`)
            }
            else {
                this.routeTo(`/groupBuy/${inGroupBuy.id}`)
            }
        },
        saveGroupBuy(inGroupBuyId, inForm) {
            this.saveStart()

            buildzAxios?.post(this.getRoute('groupOffer', inGroupBuyId), inForm)
                .then((response) => {
                    let myRecord = Object.entries(this.vendorGroupBuys).find((entry) => entry[1].id == inGroupBuyId)
                    let myRecords = this.objCopy(this.vendorGroupBuys)
                    myRecords[myRecord[0]] = this.objCopy(response.data.groupBuy)

                    this.setStateData([
                        { vendorGroupBuys: myRecords }
                    ])

                    this.saveDone()
                })
                .catch((error) => {
                    console.log(error)
                    this.saveDone()
                    this.pageMessage('error', 'Error updating Group Buy')
                })
        },
        showGroupBuyLabels(inType) {
            return inType != "giveAway"
        },
        updateGroupBuyLivestream(inData) {
            console.log('indata', inData)
            let myId = inData.id

            if (myId) {
                let { [`${myId}`]: _, ...theRest } = this.objCopy(this.groupBuyStreaming)

                let myStream = {
                    live_now: inData.live_now,
                    live_url: inData.live_url
                }

                let myStreaming = { ...theRest, [`${myId}`]: myStream }

                this.setStateData([
                    { groupBuyStreaming: myStreaming }
                ])
            }
        }
    }
}

export default groupBuyMixins