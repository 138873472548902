<template>
    <wizard-popup-container v-if="formLoaded"
       headingText="Text" panelWidth="800px"
       @closeWizardPopup="clickCloseBlockPopup"
    >
        <template #main>
            <div class="w-full p-1">
                <rich-text-panel 
                    fieldFormName="text" :fieldNude="true" :fieldRequired="true" :fieldValue="form.text" :hideLabel="true"
                    @fieldInput="updateText"
                >
                    <validation-message v-if="validating" 
                        message="Text must be added"
                    />
                </rich-text-panel>
            </div>
        </template>
        <template #footer>
            <div class="w-full flex items-center border-t border-gray-800 py-2 h-12 rounded-b-md">
                <div class="w-1/2">
                    <div @click="clickCloseBlockPopup()" class="secondary-button-base mx-2">
                        Close Without Saving
                    </div>
                </div>
                <div class="w-1/2">
                    <div @click="clickSaveBlock()" class="standard-button-base mx-2">
                        Save And Close
                    </div>
                </div>
            </div>
        </template>
    </wizard-popup-container>
</template>

<script>
export default {
    props: [
        'editingBlock'
    ],
    data() {
        return {
            form: {
                text: null
            },
            formLoaded: false,
            originalText: null,
            validating: false
        }
    },
    mounted() {
        if (this.editingBlock) {
            this.form.text = this.editingBlock.text
            this.originalText = this.editingBlock.text
        }

        this.formLoaded = true
    },
    methods: {
        clickCloseBlockPopup() {
            if (this.form.text == this.originalText || confirm("Confirm you wish to close without saving your changes?")) {
                this.$emit('clickCloseBlockPopup')
            }
        },
        clickSaveBlock() {
            if (this.form.text) {
                this.validating = false
                this.$emit('clickSaveBlock', this.form, 'text')
            }
            else {
                this.validating = true
            }
        },
        updateText(inField, inValue) {
            this.form.text = inValue
        }
    }
}
</script>