<template>
    <table-record 
        :extraClasses="extraClasses" :noVerticalPadding="true" :recordClasses="tableRecordClasses"
    >
        <template #record>
            <div class="w-full pl-2 pt-2 flex items-center justify-between">
                <div class="w-full flex items-center justify-center text-center relative">
                    <div class="flex justify-start w-1/3">
                        <div class="w-16">
                            <input-panel
                                fieldFormName="qty" fieldId="bulkDiscountQty" :fieldNude="true" :fieldRequired="true" 
                                fieldType="number" :fieldValue="bulkDiscountValue.qty" :forceMobile="true" :hideLabel="true"
                                @fieldInput="updateFieldValue" @hitEnter="clickSave" @hitEscape="clickCancel"
                            />
                        </div>
                    </div>
                    <div class="flex justify-center w-1/3">
                        <div class="w-16">
                            <input-panel
                                fieldFormName="percentage" :fieldNude="true" :fieldRequired="true" 
                                fieldType="number" :fieldValue="bulkDiscountValue.percentage" :forceMobile="true" :hideLabel="true"
                                @fieldInput="updateFieldValue" @hitEnter="clickSave" @hitEscape="clickCancel"
                            />
                        </div>
                    </div>
                    <div class="w-1/3 text-end pr-6">
                        <input @change="clickToggleBulkDiscountEnabled(bulkDiscountValue.enabled)" id="bulk-discount-enabled-check-edit" :checked="bulkDiscountValue.enabled" type="checkbox" class="w-4 h-4 text-orange-500 border-gray-300 rounded edit-field-text-color" :class="{ 'background-orange-500': bulkDiscountValue.enabled }">
                    </div>
                    <div class="absolute w-full left-0 -top-3 flex items-center justify-center text-xxs">
                        <div class="flex justify-start w-1/3 pl-1">
                            QUANTITY
                        </div>
                        <div class="flex justify-center w-1/3">
                            DISCOUNT %
                        </div>
                        <div class="w-1/3 text-end pr-3">
                            ENABLED
                        </div>
                    </div>
                </div>
                <div class="wizard-icon-container w-32">
                    <div @click="clickCancel()" class="cursor-pointer">
                        <fa-icon icon="ban" type="fas" class="h-5 w-5" />
                    </div>
                    <div @click="clickSave()" class="cursor-pointer ml-2">
                        <fa-icon icon="floppy-disk" type="fas" class="h-5 w-5" />
                    </div>
                </div>
            </div>
        </template>
        <template #extra>
            <div class="pr-8" :class="{ 'h-3': !validatingForm, 'py-1': validatingForm }">
                <validation-message v-if="validatingForm && (bulkDiscountValue.qty == null || !parseInt(bulkDiscountValue.qty))"
                    message="Purchase Quantity must be entered"
                />
                <validation-message v-if="validatingForm && bulkDiscountValue.qty != null && parseInt(bulkDiscountValue.qty) && duplicateValueQtyError"
                    message="Duplicate Purchase Quantity"
                />
                <validation-message v-if="validatingForm && (bulkDiscountValue.percentage == null || !parseFloat(bulkDiscountValue.percentage))"
                    message="Discount Percentage must be entered"
                />
            </div>
        </template>
    </table-record>
</template>

<script>
import TableRecord from './TableRecord'

export default {
    props:[
        'bulkDiscountValue',
        'duplicateValueQtyError',
        'extraClasses',
        'recordClasses',
        'validatingForm'
    ],
    components: {
        TableRecord
    },
    computed: {
        tableRecordClasses() {
            return this.recordClasses ?? "pt-3"
        }
    },
    methods: {
        clickCancel() {
            this.$emit('clickCancel')
        },
        clickSave() {
            this.$emit('clickSave')
        },
        clickToggleBulkDiscountEnabled(inEnabled) {
            this.$emit('updateFieldValue', 'enabled', !inEnabled)
        },
        updateFieldValue(inField, inValue) {
            this.$emit('updateFieldValue', inField, inValue)
        }
    }
}
</script>