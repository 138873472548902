<template>
    <div v-if="pages" class="w-full flex items-center justify-center mx-2 px-px bg-white" style="padding-top: 2px; padding-bottom: 2px">
        <template v-for="x in totalPages">
            <div v-if="!ignorePage(x)" class="mx-px h-4" :style="{ 'width': `${100 / (totalPages - ignorePagesLength) }%` }">
                <div @click="clickBar(x)" @mouseover="mouseOver(x)" @mouseleave="mouseLeave(x)" class="h-4 w-full" :class="{ 'bg-blue-400': x == currentPage, 'bg-green-400 cursor-pointer': validated(x) && x != currentPage && (x < firstNonValidated || !firstNonValidated), 'bg-red-400 cursor-pointer': !validated(x) && x == firstNonValidated && x != currentPage, 'bg-gray-300': firstNonValidated && x > firstNonValidated }">
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: [
        'currentPage',
        'firstNonValidated',
        'ignorePages',
        'introPages',
        'pages',
        'totalPages'
    ],
    data() {
        return {
            mouseOverOn: false
        }
    },
    computed: {
        ignorePagesLength() {
            if (!this.ignorePages || !this.ignorePages.length) {
                return 0
            }
            else {
                return this.ignorePages.length
            }
        },
        validationAdjuster() {
            return this.introPages ?? 0
        }
    },
    methods: {
        clickBar(inPageNumber) {
            if (this.validated(inPageNumber) || inPageNumber == this.firstNonValidated) {
                this.$emit('jumpToPage', inPageNumber + this.validationAdjuster)
            }
        },
        ignorePage(inPage) {
            if (!this.ignorePages || !this.ignorePages.length) {
                return false
            }
            else {
                return this.ignorePages.includes(inPage + this.validationAdjuster)
            }
        },
        mouseLeave(inPageNumber) {
            this.$emit('updateProgressLabel', inPageNumber + this.validationAdjuster, false)
        },
        mouseOver(inPageNumber) {
            this.$emit('updateProgressLabel', inPageNumber + this.validationAdjuster, inPageNumber != this.currentPage)
        },
        validated(inPageNumber) {
            return this.pages[inPageNumber + this.validationAdjuster].validation
        }
    }
}
</script>