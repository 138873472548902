const presaleMixins = {
    methods: {
        async presaleGiveaway(inGiveawayId, inBundle, inDiscountedTotal, inTimedDealActive, inDiscountCodes, inDiscountAmount, inGlobalDiscount, inCommission, inCurrencyData, inCustomerMessage, inSecondaryOfferEntries, inCreateAccount, inMarketing) {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.post(this.getRoute('presale'), {
                    presaleId: this.presaleId ?? null,
                    giveawayId: inGiveawayId,
                    bundle: inBundle,
                    deviceId: this.isMobileDevice ? this.deviceId.identifier : null,
                    browserId: !this.isMobileDevice ? this.deviceId.identifier : null,
                    type: 'giveaway',
                    timedDealActive: inTimedDealActive,
                    total: inDiscountedTotal,
                    discountCodes: inDiscountCodes,
                    discountAmount: inDiscountAmount,
                    globaDiscount: inGlobalDiscount,
                    commission: inCommission,
                    currencyData: inCurrencyData,
                    customerMessage: inCustomerMessage,
                    secondaryOfferEntries: inSecondaryOfferEntries,
                    createAccount: inCreateAccount,
                    marketing: inMarketing,
                    whiteLabel: this.whiteLabel,
                    fbp: this.getCookie('_fbp'),
                    fbc: this.getCookie('_fbc')
                })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log('error creating presale')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        }
    }
}

export default presaleMixins