<template>
    <div class="w-full">
        <select-panel
            :fieldData="yesNoData" fieldFormName="digital_product" fieldLabel="Is This a Digital Product?" fieldLabelClasses="text-gray-800" 
            :fieldRequired="true" :fieldValue="productForm.digital_product" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateProductFormSelectValue"
        >
            <validation-message v-if="validating && productForm.digital_product == null" 
                message="Select yes or no"
            />
        </select-panel>
        <div v-if="productForm.digital_product == false" class="w-full">
            <select-panel
                :fieldData="yesNoData" fieldFormName="free_shipping" fieldLabel="Does This Product Have Free Shipping?" fieldLabelClasses="text-gray-800" 
                :fieldRequired="true" :fieldValue="productForm.free_shipping" :forceMobile="true" :isLabelAndValue="true"
                @fieldChange="updateProductFormSelectValue"
            >
                <validation-message v-if="validating && productForm.free_shipping == null" 
                    message="Select yes or no"
                />
            </select-panel>
            <select-panel 
                :fieldData="itemConditionData" fieldFormName="item_condition" fieldLabel="Is This Product New or Used?" fieldLabelClasses="text-gray-800"  
                :fieldRequired="true" :fieldValue="productForm.item_condition" :forceMobile="true" :isSingleValue="true"
                @fieldChange="updateFormSelectValue"
            >
                <validation-message v-if="validating && productForm.item_condition == null" 
                    message="Item Condition must be selected"
                />
            </select-panel>
        </div>
        <div v-if="productForm.digital_product != null" class="w-full">
            <select-panel
                :fieldData="yesNoData" fieldFormName="group_buy_only" fieldLabel="Is This Product To Be Used Exclusively On A Group Offer?" fieldLabelClasses="text-gray-800" 
                :fieldRequired="true" :fieldValue="productForm.group_buy_only" :forceMobile="true" :isLabelAndValue="true"
                @fieldChange="updateProductFormSelectValue"
            >
                <validation-message v-if="validating && productForm.group_buy_only == null" 
                    message="Select yes or no"
                />
            </select-panel>
            <select-panel v-if="sellerVendor && sellerVendor.white_label"
                :fieldData="yesNoData" fieldFormName="white_label_only" fieldLabel="Is This Product To Be Visible Exclusively On Your White Label Shop?" fieldLabelClasses="text-gray-800" 
                :fieldRequired="true" :fieldValue="productForm.white_label_only" :forceMobile="true" :isLabelAndValue="true"
                @fieldChange="updateProductFormSelectValue"
            >
                <validation-message v-if="validating && productForm.white_label_only == null" 
                    message="Select yes or no"
                />
            </select-panel>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'productForm',
        'validating'
    ],
    data() {
        return {
            itemConditionData: [
                'New', 
                'Used'
            ]
        }
    },
    methods: {
        updateProductFormSelectValue(inField, inValue) {
            this.$emit('updateProductFormSelectValue', inField, inValue)
        }
    }
}
</script>