<template>
    <table-record
        :noColor="true" :noVerticalPadding="true" :recordContainerClasses="zone.rates && zone.rates.length ? 'orange-transparent' : (!zone.rates || zone.rates.length == 0 ? 'red-transparent' : '')"
    >
        <template #record>
            <wizard-table-container
                :bigVerticalPadding="true" :childTable="true" :headingText="zone.name" :nonBoldHeading="true" :showClose="!editingRateId && isZoneOpen" :showNew="false" :showOpen="!editingRateId && !isZoneOpen"
                @clickCloseRecord="clickCloseZone" @clickOpenRecord="clickOpenZone"
            >
                <template #records>
                    <zone-rates v-show="isZoneOpen" 
                        :defaultCurrency="defaultCurrency" :editingRateId="editingRateId" :editingRateZoneId="editingRateZoneId"
                        :shippingModel="shippingModel" :zone="zone"
                        @clickDeleteRate="clickDeleteRate" @clickEditRate="clickEditRate" @clickNewZoneRate="clickNewZoneRate"
                    />
                </template>
            </wizard-table-container>
        </template>
    </table-record>
</template>

<script>
import TableRecord from '@/wizards/components/TableRecord'
import ZoneRates from './ZoneRates'

export default {
    props: [
        'defaultCurrency',
        'editingRateId',
        'editingRateZoneId',
        'openZones',
        'shippingModel',
        'zone'
    ],
    components: {
        TableRecord,
        ZoneRates
    },
    data() {
        return {
            editZoneCountry: null
        }
    },
    computed: {
        isDefaultZone() {
            return this.zone.id == 1
        },
        isZoneOpen() {
            return this.openZones.includes(this.zone.id)
        }
    },
    methods: {
        clickCloseZone() {
            this.$emit('clickCloseZone', this.zone.id)
        },
        clickDeleteRate(inRate) {
            this.$emit('clickDeleteRate', this.zone.id, inRate)
        },
        clickEditRate(inRate) {
            this.$emit('clickEditRate', this.zone.id, inRate)
        },
        clickNewZoneRate() {
            this.$emit('clickNewZoneRate', this.zone.id)
        },
        clickOpenZone() {
            this.$emit('clickOpenZone', this.zone.id)
        },
        clickZone() {
            if (this.isZoneOpen) {
                this.clickCloseZone()
            }
            else {
                this.clickOpenZone()
            }
        }
    }
}
</script>