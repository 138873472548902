import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
    {
        path: '/account',
        name: 'Account',
        component: () => import(/* webpackChunkName: "account" */ '../pages/account/_Account'),
        meta: { 
            requiresAuth: true 
        }
    },{
        path: '/active',
        name: 'Active',
        component: () => import(/* webpackChunkName: "active" */ '../pages/group_buy/_GroupBuy')
    },
    {
        path: '/activity/:id',
        name: 'Activity',
        component: () => import(/* webpackChunkName: "home" */ '../pages/home/_Home'),
        meta: { requiresAuth: true }
    },
    {
        path: '/build/:handle',
        name: 'Build',
        component: () => import(/* webpackChunkName: "build" */ '../pages/build/_Build'),
        meta: {
            requiresAuth: false 
        }
    },
    {
        path: '/cart',
        name: 'Cart',
        component: () => import(/* webpackChunkName: "cart" */ '../pages/cart/_Cart')
    },
    {
        path: '/chats',
        name: 'Chats',
        component: () => import(/* webpackChunkName: "chat" */ '../pages/chats/_Chats'),
        meta: { requiresAuth: true }
    },
    {
        path: '/chat/check/:userId',
        name: 'ChatCheck',
        component: () => import(/* webpackChunkName: "chat" */ '../pages/chats/_Chats'),
        meta: { requiresAuth: true }
    },
    {
        path: '/chat/:id',
        name: 'Chat',
        component: () => import(/* webpackChunkName: "chat" */ '../pages/chats/_Chats'),
        meta: { requiresAuth: true }
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: () => import(/* webpackChunkName: "checkout" */ '../pages/checkout/_Checkout')
    },
    {
        path: '/clearData/:id',
        name: 'ClearData',
        component: () => import(/* webpackChunkName: "clearData" */ '../pages/clear_data/_ClearData')
    },{
        path: '/communities/mine',
        name: 'CommunitiesMine',
        component: () => import(/* webpackChunkName: "communitiesMine" */ '../pages/groups/my-groups/_MyGroups'),
        meta: { 
            requiresAuth: true 
        }
    },
    {
        path: '/communities/search',
        name: 'CommunitiesSearch',
        component: () => import(/* webpackChunkName: "communitiesSearch" */ '../pages/groups/search/_Search'),
        meta: { 
            requiresAuth: true 
        }
    },
    {
        path: '/community/:handle',
        name: 'Community',
        component: () => import(/* webpackChunkName: "community" */ '../pages/group/_Group'),
        meta: { requiresAuth: true }
    },
    {
        path: '/complete',
        name: 'Complete',
        component: () => import(/* webpackChunkName: "complete" */ '../pages/complete/_Complete')
    },
    {
        path: '/devices/:uid/:id',
        name: 'LinkDevice',
        component: () => import(/* webpackChunkName: "devices" */ '../pages/devices/_Devices'),
        meta: { 
            requiresAuth: true 
        }
    },
    {
        path: '/devices',
        name: 'Devices',
        component: () => import(/* webpackChunkName: "devices" */ '../pages/devices/_Devices'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/feed',
        name: 'Feed',
        component: () => import(/* webpackChunkName: "feed" */ '../pages/feed/_Feed'),
        meta: { requiresAuth: true }
    },
    {
        path: '/garage/:handle',
        name: 'Garage',
        component: () => import(/* webpackChunkName: "garage" */ '../pages/garage/_Garage'),
        meta: { 
            requiresAuth: false 
        }
    },
    {
        path: '/groupBuy/:id',
        name: 'GroupBuy',
        component: () => import(/* webpackChunkName: "groupBuy" */ '../pages/group_buy/_GroupBuy')
    },
    {
        path: '/hashtag/:hashtag',
        name: 'Hashtag',
        component: () => import(/* webpackChunkName: "hashtag" */ '../pages/hashtag/_Hashtag'),
        meta: { requiresAuth: false }
    },
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../pages/home/_Home')
    },
    {
        path: '/home',
        name: 'Homie',
        component: () => import(/* webpackChunkName: "home" */ '../pages/home/_Home')
    },
    {
        path: '/impersonate',
        name: 'Impersonate',
        component: () => import(/* webpackChunkName: "impersonate" */ '../pages/impersonate/_Impersonate'),
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../pages/login/_Login')
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () => import(/* webpackChunkName: "logout" */ '../pages/logout')
    },
    {
        path: '/marketplace/customer',
        name: 'MarketplaceCustomer',
        component: () => import(/* webpackChunkName: "marketplaceCustomer" */ '../pages/marketplace/customer/_Marketplace'),
        meta: { requiresAuth: true }
    },
    {
        path: '/marketplace/eoi/:id/:saleCode',
        name: 'MarketplaceEOI',
        component: () => import(/* webpackChunkName: "marketplaceEOI" */ '../pages/marketplace/eoi/_Eoi')
    },
    {
        path: '/marketplace/groupBuy/:id/:saleCode',
        name: 'MarketplaceGroupBuy',
        component: () => import(/* webpackChunkName: "marketplaceGroupBuy" */ '../pages/marketplace/group_buy/_GroupBuy')
    },
    {
        path: '/marketplace/seller',
        name: 'MarketplaceSeller',
        component: () => import(/* webpackChunkName: "marketplaceSeller" */ '../pages/marketplace/seller/_Seller'),
        meta: { requiresAuth: true }
    },
    {
        path: '/password/reset',
        name: 'Password',
        component: () => import(/* webpackChunkName: "password" */ '../pages/password/_Password')
    },
    {
        path: '/paymentComplete',
        name: 'PaymentComplete',
        component: () => import(/* webpackChunkName: "paymentComplete" */ '../pages/payment_complete/_PaymentComplete')
    },
    {
        path: '/paypal/onboarding',
        name: 'PaypalOnboarding',
        component: () => import(/* webpackChunkName: "paypalOnboarding" */ '../pages/paypal/onboarding/_Onboarding'),
        meta: { requiresAuth: true }
    },
    {
        path: '/purchase/:id/:saleCode',
        name: 'Purchase',
        component: () => import(/* webpackChunkName: "purchase" */ '../pages/purchase/_Purchase')
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () => import(/* webpackChunkName: "privacy" */ '../pages/privacy/_Privacy')
    },
    {
        path: '/product/:id',
        name: 'Product',
        component: () => import(/* webpackChunkName: "product" */ '../pages/product/_Product')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "register" */ '../pages/register/_Register')
    },
    {
        path: '/return/:id/:code/:session',
        name: 'Return',
        component: () => import(/* webpackChunkName: "return" */ '../pages/return/_Return')
    },
    {
        path: '/seller/:handle',
        name: 'Seller',
        component: () => import(/* webpackChunkName: "seller" */ '../pages/seller/_Seller')
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "settings" */ '../pages/settings/_Settings'),
        meta: { 
            requiresAuth: true 
        }
    },
    {
        path: '/stripe/failure',
        name: 'StripeFailure',
        component: () => import(/* webpackChunkName: "stripeFailure" */ '../pages/stripe/_Failure'),
        meta: { requiresAuth: true }
    },
    {
        path: '/stripe/success',
        name: 'StripeSuccess',
        component: () => import(/* webpackChunkName: "stripeSuccess" */ '../pages/stripe/_Success'),
        meta: { requiresAuth: true }
    },
    {
        path: '/subscription/success/:plan/:checkoutId',
        name: 'SubscriptionSuccess',
        component: () => import(/* webpackChunkName: "devices" */ '../pages/subscription/_Success'),
        meta: { requiresAuth: true }
    },
    {
        path: '/terms',
        name: 'Terms',
        component: () => import(/* webpackChunkName: "terms" */ '../pages/terms/_Terms')
    },
    {
        path: '/thankyou/:type/:id/:saleCode',
        name: 'Thankyou',
        component: () => import(/* webpackChunkName: "thankyou" */ '../pages/thankyou/_Thankyou')
    },
    {
        path: '/unsubscribe/:vendor/:unsubscriber',
        name: 'Unsubscribe',
        component: () => import(/* webpackChunkName: "unsubscribe" */ '../pages/unsubscribe/_Unsubscribe')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router

router.beforeEach((to, from, next) => {
    if (to.matched.some(route => route.meta.requiresAuth) && !store.state.isLoggedIn) {
        next({ name: 'Register' })
        return
    }

    if (to.path === '/login' && store.state.isLoggedIn) {
        next({ name: 'Home' })
        return
    }

    if (to.path === '/register' && store.state.isLoggedIn) {
        next({ name: 'Home' })
        return
    }

    next()
})

// router.events.on("routeChangeStart", (url) => {
//     window.location.href = url
//  })