<template>
    <div class="w-full">
        <select-panel
            :fieldData="countryCountData" fieldFormName="multipleCountries" fieldLabel="How Many Countries Do You Ship To?" fieldLabelClasses="text-gray-800" 
            :fieldRequired="true" :fieldValue="sellerDataForm.multipleCountries" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="changeMultipleCountries"
        >
            <validation-message v-if="validating && sellerDataForm.multipleCountries == null" 
                message="Select One or Multiple"
            />
        </select-panel>
        <div v-if="sellerDataForm.multipleCountries == false" class="w-full">
            <select-panel v-if="sellerDataForm.shippingZones && sellerDataForm.shippingZones.length == 1"
                :fieldData="countryList" fieldDataLabel="name" fieldDataValue="code" fieldLabel="Select Your Shipping Country" 
                fieldLabelClasses="text-gray-800" fieldFormName="selectedCountry" :fieldRequired="true" :fieldValue="selectedCountry" 
                :forceMobile="true"
                @fieldChange="selectCountry"
            >
                <validation-message v-if="validating && (!sellerDataForm.shippingZones || sellerDataForm.shippingZones.length < 2)" 
                    message="Country must be selected"
                />
            </select-panel>
            <div v-if="sellerDataForm.shippingZones && sellerDataForm.shippingZones.length > 1" class="w-full px-2">
                <list-zone v-for="zone in sortedShippingZones"
                    :editingZoneId="editingZoneId" :zone="zone"
                    @clickDeleteZone="clickDeleteZone" @clickEditZone="clickEditZone" @updateZoneCountry="updateZoneCountry"
                />
            </div>
        </div>
        <div v-if="sellerDataForm.multipleCountries" class="w-full">
            <div class="py-3 px-2 text-center font-semibold text-gray-800">
                Select Your Shipping Countries
            </div>
            <validation-message v-if="validating && (!sellerDataForm.shippingZones || sellerDataForm.shippingZones.length < 2)" class="pb-3"
                message="One or more countries must be selected"
            />
            <div class="round-tabs-container px-1">
                <template v-for="country in countryList">
                    <div @click="toggleShippingCountry(country.code)" class="round-tab flex items-center" :class="{ 'round-tab-off bg-gray-100': !isSelectedCountry(country.code), 'round-tab-on': isSelectedCountry(country.code) }">
                        {{ country.name }}
                    </div>
                </template>
            </div>
            <select-panel
                :fieldData="yesNoData" fieldFormName="useDefaultZone" fieldLabel="Do You Want a Default Shipping Zone?" fieldLabelClasses="text-gray-800" 
                :fieldRequired="true" :fieldValue="sellerDataForm.useDefaultZone" :forceMobile="true" :isLabelAndValue="true"
                @fieldChange="changeUseDefaultZone"
            >
                <validation-message v-if="validating && sellerDataForm.useDefaultZone == null" 
                    message="Select yes or no"
                />
            </select-panel>
            <div v-if="sellerDataForm.useDefaultZone" class="w-full">
                <div class="py-3 px-2 text-center font-semibold text-gray-800">
                    Select Your Excluded Countries
                </div>
                <div class="round-tabs-container px-1">
                    <template v-for="country in countryList">
                        <div @click="toggleExcludedCountry(country.code)" class="round-tab flex items-center" :class="{ 'round-tab-off bg-gray-100': !isExcludedCountry(country.code), 'round-tab-on': isExcludedCountry(country.code) }">
                            {{ country.name }}
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ListZone from '../components/page-04/ListZone'

export default {
    props: [
        'sellerDataForm',
        'validating'
    ],
    components: {
        ListZone
    },
    data() {
        return {
            countryCountData: {
                One: false,
                Multiple: true
            },
            editingZoneId: null,
            excludedCountries: [],
            selectedCountries: [],
            selectedCountry: null
        }
    },  
    computed: {
        sortedShippingZones() {
            return !this.sellerDataForm.shippingZones ? [] : this.sellerDataForm.shippingZones
                .filter((zone) => zone.id != 1)
                .sort((a, b) => a.name < b.name ? -1 : 1)
        }
    },
    mounted() {
        this.sellerDataForm.shippingZones.forEach((myZone) => {
            if (myZone?.countryCode ?? false) {
                this.selectedCountries.push(myZone.countryCode)
            }
        })

        this.excludedCountries = this.objCopy(this.sellerDataForm.excludedCountries)
    },
    methods: {
        changeMultipleCountries(inField, inValue) {
            this.$emit('changeMultipleCountries', inField, inValue)
        },
        changeUseDefaultZone(inField, inValue) {
            this.$emit('changeUseDefaultZone', inField, inValue)
        },
        clickDeleteZone(inZoneId) {
            if (confirm('You wish to delete this shipping zone?')) {
                let myZones = []

                this.sellerDataForm.shippingZones.forEach((zone) => {
                    if (zone.id != inZoneId) {
                        myZones.push(zone)
                    }
                })

                this.$emit('updateSellerDataFormSelectValue', 'shippingZones', myZones)
            }
        },
        clickEditZone(inZone) {
            if (inZone.id == this.editingZoneId) {
                this.editingZoneId = null
            }
            else {
                this.editingZoneId = inZone.id
            }
        },
        isExcludedCountry(inCountryCode) {
                return !this.excludedCountries ? false : this.excludedCountries.includes(inCountryCode)
            },
        isSelectedCountry(inCountryCode) {
            return this.selectedCountries.includes(inCountryCode)
        },
        selectCountry(inField, inValue) {
            let mySellerData = this.objCopy(this.sellerDataForm)
            let myZones = mySellerData.shippingZones ?? []
            
            let myZone = myZones
                .sort((a, b) => a.id < b.id ? 1 : -1)[0]

            let myNewZone = {
                id: myZone.id + 1,
                countryCode: inValue,
                name: this.coGetCountryName(inValue),
                rateId: 0
            }

            myZones.push(myNewZone)
            this.$emit('updateSellerDataFormSelectValue', 'shippingZones', myZones)
        },
        toggleExcludedCountry(inCountryCode) {
            let myExcludedCountries = []

            if (this.excludedCountries.includes(inCountryCode)) {
                let myExcludedCountries = this.objCopy(this.excludedCountries)
                myExcludedCountries = myExcludedCountries.filter((myCountry) => myCountry != inCountryCode)
                this.excludedCountries = this.objCopy(myExcludedCountries)
                myExcludedCountries = this.objCopy(myExcludedCountries)
            }
            else {
                this.excludedCountries.push(inCountryCode)
                myExcludedCountries = this.objCopy(this.excludedCountries)
            }

            this.$emit('updateSellerDataFormSelectValue', 'excludedCountries', myExcludedCountries)
        },
        toggleShippingCountry(inCountryCode) {
            let mySellerData = this.objCopy(this.sellerDataForm)
            let myZones = []

            if (this.selectedCountries.includes(inCountryCode)) {
                let deleteOkay = true
                let myZone = mySellerData.shippingZones.find((theZone) => theZone.countryCode == inCountryCode)

                if (myZone && myZone.rates && myZone.rates.length) {
                    deleteOkay = confirm("This Country has shipping rates, are you sure you want to delete it?")
                }
                
                if (deleteOkay) {
                    let mySelectedCountries = this.objCopy(this.selectedCountries)
                    mySelectedCountries = mySelectedCountries.filter((myCountry) => myCountry != inCountryCode)
                    this.selectedCountries = this.objCopy(mySelectedCountries)
                    myZones = mySellerData.shippingZones.filter((myZone) => myZone.countryCode != inCountryCode)
                }
            }
            else {
                this.selectedCountries.push(inCountryCode)
                myZones = mySellerData.shippingZones ?? []

                let myZone = myZones
                    .sort((a, b) => a.id < b.id ? 1 : -1)[0]

                let myNewZone = {
                    id: myZone.id + 1,
                    countryCode: inCountryCode,
                    name: this.coGetCountryName(inCountryCode),
                    rateId: 0
                }

                myZones.push(myNewZone)
            }

            this.$emit('updateSellerDataFormSelectValue', 'shippingZones', myZones)
        },
        updateZoneCountry(inCountryCode) {
            let mySellerData = this.objCopy(this.sellerDataForm)
            let myZones = mySellerData.shippingZones ?? []
            let myZone = myZones.find((zone) => zone.id == this.editingZoneId)
            myZone.countryCode = inCountryCode
            myZone.name = this.coGetCountryName(inCountryCode)
            this.$emit('updateSellerDataFormSelectValue', 'shippingZones', myZones)
        }
    }
}
</script>