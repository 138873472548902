<template>
    <wizard-popup-container v-if="formLoaded"
       headingText="Media" panelWidth="800px"
       @closeWizardPopup="clickCloseBlockPopup"
    >
        <template #main>
            <div class="w-full text-white p-1">
                <media-panel 
                    :fieldNude="true" :hideLabel="true" :hideSpinner="true" :imagesArray="gallery" :imagesOnly="false" :maxFiles="1" :multiple="false"
                    @saveMedia="saveLocalMedia" @updateOfflineMedia="updateOfflineMedia"
                >
                    <validation-message v-if="validating" 
                        message="Media must be added"
                    />
                </media-panel>
            </div>
        </template>
        <template #footer>
            <div class="w-full flex items-center border-t border-gray-800 py-2 h-12 rounded-b-md">
                <div class="w-1/2">
                    <div @click="clickCloseBlockPopup()" class="secondary-button-base mx-2">
                        Close Without Saving
                    </div>
                </div>
                <div class="w-1/2">
                    <div @click="clickSaveBlock()" class="standard-button-base mx-2">
                        Save And Close
                    </div>
                </div>
            </div>
        </template>
    </wizard-popup-container>
</template>

<script>
export default {
    props: [
        'editingBlock'
    ],
    data() {
        return {
            gallery: null,
            galleryChanged: false,
            formLoaded: false,
            validating: false
        }
    },
    mounted() {
        if (this.editingBlock) {
            this.gallery = [this.editingBlock.image]
        }

        this.formLoaded = true
    },
    methods: {
        clickCloseBlockPopup() {
            if (!this.galleryChanged || confirm("Confirm you wish to close without saving your changes?")) {
                this.$emit('clickCloseBlockPopup')
            }
        },
        clickSaveBlock() {
            if (this.gallery && this.gallery.length) {
                this.validating = false
                this.$emit('clickSaveBlock', this.gallery, 'image')
            }
            else {
                this.validating = true
            }
        },
        saveLocalMedia(inGallery) {
            this.galleryChanged = true
            this.gallery = inGallery
        },
        updateOfflineMedia(inGallery) {
            this.galleryChanged = true
            this.gallery = inGallery
        }
    }
}
</script>