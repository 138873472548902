<template>
    <wizard-popup-container v-if="formLoaded"
       :headingText="headingText" panelWidth="600px"
       @closeWizardPopup="clickCloseTraitPopup"
    >
        <template #main>
            <div class="w-full">
                <div v-if="currentSlide == 1" class="w-full">
                    <input-panel 
                        fieldFormName="label" fieldLabel="Label" fieldLabelClasses="text-gray-800" fieldPlaceholder="Label" :fieldRequired="true" fieldType="text" 
                        :fieldValue="form.label" :forceMobile="true"
                        @fieldInput="updateFormValue"
                    >
                        <validation-message v-if="validating && !Boolean(form.label)" 
                            message="Label must be entered"
                        />
                    </input-panel>
                    <select-panel
                        :fieldData="yesNoData" fieldFormName="digitalProduct" fieldLabel="Are These Digital Products?" fieldLabelClasses="text-gray-800" 
                        :fieldValue="form.digitalProduct" :forceMobile="true" :isLabelAndValue="true"
                        @fieldChange="updateFormValue"
                    />
                    <div v-if="form.digitalProduct == false" class="w-full">
                        <select-panel
                            :fieldData="yesNoData" fieldFormName="freeShipping" fieldLabel="Do These Products Have Free Shipping?" fieldLabelClasses="text-gray-800" 
                            :fieldValue="form.freeShipping" :forceMobile="true" :isLabelAndValue="true"
                            @fieldChange="updateFormValue"
                        />
                        <select-panel 
                            :fieldData="itemConditionData" fieldFormName="itemCondition" fieldLabel="Are These Products New or Used?" fieldLabelClasses="text-gray-800"  
                            :fieldValue="form.itemCondition" :forceMobile="true" :isSingleValue="true"
                            @fieldChange="updateFormValue"
                        />
                    </div>
                    <select-panel
                        :fieldData="yesNoData" fieldFormName="trackQuantity" fieldLabel="Do You Wish To Track Stock Quantity For These Products?" fieldLabelClasses="text-gray-800" 
                        :fieldValue="form.trackQuantity" :forceMobile="true" :isLabelAndValue="true"
                        @fieldChange="updateFormValue"
                    />
                    <input-panel 
                        fieldFormName="units" fieldLabel="Sale Unit" fieldLabelClasses="text-gray-800" fieldPlaceholder="Sale Unit" fieldSubLabel="E.g. Each, Metre, Kg."
                        fieldType="text" :fieldValue="form.units" :forceMobile="true"
                        @fieldInput="updateFormValue"
                    />
                    <select-panel 
                        :fieldData="sellerVendorData.taxRates" fieldDataLabel="label" fieldDataValue="id" fieldFormName="taxRateId" fieldLabel="Tax Rate" fieldLabelClasses="text-gray-800"
                        :fieldValue="form.taxRateId" :forceMobile="true"
                        @fieldChange="updateFormValue"
                    />
                </div>
            </div>
        </template>
        <template #footer>
            <popup-buttons
                :showClose="!hideClose" :showNext="!hideNext" :showPrevious="!hidePrevious" :showSave="!hideSave"
                @clickClose="clickCloseTraitPopup" @clickSave="clickSaveTrait" @nextSlide="nextSlide" @previousSlide="previousSlide"
            />
        </template>
    </wizard-popup-container>
</template>

<script>
import PopupButtons from '@/wizards/components/PopupButtons'

export default {
    props: [
        'editingTrait',
        'traits'
    ],
    components: {
        PopupButtons
    },
    data() {
        return {
            currentSlide: 1,
            form: {
                digitalProduct: null,
                freeShipping: null,
                id: null,
                itemCondition: null,
                label: null,
                taxRateId: null,
                trackQuantity: null,
                units: null
            },
            formLoaded: false,
            itemConditionData: [
                'New', 
                'Used'
            ],
            originalForm: null,
            validating: false
        }
    },
    computed: { 
        headingText() {
            return this.slides[this.currentSlide].heading
        },
        hideClose() {
            return !this.slides[this.currentSlide].close
        },
        hideNext() {
            return !this.slides[this.currentSlide].next
        },
        hidePrevious() {
            return !this.slides[this.currentSlide].previous
        },
        hideSave() {
            return !this.slides[this.currentSlide].save
        },
        slides() {
            return {
                1: {
                    close: true,
                    heading: `${this.editingTrait ? 'Edit' : 'New'} Product Trait`,
                    next: false,
                    previous: false,
                    save: true,
                    validation: this.form.label
                }
            }
        }
    },
    mounted() {
        if (this.editingTrait) {
            this.form.digitalProduct = this.editingTrait.digitalProduct
            this.form.freeShipping = this.editingTrait.freeShipping
            this.form.id = this.editingTrait.id
            this.form.itemCondition = this.editingTrait.itemCondition
            this.form.label = this.editingTrait.label
            this.form.taxRateId = this.editingTrait.taxRateId
            this.form.trackQuantity = this.editingTrait.trackQuantity
            this.form.units = this.editingTrait.units
        }

        this.originalForm = this.objCopy(this.form)
        this.formLoaded = true
    },
    methods: {
        clickCloseTraitPopup() {
            if (JSON.stringify(this.originalForm) == JSON.stringify(this.form) || confirm("Confirm you wish to close without saving your changes?")) {
                this.$emit('clickCloseTraitPopup')
            }
        },
        clickSaveTrait() {
            if (this.slides[this.currentSlide].validation) {
                this.validating = false

                if (!this.editingTrait) {
                    let myId = 0

                    this.traits.forEach((myTrait) => {
                        if (myTrait.id > myId) {
                            myId = myTrait.id
                        }
                    })

                    this.form.id = myId + 1
                }

                this.$emit('clickSaveTrait', this.form)
            }
            else {
                this.validating = true
            }
        },
        nextSlide() {
            if (this.slides[this.currentSlide].validation) {
                this.validating = false
                this.currentSlide = this.slides[this.currentSlide].next
            }
            else {
                this.validating = true
            }
        },
        previousSlide() {
            this.currentSlide = this.slides[this.currentSlide].previous
        },
        updateFormValue(inField, inValue) {
            this.form[inField] = inValue
        }
    }
}
</script>