<template>
    <div class="media-block rounded px-2 py-1" :class="{ 'bg-black text-white': !whiteLabel || whiteLabelDark, 'text-black border border-black': whiteLabel && whiteLabelLight }">
        <div v-for="(mediaBlock, index) in mediaBlocks" class="w-full">
            <div v-if="mediaBlock.type == 'text'" v-html="mediaBlock.text" class="w-full my-2">
            </div>
            <div v-if="mediaBlock.type == 'image'" class="w-full my-2 flex justify-center">
                <cached-image
                    :imageSrc="transformUrl(mediaBlock.image.url, ['optimize', 'lg', 'q80'])" 
                />
            </div>
            <div v-if="mediaBlock.type == 'youtube'" class="w-full py-2" v-html="youtubeLinks(mediaBlock.url)">
            </div>
            <div v-if="mediaBlock.type == 'feature'" class="w-full my-2">
                <div v-for="record in mediaBlock.list" class="flex items-center justify-start mb-px white-transparent relative">
                    <div class="w-20 mr-1" v-if="record.image">
                        <desktop-gallery v-if="featureGallery(record.image) && featureGallery(record.image).length && deviceInfo && deviceInfo.platform == 'web'" 
                            :containerId="`feature_${index}_${record.id}`" :gallery="featureGallery(record.image)" imageStyle="full" :modal="false" 
                            :noMaxHeight="true"
                            @clickMute="clickMute" 
                        />
                        <pinch-gallery v-if="featureGallery(record.image) && featureGallery(record.image).length && deviceInfo && deviceInfo.platform != 'web'" 
                            :containerId="`feature_${index}_${record.id}`" :gallery="featureGallery(record.image)" :galleryId="product.id" :modal="false"  
                            :noMaxHeight="true"
                            @clickMute="clickMute" 
                        />
                    </div>
                    <div class="text-left pr-6">
                        <div v-html="record.textOne" class="font-semibold">
                        </div>
                        <div v-html="record.textTwo">
                        </div>
                    </div>
                    <div v-if="record.url && isValidURL(record.url)" @click="openNewTabLink(record.url)" class="absolute right-1 bottom-1 cursor-pointer">
                        <fa-icon icon="arrow-up-right-from-square" type="fas" class="h-4 w-4" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DesktopGallery from '@/shared/DesktopGallery'
import PinchGallery from '@/shared/PinchGallery'

export default {
    props: [
        'mediaBlocks'
    ],
    components: {
        DesktopGallery,
        PinchGallery
    },
    methods: {
        featureGallery(inImage) {
            return [inImage]
        }
    }
}
</script>