<template>
    <div v-if="formLoaded" class="w-full">
        <select-panel
            :fieldData="yesNoData" fieldFormName="hasTraits" fieldLabel="Do You Want To Use Traits?" fieldLabelClasses="text-gray-800" 
            :fieldRequired="true" :fieldValue="sellerDataForm.hasTraits" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateSellerDataFormSelectValue"
        >
            <validation-message v-if="validating && sellerDataForm.hasTraits == null" 
                message="Select yes or no"
            />
        </select-panel>
        <div v-if="sellerDataForm.hasTraits" class="w-full text-sm">
            <wizard-table-container
                headingText="Traits" :showNew="!hideIcons"
                @clickNewRecord="clickNewTrait"
            >
                <template #records>
                    <div v-for="trait in sortedTraits" class="w-full">
                        <table-record>
                            <template #record>
                                <div @click="clickToggleShowTrait(trait.id)" class="pl-2">
                                    {{ trait.label }}
                                </div>
                                <div v-if="!hideIcons" class="wizard-icon-container">
                                    <div @click="clickToggleShowTrait(trait.id)" v-show="!isTraitOpen(trait.id)" class="cursor-pointer">
                                        <fa-icon icon="chevron-left" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div @click="clickToggleShowTrait(trait.id)" v-show="isTraitOpen(trait.id)" class="cursor-pointer">
                                        <fa-icon icon="chevron-down" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div @click="clickEditTrait(trait.id)" class="cursor-pointer ml-2">
                                        <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div @click="clickDeleteTrait(trait.id, trait.label)" class="cursor-pointer ml-2">
                                        <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                    </div>
                                </div>
                            </template>
                            <template #extra>
                                <div v-if="isTraitOpen(trait.id)" class="w-full">
                                    <product-groups 
                                        :hideIcons="hideIcons" :productCategories="productCategories" :traitId="trait.id" :traits="traits"
                                        @switchHideIcons="switchHideIcons" @updateProductCategory="updateProductCategory" @updateTrait="updateTrait"
                                    />
                                    <modifiers v-if="sellerDataForm.hasModifiers"
                                        :hideIcons="hideIcons" :modifiers="modifiers" :traitId="trait.id" :traits="traits"
                                        @switchHideIcons="switchHideIcons" @updateTrait="updateTrait"
                                    />
                                    <add-ons v-if="sellerDataForm.hasAddOns"
                                        :addOns="addOns" :hideIcons="hideIcons" :traitId="trait.id" :traits="traits"
                                        @switchHideIcons="switchHideIcons" @updateTrait="updateTrait"
                                    />
                                    <bulk-discounts 
                                        :bulkDiscounts="bulkDiscounts" :hideIcons="hideIcons" :sellerHasBulkDiscounts="sellerDataForm.hasBulkDiscounts" :traitId="trait.id" 
                                        :traits="traits"
                                        @switchHideIcons="switchHideIcons" @updateTrait="updateTrait"
                                    />
                                    <detail-blocks
                                        :addingBlockIndex="trait.id == addingBlockTraitId ? addingBlockIndex : null" :addingGroupId="trait.id == addingBlockTraitId ? addingGroupId : null" 
                                        :hideIcons="hideIcons" :traitId="trait.id" :traits="traits"
                                        @clickEditBlock="clickEditBlock" @clickNewBlock="clickNewBlock" @switchHideIcons="switchHideIcons" 
                                        @updateAddingBlockIndex="updateAddingBlockIndex" @updateAddingGroupId="updateAddingGroupId" @updateTrait="updateTrait"
                                    />
                                </div>
                            </template>
                        </table-record>
                    </div>
                </template>
            </wizard-table-container>
            <validation-message v-if="validating && sellerDataForm.hasTraits && (!sellerForm.product_traits || !sellerForm.product_traits.length)" 
                 message="You must create at least one Trait"
            />
        </div>
    </div>
</template>

<script>
import AddOns from '../components/page-12/AddOns'
import BulkDiscounts from '../components/page-12/BulkDiscounts'
import DetailBlocks from '../components/page-12/DetailBlocks'
import Modifiers from '../components/page-12/Modifiers'
import ProductGroups from '../components/page-12/ProductGroups'
import TableRecord from '@/wizards/components/TableRecord'

export default {
    props: [
        'addingBlockIndex',
        'addingBlockTraitId',
        'addingGroupId',
        'refreshTraits',
        'sellerDataForm',
        'sellerForm',
        'validating'
    ],
    components: {
        AddOns,
        BulkDiscounts,
        DetailBlocks,
        Modifiers,
        ProductGroups,
        TableRecord
    },
    data() {
        return {
            addOns: null,
            bulkDiscounts: null,
            formLoaded: false,
            hideIcons: false,
            modifiers: null,
            productCategories: null,
            traits: null,
            traitsOpen: []
        }
    },
    computed: {
        sortedTraits() {
            return this.traits.sort((a, b) => a.label < b.label ? -1 : 1)
        }
    },
    watch: {
        refreshTraits: {
            handler(val) {
                if (val) {
                    this.traits = this.objCopy(this.sellerForm.product_traits)
                    this.productCategories = this.objCopy(this.sellerForm.product_categories)
                    this.$emit('resetRefreshTraits')
                }
            }
        }
    },
    mounted() {
        this.traits = this.objCopy(this.sellerForm.product_traits)
        this.productCategories = this.objCopy(this.sellerForm.product_categories)
        this.bulkDiscounts = this.objCopy(this.sellerForm.product_bulk_discounts)
        this.modifiers = this.objCopy(this.sellerForm.product_variants)
        this.addOns = this.objCopy(this.sellerForm.product_add_ons)
        this.formLoaded = true
    },
    methods: {
        clickDeleteTrait(inId, inLabel) {
            if (confirm(`Confirm you wish to delete ${inLabel}?`)) {
                this.$emit('clickDeleteTrait', inId)
            }
        },
        clickEditBlock(inIndex, inTraitId, inType) {
            this.$emit('clickEditBlock', inIndex, inTraitId, inType)
        },
        clickEditTrait(inId) {
            this.$emit('clickEditTrait', inId)
        },
        clickNewBlock(inType) {
            this.$emit('clickNewBlock', inType)
        },
        clickNewTrait() {
            this.$emit('clickNewTrait')
        },
        clickToggleShowTrait(inId) {
            if (!this.traitsOpen.includes(inId)) {
                this.traitsOpen.push(inId)
            }
            else {
                this.traitsOpen = this.traitsOpen.filter((myTrait) => myTrait != inId)
            }
        },
        isTraitOpen(inId) {
            return this.traitsOpen.includes(inId)
        },
        switchHideIcons(inSwitch) {
            this.hideIcons = inSwitch
        },
        updateAddingBlockIndex(inIndex, inGroupId, inTraitId) {
            this.$emit('updateAddingBlockIndex', inIndex, inGroupId, inTraitId)
        },
        updateAddingGroupId(inId, inTraitId) {
            this.$emit('updateAddingGroupId', inId, inTraitId)
        },
        updateSellerDataFormSelectValue(inField, inValue) {
            this.$emit('updateSellerDataFormSelectValue', inField, inValue)
        },
        updateProductCategory(inProductCategory) {
            this.productCategories = this.productCategories.filter((myCategory) => myCategory.id != inProductCategory.id)
            this.productCategories.push(inProductCategory)
            this.$emit('updateProductCategories', this.productCategories)
        },
        updateTrait(inTrait) {
            this.traits = this.traits.filter((myTrait) => myTrait.id != inTrait.id)
            this.traits.push(inTrait)
            this.$emit('updateTraits', this.traits)
        }
    }
}
</script>