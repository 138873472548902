<template>
    <div v-if="formLoaded" class="w-full">
        <input-panel 
            fieldFormName="label" fieldLabel="Title" fieldPlaceholder="Title" :fieldRequired="true" fieldType="text" :fieldValue="bundleForm.label" 
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(bundleForm.label)" 
                message="Label must be entered"
            />
        </input-panel>
        <input-panel 
            fieldFormName="ticketQty" fieldLabel="Ticket Quantity" fieldPlaceholder="Ticket Quantity" :fieldRequired="true" fieldType="number" 
            :fieldValue="bundleForm.ticketQty" 
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(bundleForm.ticketQty)" 
                message="Ticket Quantity must be entered"
            />
        </input-panel>
        <input-panel 
            fieldFormName="price" fieldLabel="Price" fieldPlaceholder="Price" :fieldRequired="true" fieldType="number" 
            :fieldValue="bundleForm.price" 
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(bundleForm.price)" 
                message="Price must be entered"
            />
        </input-panel>
        <input-panel 
            fieldFormName="compareAtPrice" fieldLabel="Compare at Price" fieldPlaceholder="Compare at Price" fieldType="number" 
            :fieldValue="bundleForm.compareAtPrice" 
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldFormName="bannerText" fieldLabel="Banner Text" fieldPlaceholder="Banner Text" fieldType="text" :fieldValue="bundleForm.bannerText" 
            @fieldInput="updateFormValue"
        />
        <rich-text-panel v-if="!hideMediaPanel"
            fieldFormName="description" fieldLabel="Description" :fieldValue="bundleForm.description"
            @fieldInput="updateFormValue"
        />
        <media-panel v-if="!hideMediaPanel"
            fieldLabel="Image" :hideSpinner="true" :imagesArray="[]" :imagesOnly="true" :maxFiles="1" :multiple="false"
            @saveMedia="saveLocalImage" @updateOfflineMedia="updateOfflineMedia"
        />
        <select-panel 
            :fieldData="yesNoData" fieldLabel="Enabled" fieldFormName="enabled" :fieldRequired="true" :fieldValue="bundleForm.enabled" 
            :isLabelAndValue="true"
            @fieldChange="updateFormValue"
        />
        <select-panel 
            :fieldData="yesNoData" fieldLabel="Timed Deal Active" fieldFormName="doubleOfferDealActive" :fieldRequired="true" :fieldValue="bundleForm.doubleOfferDealActive" 
            :isLabelAndValue="true"
            @fieldChange="updateFormValue"
        />
        <div v-if="bundleForm.doubleOfferDealActive" class="w-full">
            <input-panel 
                fieldFormName="doubleOfferDealLabel" fieldLabel="Timed Deal Title" fieldPlaceholder="Timed Deal Title" :fieldRequired="true" fieldType="text" :fieldValue="bundleForm.doubleOfferDealLabel" 
                @fieldInput="updateFormValue"
            >
                <validation-message v-if="validating && !Boolean(bundleForm.doubleOfferDealLabel)" 
                    message="Timed Deal Label must be entered"
                />
            </input-panel>
            <input-panel 
                fieldFormName="doubleOfferDealCompareAtPrice" fieldLabel="Timed Deal Compare at Price" fieldPlaceholder="Timed Deal Compare at Price" fieldType="number" 
                :fieldValue="bundleForm.doubleOfferDealCompareAtPrice" 
                @fieldInput="updateFormValue"
            />
            <select-panel 
                :fieldData="yesNoData" fieldLabel="Show Timed Deal Odds Text" fieldFormName="doubleOfferDealShowText" :fieldRequired="true" :fieldValue="bundleForm.doubleOfferDealShowText" 
                :isLabelAndValue="true"
                @fieldChange="updateFormValue"
            />
            <input-panel 
                fieldFormName="doubleOfferDealText" fieldLabel="Timed Deal Odds Text" fieldPlaceholder="Timed Deal Odds Text" fieldType="text" :fieldValue="bundleForm.doubleOfferDealText" 
                @fieldInput="updateFormValue"
            />
            <media-panel
                fieldLabel="Timed Deal Image" :hideSpinner="true" :imagesArray="doubleOfferDealImagesArray" :imagesOnly="true" :maxFiles="1" :multiple="false"
                @saveMedia="saveLocalDoubleOfferImage" @updateOfflineMedia="updateDoubleOfferOfflineMedia"
            />
        </div>
        <select-panel 
            :fieldData="yesNoData" fieldLabel="Show Current Odds" fieldFormName="currentOddsShow" :fieldRequired="true" :fieldValue="bundleForm.currentOddsShow" 
            :isLabelAndValue="true"
            @fieldChange="updateFormValue"
        />
        <div v-if="bundleForm.currentOddsShow" class="w-full">
            <input-panel 
                fieldFormName="currentOddsText" fieldLabel="Current Odds Text" fieldPlaceholder="Current Odds Text" fieldType="text" :fieldValue="bundleForm.currentOddsText" 
                @fieldInput="updateFormValue"
            />
            <input-panel 
                fieldFormName="currentOddsSuffix" fieldLabel="Current Odds Suffix" fieldPlaceholder="Current Odds Suffix" fieldType="text" fieldSubLabel="This will show after the Current Odds (Current Odds 1 in 250 xxxxxxx)"
                :fieldValue="bundleForm.currentOddsSuffix" 
                @fieldInput="updateFormValue"
            />
        </div>
        <select-panel 
            :fieldData="yesNoData" fieldLabel="Show Minimum Odds" fieldFormName="minimumOddsShow" :fieldRequired="true" :fieldValue="bundleForm.minimumOddsShow" 
            :isLabelAndValue="true"
            @fieldChange="updateFormValue"
        />
        <div v-if="bundleForm.minimumOddsShow" class="w-full">
            <input-panel 
                fieldFormName="minimumOddsText" fieldLabel="Minimum Odds Text" fieldPlaceholder="Minimum Odds Text" fieldType="text" :fieldValue="bundleForm.minimumOddsText" 
                @fieldInput="updateFormValue"
            />
            <input-panel 
                fieldFormName="minimumOddsSuffix" fieldLabel="Minimum Odds Suffix" fieldPlaceholder="Minimum Odds Suffix" fieldType="text" fieldSubLabel="This will show after the Minimum Odds (Minimum Odds 1 in 250 xxxxxxx)"
                :fieldValue="bundleForm.minimumOddsSuffix" 
                @fieldInput="updateFormValue"
            />
        </div>
        <div class="w-full white-transparent mt-px py-4 px-2" :class="{ 'desktop-button-box justify-center': isDesktopWidth }">
            <div @click="clickCreateBundle()" class="w-full" :class=" { 'standard-button-base': allOkay, 'disabled-button-base': !allOkay } ">
                Create Bundle
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'groupBuy',
        'lastId'
    ],
    data() {
        return {
            bundleForm: {
                bannerText: null,
                compareAtPrice: null,
                currentOddsShow: true,
                currentOddsSuffix: null,
                currentOddsText: "Current Odds",
                description: null,
                doubleOfferDealActive: true,
                doubleOfferDealCompareAtPrice: null,
                doubleOfferDealLabel: null,
                doubleOfferDealShowText: true,
                doubleOfferDealTempImage: null,
                doubleOfferDealText: "Odds calculated with Double Ticket Offer",
                enabled: true,
                label: null,
                minimumOddsShow: true,
                minimumOddsSuffix: null,
                minimumOddsText: "Minimum Odds",
                price: null,
                tempImage: null,
                ticketQty: null
            },
            formLoaded: false,
            hideMediaPanel: false,
            newDoubleOfferImageGallery: null,
            newImageGallery: null,
            validating: false
        }
    },
    computed: {
        allOkay() {
            return Boolean(this.bundleForm.label && this.newImageGallery && this.bundleForm.price && this.bundleForm.ticketQty)
        }
    },
    mounted() {
        this.formLoaded = true
    },
    methods: {
        async clickCreateBundle() {
            if (this.allOkay) {
                this.validating = false
                
                let myId = this.lastId + 1

                if (this.newImageGallery) {
                    let myTempGallery = await this.buildOfflineTempGallery(this.newImageGallery, `bundle_temp${this.groupBuy.id}_${myId}}`)
                
                    let myOtherData = {
                        bundleId: myId
                    }

                    setTimeout(() => {
                        this.saveGalleryPrep(myTempGallery, 'groupBuyBundleGalleryUpdate', this.groupBuy.id, 'groupOfferBundleGallery', myOtherData)
                    }, 100)

                    this.bundleForm.tempImage = this.newImageGallery[0]
                }

                if (this.newDoubleOfferImageGallery) {
                    let myTempDoubleOfferGallery = await this.buildOfflineTempGallery(this.newDoubleOfferImageGallery, `bundle_temp${this.groupBuy.id}_double_offer_${myId}}`)
                
                    let myDoubleOfferOtherData = {
                        bundleId: myId
                    }

                    setTimeout(() => {
                        this.saveGalleryPrep(myTempDoubleOfferGallery, 'groupBuyBundleDoubleOfferGalleryUpdate', this.groupBuy.id, 'groupOfferBundleDoubleOfferGallery', myDoubleOfferOtherData)
                    }, 100)

                    this.bundleForm.doubleOfferDealTempImage = this.newDoubleOfferImageGallery[0]
                }

                this.$emit('clickCreateNewBundle', this.objCopy(this.bundleForm))
                this.pageMessage('success', "Bundle created")
                this.bundleForm.bannerText = null
                this.bundleForm.compareAtPrice = null
                this.bundleForm.currentOddsShow = true
                this.bundleForm.currentOddsSuffix = null
                this.bundleForm.currentOddsText = "Current Odds"
                this.bundleForm.description = null
                this.bundleForm.doubleOfferDealActive = true
                this.bundleForm.doubleOfferDealCompareAtPrice = null
                this.bundleForm.doubleOfferDealLabel = null
                this.bundleForm.doubleOfferDealShowText = true
                this.bundleForm.doubleOfferDealTempImage = null
                this.bundleForm.doubleOfferDealText = "Odds calculated with Double Ticket Offer"
                this.bundleForm.enabled = true
                this.bundleForm.label = null
                this.bundleForm.minimumOddsShow = true
                this.bundleForm.minimumOddsSuffix = null
                this.bundleForm.minimumOddsText = "Minimum Odds"
                this.bundleForm.price = null
                this.bundleForm.ticketQty = null
                this.newDoubleOfferImageGallery = null
                this.newImageGallery = null
                this.hideMediaPanel = true

                setTimeout(() => {
                    this.hideMediaPanel = false
                }, 100)
            }
            else {
                this.validating = true
            }
        },
        saveLocalDoubleOfferImage(inGallery) {
            this.newDoubleOfferImageGallery = inGallery
        },
        saveLocalImage(inGallery) {
            this.newImageGallery = inGallery
        },
        updateDoubleOfferOfflineMedia(inGallery) {
            this.newDoubleOfferImageGallery = inGallery
        },
        updateFormValue(inField, inValue) {
            this.bundleForm[inField] = inValue
        },
        updateOfflineMedia(inGallery) {
            this.newImageGallery = inGallery
        }
    }
}
</script>