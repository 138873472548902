<template>
    <div v-if="formLoaded" class="w-full">
        <input-panel 
            fieldFormName="label" fieldLabel="Title" fieldPlaceholder="Title" :fieldRequired="true" fieldType="text" :fieldValue="bundleForm.label" 
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(bundleForm.label)" 
                message="Label must be entered"
            />
        </input-panel>
        <input-panel 
            fieldFormName="ticketQty" fieldLabel="Ticket Quantity" fieldPlaceholder="Ticket Quantity" :fieldRequired="true" fieldType="number" 
            :fieldValue="bundleForm.ticketQty" 
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(bundleForm.ticketQty)" 
                message="Ticket Quantity must be entered"
            />
        </input-panel>
        <input-panel 
            fieldFormName="price" fieldLabel="Price" fieldPlaceholder="Price" :fieldRequired="true" fieldType="number" 
            :fieldValue="bundleForm.price" 
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(bundleForm.price)" 
                message="Price must be entered"
            />
        </input-panel>
        <input-panel 
            fieldFormName="compareAtPrice" fieldLabel="Compare at Price" fieldPlaceholder="Compare at Price" fieldType="number" 
            :fieldValue="bundleForm.compareAtPrice" 
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldFormName="bannerText" fieldLabel="Banner Text" fieldPlaceholder="Banner Text" fieldType="text" :fieldValue="bundleForm.bannerText" 
            @fieldInput="updateFormValue"
        />
        <rich-text-panel 
            fieldFormName="description" fieldLabel="Description" :fieldValue="bundleForm.description"
            @fieldInput="updateFormValue"
        />
        <media-panel
            fieldLabel="Image" :hideSpinner="true" :imagesArray="imagesArray" :imagesOnly="true" :maxFiles="1" :multiple="false"
            @saveMedia="saveLocalImage" @updateOfflineMedia="updateOfflineMedia"
        />
        <select-panel 
            :fieldData="yesNoData" fieldLabel="Enabled" fieldFormName="enabled" :fieldRequired="true" :fieldValue="bundleForm.enabled" 
            :isLabelAndValue="true"
            @fieldChange="updateFormValue"
        />
        <select-panel 
            :fieldData="yesNoData" fieldLabel="Timed Deal Active" fieldFormName="doubleOfferDealActive" :fieldRequired="true" :fieldValue="bundleForm.doubleOfferDealActive" 
            :isLabelAndValue="true"
            @fieldChange="updateFormValue"
        />
        <div v-if="bundleForm.doubleOfferDealActive" class="w-full">
            <input-panel 
                fieldFormName="doubleOfferDealLabel" fieldLabel="Timed Deal Title" fieldPlaceholder="Timed Deal Title" :fieldRequired="true" fieldType="text" :fieldValue="bundleForm.doubleOfferDealLabel" 
                @fieldInput="updateFormValue"
            >
                <validation-message v-if="validating && !Boolean(bundleForm.doubleOfferDealLabel)" 
                    message="Timed Deal Label must be entered"
                />
            </input-panel>
            <input-panel 
                fieldFormName="doubleOfferDealCompareAtPrice" fieldLabel="Timed Deal Compare at Price" fieldPlaceholder="Timed Deal Compare at Price" fieldType="number" 
                :fieldValue="bundleForm.doubleOfferDealCompareAtPrice" 
                @fieldInput="updateFormValue"
            />
            <select-panel 
                :fieldData="yesNoData" fieldLabel="Show Timed Deal Odds Text" fieldFormName="doubleOfferDealShowText" :fieldRequired="true" :fieldValue="bundleForm.doubleOfferDealShowText" 
                :isLabelAndValue="true"
                @fieldChange="updateFormValue"
            />
            <input-panel 
                fieldFormName="doubleOfferDealText" fieldLabel="Timed Deal Odds Text" fieldPlaceholder="Timed Deal Odds Text" fieldType="text" :fieldValue="bundleForm.doubleOfferDealText" 
                @fieldInput="updateFormValue"
            />
            <media-panel
                fieldLabel="Timed Deal Image" :hideSpinner="true" :imagesArray="doubleOfferDealImagesArray" :imagesOnly="true" :maxFiles="1" :multiple="false"
                @saveMedia="saveLocalDoubleOfferImage" @updateOfflineMedia="updateDoubleOfferOfflineMedia"
            />
        </div>
        <select-panel 
            :fieldData="yesNoData" fieldLabel="Show Current Odds" fieldFormName="currentOddsShow" :fieldRequired="true" :fieldValue="bundleForm.currentOddsShow" 
            :isLabelAndValue="true"
            @fieldChange="updateFormValue"
        />
        <div v-if="bundleForm.currentOddsShow" class="w-full">
            <input-panel 
                fieldFormName="currentOddsText" fieldLabel="Current Odds Text" fieldPlaceholder="Current Odds Text" fieldType="text" :fieldValue="bundleForm.currentOddsText" 
                @fieldInput="updateFormValue"
            />
            <input-panel 
                fieldFormName="currentOddsSuffix" fieldLabel="Current Odds Suffix" fieldPlaceholder="Current Odds Suffix" fieldType="text" fieldSubLabel="This will show after the Current Odds (Current Odds 1 in 250 xxxxxxx)"
                :fieldValue="bundleForm.currentOddsSuffix" 
                @fieldInput="updateFormValue"
            />
        </div>
        <select-panel 
            :fieldData="yesNoData" fieldLabel="Show Minimum Odds" fieldFormName="minimumOddsShow" :fieldRequired="true" :fieldValue="bundleForm.minimumOddsShow" 
            :isLabelAndValue="true"
            @fieldChange="updateFormValue"
        />
        <div v-if="bundleForm.minimumOddsShow" class="w-full">
            <input-panel 
                fieldFormName="minimumOddsText" fieldLabel="Minimum Odds Text" fieldPlaceholder="Minimum Odds Text" fieldType="text" :fieldValue="bundleForm.minimumOddsText" 
                @fieldInput="updateFormValue"
            />
            <input-panel 
                fieldFormName="minimumOddsSuffix" fieldLabel="Minimum Odds Suffix" fieldPlaceholder="Minimum Odds Suffix" fieldType="text" fieldSubLabel="This will show after the Minimum Odds (Minimum Odds 1 in 250 xxxxxxx)"
                :fieldValue="bundleForm.minimumOddsSuffix" 
                @fieldInput="updateFormValue"
            />
        </div>
        <div class="w-full white-transparent mt-px p-2">
            <div @click="clickSaveBundle()" class="w-full" :class=" { 'standard-button-base': allOkay, 'disabled-button-base': !allOkay } ">
                Save
            </div>
            <div @click="clickDelete()" class="mt-2 w-full secondary-button-base">
                Delete
            </div>
            <div v-if="isChanges" @click="clickCancelChanges()" class="mt-2 w-full alt-button-base">
                Cancel Changes
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'bundle',
        'groupBuy'
    ],
    data() {
        return {
            bundleForm: {
                bannerText: null,
                compareAtPrice: null,
                currentOddsShow: true,
                currentOddsSuffix: null,
                currentOddsText: null,
                description: null,
                doubleOfferDealActive: true,
                doubleOfferDealCompareAtPrice: null,
                doubleOfferDealLabel: null,
                doubleOfferDealShowText: true,
                doubleOfferDealTempImage: null,
                doubleOfferDealText: null,
                enabled: false,
                id: null,
                label: null,
                minimumOddsShow: true,
                minimumOddsSuffix: null,
                minimumOddsText: null,
                price: null,
                tempImage: null,
                ticketQty: null
            },
            doubleOfferDealImage: null,
            formLoaded: false,
            image: null,
            newDoubleOfferImageGallery: null,
            newImageGallery: null,
            validating: false
        }
    },
    computed: {
        allOkay() {
            return Boolean(this.bundleForm.label && this.image && this.bundleForm.price && this.bundleForm.ticketQty)
        },
        doubleOfferDealImagesArray() {
            if (!this.doubleOfferDealImage) {
                return []
            }

            if (!this.doubleOfferDealImage.type) {
                this.doubleOfferDealImage.type = "image"
            }

            return [this.doubleOfferDealImage]
        },
        imagesArray() {
            if (!this.image) {
                return []
            }

            if (!this.image.type) {
                this.image.type = "image"
            }

            return [this.image]
        },
        isChanges() {
            let myCheckFields = [
                'bannerText', 
                'compareAtPrice', 
                'currentOddsShow', 
                'currentOddsSuffix', 
                'currentOddsText', 
                'description',
                'doubleOfferDealActive',
                'doubleOfferDealCompareAtPrice',
                'doubleOfferDealLabel',
                'doubleOfferDealShowText',
                'doubleOfferDealTempImage',
                'doubleOfferDealText',
                'enabled',
                'label',
                'minimumOddsShow',
                'minimumOddsSuffix',
                'minimumOddsText',
                'price',
                'ticketQty'
            ]

            let myChanges = false

            myCheckFields.forEach((checkField) => {
                if (this.bundleForm[checkField] != this.bundle[checkField]) {
                    myChanges = true
                }
            })

            return myChanges && Boolean(this.image != this.bundle.image || this.doubleOfferDealImage != this.bundle.doubleOfferDealImage)
        }
    },
    mounted() {
        this.bundleForm.bannerText = this.bundle.bannerText
        this.bundleForm.compareAtPrice = this.bundle.compareAtPrice
        this.bundleForm.currentOddsShow = this?.bundle?.currentOddsShow ?? true
        this.bundleForm.currentOddsSuffix = this?.bundle?.currentOddsSuffix ?? null
        this.bundleForm.currentOddsText = this?.bundle?.currentOddsText ?? "Current Odds"
        this.bundleForm.description = this.bundle.description
        this.bundleForm.doubleOfferDealActive = this?.bundle?.doubleOfferDealActive ?? true
        this.bundleForm.doubleOfferDealCompareAtPrice = this?.bundle?.doubleOfferDealCompareAtPrice ?? this.bundle.compareAtPrice
        this.bundleForm.doubleOfferDealLabel = this?.bundle?.doubleOfferDealLabel ?? this.bundle.label
        this.bundleForm.doubleOfferDealShowText = this?.bundle?.doubleOfferDealShowText ?? true
        this.bundleForm.doubleOfferDealTempImage = this?.bundle?.doubleOfferDealTempImage ?? this.bundle.tempImage
        this.bundleForm.doubleOfferDealText = this?.bundle?.doubleOfferDealText ?? "Odds calculated with Double Ticket Offer"
        this.bundleForm.enabled = this.bundle.enabled
        this.bundleForm.id = this.bundle.id
        this.bundleForm.label = this.bundle.label
        this.bundleForm.minimumOddsShow = this?.bundle?.minimumOddsShow ?? true
        this.bundleForm.minimumOddsSuffix = this?.bundle?.minimumOddsSuffix ?? null
        this.bundleForm.minimumOddsText = this?.bundle?.minimumOddsText ?? "Minimum Odds"
        this.bundleForm.price = this.bundle.price
        this.bundleForm.tempImage = this.bundle.tempImage
        this.bundleForm.ticketQty = this.bundle.ticketQty
        this.doubleOfferDealImage = this?.bundle?.doubleOfferDealImage ?? this?.bundle?.doubleOfferDealTempImage ?? this.bundle.tempImage
        this.image = this?.bundle?.image ?? this.bundle.tempImage
        this.formLoaded = true
    },
    methods: {
        clickCancelChanges() {
            this.bundleForm.bannerText = this.bundle.bannerText
            this.bundleForm.compareAtPrice = this.bundle.compareAtPrice
            this.bundleForm.currentOddsShow = this?.bundle?.currentOddsShow ?? true
            this.bundleForm.currentOddsSuffix = this?.bundle?.currentOddsSuffix ?? null
            this.bundleForm.currentOddsText = this?.bundle?.currentOddsText ?? "Current Odds"
            this.bundleForm.description = this.bundle.description
            this.bundleForm.doubleOfferDealActive = this?.bundle?.doubleOfferDealActive ?? true
            this.bundleForm.doubleOfferDealCompareAtPrice = this?.bundle?.doubleOfferDealCompareAtPrice ?? this.bundle.compareAtPrice
            this.bundleForm.doubleOfferDealLabel = this?.bundle?.doubleOfferDealLabel ?? this.bundle.label
            this.bundleForm.doubleOfferDealShowText = this?.bundle?.doubleOfferDealShowText ?? true
            this.bundleForm.doubleOfferDealTempImage = this?.bundle?.doubleOfferDealTempImage ?? this.bundle.tempImage
            this.bundleForm.doubleOfferDealText = this?.bundle?.doubleOfferDealText ?? "Odds calculated with Double Ticket Offer"
            this.bundleForm.enabled = this.bundle.enabled
            this.bundleForm.label = this.bundle.label
            this.bundleForm.minimumOddsShow = this?.bundle?.minimumOddsShow ?? true
            this.bundleForm.minimumOddsSuffix = this?.bundle?.minimumOddsSuffix ?? null
            this.bundleForm.minimumOddsText = this?.bundle?.minimumOddsText ?? "Minimum Odds"
            this.bundleForm.price = this.bundle.price
            this.bundleForm.ticketQty = this.bundle.ticketQty
            this.doubleOfferDealImage = this?.bundle?.doubleOfferDealImage ?? this?.bundle?.doubleOfferDealTempImage ?? this.bundle.tempImage
            this.image = this.bundle.image
        },
        clickDelete() {
            if (confirm(`You wish to delete ${this.bundle.label}?`)) {
                this.$emit('deleteBundle', this.bundle.id)
            }
        },
        async clickSaveBundle() {
            if (this.allOkay) {
                this.validating = false

                if (this.newImageGallery) {
                    this.image = this.newImageGallery[0]
                    let myTempGallery = await this.buildOfflineTempGallery(this.newImageGallery, `bundle_temp${this.groupBuy.id}_${this.bundleForm.id}`)
                
                    let myOtherData = {
                        bundleId: this.bundleForm.id
                    }
                    
                    setTimeout(() => {
                        this.saveGalleryPrep(myTempGallery, 'groupBuyBundleGalleryUpdate', this.groupBuy.id, 'groupOfferBundleGallery', myOtherData)
                    }, 100)
                }

                if (this.newDoubleOfferImageGallery) {
                    this.doubleOfferDealImage = this.newDoubleOfferImageGallery[0]
                    let myTempDoubleOfferGallery = await this.buildOfflineTempGallery(this.newDoubleOfferImageGallery, `bundle_temp${this.groupBuy.id}_double_offer_${this.bundleForm.id}`)
                
                    let myDoubleOfferOtherData = {
                        bundleId: this.bundleForm.id
                    }
                    
                    setTimeout(() => {
                        this.saveGalleryPrep(myTempDoubleOfferGallery, 'groupBuyBundleDoubleOfferGalleryUpdate', this.groupBuy.id, 'groupOfferBundleDoubleOfferGallery', myDoubleOfferOtherData)
                    }, 100)
                }
                
                this.$emit('updateBundle', this.bundleForm)
                this.pageMessage('success', "Bundle saved")
            }
            else {
                this.validating = true
            }
        },
        saveLocalDoubleOfferImage(inGallery) {
            this.doubleOfferDealImage = inGallery[0]
            this.newDoubleOfferImageGallery = inGallery
        },
        saveLocalImage(inGallery) {
            this.image = inGallery[0]
            this.newImageGallery = inGallery
        },
        updateDoubleOfferOfflineMedia(inGallery) {
            this.doubleOfferDealImage = inGallery[0]
            this.newImageGallery = inGallery
        },
        updateFormValue(inField, inValue) {
            this.bundleForm[inField] = inValue
        },
        updateOfflineMedia(inGallery) {
            this.image = inGallery[0]
            this.newImageGallery = inGallery
        }
    }
}
</script>