const queueMixins = {
    watch: {
        userQueue: {
            handler(val) {
                if (!this.postQueueProcessing) {
                    this.readQueue()
                }
            }
        }
    },
    methods: {
        addImagesToQueueUpdated(inGallery) {
            let myQueueImagesUpdated = this.objCopy(this.queueImagesUpdated)

            inGallery.forEach((galleryImage) => {
                let myImage = {
                    identifier: galleryImage.identifier,
                    image: galleryImage
                }

                myQueueImagesUpdated = { ...myQueueImagesUpdated, [`${galleryImage.identifier}`]: myImage }
            })

            this.setStateData([
                { queueImagesUpdated: myQueueImagesUpdated }
            ])
        },
        async addToQueue(inType, inData) {
            return new Promise(async (resolve, reject) => {
                let myQueue = null
                let myQueueId = null

                if (this.deviceInfo && this.deviceInfo.platform != 'web') {
                    myQueue = await this.getStorage(`queue_${this.loggedUserId}`)
                    myQueueId = await this.getStorage(`queue_id_${this.loggedUserId}`)

                    if (myQueueId.value) {
                        myQueueId = parseInt(myQueueId.value)
                    }
                    else {
                        myQueueId = 1
                    }

                    await this.setStorage(`queue_id_${this.loggedUserId}`, `${myQueueId + 1}`)

                    if (myQueue?.value) {
                        myQueue = JSON.parse(myQueue.value)
                    }
                    else {
                        myQueue = []
                    }
                }
                else {
                    myQueue = this.userQueue
                    myQueueId = this.userQueueId

                    if (myQueueId) {
                        myQueueId = parseInt(myQueueId)
                    }
                    else {
                        myQueueId = 1
                    }

                    this.setStateData([
                        { userQueueId: myQueueId + 1 }
                    ])

                    if (myQueue) {
                        myQueue = JSON.parse(myQueue)
                    }
                    else {
                        myQueue = []
                    }
                }

                let myRecord = {
                    type: inType,
                    id: myQueueId,
                    data: inData
                }

                myQueue.push(myRecord)

                if (this.deviceInfo && this.deviceInfo.platform != 'web') {
                    this.setStorage(`queue_${this.loggedUserId}`, JSON.stringify(myQueue))
                }
                else {
                    this.setStateData([
                        { userQueue: JSON.stringify(myQueue) }
                    ])
                }
                
                if (!this.serverConnected) {
                    this.pageMessage('warning', 'You are currently not connected to the internet, data will be posted when your connection is restored')
                }

                resolve(myQueueId)
            })
        },
        async buildGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            buildzAxios?.put(this.getRoute('buildGalleryUpdate', inData.id),
            {
                gallery: inData.gallery
            })
            .then((response) => {
                if (response.status == 200 && response.data.build) {
                    this.updateStoredBuild(this.objCopy(response.data.build))
                    this.stopQueue(inQueueId, true)
                }
                else {
                    this.stopQueue(inQueueId, false)
                }
            })
            .catch((error) => {
                this.stopQueue(inQueueId, false)
            })
        },
        buildListDeleteMod(inData, inQueueId) {
            buildzAxios?.post(this.getRoute('buildListDeleteMod', inData.id), inData.mod)
                .then((response) => {
                    if (response.status == 200 && response.data.build) {
                        this.updateStoredBuild(this.objCopy(response.data.build))
                        this.stopQueue(inQueueId, true)
                    }
                    else {
                        this.stopQueue(inQueueId, false)
                    }
                })
                .catch((error) => {
                    this.stopQueue(inQueueId, false)
                })
        },  
        buildListUpdate(inData, inQueueId) {
            buildzAxios?.put(this.getRoute('buildListUpdate', inData.id), { 'lists': inData.lists })
                .then((response) => {
                    if (response.status == 200 && response.data.build) {
                        this.updateStoredBuild(this.objCopy(response.data.build))
                        this.stopQueue(inQueueId, true)
                    }
                    else {
                        this.stopQueue(inQueueId, false)
                    }
                })
                .catch((error) => {
                    this.stopQueue(inQueueId, false)
                })
        },
        buildListUpdateMod(inData, inQueueId) {
            buildzAxios?.put(this.getRoute('buildListUpdateMod', inData.id), inData.mod)
                .then((response) => {
                    if (response.status == 200 && response.data.build) {
                        this.updateStoredBuild(this.objCopy(response.data.build))
                        this.stopQueue(inQueueId, true)
                    }
                    else {
                        this.stopQueue(inQueueId, false)
                    }
                })
                .catch((error) => {
                    this.stopQueue(inQueueId, false)
                })
        },  
        buildUpdate(inData, inQueueId) {
            buildzAxios?.put(this.getRoute('buildUpdate', inData.id), inData.form)
                .then((response) => {
                    if (response.status == 200 && response.data.build) {
                        this.updateStoredBuild(this.objCopy(response.data.build))
                        this.stopQueue(inQueueId, true)
                    }
                    else {
                        this.stopQueue(inQueueId, false)
                    }
                })
                .catch((error) => {
                    this.stopQueue(inQueueId, false)
                })
        },
        async garageGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            buildzAxios?.put(this.getRoute('garageGalleryUpdate', inData.id),
                {
                    gallery: inData.gallery
                })
                .then((response) => {
                    if (response.status == 200 && response.data.garage) {
                        this.updateStoredGarage(this.objCopy(response.data.garage))
                        this.stopQueue(inQueueId, true)
                    }
                    else {
                        this.stopQueue(inQueueId, false)
                    }
                })
                .catch((error) => {
                    this.stopQueue(inQueueId, false)
                })
        },
        garageUpdate(inData, inQueueId) {
            buildzAxios?.put(this.getRoute('garageUpdate', inData.id), inData.form)
                .then((response) => {
                    if (response.status == 200 && response.data.garage) {
                        this.updateStoredGarage(this.objCopy(response.data.garage))
                        this.stopQueue(inQueueId, true)
                    }
                    else {
                        this.stopQueue(inQueueId, false)
                    }
                })
                .catch((error) => {
                    this.stopQueue(inQueueId, false)
                })
        },
        async groupBuyABTestGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('groupOfferABTestGallery', inData.id), 
                {
                    gallery: inData.gallery,
                    from_queue: true,
                    otherData: inData.otherData
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async groupBuyABTestMBFeatureGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('groupOfferABTestMBFeatureGallery', inData.id), 
                {
                    gallery: inData.gallery,
                    from_queue: true,
                    otherData: inData.otherData
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async groupBuyABTestMBGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('groupOfferABTestMBGallery', inData.id), 
                {
                    gallery: inData.gallery,
                    from_queue: true,
                    otherData: inData.otherData
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async groupBuyABTestMBSliderGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('groupOfferABTestMBSliderGallery', inData.id), 
                {
                    gallery: inData.gallery,
                    from_queue: true,
                    otherData: inData.otherData
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async groupBuyABTestPMBGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('groupOfferABTestPMBGallery', inData.id), 
                {
                    gallery: inData.gallery,
                    from_queue: true,
                    otherData: inData.otherData
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async groupBuyBundleDoubleOfferGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('groupOfferBundleDoubleOfferGallery', inData.id), 
                {
                    gallery: inData.gallery,
                    from_queue: true,
                    otherData: inData.otherData
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async groupBuyBundleGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('groupOfferBundleGallery', inData.id), 
                {
                    gallery: inData.gallery,
                    from_queue: true,
                    otherData: inData.otherData
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async groupBuyGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('groupOfferGallery', inData.id), 
                {
                    gallery: inData.gallery,
                    from_queue: true
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async groupBuyMBGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('groupOfferMBGallery', inData.id), 
                {
                    gallery: inData.gallery,
                    from_queue: true
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async groupBuyMBFeatureGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('groupOfferMBFeatureGallery', inData.id), 
                {
                    gallery: inData.gallery,
                    from_queue: true
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async groupBuyPMBGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('groupOfferPMBGallery', inData.id), 
                {
                    gallery: inData.gallery,
                    from_queue: true
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async groupBuyMBSliderGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('groupOfferMBSliderGallery', inData.id), 
                {
                    gallery: inData.gallery,
                    from_queue: true
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async listingGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('productGallery', inData.id), 
                {
                    gallery: inData.gallery,
                    from_queue: true
                }
            )
            .then((response) => {
                let myRecord = Object.entries(this.vendorProducts).find((entry) => entry[1].id == inData.id)
                let myRecords = this.objCopy(this.vendorProducts)
                myRecords[myRecord[0]].gallery = inData.gallery

                this.setStateData([
                    { vendorProducts: myRecords }
                ])

                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async listingMBFeatureGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('productMBFeatureGallery', inData.id), 
                {
                    gallery: inData.gallery,
                    from_queue: true
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async listingMBGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('productMBGallery', inData.id), 
                {
                    gallery: inData.gallery,
                    from_queue: true
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async localNewActivity(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            buildzAxios?.post(this.getRoute('activityCreate'), {
                text: inData.text,
                attachments: inData.attachments,
                feeds: inData.feeds,
                recordId: inData.recordId,
                postTo: inData.postTo,
                builds: inData.buildIds,
                groups: inData.groupIds,
                imageStyle: inData.imageStyle,
                meta: null,
                postType: inData.postType,
                saleData: inData.saleData
            })
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.checklist) {
                        let myUser = this.objCopy(this.loggedUser)
                        myUser.checklist = response.data.checklist
                        myUser.badges = response.data.badges
                        myUser.xp_points = response.data.xp_points
                        myUser.xp_prestige = response.data.xp_prestige
                        myUser.xp_level = response.data.xp_level
                        myUser.xp_current = response.data.xp_current
                        myUser.xp_next = response.data.xp_next

                        this.setStateData([
                            { loggedUser: myUser }
                        ])
                    }

                    this.stopQueue(inQueueId, true)
                }
                else {
                    this.stopQueue(inQueueId, false)
                }
            })
            .catch((error) => {
                this.stopQueue(inQueueId, false)
            })
            .then(() => {
                this.updateUTCTime()
            })
        },
        localUpdateActivity(inData, inQueueId) {
            buildzAxios?.put(this.getRoute('activityUpdate', inData.activityId), {
                text: inData.text,
                attachments: inData.attachments,
                feeds: inData.feeds,
                imageStyle: inData.imageStyle,
                saleData: inData.saleData
            })
            .then((response) => {
                if (response.status == 200 && response.data) {
                    this.publishUpdatedActivity(inData.activityId)
                    this.stopQueue(inQueueId, true)
                }
                else {
                    this.stopQueue(inQueueId, false)
                }
            })
            .catch((error) => {
                this.stopQueue(inQueueId, false)
            })
        },
        async modGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            buildzAxios?.put(this.getRoute('modGalleryUpdate', inData.id),
                {
                    gallery: inData.gallery,
                    otherData: inData.otherData
                })
                .then((response) => {
                    if (response.status == 200) {
                        this.stopQueue(inQueueId, true)
                    }
                    else {
                        this.stopQueue(inQueueId, false)
                    }
                })
                .catch((error) => {
                    this.stopQueue(inQueueId, false)
                })
        },
        async postNewMessageAfterImagePush(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.sendMessage(inData.otherData.chat, inData.otherData.messageText ?? ' ', inData.gallery[0])
            this.stopQueue(inQueueId, true)
        },
        async postNewActivityAfterImagePush(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            let myAttachments = inData.otherData.attachments
            myAttachments.images = inData.gallery

            this.newActivity(inData.otherData.text, inData.otherData.attachments, inData.otherData.feeds, inData.otherData.imageStyle, inData.otherData.recordId, inData.otherData.postTo, inData.otherData.buildIds, inData.otherData.groupIds, inData.otherData.postType, inData.otherData.saleData, inData.otherData.meta)
            this.stopQueue(inQueueId, true)
        },
        async postNewChatAfterImagePush(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            let myForm = this.objCopy(inData.otherData.form)
            myForm.image = inData.gallery[0].url

            if (this.chatEditForm) {
                this.submitChatData(myForm)
            }
            else {
                this.submitNewChat(myForm)
            }

            this.stopQueue(inQueueId, true)
        },
        async postNewReactionAfterImagePush(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.newPostReaction(inData.otherData.text, inData.gallery[0].url, inData.otherData.feeds)
            this.stopQueue(inQueueId, true)
        },
        async postUpdateActivityAfterImagePush(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            let myAttachments = inData.otherData.attachments
            myAttachments.images = inData.gallery

            this.updateActivity(inData.otherData.text, inData.otherData.attachments, inData.otherData.feeds, inData.otherData.imageStyle, inData.otherData.recordId, inData.otherData.saleData)
            this.stopQueue(inQueueId, true)
        },
        async postUpdateReactionAfterImagePush(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            let myAttachments = inData.otherData.attachments
            myAttachments.images = inData.gallery

            this.updatePostReaction(inData.otherData.text, inData.gallery[0].url, inData.otherData.feeds, inData.otherData.reactionId, inData.otherData.activityId)
            this.stopQueue(inQueueId, true)
        },
        async readQueue() {
            if (this.serverConnected && !this.postQueueProcessing) {
                this.setStateData([
                    { postQueueProcessing: true }
                ])

                let myQueue = null

                if (this.deviceInfo && this.deviceInfo.platform != 'web') {
                    myQueue = await this.getStorage(`queue_${this.loggedUserId}`)

                    if (myQueue?.value) {
                        myQueue = JSON.parse(myQueue.value)
                    }
                    else {
                        myQueue = []
                    }
                }
                else {
                    myQueue = this.userQueue

                    if (myQueue) {
                        myQueue = JSON.parse(myQueue)
                    }
                    else {
                        myQueue = []
                    }
                }

                let myRecordIndex = null

                if (myQueue.length) {
                    myRecordIndex = 0
                }

                if (myRecordIndex != null) {
                    let myRecord = myQueue[myRecordIndex]
                    let allGood = true

                    if (myRecord.data.wait) {
                        allGood = false

                        let myMatched = []
                        let myImages = []

                        this.uploadImages.forEach((upImage) => {
                            if (myRecord.data.waitQueueId.includes(upImage.queueId)) {
                                myMatched.push(upImage)
                            }
                            else {
                                myImages.push(upImage)
                            }
                        })

                        if (myImages.length) {
                            this.replaceStateDataArray([
                                { uploadImages: myImages }
                            ])
                        }

                        if (myMatched.length) {
                            myMatched.forEach((myMatch) => {
                                myRecord.data.waitQueueId = myRecord.data.waitQueueId.filter((myId) => myId != myMatch.queueId)
                            })

                            myQueue[myRecordIndex] = this.objCopy(myRecord)

                            if (this.deviceInfo && this.deviceInfo.platform != 'web') {
                                this.setStorage(`queue_${this.loggedUserId}`, JSON.stringify(myQueue))
                            }
                            else {
                                this.setStateData([
                                    { userQueue: JSON.stringify(myQueue) }
                                ])
                            }
                        }

                        let myFinished = myRecord.data.waitQueueId.length

                        if (myFinished == 0) {
                            allGood = true

                            if (myMatched.length) {
                                if (myRecord.type == 'buildListUpdateMod') {
                                    let myGallery = []

                                    myRecord.data.mod.gallery.forEach((item) => {
                                        if (!item.queueId) {
                                            myGallery.push(item)
                                        }
                                        else {
                                            let myMatch = myMatched.filter((match) => match.queueId == item.queueId)

                                            if (myMatch.length) {
                                                myGallery.push(myMatch[0].image)
                                            }
                                        }
                                    })

                                    myRecord.data.mod.gallery = myGallery
                                }
                                else if (myRecord.type == 'newActivity') {
                                    let myGallery = []

                                    myRecord.data.attachments.images.forEach((item) => {
                                        if (!item.queueId) {
                                            myGallery.push(item)
                                        }
                                        else {
                                            let myMatch = myMatched.filter((match) => match.queueId == item.queueId)

                                            if (myMatch.length) {
                                                myGallery.push(myMatch[0].image)
                                            }
                                        }
                                    })

                                    myRecord.data.attachments.images = myGallery
                                }
                                else if (myRecord.type == 'updateActivity') {
                                    let myGallery = []

                                    myRecord.data.attachments.images.forEach((item) => {
                                        if (!item.queueId) {
                                            myGallery.push(item)
                                        }
                                        else {
                                            let myMatch = myMatched.filter((match) => match.queueId == item.queueId)

                                            if (myMatch.length) {
                                                myGallery.push(myMatch[0].image)
                                            }
                                        }
                                    })

                                    myRecord.data.attachments.images = myGallery
                                }
                                else if (['chatNew', 'sendChatMessage', 'activityNew', 'activityUpdate', 'reactionNew', 'reactionUpdate', 'modGalleryUpdate', 'buildGalleryUpdate', 'listingGalleryUpdate', 'listingMBFeatureGalleryUpdate', 'listingMBGalleryUpdate', 'groupBuyABTestGalleryUpdate', 'groupBuyABTestMBFeatureGalleryUpdate', 'groupBuyABTestMBGalleryUpdate', 'groupBuyABTestMBSliderGalleryUpdate', 'groupBuyABTestPMBGalleryUpdate', 'groupBuyBundleDoubleOfferGalleryUpdate', 'groupBuyBundleGalleryUpdate', 'groupBuyGalleryUpdate', 'groupBuyMBGalleryUpdate', 'groupBuyMBFeatureGalleryUpdate', 'groupBuyPMBGalleryUpdate', 'groupBuyMBSliderGalleryUpdate', 'garageGalleryUpdate', 'settingsAvatarUpdate', 'sellerGalleryUpdate', 'sellerAvatarUpdate', 'sellerFavIconUpdate', 'sellerLogoUpdate', 'traitMBGalleryUpdate'].includes(myRecord.type)) {
                                    let myGallery = []

                                    myRecord.data.gallery.forEach((item) => {
                                        if (!item.queueId) {
                                            myGallery.push(item)
                                        }
                                        else {
                                            let myMatch = myMatched.filter((match) => match.queueId == item.queueId)

                                            if (myMatch.length) {
                                                myGallery.push(myMatch[0].image)
                                            }
                                        }
                                    })

                                    myRecord.data.gallery = myGallery
                                }
                            }
                        }
                    }

                    if (allGood) {
                        if (myRecord.type == 'buildUpdate') {
                            setTimeout(() => {
                                this.buildUpdate(myRecord.data, myRecord.id)    
                            }, 10)
                        }
                        else if (myRecord.type == 'buildGalleryUpdate') {
                            setTimeout(() => {
                                this.buildGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'listingGalleryUpdate') {
                            setTimeout(() => {
                                this.listingGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'listingMBFeatureGalleryUpdate') {
                            setTimeout(() => {
                                this.listingMBFeatureGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'listingMBGalleryUpdate') {
                            setTimeout(() => {
                                this.listingMBGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'groupBuyABTestGalleryUpdate') {
                            setTimeout(() => {
                                this.groupBuyABTestGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'groupBuyABTestMBFeatureGalleryUpdate') {
                            setTimeout(() => {
                                this.groupBuyABTestMBFeatureGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'groupBuyABTestMBSliderGalleryUpdate') {
                            setTimeout(() => {
                                this.groupBuyABTestMBSliderGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'groupBuyABTestMBGalleryUpdate') {
                            setTimeout(() => {
                                this.groupBuyABTestMBGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'groupBuyABTestPMBGalleryUpdate') {
                            setTimeout(() => {
                                this.groupBuyABTestPMBGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'groupBuyBundleDoubleOfferGalleryUpdate') {
                            setTimeout(() => {
                                this.groupBuyBundleDoubleOfferGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'groupBuyBundleGalleryUpdate') {
                            setTimeout(() => {
                                this.groupBuyBundleGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'groupBuyGalleryUpdate') {
                            setTimeout(() => {
                                this.groupBuyGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'groupBuyMBGalleryUpdate') {
                            setTimeout(() => {
                                this.groupBuyMBGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'groupBuyMBFeatureGalleryUpdate') {
                            setTimeout(() => {
                                this.groupBuyMBFeatureGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'groupBuyPMBGalleryUpdate') {
                            setTimeout(() => {
                                this.groupBuyPMBGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'groupBuyMBSliderGalleryUpdate') {
                            setTimeout(() => {
                                this.groupBuyMBSliderGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'garageGalleryUpdate') {
                            setTimeout(() => {
                                this.garageGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'modGalleryUpdate') {
                            setTimeout(() => {
                                this.modGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'buildListUpdateMod') {
                            setTimeout(() => {
                                this.buildListUpdateMod(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'buildListUpdate') {
                            setTimeout(() => {
                                this.buildListUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'buildListDeleteMod') {
                            setTimeout(() => {
                                this.buildListDeleteMod(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'updateActivity') {
                            setTimeout(() => {
                                this.localUpdateActivity(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'newActivity') {
                            setTimeout(() => {
                                this.localNewActivity(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'garageUpdate') {
                            setTimeout(() => {
                                this.garageUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'updateSingleImage') {
                            setTimeout(() => {
                                this.updateSingleImage(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'sellerAvatarUpdate') {
                            setTimeout(() => {
                                this.sellerAvatarUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'settingsAvatarUpdate') {
                            setTimeout(() => {
                                this.settingsAvatarUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'sellerFavIconUpdate') {
                            setTimeout(() => {
                                this.sellerFavIconUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'sellerLogoUpdate') {
                            setTimeout(() => {
                                this.sellerLogoUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'sellerGalleryUpdate') {
                            setTimeout(() => {
                                this.sellerGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'traitMBGalleryUpdate') {
                            setTimeout(() => {
                                this.traitMBGalleryUpdate(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'activityNew') {
                            setTimeout(() => {
                                this.postNewActivityAfterImagePush(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'activityUpdate') {
                            setTimeout(() => {
                                this.postUpdateActivityAfterImagePush(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'reactionNew') {
                            setTimeout(() => {
                                this.postNewReactionAfterImagePush(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'reactionUpdate') {
                            setTimeout(() => {
                                this.postUpdateReactionAfterImagePush(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'sendChatMessage') {
                            setTimeout(() => {
                                this.postNewMessageAfterImagePush(myRecord.data, myRecord.id)
                            }, 10)
                        }
                        else if (myRecord.type == 'chatNew') {
                            setTimeout(() => {
                                this.postNewChatAfterImagePush(myRecord.data, myRecord.id)
                            }, 10)
                        }
                    }
                    else {
                        setTimeout(() => {
                            this.readQueue()
                        }, 10000)
                    }
                }
                else {
                    this.setStateData([
                        { postQueueProcessing: false }
                    ])    
                }
            }
        },
        async sellerAvatarUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('sellerAvatar', inData.id), 
                {
                    vendor_id: inData.id,
                    gallery: inData.gallery,
                    from_queue: true
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async settingsAvatarUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('settingsAvatar', inData.id), 
                {
                    gallery: inData.gallery,
                    from_queue: true
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async sellerFavIconUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('sellerFavIcon', inData.id), 
                {
                    vendor_id: inData.id,
                    gallery: inData.gallery,
                    from_queue: true
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async sellerGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('sellerGallery', inData.id), 
                {
                    vendor_id: inData.id,
                    gallery: inData.gallery,
                    from_queue: true
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async sellerLogoUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('sellerLogo', inData.id), 
                {
                    gallery: inData.gallery,
                    from_queue: true
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async stopQueue(inQueueId, inSuccess) {
            if (inSuccess) {
                let myQueue = null

                if (this.deviceInfo && this.deviceInfo.platform != 'web') {
                    myQueue = await this.getStorage(`queue_${this.loggedUserId}`)

                    if (myQueue?.value) {
                        myQueue = JSON.parse(myQueue.value)
                    }
                    else {
                        myQueue = []
                    }
                }
                else {
                    myQueue = this.userQueue

                    if (myQueue) {
                        myQueue = JSON.parse(myQueue)
                    }
                    else {
                        myQueue = []
                    }
                }

                myQueue = myQueue.filter((queue) => queue.id != inQueueId)

                if (this.deviceInfo && this.deviceInfo.platform != 'web') {
                    this.setStorage(`queue_${this.loggedUserId}`, JSON.stringify(myQueue))
                }
                else {
                    this.setStateData([
                        { userQueue: JSON.stringify(myQueue) }
                    ])
                }

                this.saveDone()

                setTimeout(() => {
                    this.setStateData([
                        { postQueueProcessing: false }
                    ])
                    
                    this.readQueue()
                }, 10)
            }
            else {
                this.setStateData([
                    { postQueueProcessing: false }
                ])
            }
        },
        async traitMBGalleryUpdate(inData, inQueueId) {
            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            this.addImagesToQueueUpdated(inData.gallery)

            buildzAxios?.post(this.getRoute('sellerTraitMBGallery', inData.id), 
                {
                    vendor_id: inData.id,
                    gallery: inData.gallery,
                    from_queue: true,
                    otherData: inData.otherData
                }
            )
            .then((response) => {
                this.stopQueue(inQueueId, true)
            })
            .catch((error) => {
                console.log(error)
                this.stopQueue(inQueueId, false)
            })
        },
        async updateSingleImage(inData, inQueueId) {
            let response = await fetch(inData.file.image)
            let blob = await response.blob()
            let myImage = new File([blob], inData.file.imageName, { type: inData.file.imageType })
            this.uploadImage(myImage, inQueueId, inData.identifier)
        },
        updateStoredBuild(inBuild) {
            this.setStorage(`build_${inBuild.handle}`, JSON.stringify(inBuild))

            let myStoredBuildz = this.objCopy(this.storedBuildz)
            myStoredBuildz[inBuild.handle] = this.objCopy(inBuild)

            this.replaceStateData([
                { storedBuildz: myStoredBuildz }
            ])

            this.setStateData([
                { reloadBuildId: inBuild.id }
            ])
        },
        updateStoredGarage(inGarage) {
            this.setStorage(`garage_${inGarage.handle}`, JSON.stringify(inGarage))

            let myStoredGarages = this.objCopy(this.storedGarages)
            myStoredGarages[inGarage.handle] = this.objCopy(inGarage)

            this.replaceStateData([
                { storedGarages: myStoredGarages }
            ])
        }
    }
}

export default queueMixins

async function waitUntil(f, timeoutMs) {
    return new Promise((resolve, reject) => {
        let timeWas = new Date();
        let wait = setInterval(function () {

            if (f()) {
                console.log("resolved after", new Date() - timeWas, "ms");
                clearInterval(wait);
                resolve();
            } else if (new Date() - timeWas > timeoutMs) { // Timeout
                console.log("rejected after", new Date() - timeWas, "ms");
                clearInterval(wait);
                reject();
            }
        }, 200);
    });
}