<template>
    <div v-if="formLoaded" class="w-full">
        <select-panel
            :fieldData="yesNoData" fieldFormName="hasBulkDiscounts" fieldLabel="Do You Want To Use Bulk Discount Groups?" fieldLabelClasses="text-gray-800" 
            :fieldRequired="true" :fieldValue="sellerDataForm.hasBulkDiscounts" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateSellerDataFormSelectValue"
        >
            <validation-message v-if="validating && sellerDataForm.hasBulkDiscounts == null" 
                message="Select yes or no"
            />
        </select-panel>
        <div v-if="sellerDataForm.hasBulkDiscounts" class="w-full text-sm">
            <wizard-table-container
                headingText="Bulk Discount Groups" :showNew="showIcons"
                @clickNewRecord="clickNewBulkDiscount"
            >
                <template #records>
                    <edit-table-record v-if="addingBulkDiscount"
                        :duplicateValueError="duplicateBulkDiscountNameError" fieldFormName="label" fieldId="inputNewBulkDiscount" fieldType="text" 
                        :fieldValue="addBulkDiscountForm.label" :validatingForm="validatingForm"
                        @clickCancel="clickCancelNewBulkDiscount" @clickSave="clickSaveBulkDiscount" @updateFieldValue="updateAddBulkDiscountFormValue"
                    />
                    <div v-for="bulkDiscount in sortedBulkDiscounts" class="w-full">
                        <table-record v-if="editingValueId || editingBulkDiscountId != bulkDiscount.id">
                            <template #record>
                                <div @click="clickToggleShowBulkDiscount(bulkDiscount.id)" class="pl-2 relative w-full">
                                    <div class="text-left">
                                        {{ bulkDiscount.label }}
                                    </div>
                                    <div v-if="showIcons && isBulkDiscountOpen(bulkDiscount.id)" class="absolute -bottom-3 right-0 flex items-center justify-end text-xxs text-center w-full">
                                        <div class="text-right">
                                            ENABLED
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showIcons" class="wizard-icon-container w-32">
                                    <div @click="clickToggleShowBulkDiscount(bulkDiscount.id)" v-show="!isBulkDiscountOpen(bulkDiscount.id)" class="cursor-pointer">
                                        <fa-icon icon="chevron-left" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div @click="clickToggleShowBulkDiscount(bulkDiscount.id)" v-show="isBulkDiscountOpen(bulkDiscount.id)" class="cursor-pointer">
                                        <fa-icon icon="chevron-down" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div @click="clickNewValue(bulkDiscount.id)" class="cursor-pointer ml-2">
                                        <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div @click="clickEditBulkDiscount(bulkDiscount.id)" class="cursor-pointer ml-2">
                                        <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div @click="clickDeleteBulkDiscount(bulkDiscount.id, bulkDiscount.label)" class="cursor-pointer ml-2">
                                        <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                    </div>
                                </div>
                            </template>
                            <template #extra>
                                <div v-if="isBulkDiscountOpen(bulkDiscount.id)" class="w-full">
                                    <wizard-table-container
                                        :childTable="true" :hideHeader="true"
                                    >
                                        <template #records>
                                            <edit-bulk-discount-record v-if="addingBulkDiscountId && addingBulkDiscountId == bulkDiscount.id"
                                                :bulkDiscountValue="addValueForm" :duplicateValueQtyError="duplicateValueQtyError"
                                                :validatingForm="validatingForm"
                                                @clickCancel="clickCancelNewValue" @clickSave="clickSaveValue" @updateFieldValue="updateAddValueFormValue"
                                            />
                                            <div v-for="bulkDiscountValue in sortedBulkDiscountValues(bulkDiscount.values)">
                                                <table-record v-if="editingValueId != bulkDiscountValue.id || editingBulkDiscountId != bulkDiscount.id">
                                                    <template #record>
                                                        <div class="w-full flex items-center justify-start text-center">
                                                            <div class="w-1/3">
                                                                <div class="flex items-center justify-start w-full pl-4 text-left">
                                                                    <child-line-prefix />
                                                                    <div>
                                                                        {{ bulkDiscountValue.qty }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="w-1/3">
                                                                {{ bulkDiscountValue.percentage }}%
                                                            </div>
                                                            <div class="w-1/3 text-right pr-6">
                                                                <input @change="clickToggleBulkDiscountEnabled(bulkDiscount.id, bulkDiscountValue.id, bulkDiscountValue.enabled)" :id="`bulk-discount-enabled-check-${bulkDiscount.id}-${bulkDiscountValue.id}`" :checked="bulkDiscountValue.enabled" type="checkbox" class="w-4 h-4 text-orange-500 border-gray-300 rounded edit-field-text-color" :class="{ 'background-orange-500': bulkDiscountValue.enabled }">
                                                            </div>
                                                        </div>
                                                        <div class="wizard-icon-container w-32">
                                                            <div v-if="showIcons" @click="clickEditValue(bulkDiscountValue, bulkDiscount.id)" class="cursor-pointer">
                                                                <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                                                            </div>
                                                            <div v-if="showIcons" @click="clickDeleteValue(bulkDiscountValue.id, bulkDiscount.id)" class="cursor-pointer ml-2">
                                                                <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                                            </div>
                                                        </div>
                                                    </template>
                                                </table-record>
                                                <edit-bulk-discount-record v-if="editingValueId == bulkDiscountValue.id && editingBulkDiscountId == bulkDiscount.id"
                                                    :bulkDiscountValue="editValueForm" :duplicateValueQtyError="duplicateValueQtyError"
                                                    :validatingForm="validatingForm"
                                                    @clickCancel="clickCancelEditValue" @clickSave="clickSaveValue" @updateFieldValue="updateEditValueFormValue"
                                                />
                                            </div>
                                        </template>
                                    </wizard-table-container>
                                </div>
                            </template>
                        </table-record>
                        <edit-table-record v-if="!editingValueId && editingBulkDiscountId == bulkDiscount.id"
                            :duplicateValueError="duplicateBulkDiscountNameError" fieldFormName="label" fieldId="inputEditBulkDiscount" fieldType="text" :fieldValue="editBulkDiscountForm.label"
                            :validatingForm="validatingForm"
                            @clickCancel="clickCancelEditBulkDiscount" @clickSave="clickSaveBulkDiscount" @updateFieldValue="updateEditBulkDiscountFormValue"
                        />
                    </div>
                </template>
            </wizard-table-container>
            <validation-message v-if="validating && sellerDataForm.hasBulkDiscounts && (!sellerForm.product_bulk_discounts || !sellerForm.product_bulk_discounts.length)" 
                 message="You must create at least one Bulk Discount Group"
            />
        </div>
    </div>
</template>

<script>
import EditBulkDiscountRecord from '@/wizards/components/EditBulkDiscountRecord'
import EditTableRecord from '@/wizards/components/EditTableRecord'
import TableRecord from '@/wizards/components/TableRecord'

export default {
    props: [
        'sellerDataForm',
        'sellerForm',
        'validating'
    ],
    components: {
        EditBulkDiscountRecord,
        EditTableRecord,
        TableRecord
    },
    data() {
        return {
            addingBulkDiscount: false,
            addingBulkDiscountId: null,
            addBulkDiscountForm: {
                id: null,
                label: null,
                values: []
            },
            addValueForm: {
                enabled: true,
                id: null,
                percentage: null,
                qty: null
            },
            bulkDiscounts: null,
            bulkDiscountsOpen: [],
            duplicateBulkDiscountNameError: false,
            duplicateValueQtyError: false,
            editBulkDiscountForm: {
                label: null
            },
            editingBulkDiscountId: null,
            editingValueId: null,
            editValueForm: {
                enabled: null,
                percentage: null,
                qty: null
            },
            formLoaded: false,
            validatingForm: false
        }
    },
    computed: {
        showIcons() {
            return !this.addingBulkDiscount && !this.addingBulkDiscountId && !this.editingBulkDiscountId
        },
        sortedBulkDiscounts() {
            return this.bulkDiscounts.sort((a, b) => a.label < b.label ? -1 : 1)
        }
    },
    mounted() {
        this.bulkDiscounts = this.objCopy(this.sellerForm.product_bulk_discounts)
        this.formLoaded = true
    },
    methods: {
        clickCancelEditBulkDiscount() {
            this.editBulkDiscountForm.label = null
            this.editingBulkDiscountId = null
            this.validatingForm = false
        },
        clickCancelEditValue() {
            this.editValueForm.enabled = false
            this.editValueForm.percentage = null
            this.editValueForm.qty = null
            this.editingBulkDiscountId = null
            this.editingValueId = null
            this.validatingForm = false
        },
        clickCancelNewBulkDiscount() {
            this.addBulkDiscountForm.label = null
            this.addBulkDiscountForm.id = null
            this.addingBulkDiscount = false
            this.validatingForm = false
        },
        clickCancelNewValue() {
            this.addValueForm.enabled = true
            this.addValueForm.id = null
            this.addValueForm.percentage = null
            this.addValueForm.qty = null
            this.addingBulkDiscountId = null
            this.validatingForm = false
        },
        clickDeleteBulkDiscount(inId, inLabel) {
            if (confirm(`Confirm you wish to delete ${inLabel}?`)) {
                this.bulkDiscounts = this.bulkDiscounts.filter((myBulkDiscount) => myBulkDiscount.id != inId)
                this.$emit('updateBulkDiscounts', this.bulkDiscounts)
            }
        },
        clickDeleteValue(inId, inBulkDiscountId) {
            if (confirm(`Confirm you wish to delete this Bulk Discount?`)) {
                let myBulkDiscount = this.bulkDiscounts.find((myValues) => myValues.id == inBulkDiscountId)

                if (myBulkDiscount) {
                    myBulkDiscount.values = myBulkDiscount.values.filter((myValue) => myValue.id != inId)
                    this.$emit('updateBulkDiscounts', this.bulkDiscounts)
                }
            }
        },
        clickEditBulkDiscount(inBulkDiscountId, inLabel) {
            this.editingBulkDiscountId = inBulkDiscountId
            this.editBulkDiscountForm.label = inLabel

            this.$nextTick(() => {
                this.elGet('inputEditBulkDiscount').focus()
            })
        },
        clickEditValue(inValue, inBulkDiscountId) {
            this.editingBulkDiscountId = inBulkDiscountId
            this.editingValueId = inValue.id
            this.editValueForm.enabled = inValue.enabled
            this.editValueForm.percentage = inValue.percentage
            this.editValueForm.qty = inValue.qty

            this.$nextTick(() => {
                this.elGet('bulkDiscountQty').focus()
            })
        },
        clickNewBulkDiscount() {
            this.addingBulkDiscount = true

            this.$nextTick(() => {
                this.elGet('inputNewBulkDiscount').focus()
            })
        },
        clickNewValue(inBulkDiscountId) {
            this.addingBulkDiscountId = inBulkDiscountId

            if (!this.isBulkDiscountOpen(inBulkDiscountId)) {
                this.clickToggleShowBulkDiscount(inBulkDiscountId)
            }

            this.$nextTick(() => {
                this.elGet('bulkDiscountQty').focus()
            })
        },
        clickSaveBulkDiscount() {
            if (((this.editingBulkDiscountId && this.editBulkDiscountForm.label) || this.addBulkDiscountForm.label) && this.isBulkDiscountNameUnique()) {
                this.validatingForm = false

                if (this.editingBulkDiscountId) {
                    let myBulkDiscount = this.bulkDiscounts.find((myBDiscount) => myBDiscount.id == this.editingBulkDiscountId)

                    if (myBulkDiscount) {
                        myBulkDiscount.label = this.editBulkDiscountForm.label
                    }
                }
                else {
                    let myId = 0

                    if (this.bulkDiscounts) {
                        this.bulkDiscounts.forEach((myBulkDiscount) => {
                            if (myBulkDiscount.id > myId) {
                                myId = myBulkDiscount.id
                            }
                        })
                    }
                    else {
                        this.bulkDiscounts = []
                    }

                    this.addBulkDiscountForm.id = myId + 1
                    this.bulkDiscounts.push(this.objCopy(this.addBulkDiscountForm))
                }
                
                this.$emit('updateBulkDiscounts', this.bulkDiscounts)
                this.addBulkDiscountForm.id = null
                this.addBulkDiscountForm.label = null
                this.editBulkDiscountForm.label = null
                this.editingBulkDiscountId = null
            }
            else {
                this.validatingForm = true
            }
        },
        clickSaveValue() {
            if (((this.addingBulkDiscountId && this.addValueForm.percentage && this.addValueForm.qty) || (this.editingBulkDiscountId && this.editValueForm.percentage && this.editValueForm.qty)) && this.isValueQtyUnique()) {
                this.validatingForm = false
                let myBulkDiscount = this.bulkDiscounts.find((myBDiscount) => myBDiscount.id == (this.addingBulkDiscountId ?? this.editingBulkDiscountId))
                
                if (myBulkDiscount) {
                    if (this.editingBulkDiscountId && myBulkDiscount.values && myBulkDiscount.values.length) {
                        let myValue = myBulkDiscount.values.find((myBulkDiscountValues) => myBulkDiscountValues.id == this.editingValueId)

                        if (myValue) {
                            myValue.enabled = this.editValueForm.enabled
                            myValue.percentage = this.editValueForm.percentage
                            myValue.qty = this.editValueForm.qty
                        }
                    }
                    else if (this.addingBulkDiscountId) {
                        if (!myBulkDiscount.values) {
                            myBulkDiscount.values = []
                        }

                        let myId = 0

                        myBulkDiscount.values.forEach((myValue) => {
                            if (myValue.id > myId) {
                                myId = myValue.id
                            }
                        })

                        this.addValueForm.id = myId + 1
                        myBulkDiscount.values.push(this.objCopy(this.addValueForm))
                        this.elGet('bulkDiscountQty').focus()
                    }

                    this.$emit('updateBulkDiscounts', this.bulkDiscounts)
                    this.addValueForm.enabled = true
                    this.addValueForm.percentage = null
                    this.addValueForm.qty = null
                    this.addValueForm.id = null
                    this.editValueForm.enabled = null
                    this.editValueForm.percentage = null
                    this.editValueForm.qty = null
                    this.editingBulkDiscountId = null
                    this.editingValueId = null
                }
            }
            else {
                this.validatingForm = true
            }
        },
        clickToggleBulkDiscountEnabled(inBulkDiscountId, inId, inEnabled) {
            let myBulkDiscount = this.bulkDiscounts.find((myDiscount) => myDiscount.id == inBulkDiscountId)
            let myQtyOkay = true

            if (myBulkDiscount) {
                if (!inEnabled) {
                    let myBulkDiscountValue = myBulkDiscount.values.find((myValue) => myValue.id == inId)

                    if (myBulkDiscountValue) {
                        myQtyOkay = !Boolean(myBulkDiscount.values.find((myValue) => myValue.qty == myBulkDiscountValue.qty && myValue.enabled))
                    }
                }

                if (myQtyOkay) {
                    myBulkDiscount.values.find((myValue) => myValue.id == inId).enabled = !inEnabled
                    this.$emit('updateBulkDiscounts', this.bulkDiscounts, true)
                }
                else {
                    this.elGet(`bulk-discount-enabled-check-${inBulkDiscountId}-${inId}`).checked = false
                    alert("Unable to enable this Bulk Discount, there is already an enabled Bulk Discount with this Purchase Quantity.")
                }
            }
        },
        clickToggleShowBulkDiscount(inId) {
            if (!this.bulkDiscountsOpen.includes(inId)) {
                this.bulkDiscountsOpen.push(inId)
            }
            else {
                this.bulkDiscountsOpen = this.bulkDiscountsOpen.filter((myBulkDiscount) => myBulkDiscount != inId)
            }
        },
        isBulkDiscountNameUnique() {
            let myDuplicateError = false
            
            if (this.editingBulkDiscountId) {
                myDuplicateError = Boolean(this.bulkDiscounts.find((myBulkDiscount) => myBulkDiscount.id != this.editingBulkDiscountId && myBulkDiscount.label.toUpperCase() == this.editBulkDiscountForm.label.toUpperCase()))
            }
            else {
                myDuplicateError = Boolean(this.bulkDiscounts.find((myBulkDiscount) => myBulkDiscount.label.toUpperCase() == this.addBulkDiscountForm.label.toUpperCase()))
            }
            
            this.duplicateBulkDiscountNameError = myDuplicateError
            return !myDuplicateError
        },
        isBulkDiscountOpen(inId) {
            return this.bulkDiscountsOpen.includes(inId)
        },
        isValueQtyUnique() {
            let myDuplicateError = false
            let myBulkDiscount = this.bulkDiscounts.find((myBDiscount) => myBDiscount.id == (this.addingBulkDiscountId ?? this.editingBulkDiscountId))
            let myValues = []

            if (myBulkDiscount && myBulkDiscount.values && myBulkDiscount.values.length) {
                myValues = myBulkDiscount.values
            }

            if (this.addingBulkDiscountId && this.addValueForm.enabled) {
                myDuplicateError = Boolean(myValues.find((myBDiscount) => myBDiscount.qty == this.addValueForm.qty && myBDiscount.enabled))
            }
            else if (this.editValueForm.enabled) {
                myDuplicateError = Boolean(myValues.find((myBDiscount) => myBDiscount.id != this.editingValueId && myBDiscount.qty == this.editValueForm.qty && myBDiscount.enabled))
            }
            
            this.duplicateValueQtyError = myDuplicateError
            return !myDuplicateError
        },
        sortedBulkDiscountValues(inBulkDiscountValues) {
            return inBulkDiscountValues.sort((a, b) => a.qty < b.qty ? -1 : 1)
        },
        updateAddBulkDiscountFormValue(inField, inValue) {
            inValue = this.capitalizeWordsFirstLetter(inValue.toLowerCase())
            this.addBulkDiscountForm[inField] = inValue
            this.isBulkDiscountNameUnique()
        },
        updateAddValueFormValue(inField, inValue) {
            this.addValueForm[inField] = inValue

            if (inField == "qty") {
                this.isValueQtyUnique()
            }
        },
        updateEditBulkDiscountFormValue(inField, inValue) {
            inValue = this.capitalizeWordsFirstLetter(inValue.toLowerCase())
            this.editBulkDiscountForm[inField] = inValue
            this.isBulkDiscountNameUnique()
        },
        updateEditValueFormValue(inField, inValue) {
            this.editValueForm[inField] = inValue
            
            if (inField == "qty") {
                this.isValueQtyUnique()
            }
        },
        updateSellerDataFormSelectValue(inField, inValue) {
            this.$emit('updateSellerDataFormSelectValue', inField, inValue)
        }
    }
}
</script>