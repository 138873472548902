<template>
    <modal v-if="showSelectProductsModal" :open="showSelectProductsModal" @close="$emit('closeSelectProductsModal')" class="text-white font-normal">
        <modal-background-image
            :bgImage="defaultHero"
        />
        <div class="absolute top-0 left-0 w-full" :class="{ 'pb-16': windowWidth < desktopMaxWidth, 'desktop-modal-box': windowWidth >= desktopMaxWidth }">
            <div class="relative" :class="{ 'desktop-modal': windowWidth >= desktopMaxWidth }">
                <div class="w-full mt-4 px-1">
                    <h3 class="section-heading mb-3 text-center">
                        Select Products
                    </h3>
                    <div @click="clickAddProducts()" class="mt-4" :class=" { 'standard-button-base': selectedProducts.length, 'disabled-button-base': !selectedProducts.length } ">
                        Add selected products
                    </div>
                </div>
                <div class="my-4 relative">
                    <input type="text" placeholder="Text search" v-model="textSearch" class="form-input edit-field-text-color bg-white py-1 w-full pr-8" />
                    <div @click="clickSearch()" class="absolute top-0 right-0 bg-orange-500 rounded-r-md text-white w-8 flex items-center justify-center cursor-pointer" style="height: 34px;">
                        <fa-icon icon="magnifying-glass" type="fas" class="h-5 w-5" />
                    </div>
                </div>
                <div v-if="searchListings" class="w-full mt-4">
                    <template v-for="(listing, index) in Object.values(searchListings)" :key="index">
                        <div v-if="isListingOkay(listing)" @click="changeListingSelected(listing.id)">
                            <product-card
                                :product="listing" :showDelete="false" :selected="isSelected(listing.id)"
                            />
                        </div>
                    </template>
                </div>
                <div v-else-if="!gettingMore" class="w-full mt-4 text-center">
                    No Listings Found
                </div>
                <div v-else class="w-full mt-4 text-center flex items-center justify-center">
                    <fa-icon icon="spinner" type="fas" class="spin w-10 h-10 text-white" />
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/shared/Modal'
import ModalBackgroundImage from '@/shared/ModalBackgroundImage'
import ProductCard from '@/shared/Cards/ProductCard'

export default {
    props: [
        'productIds',
        'showSelectProductsModal'
    ],
    components: {
        Modal,
        ModalBackgroundImage,
        ProductCard
    },
    data() {
        return {
            gettingMore: false,
            lastPage: false,
            listingsPage: 1,
            searchListings: null,
            selectedProducts: [],
            textSearch: null
        }
    },
    mounted() {
        this.getListings()
    },
    methods: {
        changeListingSelected(inListingId, inFromLabel) {
            if (this.selectedProducts.includes(inListingId)) {
                this.selectedProducts = this.selectedProducts
                    .filter((product) => product != inListingId)
            }
            else {
                this.selectedProducts.push(inListingId)
            }
        },
        clickAddProducts() {
            if (this.selectedProducts.length && confirm('You wish to add these products?')) {
                this.$emit('addProducts', this.selectedProducts, this.objCopy(this.searchListings))
            }
        },
        clickSearch() {
            this.gettingMore = true
            this.searchListings = null
            this.lastPage = false
            this.listingsPage = 1
            this.getListings()
        },
        getListings() {
            if (!this.lastPage) {
                this.gettingMore = true

                buildzAxios?.get(this.getRoute('products'), {
                        params: {
                            page: this.listingsPage,
                            searchText: this.textSearch
                        }
                    })
                    .then((response) => {
                        if (response.status == 200 && response.data && response.data.products && response.data.products.length) {
                            let myVendorProducts = this.searchListings ?? {}
                            myVendorProducts = Object.values(myVendorProducts)
                            
                            let myNew = Object.values(response.data.products)
                            let listings = this.objCopy(myVendorProducts.concat(myNew))
                            this.searchListings = listings
                        }
                        else if (response.status == 200 && response.data && (!response.data.products || !response.data.products.length)) {
                            this.lastPage = true
                        }

                        this.gettingMore = false
                    })
                    .catch((error) => {
                        console.log(error)
                        this.gettingMore = false
                    })
            }
        },
        isListingOkay(inListing) {
            let qtyOk = inListing.qty > 0 || !inListing.track_quantity
            let disabledOk = !inListing.disabled
            let shopifyOk = inListing.shopify_status == 'active'
            let archivedOk = !inListing.archived
            
            return qtyOk && disabledOk && (!this.shopifyStore || shopifyOk) && archivedOk
        },
        isSelected(inListingId) {
            return this.selectedProducts.includes(inListingId)
        }
    }
}
</script>