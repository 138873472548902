<template>
    <draggable
        animation="250" easing="cubic-bezier(1, 0, 0, 1)" handle=".handle-value" item-key="id" :list="modifier.values" tag="div" 
        @change="saveOrder()"
    >
        <template #item="{element, index}">
            <div class="w-full">
                <table-record v-if="editingValueId != element.id || editingModifierId != modifier.id">
                    <template #record>
                        <div class="flex items-center justify-start w-full pl-2">
                            <child-line-prefix />
                            <div>
                                {{ element.label }}
                            </div>
                        </div>
                        <div v-if="showIcons" class="wizard-icon-container">
                            <div class="cursor-move handle-value">
                                <fa-icon icon="up-down-left-right" type="fas" class="h-5 w-5" />
                            </div>
                            <div @click="clickEditValue(element.id, modifier.id, element.label)" class="cursor-pointer ml-2">
                                <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                            </div>
                            <div @click="clickDeleteValue(element.id, modifier.id, element.label)" class="cursor-pointer ml-2">
                                <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                            </div>
                        </div>
                    </template>
                </table-record>
                <edit-table-record v-if="editingValueId == element.id && editingModifierId == modifier.id"
                    :duplicateValueError="duplicateValueNameError" fieldFormName="label" fieldId="inputEditValue" fieldType="text" :fieldValue="editValueForm.label"
                    :validatingForm="validatingForm"
                    @clickCancel="clickCancelEditValue" @clickSave="clickSaveValue" @updateFieldValue="updateEditValueFormValue"
                />
            </div>
        </template>
    </draggable>
</template>

<script>
import draggable from 'vuedraggable'
import EditTableRecord from '@/wizards/components/EditTableRecord'
import TableRecord from '@/wizards/components/TableRecord'

export default {
    props: [
        'duplicateValueNameError',
        'editingModifierId',
        'editValueForm',
        'editingValueId',
        'modifier',
        'showIcons',
        'validatingForm'
    ],
    components: {
        draggable,
        EditTableRecord,
        TableRecord
    },
    methods: {
        clickCancelEditValue() {
            this.$emit('clickCancelEditValue')
        },
        clickDeleteValue(inId, inModifierId, inValueLabel) {
            this.$emit('clickDeleteValue', inId, inModifierId, inValueLabel)
        },
        clickEditValue(inId, inModifierId, inValueLabel) {
            this.$emit('clickEditValue', inId, inModifierId, inValueLabel)
        },
        clickSaveValue() {
            this.$emit('clickSaveValue')
        },
        saveOrder() {
            this.$emit('saveOrder')
        },
        updateEditValueFormValue(inField, inValue) {
            this.$emit('updateEditValueFormValue', inField, inValue)
        }
    }
}
</script>