<template>
    <wizard-popup-container v-if="formLoaded"
       :headingText="headingText" panelWidth="800px"
       @closeWizardPopup="clickCloseFeaturesBlockPopup"
    >
        <template #main>
            <div class="w-full">
                <div v-if="currentSlide == 1" class="w-full p-1">
                    <feature-list
                        :featureList="featureList"
                        @deleteFeatureListRecord="deleteFeatureListRecord" @editFeatureListRecord="editFeatureListRecord"
                    />
                    <div @click="clickNewFeatureListRecord()" class="w-full" :class="{ 'mt-2': featureList && featureList.length }">
                        <div class="secondary-button-base">
                            Add Feature List Segment
                        </div>
                    </div>
                </div>
                <div v-if="currentSlide == 2" class="w-full">
                    <rich-text-panel 
                        fieldFormName="textOne" :fieldRequired="true" :fieldValue="editingRecord.textOne" :forceMobile="true" :hideLabel="true"
                        @fieldInput="updateFormValue"
                    >
                        <validation-message v-if="validating && editingRecord.textOne" 
                            message="Heading must be added"
                        />
                    </rich-text-panel>
                </div>
                <div v-if="currentSlide == 3" class="w-full">
                    <div class="text-sm">
                        When you paste a URL or click the URL preview icon the URL will be checked. If it is valid and meta data is found the List Segment Description and Image will be updated from the result if they are currently empty.
                    </div>
                    <div class="flex items-center w-full">
                        <input-panel 
                            fieldFormName="url" :fieldValue="editingRecord.url" :forceMobile="true" :hideLabel="true"
                            @fieldInput="updateFormValue" @fieldPaste="getUrlPreview"
                        />
                        <div v-if="editingRecord.url" @click="getUrlPreview(editingRecord.url)" class="cursor-pointer mr-2">
                            <fa-icon icon="globe" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                    <div v-if="checkingURL" class="absolute left-0 top-0 w-full h-full flex items-center justify-center text-white" style="background: rgba(0, 0, 0, 0.80);">
                        <div>
                            <fa-icon icon="spinner" type="fas" class="spin h-6 w-6" />
                        </div>
                        <div class="text-base font-bold ml-2">
                            Checking URL
                        </div>
                    </div>
                    <div v-if="preview && preview.title" class="orange-transparent rounded-md p-1">
                        <div class="font-semibold">
                            URL Preview
                        </div>
                        <div>
                            {{ preview.title.slice(0,50) }}
                        </div>
                        <div v-if="preview && preview.images && preview.images.length" class="w-full flex justify-center">
                            <img :src="preview.images[0]" class="h-16" />
                        </div>
                    </div>
                </div>
                <div v-if="currentSlide == 4" class="w-full">
                    <rich-text-panel 
                        fieldFormName="textTwo" :fieldRequired="true" :fieldValue="editingRecord.textTwo" :forceMobile="true" :hideLabel="true"
                        @fieldInput="updateFormValue"
                    >
                        <validation-message v-if="validating && editingRecord.textTwo" 
                            message="Description must be added"
                        />
                    </rich-text-panel>
                </div>
                <div v-if="currentSlide == 5" class="w-full text-white">
                    <media-panel 
                        :hideLabel="true" :hideSpinner="true" :imagesArray="imagesArray" :imagesOnly="true" :maxFiles="1" :multiple="false"
                        @saveMedia="saveLocalMedia" @updateOfflineMedia="updateOfflineMedia"
                    />
                </div>
                <div v-if="currentSlide == 6" class="w-full">
                    <div class="flex items-center justify-start mb-px white-transparent relative">
                        <div class="w-20 mr-1" v-if="editingRecord.gallery && editingRecord.gallery.length">
                            <img :src="editingRecordPreviewSource" class="h-16" />
                        </div>
                        <div class="text-left pr-6">
                            <div v-html="editingRecord.textOne" class="font-semibold">
                            </div>
                            <div v-html="editingRecord.textTwo">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="w-full flex items-center bg-gray-100 border-t border-gray-800 py-2 h-12 rounded-b-md">
                <div class="w-1/2">
                    <div v-if="!hidePrevious" @click="previousSlide()" class="secondary-button-base mx-2">
                        Back
                    </div>
                    <div v-if="!hideClose && currentSlide == 1" @click="clickCloseFeaturesBlockPopup()" class="secondary-button-base mx-2">
                        Close Without Saving
                    </div>
                    <div v-if="!hideClose && currentSlide != 1" @click="clickCloseFeatureListRecord()" class="secondary-button-base mx-2">
                        Cancel
                    </div>
                </div>
                <div class="w-1/2">
                    <div v-if="!hideNext" @click="nextSlide()" class="standard-button-base mx-2">
                        Continue
                    </div>
                    <div v-if="!hideSave && currentSlide == 1" @click="clickSaveFeaturesBlock()" class="standard-button-base mx-2">
                        Yes, Save Features Block
                    </div>
                    <div v-if="!hideSave && currentSlide != 1" @click="clickSaveFeatureListRecord()" class="standard-button-base mx-2">
                        Yes, Save Feature Segment
                    </div>
                </div>
            </div>
        </template>
    </wizard-popup-container>
</template>

<script>
import draggable from 'vuedraggable'
import FeatureList from '../components/features-block-popup/FeatureList'

export default {
    props: [
        'editingBlock',
    ],
    components: {
        draggable,
        FeatureList
    },
    data() {
        return {
            checkingURL: false,
            currentSlide: 1,
            editingRecord: null,
            featureList: [],
            featureListId: 1,
            featureListNextRecordId: 0,
            formLoaded: false,
            originalList: [],
            preview: null,
            validating: false
        }
    },
    computed: { 
        editingRecordPreviewSource() {
            if (this.editingRecord.gallery[0].url) {
                return this.editingRecord.gallery[0].url
            }
            else {
                return URL.createObjectURL(this.editingRecord.gallery[0])
            }
        },
        headingText() {
            return this.slides[this.currentSlide].heading
        },
        hideClose() {
            return !this.slides[this.currentSlide].close
        },
        hideNext() {
            return !this.slides[this.currentSlide].next
        },
        hidePrevious() {
            return !this.slides[this.currentSlide].previous
        },
        hideSave() {
            return !this.slides[this.currentSlide].save
        },
        imagesArray() {
            if (!this?.editingRecord?.gallery.length) {
                return []
            }

            let myGallery = []

            this.editingRecord.gallery.forEach((myImage) => {
                myGallery.push(myImage)
            })

            return myGallery
        },
        slides() {
            return {
                1: {
                    close: true,
                    heading: "Feature List Segments",
                    next: false,
                    previous: false,
                    save: true,
                    validation: true
                },
                2: {
                    close: true,
                    heading: "Heading",
                    next: 3,
                    previous: false,
                    save: false,
                    validation: Boolean(this.editingRecord) && Boolean(this.editingRecord.textOne)
                },
                3: {
                    close: false,
                    heading: "URL",
                    next: 4,
                    previous: 2,
                    save: false,
                    validation: true
                },
                4: {
                    close: false,
                    heading: "Description",
                    next: 5,
                    previous: 3,
                    save: false,
                    validation: Boolean(this.editingRecord) && Boolean(this.editingRecord.textTwo)
                },
                5: {
                    close: false,
                    heading: "Image",
                    next: 6,
                    previous: 4,
                    save: false,
                    validation: true
                },
                6: {
                    close: false,
                    heading: "Is This Correct?",
                    next: false,
                    previous: 5,
                    save: true,
                    validation: true
                }
            }
        }
    },
    mounted() {
        if (this.editingBlock) {
            this.featureList = this.objCopy(this.editingBlock.list)

            this.featureList.forEach((myRecord) => {
                if (myRecord.image) {
                    myRecord.gallery = null
                    myRecord.gallery = [myRecord.image]
                }
            })

            this.originalList = this.objCopy(this.featureList)
        }
        
        if (!this.featureList || !this.featureList.length) {
            this.clickNewFeatureListRecord()
        }
        
        this.formLoaded = true
    },
    methods: {
        clickCloseFeaturesBlockPopup() {
            if (JSON.stringify(this.originalList) == JSON.stringify(this.featureList) || confirm("Confirm you wish to close without saving your changes?")) {
                this.$emit('clickCloseFeaturesBlockPopup')
            }
        },
        clickCloseFeatureListRecord() {
            this.editingRecord = null
            this.currentSlide = 1
        },
        clickNewFeatureListRecord() {
            this.editingRecord = {
                gallery: [],
                image: null,
                id: null,
                textOne: null,
                textTwo: null,
                url: null
            }

            this.currentSlide = 2
        },
        clickSaveFeatureListRecord() {
            if (this.editingRecord.id) {
                let myRecord = this.featureList.find((myRecord) => myRecord.id == this.editingRecord.id)
                myRecord.gallery = this.editingRecord.gallery
                myRecord.textOne = this.editingRecord.textOne
                myRecord.textTwo = this.editingRecord.textTwo
                myRecord.url = this.editingRecord.url
            }
            else {
                let myRecordId = 0

                this.featureList.forEach((myRecord) => {
                    if (myRecord.id > myRecordId) {
                        myRecordId = myRecord.id
                    }
                })

                this.editingRecord.id = myRecordId + 1
                this.featureList.push(this.editingRecord)
            }

            this.clickCloseFeatureListRecord()
        },
        clickSaveFeaturesBlock() {
            if (this.featureList && this.featureList.length) {
                this.$emit('clickSaveFeaturesBlock', this.featureList)
            }
        },
        deleteFeatureListRecord(inRecordId) {
            this.featureList = this.featureList.filter((myRecord) => myRecord.id != inRecordId)
        },
        editFeatureListRecord(inRecordId) {
            this.editingRecord = this.objCopy(this.featureList.find((myRecord) => myRecord.id == inRecordId))

            if (!this.editingRecord.gallery) {
                this.editingRecord.gallery = []
            }

            this.currentSlide = 2
        },
        getUrlPreview(inText) {
            if (this.isValidURL(inText)) {
                this.checkingURL = true
                this.preview = null
                
                buildzAxios?.get(this.getRoute('modSearch'), {
                    params: {
                        'type': 'meta',
                        'url': inText
                    }
                })
                .then((response) => {
                    if (!this.preview && response.data.title) {
                        this.preview = response.data

                        if (this?.preview?.title && !this.editingRecord.textTwo) {
                            this.editingRecord.textTwo = this?.preview?.title ? this?.preview?.title.slice(0,50) : null
                        }

                        this.editingRecord.url = inText
                        let myGallery = this.preview.images

                        if (myGallery && myGallery.length && !this.editingRecord.gallery.length) {
                            let myImage = {
                                url: myGallery[0],
                                type: "image",
                                identifier: this.getRandomString(20)
                            }
                            
                            this.editingRecord.gallery = [myImage]
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)
                    alert("Unable to reach URL")
                })
                .then(() => {
                    this.checkingURL = false
                })
            }
        },
        nextSlide() {
            if (this.slides[this.currentSlide].validation) {
                this.validating = false
                this.currentSlide = this.slides[this.currentSlide].next
            }
            else {
                this.validating = true
            }
        },
        previousSlide() {
            this.currentSlide = this.slides[this.currentSlide].previous
        },
        saveListOrder() {

        },
        saveLocalMedia(inGallery) {
            this.editingRecord.gallery = inGallery
        },
        updateFeatureListRecord(inRecord) {
            let myRecord = this.featureList.find((myRecord) => myRecord.id == inRecord.id)
            myRecord.gallery = inRecord.gallery
            myRecord.textOne =  inRecord.textOne
            myRecord.textTwo =  inRecord.textTwo
            myRecord.url = inRecord.url
        },
        updateFormValue(inField, inValue) {
            this.editingRecord[inField] = inValue
        },
        updateOfflineMedia(inGallery) {
            this.editingRecord.gallery = inGallery
        }
    }
}
</script>