<template>
    <wizard-popup-container v-if="formLoaded"
       :headingText="headingText" panelWidth="800px"
       @closeWizardPopup="clickCloseYouTubeBlockPopup"
    >
        <template #main>
            <div class="w-full">
                <div v-if="currentSlide == 1" class="w-full">
                    <input-panel 
                        fieldFormName="url" :fieldRequired="true" :fieldValue="form.url" :forceMobile="true" :hideLabel="true"
                        @fieldInput="updateFormValue"
                    >
                        <validation-message v-if="validating && form.url == null" 
                            message="Video URL must be entered"
                        />
                    </input-panel>
                </div>
                <div v-if="currentSlide == 2" class="w-full">
                    <select-panel 
                        :fieldData="orientationData" fieldFormName="orientation" :fieldRequired="true" :fieldValue="form.orientation" 
                        :forceMobile="true" :hideLabel="true" :isReverseLabelAndValue="true"
                        @fieldChange="updateFormValue"
                    >
                        <validation-message v-if="validating && form.orientation == null" 
                            message="Orientation must be selected"
                        />
                    </select-panel>
                </div>
                <div v-if="currentSlide == 3" class="w-full">
                    <select-panel 
                        :fieldData="yesNoData" fieldFormName="autoplay" :fieldRequired="true" :fieldValue="form.autoplay" 
                        :forceMobile="true" :hideLabel="true" :isLabelAndValue="true"
                        @fieldChange="updateFormValue"
                    >
                        <validation-message v-if="validating && form.autoplay == null" 
                            message="Autoplay must be selected"
                        />
                    </select-panel>
                </div>
                <div v-if="currentSlide == 4" class="w-full">
                    <select-panel 
                        :fieldData="yesNoData" fieldFormName="loop" :fieldRequired="true" :fieldValue="form.loop" 
                        :forceMobile="true" :hideLabel="true" :isLabelAndValue="true"
                        @fieldChange="updateFormValue"
                    >
                        <validation-message v-if="validating && form.loop == null" 
                            message="Loop must be selected"
                        />
                    </select-panel>
                </div>
                <div v-if="currentSlide == 5" class="w-full p-1">
                    <div class="w-full" v-html="youtubeLinks(form.url, form.autoplay, form.loop, form.orientation)">
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="w-full flex items-center bg-gray-100 border-t border-gray-800 py-2 h-12 rounded-b-md">
                <div class="w-1/2">
                    <div v-if="!hidePrevious" @click="previousSlide()" class="secondary-button-base mx-2">
                        Back
                    </div>
                    <div v-if="!hideClose" @click="clickCloseYouTubeBlockPopup()" class="secondary-button-base mx-2">
                        Close Without Saving
                    </div>
                </div>
                <div class="w-1/2">
                    <div v-if="!hideNext" @click="nextSlide()" class="standard-button-base mx-2">
                        Continue
                    </div>
                    <div v-if="!hideSave" @click="clickSaveYouTubeBlock()" class="standard-button-base mx-2">
                        Yes, Save YouTube Block
                    </div>
                </div>
            </div>
        </template>
    </wizard-popup-container>
</template>

<script>
export default {
    props: [
        'editingBlock'
    ],
    data() {
        return {
            currentSlide: 1,
            form: {
                autoplay: null,
                loop: null,
                orientation: null,
                url: null
            },
            formLoaded: false,
            orientationData: {
                landscape: "Landscape",
                portrait: "Portrait"
            },
            originalForm: null,
            validating: false
        }
    },
    computed: { 
        headingText() {
            return this.slides[this.currentSlide].heading
        },
        hideClose() {
            return !this.slides[this.currentSlide].close
        },
        hideNext() {
            return !this.slides[this.currentSlide].next
        },
        hidePrevious() {
            return !this.slides[this.currentSlide].previous
        },
        hideSave() {
            return !this.slides[this.currentSlide].save
        },
        slides() {
            return {
                1: {
                    close: true,
                    heading: "Type Or Paste Video URL",
                    next: 2,
                    previous: false,
                    save: false,
                    validation: Boolean(this.form.url)
                },
                2: {
                    close: false,
                    heading: "Is This a Landscape Or Portrait Video?",
                    next: 3,
                    previous: 1,
                    save: false,
                    validation: Boolean(this.form.orientation)
                },
                3: {
                    close: false,
                    heading: "Do You Want This Video To Autoplay?",
                    next: 4,
                    previous: 2,
                    save: false,
                    validation: Boolean(this.form.autoplay)
                },
                4: {
                    close: false,
                    heading: "Do You Want This Video To Loop?",
                    next: 5,
                    previous: 3,
                    save: false,
                    validation: Boolean(this.form.loop)
                },
                5: {
                    close: false,
                    heading: "Is This Correct?",
                    next: false,
                    previous: 4,
                    save: true,
                    validation: true
                }
            }
        },
    },
    mounted() {
        if (this.editingBlock) {
            this.form.autoplay = this.editingBlock.autoplay
            this.form.loop = this.editingBlock.loop
            this.form.orientation = this.editingBlock.orientation
            this.form.url = this.editingBlock.url
        }

        this.originalForm = this.objCopy(this.form)
        this.formLoaded = true
    },
    methods: {
        clickCloseYouTubeBlockPopup() {
            if (JSON.stringify(this.originalForm) == JSON.stringify(this.form) || confirm("Confirm you wish to close without saving your changes?")) {
                this.$emit('clickCloseYouTubeBlockPopup')
            }
        },
        clickSaveYouTubeBlock() {
            this.$emit('clickSaveYouTubeBlock', this.form)
        },
        nextSlide() {
            if (this.slides[this.currentSlide].validation) {
                this.validating = false
                this.currentSlide = this.slides[this.currentSlide].next
            }
            else {
                this.validating = true
            }
        },
        previousSlide() {
            this.currentSlide = this.slides[this.currentSlide].previous
        },
        updateFormValue(inField, inValue) {
            this.form[inField] = inValue
        }
    }
}
</script>