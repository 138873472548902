<template>
    <input-panel 
        fieldFormName="title" fieldLabel="Product Name" fieldLabelClasses="text-gray-800" fieldPlaceholder="Product Name" :fieldRequired="true" fieldType="text" 
        :fieldValue="productForm.title" :forceMobile="true"
        @fieldInput="updateProductFormValue"
    >
        <validation-message v-if="validating && !productForm.title" 
            message="Product Name must be entered"
        />
    </input-panel>
</template>

<script>
export default {
    props: [
        'productForm',
        'validating'
    ],
    methods: {
        updateProductFormValue(inField, inValue) {
            this.$emit('updateProductFormValue', inField, inValue)
        }
    }
}
</script>