<template>
    <div class="w-full">
        <div class="w-full text-sm">
            <wizard-table-container
                headingText="Product Categories" :showNew="showIcons && sortedCategories && vendorProductCategories && sortedCategories.length != vendorProductCategories.length"
                @clickNewRecord="clickNewProductCategory"
            >
                <template #records>
                    <edit-table-record v-if="addingCategory"
                        :fieldData="sortedSellerVendorProductCategories" fieldDataLabel="name" fieldDataValue="id" fieldFormName="id" fieldType="select" 
                        :fieldValue="addCategoryForm.id" fieldValueErrorMessage="Product Category must be selected" :validatingForm="validatingForm"
                        @clickCancel="clickCancelNewCategory" @clickSave="clickSaveNewCategory" @updateFieldValue="updateAddCategoryFormValue"
                    />
                    <div v-for="category in sortedCategories" class="w-full">
                        <table-record>
                            <template #record>
                                <div @click="clickToggleShowCategory(category.id)" class="pl-2">
                                    {{ category.name }}
                                </div>
                                <div v-if="showIcons" class="wizard-icon-container">
                                    <div @click="clickToggleShowCategory(category.id)" v-show="!isCategoryOpen(category.id)" class="cursor-pointer">
                                        <fa-icon icon="chevron-left" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div @click="clickToggleShowCategory(category.id)" v-show="isCategoryOpen(category.id)" class="cursor-pointer">
                                        <fa-icon icon="chevron-down" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div @click="clickNewGroup(category.id)" class="cursor-pointer ml-2">
                                        <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div @click="clickDeleteProductCategory(category.id, category.name)" class="cursor-pointer ml-2">
                                        <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                    </div>
                                </div>
                            </template>
                            <template #extra>
                                <div v-if="isCategoryOpen(category.id)" class="w-full">
                                    <wizard-table-container
                                        :childTable="true" :hideHeader="true" :showNew="false"
                                    >
                                        <template #records>
                                            <edit-table-record v-if="addingCategoryId && addingCategoryId == category.id"
                                                :duplicateValueError="duplicateGroupNameError" fieldFormName="name" fieldId="inputNewGroup" fieldType="text" :fieldValue="addGroupForm.name"
                                                :validatingForm="validatingForm"
                                                @clickCancel="clickCancelNewGroup" @clickSave="clickSaveGroup" @updateFieldValue="updateAddGroupFormValue"
                                            />
                                            <div v-for="productGroup in sortedGroups(category.id)">
                                                <table-record v-if="!editingGroupId || editingCategoryId != category.id || editingGroupId != productGroup.id"
                                                    :recordClasses="productGroup.id > 99999 ? 'orange-transparent' : ''"
                                                >
                                                    <template #record>
                                                        <div class="flex items-center justify-start w-full pl-2">
                                                            <child-line-prefix />
                                                            <div>
                                                                {{ productGroup.name }}
                                                            </div>
                                                        </div>
                                                        <div v-if="showIcons && productGroup.id > 99999 && !editingGroupId" class="wizard-icon-container">
                                                            <div @click="clickEditGroup(productGroup.id, category.id, productGroup.name)" class="cursor-pointer">
                                                                <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                                                            </div>
                                                            <div @click="clickDeleteGroup(productGroup.id, productGroup.name, category.id)" class="cursor-pointer">
                                                                <fa-icon icon="trash-can" type="fas" class="h-5 w-5 ml-2" />
                                                            </div>
                                                        </div>
                                                    </template>
                                                </table-record>
                                                <edit-table-record v-if="editingCategoryId == category.id && editingGroupId == productGroup.id"
                                                    :duplicateValueError="duplicateGroupNameError" extraClasses="orange-transparent" fieldFormName="name" fieldId="inputEditGroup" fieldType="text" 
                                                    :fieldValue="editGroupForm.name" tableRecordClasses="pt-3 orange-transparent" :validatingForm="validatingForm"
                                                    @clickCancel="clickCancelEditGroup" @clickSave="clickSaveGroup" @updateFieldValue="updateEditGroupFormValue"
                                                />
                                            </div>
                                        </template>
                                    </wizard-table-container>
                                </div>
                            </template>
                        </table-record>
                    </div>
                </template>
            </wizard-table-container>
            <validation-message v-if="validating && !sortedCategories.length" 
                message="You must create at least one Product Category"
            />
        </div>
    </div>
</template>

<script>
import EditTableRecord from '@/wizards/components/EditTableRecord'
import TableRecord from '@/wizards/components/TableRecord'

export default {
    props: [
        'sellerForm',
        'validating'
    ],
    components: {
        EditTableRecord,
        TableRecord
    },
    data() {
        return {
            addingCategory: false,
            addingCategoryId: null,
            addCategoryForm: {
                groups: [],
                id: null
            },
            addGroupForm: {
                id: null,
                name: null
            },
            duplicateGroupNameError: false,
            editGroupForm: {
                name: null
            },
            editingCategoryId: null,
            editingGroupId: null,
            formLoaded: false,
            productCategories: null,
            productCategoriesOpen: [],
            validatingForm: false
        }
    },
    computed: {
        showIcons() {
            return !this.addingCategory && !this.addingCategoryId && !this.editingCategoryId
        },
        sortedCategories() {
            if (!this.productCategories) {
                return []
            }

            if (!this.vendorProductCategories) {
                return []
            }

            return Object.values(this.vendorProductCategories)
                .filter((myCategory) => this.productCategories.find((myProductCategory) => myProductCategory.id == myCategory.id))
                .sort((a, b) => a.name < b.name ? -1 : 1)
        },
        sortedSellerVendorProductCategories() {
            if (!this.vendorProductCategories) {
                return []
            }

            return Object.values(this.vendorProductCategories)
                .filter((myCategory) => !this.productCategories.find((myProductCategory) => myProductCategory.id == myCategory.id))
                .sort((a, b) => a.name < b.name ? -1 : 1)
        }
    },
    mounted() {
        this.productCategories = this.objCopy(this.sellerForm.product_categories)
        this.formLoaded = true
    },
    methods: {
        clickCancelEditGroup() {
            this.editGroupForm.name = null
            this.editingGroupId = null
            this.editingCategoryId = null
            this.validatingForm = false
        },
        clickCancelNewCategory() {
            this.addCategoryForm.id = null
            this.addingCategory = false
            this.validatingForm = false
        },
        clickCancelNewGroup() {
            this.addGroupForm.name = null
            this.addGroupForm.id = null
            this.addingCategoryId = null
            this.validatingForm = false
        },
        clickDeleteProductCategory(inId, inName) {
            if (confirm(`Confirm you wish to delete ${inName}?`)) {
                this.productCategories = this.productCategories.filter((myCategory) => myCategory.id != inId)
                this.$emit('updateProductCategories', this.productCategories)
            }
        },
        clickDeleteGroup(inId, inName, inCategoryId) {
            if (confirm(`Confirm you wish to delete ${inName}?`)) {
                let myCategory = this.productCategories.find((myCategory) => myCategory.id == inCategoryId)

                if (myCategory) {
                    myCategory.groups = myCategory.groups.filter((myGroup) => myGroup.id != inId)
                    this.$emit('updateProductCategories', this.productCategories)
                }
            }
        },
        clickEditGroup(inGroupId, inCategoryId, inName) {
            this.editingCategoryId = inCategoryId
            this.editingGroupId = inGroupId
            this.editGroupForm.name = inName

            this.$nextTick(() => {
                this.elGet('inputEditGroup').focus()
            })
        },
        clickNewProductCategory() {
            this.addingCategory = true
        },
        clickNewGroup(inCategoryId) {
            this.addingCategoryId = inCategoryId

            if (!this.isCategoryOpen(inCategoryId)) {
                this.clickToggleShowCategory(inCategoryId)
            }

            this.$nextTick(() => {
                this.elGet('inputNewGroup').focus()
            })
        },
        clickSaveGroup() {
            if (((this.addingCategoryId && this.addGroupForm.name) || (this.editingCategoryId && this.editGroupForm.name)) && this.isGroupNameUnique()) {
                this.validatingForm = false
                let myCategory = this.productCategories.find((myPCategory) => myPCategory.id == (this.addingCategoryId ?? this.editingCategoryId))
                
                if (myCategory) {
                    if (this.editingCategoryId && myCategory.groups && myCategory.groups.length) {
                        let myGroup = myCategory.groups.find((myPGroup) => myPGroup.id == this.editingGroupId)

                        if (myGroup) {
                            myGroup.name = this.editGroupForm.name
                        }
                    }
                    else if (this.addingCategoryId) {
                        if (!myCategory.groups) {
                            myCategory.groups = []
                        }

                        let myId = 99999

                        myCategory.groups.forEach((myGroup) => {
                            if (myGroup.id > myId) {
                                myId = myGroup.id
                            }
                        })

                        this.addGroupForm.id = myId + 1
                        myCategory.groups.push(this.objCopy(this.addGroupForm))
                    }
                }

                this.$emit('updateProductCategories', this.productCategories)
                this.editGroupForm.name = null
                this.editingGroupId = null
                this.editingCategoryId = null
                this.addGroupForm.name = null
                this.addGroupForm.id = null
            }
            else {
                this.validatingForm = true
            }
        },
        clickSaveNewCategory() {
            if (this.addCategoryForm.id) {
                this.validatingForm = false
                this.productCategories.push(this.objCopy(this.addCategoryForm))
                this.$emit('updateProductCategories', this.productCategories)
                this.addCategoryForm.id = null
            }
            else {
                this.validatingForm = true
            }
        },
        clickToggleShowCategory(inId) {
            if (!this.productCategoriesOpen.includes(inId)) {
                this.productCategoriesOpen.push(inId)
            }
            else {
                this.productCategoriesOpen = this.productCategoriesOpen.filter((myCategory) => myCategory != inId)
            }
        },
        isCategoryOpen(inId) {
            return this.productCategoriesOpen.includes(inId)
        },
        isGroupNameUnique() {
            let myDuplicateError = false
            let myCategory = this.productCategories.find((myPCategory) => myPCategory.id == (this.addingCategoryId ?? this.editingCategoryId))
            let myGroups = []

            if (myCategory && myCategory.groups && myCategory.groups.length) {
                myGroups = myCategory.groups
            }

            if (this.addingCategoryId) {
                myDuplicateError = Boolean([...this.vendorProductGroupsData(this.addingCategoryId), ...myGroups].find((myGroup) => myGroup.name.toUpperCase() == this.addGroupForm.name.toUpperCase()))
            }
            else {
                myDuplicateError = Boolean([...this.vendorProductGroupsData(this.editingCategoryId), ...myGroups].find((myGroup) => myGroup.id != this.editingGroupId && myGroup.name.toUpperCase() == this.editGroupForm.name.toUpperCase()))
            }
            
            this.duplicateGroupNameError = myDuplicateError
            return !myDuplicateError
        },
        sortedGroups(inCategoryId) {
            let myCategory = this.productCategories.find((myProdCategory) => myProdCategory.id == inCategoryId)
            let myGroups = []

            if (myCategory && myCategory.groups && myCategory.groups.length) {
                myGroups = myCategory.groups
            }

            return [...this.vendorProductGroupsData(inCategoryId), ...myGroups]
                .sort((a, b) => a.name < b.name ? -1 : 1)
        },
        updateAddCategoryFormValue(inField, inValue) {
            this.addCategoryForm[inField] = inValue
        },
        updateAddGroupFormValue(inField, inValue) {
            inValue = this.capitalizeWordsFirstLetter(inValue.toLowerCase())
            this.addGroupForm[inField] = inValue
            this.isGroupNameUnique()
        },
        updateEditGroupFormValue(inField, inValue) {
            inValue = this.capitalizeWordsFirstLetter(inValue.toLowerCase())
            this.editGroupForm[inField] = inValue
            this.isGroupNameUnique()
        },
        vendorProductGroupsData(inCategoryId) {
            return Object.values(this.vendorProductGroups)
                .filter((myGroup) => myGroup.product_category_id == inCategoryId)
        }
    }
}
</script>