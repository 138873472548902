<template>
    <div class="w-full flex items-center bg-gray-100 border-t border-gray-800 py-2 h-12 rounded-b-md">
        <div class="w-1/2">
            <div v-if="showPrevious" @click="previousSlide()" class="secondary-button-base mx-2">
                Back
            </div>
            <div v-if="showClose" @click="clickClose()" class="secondary-button-base mx-2">
                Close Without Saving
            </div>
            <div v-if="showCloseSecond" @click="clickCloseSecond()" class="secondary-button-base mx-2">
                Cancel Changes
            </div>
        </div>
        <div class="w-1/2">
            <div v-if="showNext" @click="nextSlide()" class="standard-button-base mx-2">
                Continue
            </div>
            <div v-if="showSave" @click="clickSave()" class="standard-button-base mx-2">
                {{ saveButtonText }}
            </div>
            <div v-if="showSaveSecond" @click="clickSaveSecond()" class="standard-button-base mx-2">
                {{ secondSaveText }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'saveText',
        'secondSaveText',
        'showClose',
        'showCloseSecond',
        'showNext',
        'showPrevious',
        'showSave',
        'showSaveSecond'
    ],
    computed: {
        saveButtonText() {
            return this.saveText ?? "Save And Close"
        }
    },
    methods: {
        clickClose() {
            this.$emit('clickClose')
        },
        clickCloseSecond() {
            this.$emit('clickCloseSecond')
        },
        clickSave() {
            this.$emit('clickSave')
        },
        clickSaveSecond() {
            this.$emit('clickSaveSecond')
        },
        nextSlide() {
            this.$emit('nextSlide')
        },
        previousSlide() {
            this.$emit('previousSlide')
        }
    }
}
</script>