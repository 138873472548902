<template>
    <wizard-popup-container v-if="formLoaded"
       :headingText="headingText" :hideClose="currentSlide > 2" panelWidth="600px"
       @closeWizardPopup="clickCloseAddOnPopup"
    >
        <template #main>
            <div class="w-full">
                <div v-if="currentSlide == 1" class="w-full">
                    <select-panel
                        :fieldData="sortedSellerAddOns" fieldDataLabel="label" fieldDataValue="identifier" fieldFormName="addOn" :fieldRequired="true" :fieldValue="vendorAddOn" 
                        :forceMobile="true" :hideLabel="true"
                        @fieldChange="selectSellerAddOn"
                    >
                        <validation-message v-if="validating && !form.addOn" 
                            message="Add-On must be selected"
                        />
                    </select-panel>
                </div>
                <div v-if="currentSlide == 2" class="w-full">
                    <div @click="clickNewValue()" class="absolute top-4 right-2 cursor-pointer text-gray-800">
                        <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                    </div>
                    <draggable
                        animation="250" easing="cubic-bezier(1, 0, 0, 1)" handle=".handle-value" item-key="index" :list="form.values" tag="div" 
                        @change="saveOrder()"
                    >
                        <template #item="{element, index}">
                            <div class="flex items-center justify-start w-full orange-transparent py-3 mt-px">
                                <div class="cursor-move handle-value text-gray-600 pl-2 mr-2">
                                    <fa-icon icon="up-down-left-right" type="fas" class="h-5 w-5" />
                                </div>
                                <div class="flex items-center justify-between w-full">
                                    <div>
                                        {{ element.label }}
                                    </div>
                                    <div class="wizard-icon-container">
                                        <div @click="clickEditValue(element.identifier)" class="cursor-pointer">
                                            <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                                        </div>
                                        <div @click="clickDeleteValue(element.identifier)" class="cursor-pointer ml-2">
                                            <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </draggable>
                </div>
                <div v-if="currentSlide == 3" class="w-full">
                    <select-panel v-if="!editingIdentifier"
                        :fieldData="vendorValuesData" fieldFormName="selectVendorValue" :fieldLabel="`Select Base ${form.addOn.label} Option`" fieldLabelClasses="text-gray-800" 
                        fieldSubLabel="Or enter new values below." :fieldValue="selectVendorValue" :forceMobile="true" :isLabelAndValue="true"
                        @fieldChange="selectAddOnValue"
                    />
                    <input-panel 
                        fieldFormName="label" :fieldLabel="`${form.addOn.label} Label`" fieldLabelClasses="text-gray-800" :fieldPlaceholder="`${form.addOn.label} Label`" 
                        :fieldRequired="true" :fieldValue="valueForm.label" :forceMobile="true"
                        @fieldInput="updateValueFormValue"
                    >
                        <validation-message v-if="validating && valueForm.label == null" 
                            message="Label must be entered"
                        />
                    </input-panel>
                    <input-panel 
                        fieldFormName="identifier" :fieldLabel="`${form.addOn.label} Identifier`" fieldLabelClasses="text-gray-800" :fieldNonEdit="editingIdentifier" 
                        :fieldPlaceholder="`${form.addOn.label} Identifier`" :fieldRequired="true" fieldSubLabel="This is a unique identifier, it is only used as an index. It is not shown to the customers." 
                        :fieldValue="valueForm.identifier" :forceMobile="true"
                        @fieldInput="updateValueFormValue"
                    >
                        <validation-message v-if="validating && valueForm.identifier == null" 
                            message="Identifier must be entered"
                        />
                        <validation-message v-if="validating && uniqueIdentifier == false" 
                            message="Identifier must be unique"
                        />
                    </input-panel>
                    <input-panel 
                        fieldFormName="priceVariance" fieldLabel="Price Adjustment Amount" fieldLabelClasses="text-gray-800" fieldPlaceholder="Price Adjustment Amount" 
                        fieldSubLabel="This amount will be added to the base price of this listing, please enter a tax inclusive price." fieldType="number" 
                        :fieldValue="valueForm.priceVariance" :forceMobile="true"
                        @fieldInput="updateValueFormValue"
                    />
                </div>
            </div>
        </template>
        <template #footer>
            <div class="w-full flex items-center bg-gray-100 border-t border-gray-800 py-2 h-12 rounded-b-md">
                <div class="w-1/2">
                    <div v-if="!hidePrevious" @click="previousSlide()" class="secondary-button-base mx-2">
                        Back
                    </div>
                    <div v-if="!hideClose && currentSlide <= 2" @click="clickCloseAddOnPopup()" class="secondary-button-base mx-2">
                        Close Without Saving
                    </div>
                    <div v-if="!hideClose && currentSlide > 2" @click="clickCancelValueChanges()" class="secondary-button-base mx-2">
                        Cancel Changes
                    </div>
                </div>
                <div class="w-1/2">
                    <div v-if="!hideNext" @click="nextSlide()" class="standard-button-base mx-2">
                        Continue
                    </div>
                    <div v-if="!hideSave && currentSlide <= 2" @click="clickSaveAddOn()" class="standard-button-base mx-2">
                        Save And Close
                    </div>
                    <div v-if="!hideSave && currentSlide > 2" @click="clickSaveValue()" class="standard-button-base mx-2">
                        Save Option
                    </div>
                </div>
            </div>
        </template>
    </wizard-popup-container>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    props: [
        'editingAddOn',
        'editingAddOnValues',
        'productAddOns'
    ],
    components: {
        draggable
    },
    data() {
        return {
            addOnValues: null,
            currentSlide: 1,
            editingIdentifier: null,
            form: {
                addOn: null,
                values: []
            },
            formLoaded: false,
            originalForm: null,
            originalAddOnValues: null,
            originalValueForm: null,
            selectVendorValue: null,
            uniqueIdentifier: false,
            validating: false,
            valueForm: {
                identifier: null,
                label: null,
                priceVariance: null
            },
            vendorAddOn: null
        }
    },
    computed: { 
        headingText() {
            return this.slides[this.currentSlide].heading
        },
        hideClose() {
            return !this.slides[this.currentSlide].close
        },
        hideNext() {
            return !this.slides[this.currentSlide].next
        },
        hidePrevious() {
            return !this.slides[this.currentSlide].previous
        },
        hideSave() {
            return !this.slides[this.currentSlide].save
        },
        slides() {
            return {
                1: {
                    close: true,
                    heading: "Select Add-On",
                    next: 2,
                    previous: false,
                    save: false,
                    validation: Boolean(this.form.addOn)
                },
                2: {
                    close: this.editingAddOn,
                    heading: this?.form?.addOn ?? false ? `${this.form.addOn.label} Options` : null,
                    next: false,
                    previous: this.editingAddOn ? null : 1,
                    save: true,
                    validation: true
                },
                3: {
                    close: true,
                    heading: this?.form?.addOn?.label ? `${this.editingIdentifier ? 'Edit' : 'New'} ${this.form.addOn.label} Option` : null,
                    next: false,
                    previous: false,
                    save: true,
                    validation: Boolean(this.valueForm.label) && Boolean(this.valueForm.identifier) && (this.editingIdentifier || this.isIdentifierUnique())
                }
            }
        },
        sortedSellerAddOns() {
            return this.productAddOns.vendorAddOns
                .filter((myAddOn) => !myAddOn.selected)
        },
        vendorValuesData() {
            let mySellerAddOn = this.sellerVendor.product_add_ons.find((myAddOn) => myAddOn.identifier == this.form.addOn.identifier)

            if (mySellerAddOn.values && mySellerAddOn.values.length) {
                let myValues = {}

                mySellerAddOn.values
                    .sort((a, b) => a.identifier < b.identifier ? -1 : 1)
                    .forEach((myValue) => {
                        myValues = {...myValues, ...{[myValue.label]: myValue.identifier}}
                    })

                return myValues
            }

            return null
        }
    },
    mounted() {
        if (this.editingAddOn) {
            this.form.addOn = this.objCopy(this.editingAddOn)
            this.form.values = this.objCopy(this.editingAddOnValues)
            this.currentSlide = 2
        }

        this.addOnValues = this.objCopy(this.productAddOns.addOnValues)
        this.originalForm = this.objCopy(this.form)
        this.originalAddOnValues = this.objCopy(this.addOnValues)
        this.formLoaded = true
    },
    methods: {
        clickCancelValueChanges() {
            if (JSON.stringify(this.originalValueForm) == JSON.stringify(this.valueForm) || confirm("Confirm you wish to cancel your changes?")) {
                this.valueForm = {
                    identifier: null,
                    label: null,
                    priceVariance: null
                }

                this.selectVendorValue = null
                this.originalValueForm = null
                this.editingIdentifier = null
                this.currentSlide = 2
            }
        },
        clickCloseAddOnPopup() {
            if ((JSON.stringify(this.originalForm) == JSON.stringify(this.form) && JSON.stringify(this.originalAddOnValues) == JSON.stringify(this.addOnValues)) || confirm("Confirm you wish to close without saving your changes?")) {
                this.$emit('clickCloseAddOnPopup')
            }
        },
        clickDeleteValue(inIdentifier) {
            if (confirm(`Confirm you wish to delete ${this.form.values.find((myValue) => myValue.identifier == inIdentifier).label}?`)) {
                this.form.values = this.form.values.filter((myValue) => myValue.identifier != inIdentifier)
            }
        },
        clickEditValue(inIdentifier) {
            this.valueForm = this.objCopy(this.form.values.find((myValue) => myValue.identifier == inIdentifier))
            this.originalValueForm = this.objCopy(this.valueForm)
            this.editingIdentifier = inIdentifier
            this.currentSlide = 3
        },
        clickNewValue() {
            this.valueForm = {
                identifier: null,
                label: null,
                priceVariance: null
            }

            this.editingIdentifier = null
            this.currentSlide = 3
        },
        clickSaveAddOn() {
            this.$emit('clickSaveAddOn', this.form)
        },
        clickSaveValue() {
            if (this.editingIdentifier) {
                this.form.values.find((myValue) => myValue.identifier == this.editingIdentifier).label = this.valueForm.label
                this.form.values.find((myValue) => myValue.identifier == this.editingIdentifier).priceVariance = this.valueForm.priceVariance
            }
            else {
                this.form.values.push(this.objCopy(this.valueForm))
            }

            this.$emit('updateAddOnValues', this.addOnValues, false)

            this.valueForm = {
                identifier: null,
                label: null,
                priceVariance: null
            }

            this.selectVendorValue = null
            this.originalValueForm = null
            this.editingIdentifier = null
            this.currentSlide = 2
        },
        isIdentifierUnique() {
            let checkValue = null
                
            if (this.addOnValues) {
                checkValue = Boolean(this.addOnValues.find((myValue) => myValue.identifier == this.valueForm.identifier))
            }

            this.uniqueIdentifier = !checkValue
            return !checkValue
        },
        nextSlide() {
            if (this.slides[this.currentSlide].validation) {
                this.validating = false

                if (this.currentSlide == 1 && !this.form.values.length) {
                    this.currentSlide = 3
                }
                else {
                    this.currentSlide = this.slides[this.currentSlide].next
                }
            }
            else {
                this.validating = true
            }
        },
        previousSlide() {
            this.currentSlide = this.slides[this.currentSlide].previous
        },
        saveOrder() {
            
        },
        selectSellerAddOn(inField, inValue) {
            let mySellerAddOn = this.productAddOns.vendorAddOns.find((myAddOn) => myAddOn.identifier == inValue)
            
            if (mySellerAddOn) {
                this.form.addOn = this.objCopy(mySellerAddOn)
                this.form.addOn.selected = true
                this.form.values = []
                this.vendorAddOn = inValue
            }
        },
        selectAddOnValue(inField, inValue) {
            let mySellerAddOn = this.sellerVendor.product_add_ons.find((myAddOn) => myAddOn.identifier == this.form.addOn.identifier)

            if (mySellerAddOn.values && mySellerAddOn.values.length) {
                let myVendorValue = mySellerAddOn.values.find((myValue) => myValue.identifier == inValue)

                if (myVendorValue) {
                    this.valueForm.identifier = myVendorValue.identifier
                    this.valueForm.label = myVendorValue.label
                }
            }
            
            this.selectVendorValue = inValue
        },
        updateValueFormValue(inField, inValue) {
            if (inField == 'identifier') {
                inValue = this.stripString(inValue.toLowerCase())
            }
            else if (inField == 'label') {
                this.valueForm.identifier = this.stripString(inValue.toLowerCase())
            }

            this.valueForm[inField] = inValue
        }
    }
}
</script>