<template>
    <div class="items-start w-56 fixed pt-4 white-transparent" style="min-height: calc(100vh);">
        <div v-if="loggedUser || !isDesktopWidth" @click="goHome()" class="cursor-pointer flex flex-row shrink-0 ml-2 mb-4">
            <cached-image imageClasses="block w-auto h-8" :imageSrc="bzLogo" />
            <span class="hidden h-full ml-2 text-xl font-semibold tracking-tight text-white sm:inline">Buildz</span>
            <span class="hidden h-full text-xl italic font-light tracking-tight text-white sm:inline">.pro</span>
        </div>
        <div class="inline-flex flex-col justify-end px-1 w-full">
            <template v-for="(menuLine, index) in sortedMenu" :key="index">
                <div v-if="isMenuLineOkay(menuLine)" class="mt-4" :class="menuLine.class">
                    <div @click="clickMenuOption(menuLine)" class="inline-flex items-center cursor-pointer rounded-full w-full" :class="{ 'bg-orange-500': $route.name == menuLine.route }">
                        <div class="overlay-menu-icon-box">
                            <fa-icon :icon="menuLine.icon" :type="menuLine.iconType" class="overlay-menu-icon" :class="menuLine.iconClass" />
                        </div>
                        <div class="mx-2 flex flex-col">
                            <div>
                                {{ getLabel(menuLine) }}
                            </div>
                            <div v-if="menuLine.description" class="font-semibold text-xs">
                                {{ menuLine.description }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            clickedMenu: null
        }
    },
    computed: {
        sortedMenu() {
            return Object.values(this.menu).sort((a, b) => a.sort < b.sort ? -1 : 1)
        }
    },
    methods: {
        clickMenuOption(inMenuLine) {
            this.clickedMenu = inMenuLine.name

            if (inMenuLine.flasher) {
                this.setFlasher(inMenuLine.flasher)
            }

            setTimeout(() => {
                if (inMenuLine.name == 'signout') {
                    localStorage.removeItem("token")
        
                    this.setStateData([
                        { isLoggedIn: false },
                        { loggedUser: null },
                        { streamToken: null }
                    ])

                    this.$router.push({ name: "Logout" })
                }
                else if (inMenuLine.name == 'becomeSeller') {

                }
                else if (inMenuLine.route) {
                    if (inMenuLine.name == "event") {
                        this.routeTo(`/event/${this.currentEventId}`)
                    }
                    else if (inMenuLine.name == "community") {
                        this.routeTo(`/community/${this.currentGroupHandle}`)
                    }
                    else {
                        if (!this.serverConnected && inMenuLine.name == 'chat') {
                            this.pageMessage('warning', 'You are currently not connected to the internet, chat is unavailable')
                        }
                        else {
                            this.$router.push({ name: inMenuLine.route })
                        }
                    }
                }

                this.setSelectedMenu(inMenuLine.name)

                if (inMenuLine.name == 'help') {
                    if (!this.serverConnected) {
                        this.pageMessage('warning', 'You are currently not connected to the internet, help is unavailable')
                    }
                    else {
                        this.routeTo(`/chat/check/1`)
                    }
                }
                else {
                    if (!this.serverConnected && inMenuLine.name != 'chat') {
                        this.pageMessage('warning', 'You are currently not connected to the internet, data might be missing or incomplete')
                    }
                }
            }, 100)
        },
        clickTest() {
            console.log("click")
            buildzAxios?.post(this.getRoute('shopifyScrapeTest'))
                .then((response) => {
                    alert("done")
                })
                .catch((error) => {
                    console.log(error)
                    alert("error")
                })
        },
        getLabel(inMenuLine) {
            if (inMenuLine.name == "event") {
                return this.currentEventName
            }
            else if (inMenuLine.name == "community") {
                return this.currentGroupName
            }
            
            return inMenuLine.label
        },
        goHome() {
            this.routeTo('/')
        },
        hasVendor() {
            if (this.loggedUser.current_vendor_id || this.loggedUser.temp_vendor_id) {
                return true
            }

            return false
        },
        isMenuLineOkay(inMenuLine) {
            let isOkay = true

            if (inMenuLine.name == "events" && this.currentEventId) {
                isOkay = false
            }
            else if (inMenuLine.name == "event" && !this.currentEventId) {
                isOkay = false
            }
            else if (inMenuLine.name == "communities" && this.currentGroupId) {
                isOkay = false
            }
            else if (inMenuLine.name == "community" && !this.currentGroupId) {
                isOkay = false
            }
            else if (inMenuLine.name == "seller" && !this.hasVendor()) {
                isOkay = false
            }
            else if (inMenuLine.name == "becomeSeller" && this.hasVendor()) {
                isOkay = false
            }
            else if (inMenuLine.name == "impersonateUser") {
                isOkay = false
            }
            
            if (inMenuLine.name == "impersonateUser" && (this.userBuildzStaff || this.impersonatingUser)) {
                isOkay = true
            }

            return isOkay
        }
    }
}
</script>