<template>
    <input-panel 
        fieldFormName="linked_domain" fieldLabel="Linked Domain" fieldLabelClasses="text-gray-800" fieldPlaceholder="Linked Domain" :fieldRequired="true" fieldType="text" 
        :fieldValue="sellerWhiteLabelDataForm.linked_domain" :forceMobile="true"
        @fieldInput="updateSellerWhiteLabelDataFormValue"
    />
</template>

<script>
export default {
    props: [
        'sellerWhiteLabelDataForm',
        'validating'
    ],
    methods: {
        updateSellerWhiteLabelDataFormValue(inField, inValue) {
            this.$emit('updateSellerWhiteLabelDataFormValue', inField, inValue)
        }
    }
}
</script>