<template>
    <wizard-table-container 
        :childTable="true" :hideHeader="true"
    >
        <template #records>
            <table-record v-if="selectingBlockType && addingBlockIndex == 0"
                :noVerticalPadding="true" recordClasses="py-1 mt-px"
            >
                <template #record>
                    <select-block-type
                        @clickCloseSelectBlockType="clickCloseSelectBlockType" @clickNewBlock="clickNewBlock"
                    />
                </template>
            </table-record>
            <draggable v-if="blocks && blocks.length" 
                animation="250" easing="cubic-bezier(1, 0, 0, 1)" group="media" handle=".handle-media" item-key="index" :list="blocks" tag="div" 
                @change="saveOrder()" @end="dragEnd()" @start="dragStart()"
            >
                <template #item="{element, index}">
                    <div class="w-full">
                        <table-record
                            :extraClasses="selectingBlockType && addingBlockIndex == index + 1 ? 'mt-px py-2 orange-transparent' : ''" :noColor="true" :noVerticalPadding="true" recordClasses="orange-transparent pl-4"
                        >
                            <template #record>
                                <div class="w-full flex items-center" :class="{ 'h-12 overflow-hidden': !expanded(index), 'py-3': expanded(index) }">
                                    <div v-if="element.type == 'text'" v-html="element.text" class="w-full">
                                    </div>
                                    <div v-if="element.type == 'image'" class="w-full">
                                        <offline-media-card 
                                            :element="element.image" :filesCount="1" :hideDelete="true" :index="0"
                                            @updateElement="updateElement"
                                        />
                                    </div>
                                    <div v-if="element.type == 'youtube'" class="w-full" v-html="youtubeLinks(element.url)">
                                    </div>
                                    <div v-if="element.type == 'feature'" class="w-full">
                                        <div v-for="listRecord in element.list">
                                            <media-block-feature
                                                :record="listRecord"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!hideIcons" class="wizard-icon-container">
                                    <div class="cursor-move handle-media">
                                        <fa-icon icon="up-down-left-right" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div v-if="!expanded(index)" @click="expandBlock(index)" class="cursor-pointer ml-2">
                                        <fa-icon icon="expand" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div v-else @click="compressBlock(index)" class="cursor-pointer ml-2">
                                        <fa-icon icon="compress" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div @click="clickAddBlock(index + 1)" class="cursor-pointer ml-2">
                                        <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div @click="clickEditBlock(index, element.type)" class="cursor-pointer ml-2">
                                        <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div @click="clickDeleteBlock(index)" class="cursor-pointer ml-2">
                                        <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                    </div>
                                </div>
                            </template>
                            <template #extra>
                                <select-block-type v-if="selectingBlockType && addingBlockIndex == index + 1"
                                    @clickCloseSelectBlockType="clickCloseSelectBlockType" @clickNewBlock="clickNewBlock"
                                />
                            </template>
                        </table-record>
                    </div>
                </template>
            </draggable>
        </template>
    </wizard-table-container>
</template>

<script>
import draggable from 'vuedraggable'

import MediaBlockFeature from '@/shared/MediaBlocks/MediaBlockFeature'
import OfflineMediaCard from '@/shared/Cards/OfflineMediaCard'
import SelectBlockType from './SelectBlockType'
import TableRecord from '@/wizards/components/TableRecord'

export default {
    props: [
        'addingBlockIndex',
        'addTopBlock',
        'groupIndex',
        'hideIcons',
        'inBlocks',
        'traitId'
    ],
    components: {
        draggable,
        MediaBlockFeature,
        OfflineMediaCard,
        SelectBlockType,
        TableRecord
    },
    data() {
        return {
            blocks: null,
            dragging: false,
            expandedBlocks: [],
            selectingBlockType: false
        }
    },
    watch: {
        addTopBlock: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.clickAddBlock(0)
                    this.$emit('resetAddTopBlock')
                }
            }
        },
        inBlocks: {
            handler(val) {
                this.blocks = this.objCopy(this.inBlocks)
            }
        }
    },
    mounted() {
        this.blocks = this.objCopy(this.inBlocks)
    },
    methods: {
        clickAddBlock(inIndex) {
            this.$emit('updateAddingBlockIndex', inIndex, this.groupIndex, this.traitId)
            this.selectingBlockType = true
        },
        clickCloseSelectBlockType() {
            if (this.blocks.length) {
                this.selectingBlockType = false
                this.$emit('updateAddingBlockIndex', null, null, null)
            }
        },
        clickDeleteBlock(inIndex) {
            if (confirm(`Confirm you wish to delete this Detail Block?`)) {
                this.blocks = this.blocks.filter((myBlock, index) => index != inIndex)
                this.$emit('updateBlocks', this.blocks, this.groupIndex)
            }
        },
        clickEditBlock(inIndex, inType) {
            this.$emit('clickEditBlock', inIndex, this.groupIndex, inType)
        },
        clickNewBlock(inType) {
            this.selectingBlockType = false
            this.$emit('clickNewBlock', this.groupIndex, inType)
        },
        compressBlock(inIndex) {
            this.expandedBlocks = this.expandedBlocks.filter((myBlock) => myBlock != inIndex)
        },
        dragEnd() {
            this.dragging = false
        },
        dragStart() {
            this.expandedBlocks = []
            this.dragging = true
        },
        expandBlock(inIndex) {
            if (!this.expandedBlocks.includes(inIndex)) {
                this.expandedBlocks.push(inIndex)
            }
        },
        expanded(inIndex) {
            return this.expandedBlocks.includes(inIndex)
        },
        saveOrder() {
            this.$emit('updateBlocks', this.blocks, this.groupIndex)
        },
        updateElement(inImage, inIndex) {
            let myBlocks = []

            this.objCopy(this.blocks).forEach((myBlock) => {
                if (myBlock.type == 'image' && myBlock.image.identifier == inImage.identifier) {
                    myBlock.image = inImage    
                }
                
                myBlocks.push(myBlock)
            })

            this.$emit('updateBlocks', myBlocks, this.groupIndex)
        }
    }
}
</script>