<template>
    <draggable v-if="mediaBlocks && mediaBlocks.length" 
        animation="250" easing="cubic-bezier(1, 0, 0, 1)" group="media" handle=".handle-media" item-key="index" :list="mediaBlocks" tag="div" 
        @change="saveOrder()" @end="dragEnd()" @start="dragStart()"
    >
        <template #item="{element, index}">
            <div class="w-full">
                <div class="flex items-start mt-px w-full rounded-md overflow-hidden orange-transparent" :class="{ 'h-20': !expanded(index) }">
                    <div>
                        <div class="cursor-move handle-media p-2 text-gray-600">
                            <fa-icon icon="up-down-left-right" type="fas" class="h-6 w-6" />
                        </div>
                        <div v-if="!expanded(index)" @click="expandBlock(index)" class="cursor-pointer p-2 text-gray-600">
                            <fa-icon icon="expand" type="fas" class="h-5 w-5" />
                        </div>
                        <div v-else @click="compressBlock(index)" class="cursor-pointer p-2 text-gray-600">
                            <fa-icon icon="compress" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                    <div v-if="element.type == 'text'" v-html="element.text" class="w-full py-2">
                    </div>
                    <div v-if="element.type == 'image'" class="w-full py-2">
                        <offline-media-card 
                            :element="element.image" :filesCount="1" :hideDelete="true" :index="0"
                        />
                    </div>
                    <div v-if="element.type == 'youtube'" class="w-full py-2" v-html="youtubeLinks(element.url)">
                    </div>
                    <div v-if="element.type == 'feature'" class="w-full py-2">
                        <div v-for="listRecord in element.list">
                            <media-block-feature
                                :record="listRecord"
                            />
                        </div>
                    </div>
                    <div class="w-10 text-gray-600">
                        <div @click="clickDeleteMediaBlock(index, element.type)" class="cursor-pointer p-2">
                            <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                        </div>
                        <div v-if="element.type == 'text'" @click="clickEditTextBlock(index)" class="cursor-pointer p-2">
                            <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                        </div>
                        <div v-if="element.type == 'image'" @click="clickEditMediaBlock(index)" class="cursor-pointer p-2">
                            <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                        </div>
                        <div v-if="element.type == 'youtube'" @click="clickEditYouTubeBlock(index)" class="cursor-pointer p-2">
                            <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                        </div>
                        <div v-if="element.type == 'feature'" @click="clickEditFeaturesBlock(index)" class="cursor-pointer p-2">
                            <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                </div>
                <div v-if="!dragging && index < mediaBlocks.length - 1" @click="clickAddBlock(index)" class="alt-secondary-button-base my-1">
                    Insert Block
                </div>
                <div v-if="!dragging && index == mediaBlocks.length - 1" @click="clickAddBlock()" class="alt-secondary-button-base my-1">
                    Add Block
                </div>
            </div>
        </template>
    </draggable>
</template>

<script>
import draggable from 'vuedraggable'

import MediaBlockFeature from '@/shared/MediaBlocks/MediaBlockFeature'
import OfflineMediaCard from '@/shared/Cards/OfflineMediaCard'

export default {
    props: [
        'mediaBlocks'
    ],
    components: {
        draggable,
        MediaBlockFeature,
        OfflineMediaCard
    },
    data() {
        return {
            dragging: false,
            expandedBlocks: []
        }
    },
    methods: {
        clickAddBlock(inIndex) {
            this.$emit('clickAddBlock', inIndex)
        },
        clickDeleteMediaBlock(inIndex, inType) {
            this.$emit('clickDeleteMediaBlock', inIndex, inType)
        },
        clickEditFeaturesBlock(inIndex) {
            this.$emit('clickEditFeaturesBlock', inIndex)
        },
        clickEditMediaBlock(inIndex) {
            this.$emit('clickEditMediaBlock', inIndex)
        },
        clickEditTextBlock(inIndex) {
            this.$emit('clickEditTextBlock', inIndex)
        },
        clickEditYouTubeBlock(inIndex) {
            this.$emit('clickEditYouTubeBlock', inIndex)
        },
        compressBlock(inIndex) {
            this.expandedBlocks = this.expandedBlocks.filter((myBlock) => myBlock != inIndex)
        },
        dragEnd() {
            this.dragging = false
        },
        dragStart() {
            this.expandedBlocks = []
            this.dragging = true
        },
        expandBlock(inIndex) {
            if (!this.expandedBlocks.includes(inIndex)) {
                this.expandedBlocks.push(inIndex)
            }
        },
        expanded(inIndex) {
            return this.expandedBlocks.includes(inIndex)
        },
        saveOrder() {
            this.$emit('saveOrder')
        }
    }
}
</script>