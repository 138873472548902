<template>
    <wizard-container
    :openConditions="wizardData && sellerDataForm"
    @closeWizard="closeDiscountCodeWizard"
    >
        <template #main>
            <wizard-page v-if="formLoaded && sellerReady"
                :currentPage="currentPage" :hideLogo="false" :hidePrevious="true" :hideProgress="true" :ignorePages="ignorePages" 
                :introPages="introPages" :myPage="currentPage" :outroPages="outroPages" :pages="pages" :startPage="startPage" :wizardData="wizardData"
                @jumpToPage="jumpToPage" @nextPage="nextPage" @previousPage="previousPage"
            >
                <template #main>
                    <page01 v-if="currentPage == 1"
                        :sellerDataForm="sellerDataForm" :sellerForm="sellerForm" :validating="validating"
                        @clickDeleteDiscountCode="clickDeleteDiscountCode" @clickEditDiscountCode="clickEditDiscountCode" @clickNewDiscountCode="clickNewDiscountCode" 
                        @clickToggleDiscountCodeEnabled="clickToggleDiscountCodeEnabled" @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                </template>
                <template #popups>
                    <discount-code-popup v-if="showDiscountCodePopup && currentPage == 1" 
                        :discountCodes="sellerForm.discount_codes" :editingDiscountCode="editingDiscountCode"
                        @clickCloseDiscountCodePopup="clickCloseDiscountCodePopup" @clickSaveDiscountCode="clickSaveDiscountCode"
                    />
                </template>
            </wizard-page>
        </template>
    </wizard-container>
</template>

<script>
import {debounce} from 'lodash'

import DiscountCodePopup from './popups/DiscountCodePopup'
import Page01 from './pages/Page01'
import WizardPage from '../WizardPage'

export default {
    components: {
        DiscountCodePopup,
        Page01,
        WizardPage
    },
    data() {
        return {
            currentPage: 1,
            editingDiscountCode: null,
            formLoaded: false,
            ignorePages: [],
            introPages: 0,
            outroPages: 0,
            sellerDataForm: {
                hasDiscountCodes: null
            },
            sellerForm: {
                discount_codes: null
            },
            sellerId: null,
            sellerReady: false,
            showDiscountCodePopup: false,
            startPage: null,
            validating: false,
            wizardData: null
        }
    },
    computed: {
        pages() {
            return {
                1: {
                    next: 1,
                    nextText: "Close",
                    previous: false,
                    validation: true
                }
            }
        }
    },
    watch: {
        loggedUser: {
            immediate: true,
            handler(val) {
                this.setStateData([
                    { tempSellerVendorData: this.sellerVendorData },
                    { tempSellerVendor: this.sellerVendor },
                    { tempSellerVendorWhiteLabelData: this.sellerVendorWhiteLabelData }
                ])

                this.sellerReady = true
            }
        },
        wizardDiscountCodeData: {
            immediate: true,
            handler(val) {
                this.wizardData = this.wizardDiscountCodeData
            }
        },
        tempSellerVendor: {
            immediate: true,
            handler(val) {
                if (!this.sellerId) {
                    this.sellerId = this.tempSellerVendor.id
                    this.sellerForm.discount_codes = this.tempSellerVendor.discount_codes ?? []
                }
            }
        },
        tempSellerVendorData: {
            immediate: true,
            handler(val) {
                this.sellerDataForm.hasDiscountCodes = this?.tempSellerVendorData?.hasDiscountCodes ?? null    
            }
        }
    },
    mounted() {
        this.formLoaded = true
    },
    beforeUnmount() {
        this.setStateData([
            { tempSellerVendorData: null },
            { tempSellerVendor: null },
            { tempSellerVendorWhiteLabelData: null }
        ])
    },
    methods: {
        clickCloseDiscountCodePopup() {
            this.editingDiscountCode = null
            this.showDiscountCodePopup = false
        },
        clickDeleteDiscountCode(inCode) {
            this.sellerForm.discount_codes = this.sellerForm.discount_codes.filter((myCode) => myCode.code != inCode)
            this.save()
        },
        clickEditDiscountCode(inCode) {
            let editDiscountCode = this.sellerForm.discount_codes.find((myCode) => myCode.code == inCode)

            if (editDiscountCode) {
                this.editingDiscountCode = this.objCopy(editDiscountCode)
                this.showDiscountCodePopup = true
            }
        },
        clickNewDiscountCode() {
            this.showDiscountCodePopup = true
        },
        clickSaveDiscountCode(inForm) {
            if (this.editingDiscountCode) {
                this.sellerForm.discount_codes.find((myCode) => myCode.identifier == inForm.identifier).code = inForm.code
                this.sellerForm.discount_codes.find((myCode) => myCode.identifier == inForm.identifier).enabled = inForm.enabled
                this.sellerForm.discount_codes.find((myCode) => myCode.identifier == inForm.identifier).endTime = inForm.endTime
                this.sellerForm.discount_codes.find((myCode) => myCode.identifier == inForm.identifier).label = inForm.label
                this.sellerForm.discount_codes.find((myCode) => myCode.identifier == inForm.identifier).maxUses = inForm.maxUses
                this.sellerForm.discount_codes.find((myCode) => myCode.identifier == inForm.identifier).multiple = inForm.multiple
                this.sellerForm.discount_codes.find((myCode) => myCode.identifier == inForm.identifier).percentage = inForm.percentage
                this.sellerForm.discount_codes.find((myCode) => myCode.identifier == inForm.identifier).startTime = inForm.startTime
            }
            else {
                inForm.uses = 0
                this.sellerForm.discount_codes.push(inForm)
            }

            this.save()
            this.clickCloseDiscountCodePopup()
        },
        clickToggleDiscountCodeEnabled(inCode, inEnabled) {
            this.sellerForm.discount_codes.find((myCode) => myCode.code == inCode).enabled = inEnabled
            this.save()
        },
        jumpToPage(inPageNumber) {
            this.currentPage = inPageNumber
        },
        nextPage() {
            if (this.pages[this.currentPage].validation) {
                this.validating = false
                this.closeDiscountCodeWizard()
            }
            else {
                this.validating = true
            }
        },
        previousPage() {
            this.currentPage = this.pages[this.currentPage].previous
        },
        save() {
            this.saveStart()

            let myData = { ...this.sellerForm, ...this.sellerDataForm }
            myData.vendor_id = this.sellerId

            buildzAxios?.post(this.getRoute('seller'), this.objCopy(myData))
                .then((response) => {
                    this.saveDone()

                    if (this.activating) {
                        buildzAxios?.put(this.getRoute('garageUpdate', this.loggedUser.id),
                            {
                                current_vendor_id: this.sellerId 
                            }
                        )
                        .then((response) => {
                            this.routeTo('/marketplace/seller')

                            setTimeout(() => {
                                window.location.reload()
                            }, 100)
                        })
                        .catch((error) => {
                            console.log(error)
                            this.closeSpinner()
                        })

                        this.closeDiscountCodeWizard()
                    }
                    else if (!this.wizardEditingTempSeller) {
                        this.setStateData([
                            { sellerVendor: response.data.marketplace_vendor },
                            { sellerVendorData: response.data.marketplace_vendor_data },
                            { sellerVendorWhiteLabelData: response.data.marketplace_vendor_white_label_data }
                        ])
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.saveDone()
                })
        },
        saveDebounce: debounce(function() {
            this.save()
        }, 2000),
        updateSellerDataFormSelectValue(inField, inValue) {
            this.sellerDataForm[inField] = inValue
            this.save()
        }
    }
}
</script>