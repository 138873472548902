<template>
    <div v-if="formLoaded" class="w-full px-2">
        <div @click="editingBlocks = !editingBlocks" class="cursor-pointer mb-1">
            <div v-if="editingBlocks" class="secondary-button-base">
                View Blocks
            </div>
            <div v-else class="secondary-button-base">
                Edit Blocks
            </div>
        </div>
        <view-detail-blocks v-if="!editingBlocks"
            :mediaBlocks="mediaBlocks"
        />
        <edit-detail-blocks v-else
            :mediaBlocks="mediaBlocks"
            @clickAddBlock="clickAddBlock" @clickDeleteMediaBlock="clickDeleteMediaBlock" @clickEditFeaturesBlock="clickEditFeaturesBlock" @clickEditMediaBlock="clickEditMediaBlock" 
            @clickEditTextBlock="clickEditTextBlock" @clickEditYouTubeBlock="clickEditYouTubeBlock" @saveOrder="saveOrder"
        />
    </div>
</template>

<script>
import EditDetailBlocks from '../components/page-04/EditDetailBlocks'
import ViewDetailBlocks from '../components/page-04/ViewDetailBlocks'

export default {
    props: [
        'productForm'
    ],
    components: {
        EditDetailBlocks,
        ViewDetailBlocks
    },
    data() {
        return {
            editingBlocks: true,
            formLoaded: false
        }
    },
    computed: {
        mediaBlocks() {
            return this?.productForm?.media_blocks ?? []
        }
    },
    mounted() {
        this.formLoaded = true
    },
    methods: {
        clickAddBlock(inIndex) {
            this.$emit('clickAddBlock', inIndex)
        },
        clickDeleteMediaBlock(inIndex, inType) {
            this.$emit('clickDeleteMediaBlock', inIndex, inType)
        },
        clickEditFeaturesBlock(inIndex) {
            this.$emit('clickEditFeaturesBlock', inIndex)
        },
        clickEditMediaBlock(inIndex) {
            this.$emit('clickEditMediaBlock', inIndex)
        },
        clickEditTextBlock(inIndex) {
            this.$emit('clickEditTextBlock', inIndex)
        },
        clickEditYouTubeBlock(inIndex) {
            this.$emit('clickEditYouTubeBlock', inIndex)
        },
        saveOrder() {
            this.updateMediaBlocks()
        },
        updateMediaBlocks() {
            this.$emit('updateMediaBlocks', this.mediaBlocks)
        }
    }
}
</script>