<template>
    <wizard-container
       :openConditions="wizardData && sellerDataForm"
       @closeWizard="closeListingWizard"
    >
        <template #main>
            <wizard-page v-if="formLoaded && sellerReady"
                :checkNonValidated="checkNonValidated" :currentPage="currentPage" :hidePrevious="currentPage == 1" :ignorePages="ignorePages" :introPages="introPages" 
                :myPage="currentPage" :outroPages="outroPages" :pages="pages" :startPage="startPage" :wizardData="wizardData"
                @jumpToPage="jumpToPage" @nextPage="nextPage" @previousPage="previousPage" @resetCheckNonValidated="resetCheckNonValidated"
            >
                <template #main>
                    <page01 v-if="currentPage == 1"
                        :sellerDataForm="sellerDataForm" :validating="validating"
                        @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                    <page02 v-if="currentPage == 2"
                        :manualPickupAddress="manualPickupAddress" :sellerDataForm="sellerDataForm" :validating="validating"
                        @updateManualPickupAddress="updateManualPickupAddress" @updateSellerDataFormLocalAddressValue="updateSellerDataFormLocalAddressValue" 
                        @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                    <page03 v-if="currentPage == 3"
                        :sellerDataForm="sellerDataForm" :validating="validating"
                        @updateSellerDataFormValue="updateSellerDataFormValue"
                    />
                    <page04 v-if="currentPage == 4"
                        :sellerDataForm="sellerDataForm" :validating="validating"
                        @changeMultipleCountries="changeMultipleCountries" @changeUseDefaultZone="changeUseDefaultZone" @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                    <page05 v-if="currentPage == 5"
                        :sellerDataForm="sellerDataForm" :validating="validating"
                        @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                    <page06 v-if="currentPage == 6"
                        :sellerDataForm="sellerDataForm" :validating="validating"
                        @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                    <page07 v-if="currentPage == 7"
                        :editingRateId="editingRateId" :editingRateZoneId="editingRateZoneId" :sellerDataForm="sellerDataForm" :shippingZonesOkay="shippingZonesOkay" :validating="validating"
                        @clickDeleteRate="clickDeleteRate" @clickEditRate="clickEditRate" @clickNewZoneRate="clickNewZoneRate" @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                    <page08 v-if="currentPage == 8"
                        :sellerForm="sellerForm" :validating="validating"
                        @updateProductCategories="updateProductCategories"
                    />
                    <page09 v-if="currentPage == 9"
                        :sellerDataForm="sellerDataForm" :sellerForm="sellerForm" :validating="validating"
                        @updateModifiers="updateModifiers" @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                    <page10 v-if="currentPage == 10"
                        :sellerDataForm="sellerDataForm" :sellerForm="sellerForm" :validating="validating"
                        @updateAddOns="updateAddOns" @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                    <page11 v-if="currentPage == 11"
                        :sellerDataForm="sellerDataForm" :sellerForm="sellerForm" :validating="validating"
                        @updateBulkDiscounts="updateBulkDiscounts" @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                    <page12 v-if="currentPage == 12"
                        :addingBlockIndex="addingBlockIndex" :addingBlockTraitId="addingBlockTraitId" :addingGroupId="addingGroupId" :refreshTraits="refreshTraits" 
                        :sellerDataForm="sellerDataForm" :sellerForm="sellerForm" :validating="validating"
                        @clickDeleteTrait="clickDeleteTrait" @clickEditBlock="clickEditBlock" @clickEditTrait="clickEditTrait" @clickNewBlock="clickNewBlock" 
                        @clickNewTrait="clickNewTrait" @resetRefreshTraits="resetRefreshTraits" @updateAddingBlockIndex="updateAddingBlockIndex" 
                        @updateAddingGroupId="updateAddingGroupId" @updateProductCategories="updateProductCategories" @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue" 
                        @updateTraits="updateTraits"
                    />
                    <page13 v-if="currentPage == 13"
                        :sellerDataForm="sellerDataForm" :validating="validating"
                        @changeExistingPlatform="changeExistingPlatform" @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                </template>
                <template #popups>
                    <features-block-popup v-if="showFeaturesBlockPopup && currentPage == 12" 
                        :editingBlock="editingBlock" 
                        @clickCloseBlockPopup="clickCloseBlockPopup" @clickSaveBlock="clickSaveBlock"
                    />
                    <media-block-popup v-if="showMediaBlockPopup && currentPage == 12" 
                        :editingBlock="editingBlock"
                        @clickCloseBlockPopup="clickCloseBlockPopup" @clickSaveBlock="clickSaveBlock"
                    />
                    <text-block-popup v-if="showTextBlockPopup && currentPage == 12" 
                        :editingBlock="editingBlock" 
                        @clickCloseBlockPopup="clickCloseBlockPopup" @clickSaveBlock="clickSaveBlock"
                    />
                    <trait-popup v-if="showTraitPopup && currentPage == 12" 
                        :editingTrait="editingTrait" :productCategories="sellerForm.product_categories" :traits="sellerForm.product_traits"
                        @clickCloseTraitPopup="clickCloseTraitPopup" @clickSaveTrait="clickSaveTrait"
                    />
                    <you-tube-block-popup v-if="showYouTubeBlockPopup && currentPage == 12" 
                        :editingBlock="editingBlock" 
                        @clickCloseBlockPopup="clickCloseBlockPopup" @clickSaveBlock="clickSaveBlock"
                    />
                    <zone-rate-popup v-if="showZoneRatePopup && currentPage == 7" 
                        :addingRateZone="addingRateZone" :editingRate="editingRate" :editingRateZone="editingRateZone" :rateType="sellerDataForm.defaultShippingModel" 
                        :shippingRateLabels="sellerDataForm.shippingRateLabels"
                        @clickCloseZoneRatePopup="clickCloseZoneRatePopup" @clickSaveZoneRate="clickSaveZoneRate"
                    />
                </template>
            </wizard-page>
        </template>
    </wizard-container>
</template>

<script>
import {debounce} from 'lodash'

import FeaturesBlockPopup from './popups/FeaturesBlockPopup'
import MediaBlockPopup from './popups/MediaBlockPopup'
import Page01 from './pages/Page01'
import Page02 from './pages/Page02'
import Page03 from './pages/Page03'
import Page04 from './pages/Page04'
import Page05 from './pages/Page05'
import Page06 from './pages/Page06'
import Page07 from './pages/Page07'
import Page08 from './pages/Page08'
import Page09 from './pages/Page09'
import Page10 from './pages/Page10'
import Page11 from './pages/Page11'
import Page12 from './pages/Page12'
import Page13 from './pages/Page13'
import TextBlockPopup from './popups/TextBlockPopup'
import TraitPopup from './popups/TraitPopup'
import WizardPage from '../WizardPage'
import YouTubeBlockPopup from './popups/YouTubeBlockPopup'
import ZoneRatePopup from './popups/ZoneRatePopup'

export default {
    components: {
        FeaturesBlockPopup,
        MediaBlockPopup,
        Page01,
        Page02,
        Page03,
        Page04,
        Page05,
        Page06,
        Page07,
        Page08,
        Page09,
        Page10,
        Page11,
        Page12,
        Page13,
        TextBlockPopup,
        TraitPopup,
        WizardPage,
        YouTubeBlockPopup,
        ZoneRatePopup
    },
    data() {
        return {
            activating: false,
            addingBlockIndex: null,
            addingBlockTraitId: null,
            addingGroupId: null,
            addingRateZone: null,
            checkNonValidated: false,
            currentPage: 1,
            editingBlock: null,
            editingBlockIndex: null,
            editingBlockTraitId: null,
            editingGroupId: null,
            editingRate: null,
            editingRateId: null,
            editingRateZone: null,
            editingRateZoneId: null,
            editingTrait: null,
            formLoaded: false,
            gallery: [],
            ignorePages: [],
            introPages: 0,
            manualPickupAddress: false,
            outroPages: 0,
            refreshTraits: false,
            sellerDataForm: {
                defaultGroupBuyCategory: null,
                defaultProductCategory: null,
                defaultShippingModel: null,
                excludedCountries: null,
                existingPlatform: null,
                hasAddOns: null,
                hasBulkDiscounts: null,
                hasTraits: null,
                hasModifiers: null,
                listingTypes: null,
                localPickup: null,
                localPickupAddress: {
                    address: {
                        city: null,
                        country: null,
                        line1: null,
                        line2: null,
                        postal_code: null,
                        state: null
                    },
                    formattedAddress: null,
                    location: null
                },
                multipleCountries: null,
                newZoneRateZoneId: null,
                platform: null,
                shippingFrom: {
                    geoloc: null,
                    location: null
                },
                shippingRateLabels: null,
                shippingTaxRate: null,
                shippingZones: null,
                useDefaultZone: null
            },
            sellerForm: {
                product_add_ons: null,
                product_bulk_discounts: null,
                product_categories: null,
                product_traits: null,
                product_variants: null
            },
            sellerId: null,
            sellerReady: false,
            showFeaturesBlockPopup: false,
            showMediaBlockPopup: false,
            showSliderBlockPopup: false,
            showTextBlockPopup: false,
            showTraitPopup: false,
            showYouTubeBlockPopup: false,
            showZoneRatePopup: false,
            startPage: null,
            validating: false,
            wizardData: null
        }
    },
    computed: {
        defaultShippingZone() {
            return !this.sellerDataForm.shippingZones ? null : this.sellerDataForm.shippingZones.find((zone) => zone.id == 1)
        },
        isPickupAddressOkay() {
            return this.sellerDataForm.localPickup != null && Boolean(!this.sellerDataForm.localPickup || (!this.manualPickupAddress && this.sellerDataForm.localPickupAddress.formattedAddress) || (this.manualPickupAddress && this.sellerDataForm.localPickupAddress.address.line1 && this.sellerDataForm.localPickupAddress.address.city && this.sellerDataForm.localPickupAddress.address.state && this.sellerDataForm.localPickupAddress.address.postal_code && this.sellerDataForm.localPickupAddress.address.country))
        },
        pages() {
            return {
                1: {
                    next: this.hasListingType('physical') ? 2 : (this.hasListingType('digital') ? 8 : false),
                    nextText: (this.hasListingType('physical') || this.hasListingType('digital')) ? "Continue" : "Close",
                    previous: false,
                    validation: this.hasListingType('physical') != null && this.hasListingType('digital') != null && this.hasListingType('groupBuy') != null && this.hasListingType('giveAway') != null && this.hasListingType('memberGiveAway') != null
                },
                2: {
                    next: 3,
                    previous: 1,
                    validation: this.sellerDataForm.localPickup != null && (this.sellerDataForm.localPickup == false || Boolean(this.isPickupAddressOkay))
                },
                3: {
                    next: 4,
                    previous: 2,
                    validation: Boolean(this.sellerDataForm.shippingFrom.location)
                },
                4: {
                    next: 5,
                    previous: 3,
                    validation: this.sellerDataForm.multipleCountries != null && Boolean(this.sellerDataForm.shippingZones && this.sellerDataForm.shippingZones.length > 1) && (this.sellerDataForm.multipleCountries == false || this.sellerDataForm.useDefaultZone != null)
                },
                5: {
                    next: 6,
                    previous: 4,
                    validation: (this.sellerDataForm.multipleCountries == false || this.sellerDataForm.useDefaultZone != null) && Boolean(this.sellerDataForm.defaultShippingModel) && (!this.sellerDataForm.chargeTax || Boolean(this.sellerDataForm.shippingTaxRate))
                },
                6: {
                    next: 7,
                    previous: 5,
                    validation: (this.sellerDataForm.multipleCountries == false || this.sellerDataForm.useDefaultZone != null) && Boolean(this.sellerDataForm.shippingRateLabels && this.sellerDataForm.shippingRateLabels.length > 0)
                },
                7: {
                    next: 8,
                    previous: 6,
                    validation: this.shippingZonesOkay
                },
                8: {
                    next: 9,
                    previous: this.hasListingType('physical') ? 7 : 1,
                    validation: Boolean(this.sellerForm.product_categories && this.sellerForm.product_categories.length)
                },
                9: {
                    next: 10,
                    previous: 8,
                    validation: this.sellerDataForm.hasModifiers == false || Boolean(this.sellerDataForm.hasModifiers && this.sellerForm.product_variants && this.sellerForm.product_variants.length)
                },
                10: {
                    next: 11,
                    previous: 9,
                    validation: this.sellerDataForm.hasAddOns == false || Boolean(this.sellerDataForm.hasAddOns && this.sellerForm.product_add_ons && this.sellerForm.product_add_ons.length)
                },
                11: {
                    next: 12,
                    previous: 10,
                    validation: this.sellerDataForm.hasBulkDiscounts == false || Boolean(this.sellerDataForm.hasBulkDiscounts && this.sellerForm.product_bulk_discounts && this.sellerForm.product_bulk_discounts.length)
                },
                12: {
                    next: 13,
                    previous: 11,
                    validation: this.sellerDataForm.hasTraits == false || Boolean(this.sellerDataForm.hasTraits && this.sellerForm.product_traits && this.sellerForm.product_traits.length)
                },
                13: {
                    next: 13,
                    nextText: "Close",
                    previous: 12,
                    validation: this.sellerDataForm.existingPlatform == false || (this.sellerDataForm.existingPlatform && Boolean(this.sellerDataForm.platform))
                }
            }
        },
        shippingZonesOkay() {
            let allOkay = true

            this.sellerDataForm.shippingZones.forEach((myZone) => {
                if (myZone.id != 1 || this.sellerDataForm.useDefaultZone) {
                    if (!myZone.rates || !myZone.rates.length) {
                        allOkay = false
                    }
                }
            })

            return allOkay
        }
    },
    watch: {
        sellerVendor: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.setStateData([
                        { tempSellerVendorData: this.sellerVendorData },
                        { tempSellerVendor: this.sellerVendor },
                        { tempSellerVendorWhiteLabelData: this.sellerVendorWhiteLabelData }
                    ])

                    this.sellerReady = true
                }
            }
        },
       tempSellerVendor: {
            immediate: true,
            handler(val) {
                if (!this.sellerId) {
                    this.sellerId = this.tempSellerVendor.id
                    this.sellerForm.product_add_ons = this.tempSellerVendor.product_add_ons ?? []
                    this.sellerForm.product_bulk_discounts = this.tempSellerVendor.product_bulk_discounts ?? []
                    this.sellerForm.product_categories = this.tempSellerVendor.product_categories ?? []
                    this.sellerForm.product_traits = this.tempSellerVendor.product_traits ?? []
                    this.sellerForm.product_variants = this.tempSellerVendor.product_variants ?? []
                }
            }
        },
        tempSellerVendorData: {
            immediate: true,
            handler(val) {
                if (this.tempSellerVendorData.shippingRateLabels) {
                    this.sellerDataForm.shippingRateLabels = this.tempSellerVendorData.shippingRateLabels
                }
                else {
                    let myLabels = []

                    this.defaultShippingRateLabels.forEach((myLabel) => {
                        myLabels.push(myLabel)
                    })

                    this.sellerDataForm.shippingRateLabels = myLabels
                }

                this.sellerDataForm.defaultShippingModel = this?.tempSellerVendorData?.defaultShippingModel ?? null
                this.sellerDataForm.excludedCountries = this?.tempSellerVendorData?.excludedCountries ?? []
                this.sellerDataForm.existingPlatform = (this?.tempSellerVendorData?.existingPlatform ?? null) != null ? this.tempSellerVendorData.existingPlatform : null
                this.sellerDataForm.listingTypes = this?.tempSellerVendorData?.listingTypes ?? []
                this.sellerDataForm.hasAddOns = this?.tempSellerVendorData?.hasAddOns ?? null
                this.sellerDataForm.hasBulkDiscounts = this?.tempSellerVendorData?.hasBulkDiscounts ?? null
                this.sellerDataForm.hasModifiers = this?.tempSellerVendorData?.hasModifiers ?? null
                this.sellerDataForm.hasTraits = this?.tempSellerVendorData?.hasTraits ?? null
                this.sellerDataForm.localPickup = this?.tempSellerVendorData?.localPickup ?? null

                this.sellerDataForm.localPickupAddress = this?.tempSellerVendorData?.localPickupAddress ?? {
                    address: {
                        city: null,
                        country: null,
                        line1: null,
                        line2: null,
                        postal_code: null,
                        state: null
                    },
                    formattedAddress: null,
                    location: null
                }

                this.sellerDataForm.multipleCountries = (this?.tempSellerVendorData?.multipleCountries ?? null) != null ? this.tempSellerVendorData.multipleCountries : null
                this.sellerDataForm.platform = this?.tempSellerVendorData?.platform ?? null

                this.sellerDataForm.shippingFrom = this?.tempSellerVendorData?.shippingFrom ?? {
                    geoloc: null,
                    location: null
                }

                this.sellerDataForm.shippingTaxRate = this?.tempSellerVendorData?.shippingTaxRate ?? null
            
                if ((this?.tempSellerVendorData?.shippingZones ?? false) && this.tempSellerVendorData.shippingZones.length) {
                    this.sellerDataForm.shippingZones = this.tempSellerVendorData.shippingZones
                }
                else {
                    this.sellerDataForm.shippingZones = [
                        {
                            id: 1,
                            name: 'Default zone',
                            rates: [],
                            rateId: 0
                        }
                    ]
                }

                this.sellerDataForm.useDefaultZone = (this?.tempSellerVendorData?.useDefaultZone ?? null) != null ? this.tempSellerVendorData.useDefaultZone : null
                
                if (this.sellerDataForm.localPickupAddress.formattedAddress || !this.sellerDataForm.localPickupAddress.address.line1) {
                    this.manualPickupAddress = false
                }
                else {
                    this.manualPickupAddress = true
                }

                this.getIgnorePages()
            }
        },
        wizardListingData: {
            immediate: true,
            handler(val) {
                this.wizardData = this.wizardListingData
            }
        }
    },
    mounted() {
        if (this.wizardListingStartPage) {
            this.startPage = this.wizardListingStartPage
        }

        this.formLoaded = true
    },
    beforeUnmount() {
        this.setStateData([
            { wizardListingStartPage: null },
            { tempSellerVendorData: null },
            { tempSellerVendor: null },
            { tempSellerVendorWhiteLabelData: null }
        ])
    },
    methods: {
        changeExistingPlatform(inField, inValue) {
            if (!inValue) {
                this.sellerDataForm.platform = null
            }

            this.updateSellerDataFormSelectValue(inField, inValue)
        },
        changeMultipleCountries(inField, inValue) {
            if (inValue == false && this.sellerDataForm.shippingZones.length > 1) {
                
            }
            else if (!inValue) {
                this.sellerDataForm.useDefaultZone = null
            }

            this.updateSellerDataFormSelectValue(inField, inValue)
        },
        changeUseDefaultZone(inField, inValue) {
            if (!inValue && this.defaultShippingZone) {
                let myZone = this.sellerDataForm.shippingZones.find((zone) => zone.id == 1)

                if (myZone) {
                    myZone.disabled = true
                }
                else {
                    myZone.disabled = false
                }
            }

            this.updateSellerDataFormSelectValue(inField, inValue)
        },
        checkValidation() {
            this.checkNonValidated = true
        },
        clickCloseBlockPopup() {
            this.editingBlock = null
            this.editingBlockIndex = null
            this.editingBlockTraitId = null
            this.editingGroupId = null
            this.addingBlockIndex = null
            this.addingBlockTraitId = null
            this.addingGroupId = null
            this.showFeaturesBlockPopup = false
            this.showMediaBlockPopup = false
            this.showSliderBlockPopup = false
            this.showTextBlockPopup = false
            this.showYouTubeBlockPopup = false
        },
        clickCloseTraitPopup() {
            this.editingTrait = null
            this.showTraitPopup = false
        },
        clickCloseZoneRatePopup() {
            this.newZoneRateZoneId = null
            this.editingRate = null
            this.editingRateId = null
            this.editingRateZone = null
            this.editingRateZoneId = null
            this.addingRateZone = null
            this.showZoneRatePopup = false
        },
        clickDeleteRate(inZoneId, inRate) {
            let myZone = this.sellerDataForm.shippingZones.find((zone) => zone.id == inZoneId)
            let myNewRates = []

            myZone.rates.forEach((myRate) => {
                if (myRate.basedOn == inRate.basedOn && myRate.customRateName == inRate.customRateName) {
                    
                }
                else {
                    myNewRates.push(myRate)
                }
            })

            myZone.rates = myNewRates
            this.save()
        },
        clickDeleteTrait(inId) {
            this.sellerForm.product_traits = this.sellerForm.product_traits.filter((myTrait) => myTrait.id != inId)
            this.refreshTraits = true
            this.save()
        },
        clickEditBlock(inIndex, inGroupId, inTraitId, inType) {
            let editTrait = this.sellerForm.product_traits.find((myTrait) => myTrait.id == inTraitId)
            let editGroup = editTrait.groups.find((myGroup) => myGroup.id == inGroupId)
            this.editingBlock = editGroup.blocks.find((myBlock, index) => index == inIndex && myBlock.type == inType)
            this.editingBlockIndex = inIndex
            this.editingGroupId = inGroupId
            this.editingBlockTraitId = inTraitId
            this.clickNewBlock(inType)
        },
        clickEditRate(inZoneId, inRate) {
            this.editingRate = inRate
            this.editingRateId = inRate.id
            this.editingRateZoneId = inZoneId
            this.editingRateZone = this.sellerDataForm.shippingZones.find((zone) => zone.id == inZoneId)
            this.showZoneRatePopup = true
        },
        clickEditTrait(inId) {
            let editTrait = this.sellerForm.product_traits.find((myTrait) => myTrait.id == inId)

            if (editTrait) {
                this.editingTrait = this.objCopy(editTrait)
                this.showTraitPopup = true
            }
        },
        clickNewBlock(inType) {
            if (inType == 'feature') {
                this.showFeaturesBlockPopup = true
            }
            else if (inType == 'image') {
                this.showMediaBlockPopup = true
            }
            else if (inType == 'slider') {
                this.showSliderBlockPopup = true
            }
            else if (inType == 'text') {
                this.showTextBlockPopup = true
            }
            else if (inType == 'youtube') {
                this.showYouTubeBlockPopup = true
            }
        },
        clickNewTrait() {
            this.showTraitPopup = true
        },
        clickNewZoneRate(inZoneId) {
            this.newZoneRateZoneId = inZoneId
            this.addingRateZone = this.sellerDataForm.shippingZones.find((zone) => zone.id == inZoneId)
            this.showZoneRatePopup = true
        },
        async clickSaveBlock(inData, inType) {
            let myTrait = this.sellerForm.product_traits.find((myPTrait) => myPTrait.id == (this?.addingBlockTraitId ?? this.editingBlockTraitId))

            if (myTrait) {
                let myGroup = myTrait.groups.find((myTGroup, index) => index == (this?.addingGroupId ?? this.editingGroupId))
                
                if (myGroup) {
                    let myFormBlocks = this.objCopy(myGroup?.blocks ?? [])

                    if (inType == 'feature') {
                        inData.forEach((myRecord) => {
                            if (myRecord.gallery && myRecord.gallery.length) {
                                myRecord.image = myRecord.gallery[0]
                            }
                            else {
                                myRecord.image = null
                            }
                        })
                    }

                    if (this.editingBlock) {
                        let myTempBlocks = []
                        
                        myFormBlocks.forEach((myBlock, index) => {
                            if (myBlock.type == 'feature' && index == this.editingBlockIndex) {
                                myBlock.list = inData    
                            }
                            else if (myBlock.type == 'image' && index == this.editingBlockIndex) {
                                myBlock.image = inData[0]  
                            }
                            else if (myBlock.type == 'text' && index == this.editingBlockIndex) {
                                myBlock.text = inData.text    
                            }
                            else if (myBlock.type == 'youtube' && index == this.editingBlockIndex) {
                                myBlock.autoplay = inData.autoplay
                                myBlock.loop = inData.loop
                                myBlock.orientation = inData.orientation
                                myBlock.url = inData.url    
                            }
                            
                            myTempBlocks.push(myBlock)
                        })

                        myFormBlocks = myTempBlocks
                    }
                    else {
                        let myNewBlock = null

                        if (inType == 'feature') {
                            myNewBlock = {
                                type: 'feature',
                                list: inData
                            }
                        }
                        else if (inType == 'image') {
                            myNewBlock = {
                                type: 'image',
                                image: inData[0]
                            }
                        }
                        else if (inType == 'text') {
                            myNewBlock = {
                                type: 'text',
                                text: inData.text
                            }
                        }
                        else if (inType == 'youtube') {
                            myNewBlock = {
                                type: 'youtube',
                                autoplay: inData.autoplay,
                                loop: inData.loop,
                                orientation: inData.orientation,
                                url: inData.url
                            }
                        }

                        if (myNewBlock) {
                            if (myFormBlocks.length) {
                                let myTempBlocks = []
                            
                                myFormBlocks.forEach((myBlock, index) => {
                                    if (index == this.addingBlockIndex) {
                                        myTempBlocks.push(myNewBlock)  
                                    }

                                    myTempBlocks.push(myBlock)
                                })

                                if (this.addingBlockIndex == myFormBlocks.length) {
                                    myTempBlocks.push(myNewBlock) 
                                }

                                myFormBlocks = myTempBlocks
                            }
                            else {
                                myFormBlocks.push(myNewBlock)
                            }
                        }
                    }
                    
                    if (inType == 'image') {
                        let myTempGallery = await this.buildOfflineTempGallery(inData, `traitMB_temp${this?.addingBlockTraitId ?? this.editingBlockTraitId}`)
                        
                        let myOtherData = {
                            groupIndex: this?.addingGroupId ?? this.editingGroupId,
                            traitId: this?.addingBlockTraitId ?? this.editingBlockTraitId
                        }

                        setTimeout(() => {
                            this.saveGalleryPrep(myTempGallery, 'traitMBGalleryUpdate', this.tempSellerVendor.id, 'sellerTraitMBGallery', myOtherData)
                        }, 100)
                    }

                    myGroup.blocks = myFormBlocks
                    this.refreshTraits = true
                    this.save()
                    this.clickCloseBlockPopup()
                }
            }
        },
        clickSaveTrait(inForm) {
            if (this.editingTrait) {
                this.sellerForm.product_traits.find((myTrait) => myTrait.id == inForm.id).digitalProduct = inForm.digitalProduct
                this.sellerForm.product_traits.find((myTrait) => myTrait.id == inForm.id).freeShipping = inForm.freeShipping
                this.sellerForm.product_traits.find((myTrait) => myTrait.id == inForm.id).itemCondition = inForm.itemCondition
                this.sellerForm.product_traits.find((myTrait) => myTrait.id == inForm.id).label = inForm.label
                this.sellerForm.product_traits.find((myTrait) => myTrait.id == inForm.id).taxRateId = inForm.taxRateId
                this.sellerForm.product_traits.find((myTrait) => myTrait.id == inForm.id).trackQuantity = inForm.trackQuantity
                this.sellerForm.product_traits.find((myTrait) => myTrait.id == inForm.id).units = inForm.units
            }
            else {
                this.sellerForm.product_traits.push(inForm)
            }

            this.save()
            this.refreshTraits = true
            this.clickCloseTraitPopup()
        },
        clickSaveZoneRate(inForm, inRates) {
            if (this.editingRate) {
                let myZone = this.sellerDataForm.shippingZones.find((zone) => zone.id == this.editingRateZoneId)

                if (!myZone.rates) {
                    myZone.rates = []
                }

                if (this.sellerDataForm.defaultShippingModel == 'weight') {
                    inRates.forEach((weightRate) => {
                        if (myZone.rates.find((zRate) => zRate.id == weightRate.id)) {
                            myZone.rates.find((zRate) => zRate.id == weightRate.id).minWeight = weightRate?.minWeight ?? 0
                            myZone.rates.find((zRate) => zRate.id == weightRate.id).maxWeight = weightRate?.maxWeight ?? 0
                            myZone.rates.find((zRate) => zRate.id == weightRate.id).price = weightRate.price
                        }
                        else {
                            let myForm = this.objCopy(inForm)
                            myForm.minWeight = weightRate?.minWeight ?? 0
                            myForm.maxWeight = weightRate?.maxWeight ?? 999999999
                            myForm.price = weightRate.price
                            myForm.id = myZone.rateId + 1 ?? 1
                            myZone.rateId = myForm.id
                            myZone.rates.push(myForm)
                        }
                    })

                    this.showZoneRatePopup = false
                    this.newZoneRateZoneId = null
                    this.editingRate = null
                    this.editingRateId = null
                    this.editingRateZone = null
                    this.editingRateZoneId = null
                    this.save()
                }
                else if (this.sellerDataForm.defaultShippingModel == 'price') {
                    inRates.forEach((priceRate) => {
                        if (myZone.rates.find((zRate) => zRate.id == priceRate.id)) {
                            myZone.rates.find((zRate) => zRate.id == priceRate.id).minPrice = priceRate?.minPrice ?? 0
                            myZone.rates.find((zRate) => zRate.id == priceRate.id).maxPrice = priceRate?.maxPrice ?? 0
                            myZone.rates.find((zRate) => zRate.id == priceRate.id).price = priceRate.price
                        }
                        else {
                            let myForm = this.objCopy(inForm)
                            myForm.minPrice = priceRate?.minPrice ?? 0
                            myForm.maxPrice = priceRate?.maxPrice ?? 999999999
                            myForm.price = priceRate.price
                            myForm.id = myZone.rateId + 1 ?? 1
                            myZone.rateId = myForm.id
                            myZone.rates.push(myForm)
                        }
                    })

                    this.showZoneRatePopup = false
                    this.newZoneRateZoneId = null
                    this.editingRate = null
                    this.editingRateId = null
                    this.editingRateZone = null
                    this.editingRateZoneId = null
                    this.save()
                }
                else {
                    if (myZone.rates.find((zRate) => zRate.id == this.editingRateId)) {
                        myZone.rates.find((zRate) => zRate.id == this.editingRateId).price = inForm.price
                        myZone.rates.find((zRate) => zRate.id == this.editingRateId).firstUnitPrice = inForm.firstUnitPrice
                        myZone.rates.find((zRate) => zRate.id == this.editingRateId).additionalUnitPrice = inForm.additionalUnitPrice
                        this.showZoneRatePopup = false
                        this.newZoneRateZoneId = null
                        this.editingRate = null
                        this.editingRateId = null
                        this.editingRateZone = null
                        this.editingRateZoneId = null
                        this.save()
                    }
                }
            }
            else {
                let myZone = this.sellerDataForm.shippingZones.find((zone) => zone.id == this.addingRateZone.id)

                if (!myZone.rates) {
                    myZone.rates = []
                }

                if (this.sellerDataForm.defaultShippingModel == 'weight') {
                    inRates.forEach((weightRate) => {
                        let myForm = this.objCopy(inForm)
                        myForm.minWeight = weightRate?.minWeight ?? 0
                        myForm.maxWeight = weightRate?.maxWeight ?? 999999999
                        myForm.price = weightRate.price
                        myForm.id = myZone.rateId + 1 ?? 1
                        myZone.rateId = myForm.id
                        myZone.rates.push(myForm)
                    })

                    this.showZoneRatePopup = false
                    this.newZoneRateZoneId = null
                    this.addingRateZone = null
                    this.save()
                }
                else if (this.sellerDataForm.defaultShippingModel == 'price') {
                    inRates.forEach((priceRate) => {
                        let myForm = this.objCopy(inForm)
                        myForm.minPrice = priceRate?.minPrice ?? 0
                        myForm.maxPrice = priceRate?.maxPrice ?? 999999999
                        myForm.price = priceRate.price
                        myForm.id = myZone.rateId + 1 ?? 1
                        myZone.rateId = myForm.id
                        myZone.rates.push(myForm)
                    })

                    this.showZoneRatePopup = false
                    this.newZoneRateZoneId = null
                    this.addingRateZone = null
                    this.save()
                }
                else {
                    inForm.id = myZone.rateId + 1 ?? 1
                    myZone.rateId = inForm.id
                    myZone.rates.push(inForm)
                    this.showZoneRatePopup = false
                    this.newZoneRateZoneId = null
                    this.addingRateZone = null
                    this.save()
                }
            }
        },
        getIgnorePages() {
            let myIgnorePages = []

            if (!this.hasListingType('physical')) {
                myIgnorePages.push(2, 3, 4, 5, 6, 7)
            }

            if (!this.hasListingType('physical') && !this.hasListingType('digital')) {
                myIgnorePages.push(8, 9, 10, 11, 12, 13)
            }

            this.ignorePages = myIgnorePages
        },
        hasListingType(inType) {
            if (this.sellerDataForm.listingTypes) {
                let myType = this.sellerDataForm.listingTypes.find((myType) => myType.type == inType)

                return myType ? myType.value : null
            }

            return null
        },
        jumpToPage(inPageNumber) {
            this.currentPage = inPageNumber
        },
        nextPage() {
            if (this.pages[this.currentPage].validation) {
                this.validating = false

                if (this.currentPage == 13) {
                    this.closeListingWizard()
                }
                else if (this.currentPage == 1 && !this.hasListingType('physical') && !this.hasListingType('digital')) {
                    this.closeListingWizard()
                }
                else {
                    this.currentPage = this.pages[this.currentPage].next
                }
            }
            else {
                this.validating = true
            }
        },
        previousPage() {
            this.currentPage = this.pages[this.currentPage].previous
        },
        resetCheckNonValidated() {
            this.checkNonValidated = false
        },
        resetRefreshTraits() {
            this.refreshTraits = false
        },
        save() {
            this.saveStart()
            this.checkValidation()

            let myData = { ...this.sellerForm, ...this.sellerDataForm }
            myData.vendor_id = this.sellerId

            buildzAxios?.post(this.getRoute('seller'), this.objCopy(myData))
                .then((response) => {
                    this.saveDone()

                    this.setStateData([
                        { sellerVendor: response.data.marketplace_vendor },
                        { sellerVendorData: response.data.marketplace_vendor_data },
                        { sellerVendorWhiteLabelData: response.data.marketplace_vendor_white_label_data }
                    ])
                })
                .catch((error) => {
                    console.log(error)
                    this.saveDone()
                })
        },
        saveDebounce: debounce(function() {
            this.save()
        }, 2000),
        updateAddingBlockIndex(inIndex, inGroupId, inTraitId) {
            this.addingBlockIndex = inIndex
            this.addingGroupId = inGroupId
            this.addingBlockTraitId = inTraitId
        }, 
        updateAddingGroupId(inId, inTraitId) {
            this.addingGroupId = inId
            this.addingBlockTraitId = inTraitId
        }, 
        updateAddOns(inAddOns) {
            this.sellerForm.product_add_ons = inAddOns
            this.save()
        },
        updateBulkDiscounts(inBulkDiscounts) {
            this.sellerForm.product_bulk_discounts = inBulkDiscounts
            this.save()
        },
        updateManualPickupAddress(inValue) {
            this.manualPickupAddress = inValue
        },
        updateModifiers(inModifiers) {
            this.sellerForm.product_variants = inModifiers
            this.save()
        },
        updateProductCategories(inCategories) {
            this.sellerForm.product_categories = inCategories
            this.save()
        },
        updateSellerDataFormLocalAddressValue(inField, inValue) {
            this.sellerDataForm.localPickupAddress.address[inField] = inValue
            this.saveDebounce()
        },
        updateSellerDataFormSelectValue(inField, inValue) {
            this.sellerDataForm[inField] = inValue
            this.save()
            this.getIgnorePages()
        },
        updateSellerDataFormValue(inField, inValue) {
            this.sellerDataForm[inField] = inValue
            this.saveDebounce()
        },
        updateSellerFormValue(inField, inValue) {
            this.sellerForm[inField] = inValue

            if (this.sellerId) {
                this.saveDebounce()
            }
        },
        updateTraits(inTraits) {
            this.sellerForm.product_traits = inTraits
            this.save()
        }
    }
}
</script>