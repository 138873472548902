<template>
    <wizard-popup-container v-if="formLoaded"
       headingText="Select New Detail Block Type" panelWidth="400px"
       @closeWizardPopup="clickCloseAddBlockPopup"
    >
        <template #main>
            <div class="w-full text-white p-1 flex flex-wrap items-center">
                <div :class="{ 'w-1/2 pr-1 pb-2': isDesktopWidth, 'w-full': !isDesktopWidth }">
                    <div @click="clickNewMediaBlock()" class="alt-secondary-button-base w-full">
                        Media Block
                    </div>
                </div>
                <div :class="{ 'w-1/2 pl-1 pb-2': isDesktopWidth, 'w-full': !isDesktopWidth }">
                    <div @click="clickNewTextBlock()" class="alt-secondary-button-base w-full">
                        Text Block
                    </div>
                </div>
                <div :class="{ 'w-1/2 pr-1': isDesktopWidth, 'w-full': !isDesktopWidth }">
                    <div @click="clickNewYouTubeBlock()" class="alt-secondary-button-base w-full">
                        YouTube Block
                    </div>
                </div>
                <div :class="{ 'w-1/2 pl-1': isDesktopWidth, 'w-full': !isDesktopWidth }">
                    <div @click="clickNewFeaturesBlock()" class="alt-secondary-button-base w-full">
                        Features Block
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="w-full flex items-center border-t border-gray-800 py-2 h-12 rounded-b-md">
                <div class="w-full">
                    <div @click="clickCloseAddBlockPopup()" class="secondary-button-base mx-2">
                        Cancel
                    </div>
                </div>
            </div>
        </template>
    </wizard-popup-container>
</template>

<script>
export default {
    data() {
        return {
            formLoaded: false
        }
    },
    mounted() {
        this.formLoaded = true
    },
    methods: {
        clickCloseAddBlockPopup() {
            this.$emit('clickCloseAddBlockPopup')
        },
        clickNewFeaturesBlock() {
            this.$emit('clickNewFeaturesBlock')
        },
        clickNewMediaBlock() {
            this.$emit('clickNewMediaBlock')
        },
        clickNewTextBlock() {
            this.$emit('clickNewTextBlock')
        },
        clickNewYouTubeBlock() {
            this.$emit('clickNewYouTubeBlock')
        }
    }
}
</script>