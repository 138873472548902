<template>
    <table-record 
        :extraClasses="extraClasses" :noVerticalPadding="true" :recordClasses="tableRecordClasses"
    >
        <template #record>
            <div class="w-full flex justify-start items-center pl-2">
                <child-line-prefix v-if="showChildLinePrefix" />
                <div v-if="prefixText" class="mr-1 whitespace-nowrap">
                    {{ prefixText }}
                </div>
                <select-panel v-if="fieldType == 'select'"
                    :fieldData="fieldData" :fieldDataLabel="fieldDataLabel" :fieldDataValue="fieldDataValue" :fieldFormName="fieldFormName" 
                    :fieldNude="true" :fieldRequired="true" :fieldValue="fieldValue" :forceMobile="true" :hideLabel="true"
                    @fieldChange="updateFieldValue" @hitEnter="clickSave" @hitEscape="clickCancel"
                />
                <input-panel v-else
                    :fieldFormName="fieldFormName" :fieldId="fieldId" :fieldNude="true" :fieldPlaceholder="placeholder" :fieldRequired="true" 
                    :fieldType="fieldType" :fieldValue="fieldValue" :forceMobile="true" :hideLabel="true"
                    @fieldInput="updateFieldValue" @hitEnter="clickSave" @hitEscape="clickCancel"
                />
            </div>
            <div class="wizard-icon-container">
                <div v-if="!hideCancel" @click="clickCancel()" class="cursor-pointer">
                    <fa-icon icon="ban" type="fas" class="h-5 w-5" />
                </div>
                <div @click="clickSave()" class="cursor-pointer ml-2">
                    <fa-icon icon="floppy-disk" type="fas" class="h-5 w-5" />
                </div>
            </div>
        </template>
        <template #extra>
            <div class="pr-8" :class="{ 'h-3': !validatingForm, 'py-1': validatingForm }">
                <validation-message v-if="validatingForm && !fieldValue"
                    :message="valueErrorMessage"
                />
                <validation-message v-if="validatingForm && fieldValue && duplicateValueError"
                    :message="duplicateErrorMessage"
                />
            </div>
        </template>
    </table-record>
</template>

<script>
import TableRecord from './TableRecord'

export default {
    props:[
        'duplicateValueError',
        'duplicateValueErrorMessage',
        'extraClasses',
        'fieldData',
        'fieldDataLabel',
        'fieldDataValue',
        'fieldFormName',
        'fieldId',
        'fieldPlaceholder',
        'fieldType',
        'fieldValue',
        'fieldValueErrorMessage',
        'hideCancel',
        'prefixText',
        'recordClasses',
        'showChildLinePrefix',
        'validatingForm'
    ],
    components: {
        TableRecord
    },
    computed: {
        duplicateErrorMessage() {
            return this.duplicateValueErrorMessage ?? "Duplicate Label"
        },
        placeholder() {
            return this.fieldPlaceholder ?? "Label"
        },
        tableRecordClasses() {
            return this.recordClasses ?? "pt-3"
        },
        valueErrorMessage() {
            return this.fieldValueErrorMessage ?? "Label must be entered"
        }
    },
    methods: {
        clickCancel() {
            if (!this.hideCancel) {
                this.$emit('clickCancel')
            }
        },
        clickSave() {
            this.$emit('clickSave')
        },
        updateFieldValue(inField, inValue) {
            this.$emit('updateFieldValue', inField, inValue)
        }
    }
}
</script>