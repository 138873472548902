<template>
    <wizard-container
       :openConditions="wizardData && productForm"
       @closeWizard="closeProductWizard"
    >
        <template #main>
            <wizard-page v-if="formLoaded"
                :checkNonValidated="checkNonValidated" :currentPage="currentPage" :hideLogo="currentPage == 1" :hidePrevious="currentPage < 3" :hideProgress="currentPage == 1" 
                :introPages="introPages" :myPage="currentPage" :outroPages="outroPages" :pages="pages" :startPage="startPage" :wizardData="wizardData"
                @jumpToPage="jumpToPage" @nextPage="nextPage" @previousPage="previousPage" @resetCheckNonValidated="resetCheckNonValidated"
            >
                <template #main>
                    <page01 v-if="currentPage == 1"
                    />
                    <page02 v-if="currentPage == 2"
                        :productForm="productForm" :validating="validating"
                        @updateProductFormValue="updateProductFormValue"
                    />
                    <page03 v-if="currentPage == 3"
                        :imagesArray="imagesArray" :validating="validating"
                        @galleryImageSavedOkay="galleryImageSavedOkay" @saveLocalGallery="saveLocalGallery" @updateOfflineGallery="updateOfflineGallery"
                    />
                    <page04 v-if="currentPage == 4"
                        :productForm="productForm" :validating="validating"
                        @clickAddBlock="clickAddBlock" @clickDeleteMediaBlock="clickDeleteMediaBlock" @clickEditFeaturesBlock="clickEditFeaturesBlock" @clickEditMediaBlock="clickEditMediaBlock" 
                        @clickEditTextBlock="clickEditTextBlock" @clickEditYouTubeBlock="clickEditYouTubeBlock" @updateMediaBlocks="updateMediaBlocks"
                    />
                    <page05 v-if="currentPage == 5"
                        :productForm="productForm" :validating="validating"
                        @updateProductFormSelectValue="updateProductFormSelectValue"
                    />
                    <page06 v-if="currentPage == 6"
                        :productDataForm="productDataForm" :productForm="productForm" :validating="validating"
                        @updateProductDataFormSelectValue="updateProductDataFormSelectValue" @updateProductFormValue="updateProductFormValue" @updateProductFormSelectValue="updateProductFormSelectValue"
                    />
                    <page07 v-if="currentPage == 7"
                        :productForm="productForm" :validating="validating"
                        @updateProductFormValue="updateProductFormValue" @updateProductFormSelectValue="updateProductFormSelectValue"
                    />
                    <page08 v-if="currentPage == 8"
                        :productBulkDiscounts="bulkDiscounts" :productDataForm="productDataForm" :validating="validating"
                        @clickDeleteBulkDiscount="clickDeleteBulkDiscount" @clickEditBulkDiscount="clickEditBulkDiscount" @clickNewBulkDiscount="clickNewBulkDiscount" 
                        @clickToggleBulkDiscountEnabled="clickToggleBulkDiscountEnabled" @updateProductDataFormSelectValue="updateProductDataFormSelectValue"
                    />
                    <page09 v-if="currentPage == 9"
                        :productForm="productForm" :productVariants="productVariants" :refreshVariantValues="refreshVariantValues" :validating="validating"
                        @clickDeleteVariant="clickDeleteVariant" @clickEditTheVariant="clickEditTheVariant" @clickEditVariant="clickEditVariant" @clickNewVariant="clickNewVariant" 
                        @resetRefreshVariantValues="resetRefreshVariantValues" @updateProductFormSelectValue="updateProductFormSelectValue" @updateVariantValues="updateVariantValues" 
                        @updateVendorVariants="updateVendorVariants"
                    />
                    <page10 v-if="currentPage == 10"
                        :productForm="productForm" :productAddOns="productAddOns" :refreshAddOnValues="refreshAddOnValues" :validating="validating"
                        @clickDeleteAddOn="clickDeleteAddOn" @clickEditAddOn="clickEditAddOn" @clickNewAddOn="clickNewAddOn" @resetRefreshAddOnValues="resetRefreshAddOnValues" 
                        @updateProductFormSelectValue="updateProductFormSelectValue" @updateAddOnValues="updateAddOnValues" @updateVendorAddOns="updateVendorAddOns"
                    />
                    <page11 v-if="currentPage == 11"
                        :productForm="productForm"
                        @updateProductFormSelectValue="updateProductFormSelectValue"
                    />
                </template>
                <template #popups>
                    <add-block-popup v-if="showAddBlockPopup && currentPage == 4" 
                        @clickCloseAddBlockPopup="clickCloseAddBlockPopup" @clickNewFeaturesBlock="clickNewFeaturesBlock" @clickNewMediaBlock="clickNewMediaBlock" 
                        @clickNewTextBlock="clickNewTextBlock" @clickNewYouTubeBlock="clickNewYouTubeBlock"
                    />
                    <add-on-popup v-if="showAddOnPopup && currentPage == 10" 
                        :editingAddOn="editingAddOn" :editingAddOnValues="editingAddOnValues" :productAddOns="productAddOns"
                        @clickCloseAddOnPopup="clickCloseAddOnPopup" @clickSaveAddOn="clickSaveAddOn"
                    />
                    <bulk-discount-popup v-if="showBulkDiscountPopup && currentPage == 8" 
                        :editingBulkDiscount="editingBulkDiscount" :productBulkDiscounts="bulkDiscounts"
                        @clickCloseBulkDiscountPopup="clickCloseBulkDiscountPopup" @clickSaveBulkDiscount="clickSaveBulkDiscount"
                    />
                    <edit-variant-popup v-if="showEditVariantPopup && currentPage == 9" 
                        :editingTheVariant="editingTheVariant" :editingTheVariantPrice="editingTheVariantPrice" :productForm="productForm"
                        @clickCloseEditVariantPopup="clickCloseEditVariantPopup" @clickSaveTheVariant="clickSaveTheVariant"
                    />
                    <features-block-popup v-if="showFeaturesBlockPopup && currentPage == 4" 
                        :editingBlock="editingBlock" 
                        @clickCloseFeaturesBlockPopup="clickCloseFeaturesBlockPopup" @clickSaveFeaturesBlock="clickSaveFeaturesBlock"
                    />
                    <media-block-popup v-if="showMediaBlockPopup && currentPage == 4" 
                        :editingBlock="editingBlock"
                        @clickCloseMediaBlockPopup="clickCloseMediaBlockPopup" @clickSaveMediaBlock="clickSaveMediaBlock"
                    />
                    <text-block-popup v-if="showTextBlockPopup && currentPage == 4" 
                        :editingBlock="editingBlock" 
                        @clickCloseTextBlockPopup="clickCloseTextBlockPopup" @clickSaveTextBlock="clickSaveTextBlock"
                    />
                    <variant-popup v-if="showVariantPopup && currentPage == 9" 
                        :editingVariant="editingVariant" :editingVariantValues="editingVariantValues" :productVariants="productVariants"
                        @clickCloseVariantPopup="clickCloseVariantPopup" @clickSaveVariant="clickSaveVariant"
                    />
                    <you-tube-block-popup v-if="showYouTubeBlockPopup && currentPage == 4" 
                        :editingBlock="editingBlock" 
                        @clickCloseYouTubeBlockPopup="clickCloseYouTubeBlockPopup" @clickSaveYouTubeBlock="clickSaveYouTubeBlock"
                    />
                </template>
            </wizard-page>
        </template>
    </wizard-container>
</template>

<script>
import {debounce} from 'lodash'

import AddBlockPopup from './popups/AddBlockPopup'
import AddOnPopup from './popups/AddOnPopup'
import BulkDiscountPopup from './popups/BulkDiscountPopup'
import EditVariantPopup from './popups/EditVariantPopup'
import FeaturesBlockPopup from './popups/FeaturesBlockPopup'
import MediaBlockPopup from './popups/MediaBlockPopup'
import Page01 from './pages/Page01'
import Page02 from './pages/Page02'
import Page03 from './pages/Page03'
import Page04 from './pages/Page04'
import Page05 from './pages/Page05'
import Page06 from './pages/Page06'
import Page07 from './pages/Page07'
import Page08 from './pages/Page08'
import Page09 from './pages/Page09'
import Page10 from './pages/Page10'
import Page11 from './pages/Page11'
import TextBlockPopup from './popups/TextBlockPopup'
import VariantPopup from './popups/VariantPopup'
import WizardPage from '../WizardPage'
import YouTubeBlockPopup from './popups/YouTubeBlockPopup'

export default {
    components: {
        AddBlockPopup,
        AddOnPopup,
        BulkDiscountPopup,
        EditVariantPopup,
        FeaturesBlockPopup,
        MediaBlockPopup,
        Page01,
        Page02,
        Page03,
        Page04,
        Page05,
        Page06,
        Page07,
        Page08,
        Page09,
        Page10,
        Page11,
        TextBlockPopup,
        VariantPopup,
        WizardPage,
        YouTubeBlockPopup
    },
    data() {
        return {
            avatarGallery: [],
            avatarImage: null,
            checkNonValidated: false,
            currentPage: 1,
            editingAddOn: null,
            editingAddOnValues: null,
            editingBlock: null,
            editingBlockIndex: null,
            editingBulkDiscount: null,
            editingProduct: null,
            editingTheVariant: null,
            editingTheVariantPrice: null,
            editingVariant: null,
            editingVariantValues: null,
            formLoaded: false,
            gallery: [],
            introPages: 1,
            outroPages: 0,
            newBlockIndex: null,
            productDataForm: {
                hasBulkDiscounts: null,
                hasMinimumPurchaseQty: null
            },
            productForm: {
                buildz_group_id: null,
                bulk_discounts: null,
                compare_at_price: null,
                currency: null,
                digital_product: false,
                disabled: false,
                discount_codes: null,
                fitment_data: null,
                free_shipping: false,
                grams: null,
                group_buy_only: false,
                has_add_ons: false,
                has_variants: false,
                item_condition: null,
                media_blocks: null,
                min_order_qty: 1,
                price: null,
                product_add_ons: null,
                product_group_id: null,
                product_variants: null,
                qty: null,
                sku: null,
                tax_rate_id: null,
                title: null,
                track_quantity: false,
                units: false,
                white_label_only: false
            },
            productId: null,
            refreshAddOnValues: false,
            refreshVariantValues: false,
            showAddBlockPopup: false,
            showAddOnPopup: false,
            showBulkDiscountPopup: false,
            showEditVariantPopup: false,
            showFeaturesBlockPopup: false,
            showMediaBlockPopup: false,
            showTextBlockPopup: false,
            showVariantPopup: false,
            showYouTubeBlockPopup: false,
            startPage: null,
            validating: false,
            wizardData: null
        }
    },
    computed: {
        bulkDiscounts() {
            return this.productForm.bulk_discounts
        },
        editingProduct() {
            return this.productToEdit
        },
        imagesArray() {
            let myGallery = []

            this.gallery.forEach((myImage) => {
                myGallery.push(myImage)
            })

            return myGallery
        },
        pages() {
            return {
                1: {
                    next: 2,
                    previous: false,
                    validation: true
                },
                2: {
                    next: 3,
                    previous: false,
                    validation: Boolean(this.productForm.title)
                },
                3: {
                    next: 4,
                    previous: 2,
                    validation: true
                },
                4: {
                    next: 5,
                    previous: 3,
                    validation: true
                },
                5: {
                    next: 6,
                    previous: 4,
                    validation: (this.productForm.digital_product == true || (this.productForm.digital_product == false && this.productForm.free_shipping != null && this.productForm.item_condition != null) && this.productForm.group_buy_only != null && this.productForm.white_label_only != null)
                },
                6: {
                    next: 7,
                    previous: 5,
                    validation: (this.productForm.track_quantity == false || (this.productForm.track_quantity == true && this.productForm.qty != null)) && (this.productDataForm.hasMinimumPurchaseQty == false || (this.productDataForm.hasMinimumPurchaseQty == true && this.productForm.min_order_qty != null && this.productForm.min_order_qty > 0))
                },
                7: {
                    next: 8,
                    previous: 6,
                    validation: Boolean(this.productForm.units) && Boolean(this.productForm.price) && this.productForm.price > 0 && Boolean(this.productForm.tax_rate_id) && (!Boolean(this.productForm.compare_at_price) || this.productForm.compare_at_price > this.productForm.price)
                },
                8: {
                    next: 9,
                    previous: 7,
                    validation: this.productDataForm.hasBulkDiscounts == false || (this.productDataForm.hasBulkDiscounts && (this?.productForm?.bulk_discounts?.discounts ?? false) && this.productForm.bulk_discounts.discounts.length)
                },
                9: {
                    next: 10,
                    previous: 8,
                    validation: this.productForm.has_variants == false || (this.productForm.has_variants && this.sortedVendorVariants.length)
                },
                10: {
                    next: 11,
                    previous: 9,
                    validation: this.productForm.has_add_ons == false || (this.productForm.has_add_ons && this.sortedVendorAddOns.length)
                },
                11: {
                    next: 11,
                    nextText: "Close",
                    previous: 10,
                    validation: true
                }
            }
        },
        productAddOns() {
            return this.productForm.product_add_ons
        },
        productVariants() {
            return this.productForm.product_variants
        },
        sortedVendorAddOns() {
            if (!this?.productAddOns?.vendorAddOns) {
                return []
            }

            return this.productAddOns.vendorAddOns.filter((myVariant) => myVariant.selected)
        },
        sortedVendorVariants() {
            if (!this.productVariants.vendorVariants) {
                return []
            }

            return this.productVariants.vendorVariants.filter((myVariant) => myVariant.selected)
        }
    },
    watch: {
        editingProduct: {
            immediate: true,
            handler(val) {
                if (!this.productId) {
                    if (val) {
                        this.productId = this.editingProduct.id
                        this.gallery = this?.editingProduct?.gallery ?? []
                        this.productForm.bulk_discounts = this.editingProduct.bulk_discounts

                        if (!this.productForm.bulk_discounts || !this.productForm.bulk_discounts.discounts) {
                            this.productForm.bulk_discounts = {
                                discounts: []
                            }
                        }

                        this.productForm.compare_at_price = this?.editingProduct?.compare_at_price?.amount ?? null
                        this.productForm.currency = this.sellerVendorData.defaultCurrency
                        this.productForm.digital_product = this.editingProduct.digital_product
                        this.productForm.disabled = this.editingProduct.disabled
                        this.productForm.free_shipping = this.editingProduct.free_shipping
                        this.productForm.has_add_ons = this.editingProduct.has_add_ons
                        this.productForm.has_variants = this.editingProduct.has_variants
                        this.productForm.item_condition = this.editingProduct.item_condition
                        this.productForm.media_blocks = this.editingProduct.media_blocks
                        this.productForm.min_order_qty = this.editingProduct.min_order_qty
                        this.productForm.price = this?.editingProduct?.price?.amount ?? null 

                        this.productForm.product_add_ons = this.editingProduct.product_add_ons && this.objLength(this.editingProduct.product_add_ons) 
                            ? this.editingProduct.product_add_ons 
                            : {
                                vendorAddOns: [],
                                addOnValues: []
                            }

                        this.productForm.product_variants = this.editingProduct.product_variants && this.objLength(this.editingProduct.product_variants)
                            ? this.editingProduct.product_variants
                            : {
                                theVariants: [],
                                variantValues: [],
                                vendorVariants: []
                            }

                        this.productForm.qty = this.editingProduct.qty
                        this.productForm.sku = this.editingProduct.sku
                        this.productForm.tax_rate_id = this.editingProduct.tax_rate_id
                        this.productForm.title = this.editingProduct.title
                        this.productForm.track_quantity = this.editingProduct.track_quantity
                        this.productForm.units = this.editingProduct.units

                        this.productDataForm.hasBulkDiscounts = this.editingProduct?.data?.hasBulkDiscounts ?? null
                        this.productDataForm.hasMinimumPurchaseQty = this.editingProduct?.data?.hasMinimumPurchaseQty ?? null
                    }
                    else {
                        this.productId = null
                        this.gallery = []

                        this.productForm.bulk_discounts = {
                            discounts: []
                        }

                        this.productForm.compare_at_price = null
                        this.productForm.currency = this.sellerVendorData.defaultCurrency
                        this.productForm.digital_product = null
                        this.productForm.disabled = true
                        this.productForm.free_shipping = null
                        this.productForm.has_add_ons = null
                        this.productForm.has_variants = null
                        this.productForm.item_condition = null
                        this.productForm.media_blocks = []
                        this.productForm.min_order_qty = null
                        this.productForm.price = null

                        this.productForm.product_add_ons = {
                            vendorAddOns: [],
                            addOnValues: []
                        }
                        
                        this.productForm.product_variants = {
                            theVariants: [],
                            variantValues: [],
                            vendorVariants: []
                        }

                        this.productForm.qty = null
                        this.productForm.sku = null
                        this.productForm.tax_rate_id = this.sellerVendorData.defaultTaxRateId
                        this.productForm.title = null
                        this.productForm.track_quantity = null
                        this.productForm.units = null
                        
                        this.productDataForm.hasBulkDiscounts = null
                        this.productDataForm.hasMinimumPurchaseQty = null
                    }
                }
            }
        },
        wizardProductData: {
            immediate: true,
            handler(val) {
                this.wizardData = this.wizardProductData
            }
        }
    },
    mounted() {
        if (this.wizardEditingProduct) {
            this.editingProduct = this.objCopy(this.wizardEditingProduct)
        }

        if (this.wizardProductStartPage) {
            this.startPage = this.wizardProductStartPage
        }

        this.formLoaded = true
    },
    beforeUnmount() {
        this.setStateData([
            { wizardEditingProduct: null },
            { wizardProductStartPage: null }
        ])
    },
    methods: {
        buildVariants(inVariantIdentifier, inValueIdentifier) {
            let myVariants = []
            let myCount = 0
            let myVariantString = ""

            this.sellerVendor.product_variants
                .forEach((myVendorVariant) => {
                    let myVariant = this.objCopy(this.productForm.product_variants.variantValues.find((vfVariant) => vfVariant.identifier == myVendorVariant.identifier))
                    
                    if (myVariant && this.productForm.product_variants.vendorVariants.find((pvVariant) => pvVariant.identifier == myVendorVariant.identifier).selected) {
                        if (myVariant.identifier != inVariantIdentifier) {
                            myVariants[myCount] = []

                            myVariant.values
                                .sort((a, b) => a.identifier < b.identifier ? -1 : 1)
                                .forEach((myValue) => {
                                    myVariants[myCount].push(myValue.identifier)
                                })
                        }
                        else {
                            myVariants[myCount] = [inValueIdentifier]
                        }

                        if (myCount > 0) {
                            myVariantString = `${myVariantString}|` 
                        }

                        myVariantString = `${myVariantString}${myVariant.identifier}` 
                        myCount++
                    }
                })
            
            let myStrings = []

            if (myVariants.length) {
                myVariants[0].forEach((myValue) => {
                    myStrings.push(myValue)
                    
                    myVariants.forEach((myVariant, index) => {
                        if (index != 0) {
                            myVariant.forEach((myNextValue) => {
                                myStrings.forEach((myVString) => {
                                    let pipeCount = myVString.split("|").length

                                    if (pipeCount == index && myVString.split("|")[0] == myValue) {
                                        myStrings.push(`${myVString}|${myNextValue}`)
                                    }
                                })
                            })
                        }
                    })
                })

                myStrings.forEach((myString) => {
                    let mySectionsCount = myString.split("|").length

                    if (mySectionsCount == myVariants.length) {
                        let myVariant = null

                        if (this.productForm.product_variants.theVariants && this.productForm.product_variants.theVariants.length) {
                            myVariant = this.productForm.product_variants.theVariants.find((tVariant) => tVariant.identifier == myString)
                        }
                        else {
                            this.productForm.product_variants.theVariants = []
                        }

                        if (!myVariant) {
                            let myNewVariant = {
                                enabled: true,
                                identifier: myString,
                                overridePrice: null,
                                qty: null,
                                sku: null,
                                variants: myVariantString
                            }

                            this.productForm.product_variants.theVariants.push(myNewVariant)
                        }
                    }
                })
            }

            let myTheVariants = []

            this.productForm.product_variants.theVariants.forEach((myVariant) => {
                let myVariantsAr = myVariant.variants.split("|")
                let myIdentifierAr = myVariant.identifier.split("|")
                let mySelectedCount = this.productForm.product_variants.vendorVariants.filter((myVVariant) => myVVariant.selected).length
                let myOkay = true

                if (myVariantsAr.length != mySelectedCount || myIdentifierAr.length != mySelectedCount) {
                    myOkay = false
                }
                else {
                    myVariantsAr.forEach((myItem, index) => {
                        if (myOkay) {
                            let myFoundVariant = this.productForm.product_variants.vendorVariants.find((myVVariant) => myVVariant.identifier == myItem)
                            
                            if (!myFoundVariant || !myFoundVariant.selected) {
                                myOkay = false
                            }
                            else {
                                let myFoundValues = this.productForm.product_variants.variantValues.find((myVValue) => myVValue.identifier == myItem)

                                if (!myFoundValues) {
                                    myOkay = false
                                }
                                else {
                                    if (!myFoundValues.values.find((myFValue) => myFValue.identifier == myIdentifierAr[index])) {
                                        myOkay = false
                                    }
                                }
                            }
                        }
                    })
                }

                if (myOkay) {
                    myTheVariants.push(this.objCopy(myVariant))
                }
            })

            myTheVariants.forEach((myPVariant) => {
                let myVariantsAr = myPVariant.variants.split("|")
                let myIdentifierAr = myPVariant.identifier.split("|")
                let myNewVariantsAr = []
                let myNewIdentifierAr = []

                this.productForm.product_variants.vendorVariants.forEach((myVVariant) => {
                    if (myVariantsAr.find((myV) => myV == myVVariant.identifier)) {
                        myNewVariantsAr.push(myVVariant.identifier)
                        myNewIdentifierAr.push(myIdentifierAr[myVariantsAr.findIndex((myV) => myV == myVVariant.identifier)])
                    }
                })

                myPVariant.variants = myNewVariantsAr.join("|")
                myPVariant.identifier = myNewIdentifierAr.join("|")
            })

            this.productForm.product_variants.theVariants = myTheVariants
            this.save()
        },
        checkValidation() {
            this.checkNonValidated = true
        },
        clickAddBlock(inIndex) {
            this.newBlockIndex = inIndex
            this.showAddBlockPopup = true
        },
        clickCloseAddBlockPopup() {
            this.showAddBlockPopup = false
        },
        clickCloseAddOnPopup() {
            this.editingAddOn = null
            this.editingAddOnValues = null
            this.showAddOnPopup = false
        },
        clickCloseBulkDiscountPopup() {
            this.editingBulkDiscount = null
            this.showBulkDiscountPopup = false
        },
        clickCloseEditVariantPopup() {
            this.editingTheVariant = null
            this.editingTheVariantPrice = null
            this.showEditVariantPopup = false
        },
        clickCloseFeaturesBlockPopup() {
            this.showFeaturesBlockPopup = false
            this.editingBlockIndex = null
            this.editingBlock = null
        },
        clickCloseMediaBlockPopup() {
            this.showMediaBlockPopup = false
            this.editingBlockIndex = null
            this.editingBlock = null
        },
        clickCloseTextBlockPopup() {
            this.showTextBlockPopup = false
            this.editingBlockIndex = null
            this.editingBlock = null
        },
        clickCloseVariantPopup() {
            this.showVariantPopup = false
            this.editingVariant = null
            this.editingVariantValues = null
        },
        clickCloseYouTubeBlockPopup() {
            this.showYouTubeBlockPopup = false
            this.editingBlockIndex = null
            this.editingBlock = null
        },
        clickDeleteAddOn(inIdentifier) {
            this.productForm.product_add_ons.vendorAddOns.find((myAddOn) => myAddOn.identifier == inIdentifier).selected = false
            this.save()
        },
        clickDeleteBulkDiscount(inId) {
            this.productForm.bulk_discounts.discounts = this.productForm.bulk_discounts.discounts.filter((myDiscount) => myDiscount.id != inId)
            this.save()
        },
        clickDeleteMediaBlock(inIndex, inType) {
            let theType = inType == 'image' ? 'Media' : (inType == 'youtube' ? 'YouTube' : (inType == 'feature' ? 'Features' : 'Text'))

            if (confirm(`You wish to delete this ${theType} block?`)) {
                let myFormBlocks = this.productForm.media_blocks.filter((myBlock, index) => index != inIndex)
                this.updateMediaBlocks(myFormBlocks)
            }
        },
        clickDeleteVariant(inIdentifier) {
            this.productForm.product_variants.vendorVariants.find((myVariant) => myVariant.identifier == inIdentifier).selected = false
            this.save()
            this.buildVariants()
        },
        clickEditAddOn(inIdentifier) {
            let editAddOn = this.productAddOns.vendorAddOns.find((myAddOn) => myAddOn.identifier == inIdentifier)
            let editAddOnValues = this.productAddOns.addOnValues.find((myValue) => myValue.identifier == inIdentifier)

            if (editAddOn && editAddOnValues) {
                this.editingAddOn = editAddOn
                this.editingAddOnValues = editAddOnValues?.values ?? []
                this.showAddOnPopup = true
            }
        },
        clickEditBulkDiscount(inBulkDiscountId) {
            this.editingBulkDiscount = this.productForm.bulk_discounts.discounts.find((myDiscount) => myDiscount.id == inBulkDiscountId)
            this.showBulkDiscountPopup = true
        },
        clickEditFeaturesBlock(inIndex) {
            let editBlock = this.productForm.media_blocks.find((myBlock, index) => index == inIndex && myBlock.type == 'feature')

            if (editBlock) {
                this.editingBlockIndex = inIndex
                this.editingBlock = editBlock
                this.showFeaturesBlockPopup = true
            }
        },
        clickEditMediaBlock(inIndex) {
            let editBlock = this.productForm.media_blocks.find((myBlock, index) => index == inIndex && myBlock.type == 'image')

            if (editBlock) {
                this.editingBlockIndex = inIndex
                this.editingBlock = editBlock
                this.showMediaBlockPopup = true
            }
        },
        clickEditTextBlock(inIndex) {
            let editBlock = this.productForm.media_blocks.find((myBlock, index) => index == inIndex && myBlock.type == 'text')

            if (editBlock) {
                this.editingBlockIndex = inIndex
                this.editingBlock = editBlock
                this.showTextBlockPopup = true
            }
        },
        clickEditTheVariant(inIdentifier, inPrice) {
            this.editingTheVariant = this.productForm.product_variants.theVariants.find((myVariant) => myVariant.identifier == inIdentifier)
            this.editingTheVariantPrice = inPrice
            this.showEditVariantPopup = true
        },
        clickEditYouTubeBlock(inIndex) {
            let editBlock = this.productForm.media_blocks.find((myBlock, index) => index == inIndex && myBlock.type == 'youtube')

            if (editBlock) {
                this.editingBlockIndex = inIndex
                this.editingBlock = editBlock
                this.showYouTubeBlockPopup = true
            }
        },
        clickEditVariant(inIdentifier) {
            let editVariant = this.productVariants.vendorVariants.find((myVariant) => myVariant.identifier == inIdentifier)
            let editVariantValues = this.productVariants.variantValues.find((myValue) => myValue.identifier == inIdentifier)

            if (editVariant && editVariantValues) {
                this.editingVariant = editVariant
                this.editingVariantValues = editVariantValues?.values ?? []
                this.showVariantPopup = true
            }
        },
        clickNewAddOn() {
            this.showAddOnPopup = true
        },
        clickNewBulkDiscount() {
            this.showBulkDiscountPopup = true
        },
        clickNewFeaturesBlock() {
            this.clickCloseAddBlockPopup()
            this.showFeaturesBlockPopup = true
        },
        clickNewMediaBlock() {
            this.clickCloseAddBlockPopup()
            this.showMediaBlockPopup = true
        },
        clickNewTextBlock() {
            this.clickCloseAddBlockPopup()
            this.showTextBlockPopup = true
        },
        clickNewVariant() {
            this.showVariantPopup = true
        },
        clickNewYouTubeBlock() {
            this.clickCloseAddBlockPopup()
            this.showYouTubeBlockPopup = true
        },
        clickSaveAddOn(inForm) {
            this.productForm.product_add_ons.vendorAddOns.find((myAddOn) => myAddOn.identifier == inForm.addOn.identifier).selected = true
            this.productForm.product_add_ons.addOnValues.find((myValues) => myValues.identifier == inForm.addOn.identifier).values = inForm.values
            this.save()
            this.refreshAddOnValues = true
            this.clickCloseAddOnPopup()
        },
        clickSaveBulkDiscount(inForm) {
            if (this.editingBulkDiscount) {
                this.productForm.bulk_discounts.discounts.find((myDiscount) => myDiscount.id == inForm.id).qty = inForm.qty
                this.productForm.bulk_discounts.discounts.find((myDiscount) => myDiscount.id == inForm.id).percentage = inForm.percentage
                this.productForm.bulk_discounts.discounts.find((myDiscount) => myDiscount.id == inForm.id).enabled = inForm.enabled
            }
            else {
                this.productForm.bulk_discounts.discounts.push(inForm)
            }
            
            this.save()
            this.clickCloseBulkDiscountPopup()
        },
        async clickSaveFeaturesBlock(inFeaturesList) {
            let myFormBlocks = this.objCopy(this.productForm.media_blocks)

            inFeaturesList.forEach((myRecord) => {
                if (myRecord.gallery && myRecord.gallery.length) {
                    myRecord.image = myRecord.gallery[0]
                }
                else {
                    myRecord.image = null
                }
            })

            if (this.editingBlock) {
                let myTempBlocks = []
                
                myFormBlocks.forEach((myBlock, index) => {
                    if (myBlock.type == 'feature' && index == this.editingBlockIndex) {
                        myBlock.list = inFeaturesList    
                    }
                    
                    myTempBlocks.push(myBlock)
                })

                myFormBlocks = myTempBlocks
            }
            else {
                let myFeaturesBlock = {
                    type: 'feature',
                    list: inFeaturesList
                }

                if (this.newBlockIndex) {
                    let myTempBlocks = []
                
                    myFormBlocks.forEach((myBlock, index) => {
                        myTempBlocks.push(myBlock)

                        if (index == this.newBlockIndex) {
                            myTempBlocks.push(myFeaturesBlock)  
                        }
                    })

                    myFormBlocks = myTempBlocks
                    this.newBlockIndex = null
                }
                else {
                    myFormBlocks.push(myFeaturesBlock)
                }
            }

            inFeaturesList.forEach(async (myRecord) => {
                if (myRecord.gallery && myRecord.gallery.length) {
                    let myTempGallery = await this.buildOfflineTempGallery(myRecord.gallery, `listingMBFeature_temp${this.productId}_${myRecord.id}`)
                    
                    setTimeout(() => {
                        this.saveGalleryPrep(myTempGallery, 'listingMBFeatureGalleryUpdate', this.productId, 'productMBFeatureGallery')
                    }, 100)
                }
            })

            this.updateMediaBlocks(myFormBlocks)
            this.clickCloseFeaturesBlockPopup()
        },
        async clickSaveMediaBlock(inGallery) {
            let myFormBlocks = this.objCopy(this.productForm.media_blocks)

            if (this.editingBlock) {
                let myTempBlocks = []
                
                myFormBlocks.forEach((myBlock, index) => {
                    if (myBlock.type == 'image' && index == this.editingBlockIndex) {
                        myBlock.image = inGallery[0]  
                    }
                    
                    myTempBlocks.push(myBlock)
                })

                myFormBlocks = myTempBlocks
            }
            else {
                let myMediaBlock = {
                    type: 'image',
                    image: inGallery[0]
                }

                if (this.newBlockIndex) {
                    let myTempBlocks = []
                
                    myFormBlocks.forEach((myBlock, index) => {
                        myTempBlocks.push(myBlock)

                        if (index == this.newBlockIndex) {
                            myTempBlocks.push(myMediaBlock)  
                        }
                    })

                    myFormBlocks = myTempBlocks
                    this.newBlockIndex = null
                }
                else {
                    myFormBlocks.push(myMediaBlock)
                }
            }

            let myTempGallery = await this.buildOfflineTempGallery(inGallery, `listingMB_temp${this.productId}`)
            
            setTimeout(() => {
                this.saveGalleryPrep(myTempGallery, 'listingMBGalleryUpdate', this.productId, 'productMBGallery')
            }, 100)
            
            this.updateMediaBlocks(myFormBlocks)
            this.clickCloseMediaBlockPopup()
        },
        clickSaveTextBlock(inForm) {
            let myFormBlocks = this.objCopy(this.productForm.media_blocks)

            if (this.editingBlock) {
                let myTempBlocks = []
                
                myFormBlocks.forEach((myBlock, index) => {
                    if (myBlock.type == 'text' && index == this.editingBlockIndex) {
                        myBlock.text = inForm.text    
                    }
                    
                    myTempBlocks.push(myBlock)
                })

                myFormBlocks = myTempBlocks
            }
            else {
                let myTextBlock = {
                    type: 'text',
                    text: inForm.text
                }

                if (this.newBlockIndex) {
                    let myTempBlocks = []
                
                    myFormBlocks.forEach((myBlock, index) => {
                        myTempBlocks.push(myBlock)

                        if (index == this.newBlockIndex) {
                            myTempBlocks.push(myTextBlock)  
                        }
                    })

                    myFormBlocks = myTempBlocks
                    this.newBlockIndex = null
                }
                else {
                    myFormBlocks.push(myTextBlock)
                }
            }
            
            this.updateMediaBlocks(myFormBlocks)
            this.clickCloseTextBlockPopup()
        },
        clickSaveTheVariant(inForm) {
            this.productForm.product_variants.theVariants.find((myVariant) => myVariant.identifier == inForm.identifier).enabled = inForm.enabled
            this.productForm.product_variants.theVariants.find((myVariant) => myVariant.identifier == inForm.identifier).overridePrice = inForm.overridePrice
            this.productForm.product_variants.theVariants.find((myVariant) => myVariant.identifier == inForm.identifier).qty = inForm.qty
            this.productForm.product_variants.theVariants.find((myVariant) => myVariant.identifier == inForm.identifier).sku = inForm.sku
            this.save()
            this.clickCloseEditVariantPopup()
        },
        clickSaveVariant(inForm) {
            this.productForm.product_variants.vendorVariants.find((myVariant) => myVariant.identifier == inForm.variant.identifier).selected = true
            this.productForm.product_variants.variantValues.find((myValues) => myValues.identifier == inForm.variant.identifier).values = inForm.values
            this.save()
            this.buildVariants()
            this.refreshVariantValues = true
            this.clickCloseVariantPopup()
        },
        clickSaveYouTubeBlock(inForm) {
            let myFormBlocks = this.objCopy(this.productForm.media_blocks)

            if (this.editingBlock) {
                let myTempBlocks = []

                myFormBlocks.forEach((myBlock, index) => {
                    if (myBlock.type == 'youtube' && index == this.editingBlockIndex) {
                        myBlock.autoplay = inForm.autoplay
                        myBlock.loop = inForm.loop
                        myBlock.orientation = inForm.orientation
                        myBlock.url = inForm.url    
                    }
                    
                    myTempBlocks.push(myBlock)
                })

                myFormBlocks = myTempBlocks
            }
            else {
                let myYouTubeBlock = {
                    type: 'youtube',
                    autoplay: inForm.autoplay,
                    loop: inForm.loop,
                    orientation: inForm.orientation,
                    url: inForm.url
                }

                if (this.newBlockIndex) {
                    let myTempBlocks = []
                
                    myFormBlocks.forEach((myBlock, index) => {
                        myTempBlocks.push(myBlock)

                        if (index == this.newBlockIndex) {
                            myTempBlocks.push(myYouTubeBlock)  
                        }
                    })

                    myFormBlocks = myTempBlocks
                    this.newBlockIndex = null
                }
                else {
                    myFormBlocks.push(myYouTubeBlock)
                }
            }

            this.updateMediaBlocks(myFormBlocks)
            this.clickCloseYouTubeBlockPopup()
        },
        clickToggleBulkDiscountEnabled(inId, inEnabled) {
            this.productForm.bulk_discounts.discounts.find((myDiscount) => myDiscount.id == inId).enabled = inEnabled
            this.save()
        },
        async createProduct() {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.put(this.getRoute('product'), 
                {
                    title: this.productForm.title
                })
                .then((response) => {
                    if (response?.data?.newProduct?.id ?? false) {
                        this.productId = response.data.newProduct.id
                    }

                    resolve(response)
                })
                .catch((error) => {
                    console.log('error creating product')
                    reject(false)
                })
            })

            this.saveStart()
            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            this.saveDone()
            return myReturn
        },
        galleryImageSavedOkay(inImage, inIndex) {
            this.gallery[inIndex] = inImage
        },
        jumpToPage(inPageNumber) {
            this.currentPage = inPageNumber
        },
        nextPage() {
            if (this.pages[this.currentPage].validation) {
                this.validating = false

                if (!this.productId && this.productForm.title) {
                    this.createProduct()
                }

                if (this.currentPage == 11) {
                    this.closeProductWizard()
                }
                else {
                    this.currentPage = this.pages[this.currentPage].next
                }
            }
            else {
                this.validating = true
            }
        },
        previousPage() {
            this.currentPage = this.pages[this.currentPage].previous
        },
        resetCheckNonValidated() {
            this.checkNonValidated = false
        },
        resetRefreshAddOnValues() {
            this.refreshAddOnValues = false
        },
        resetRefreshVariantValues() {
            this.refreshVariantValues = false
        },
        save() {
            if (this.productId) {
                this.checkValidation()
                let myData = { ...this.productForm, ...this.productDataForm }
                this.saveProduct(this.productId, myData)
            }
        },
        saveDebounce: debounce(function() {
            this.save()
        }, 2000),
        async saveLocalGallery(inGallery) {
            this.gallery = inGallery
            await this.postGallery('productGallery', inGallery, this.productId)
        },
        updateAddOnValues(inAddOnValues) {
            this.productForm.product_add_ons.addOnValues = inAddOnValues
            this.save()
        },
        updateMediaBlocks(inMediaBlocks) {
            this.productForm.media_blocks = inMediaBlocks
            this.save()
        },
        async updateOfflineGallery(inGallery) {
            this.gallery = inGallery
            let myTempGallery = await this.buildOfflineTempGallery(inGallery, `listing_temp${this.productId}`)
            this.tempGallery = myTempGallery

            setTimeout(() => {
                this.saveGalleryPrep(this.tempGallery, 'listingGalleryUpdate', this.productId, 'productGallery')
            }, 100)
        },
        updateProductDataFormSelectValue(inField, inValue) {
            this.productDataForm[inField] = inValue
            this.save()
        },
        updateProductFormSelectValue(inField, inValue) {
            console.log('f', inField)
            console.log('v', inValue)
            this.productForm[inField] = inValue
            this.save()
        },
        updateProductFormValue(inField, inValue) {
            this.productForm[inField] = inValue

            if (this.productId) {
                this.saveDebounce()
            }
        },
        updateVariantValues(inVariantValues, inRebuildVariants) {
            this.productForm.product_variants.variantValues = inVariantValues
            this.save()

            if (inRebuildVariants) {
                this.buildVariants()
            }
        },
        updateVendorAddOns(inVendorAddons) {
            this.productForm.product_add_ons.vendorAddOns = inVendorAddons

            this.productForm.product_add_ons.vendorAddOns.forEach((myAddon) => {
                if (!this.productForm.product_add_ons.addOnValues.find((myValue) => myValue.identifier == myAddon.identifier)) {
                    let myNewValue = {
                        identifier: myAddon.identifier,
                        values: []
                    }

                    this.productForm.product_add_ons.addOnValues.push(myNewValue)
                }
            })

            this.save()
        },
        updateVendorVariants(inVendorVariants) {
            this.productForm.product_variants.vendorVariants = inVendorVariants
            
            this.productForm.product_variants.vendorVariants.forEach((myVariant) => {
                if (!this.productForm.product_variants.variantValues.find((myValue) => myValue.identifier == myVariant.identifier)) {
                    let myNewValue = {
                        identifier: myVariant.identifier,
                        values: []
                    }

                    this.productForm.product_variants.variantValues.push(myNewValue)
                }
            })

            this.save()
            this.buildVariants()
        }
    }
}
</script>