<template>
    <wizard-table-container v-if="trait"
        :childTable="true" :hideHeader="true"
    >
        <template #records>
            <edit-table-record v-if="editingGroup"
                :fieldData="sortedBulkDiscountGroups" fieldDataLabel="label" fieldDataValue="id" fieldFormName="id" fieldType="select" 
                :fieldValue="editGroupForm.id" fieldValueErrorMessage="Bulk Product Group must be selected" prefixText="Group" 
                :showChildLinePrefix="true" :validatingForm="validatingForm"
                @clickCancel="clickCancelEditGroup" @clickSave="clickSaveEditGroup" @updateFieldValue="updateEditGroupFormValue"
            />
            <table-record v-else>
                <template #record>
                    <div class="flex items-center justify-start w-full pl-2 relative">
                        <child-line-prefix />
                        <div>
                            Bulk Discounts
                        </div>
                        <div v-if="!hideIcons && showMe" class="absolute -bottom-3 right-0 flex items-center justify-end text-xxs text-center w-full">
                            <div class="text-right">
                                ENABLED
                            </div>
                        </div>
                    </div>
                    <div v-if="!hideIcons" class="wizard-icon-container w-32">
                        <div @click="clickToggleShowBulkDiscounts()" v-show="!showMe" class="cursor-pointer">
                            <fa-icon icon="chevron-left" type="fas" class="h-5 w-5" />
                        </div>
                        <div @click="clickToggleShowBulkDiscounts()" v-show="showMe" class="cursor-pointer">
                            <fa-icon icon="chevron-down" type="fas" class="h-5 w-5" />
                        </div>
                        <div @click="clickNewDiscount()" class="cursor-pointer ml-2">
                            <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                        </div>
                        <div v-if="sellerHasBulkDiscounts && bulkDiscounts && bulkDiscounts.length" @click="clickEditGroup()" class="cursor-pointer ml-2">
                            <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                        </div>
                        <div v-if="sellerHasBulkDiscounts && bulkDiscounts && bulkDiscounts.length" @click="clickDeleteBulkDiscounts()" class="cursor-pointer ml-2">
                            <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                </template>
                <template #extra>
                    <div v-if="showMe" class="w-full">
                        <wizard-table-container
                            :childTable="true" :hideHeader="true"
                        >
                            <template #records>
                                <edit-bulk-discount-record v-if="addingDiscount"
                                    :bulkDiscountValue="addDiscountForm" :duplicateValueQtyError="duplicateDiscountQtyError" :validatingForm="validatingForm"
                                    @clickCancel="clickCancelNewDiscount" @clickSave="clickSaveDiscount" @updateFieldValue="updateAddDiscountFormValue"
                                />
                                <div v-for="bulkDiscount in sortedBulkDiscounts" class="w-full">
                                    <edit-bulk-discount-record v-if="editingDiscountId == bulkDiscount.id"
                                        :bulkDiscountValue="editDiscountForm" :duplicateValueQtyError="duplicateDiscountQtyError" :validatingForm="validatingForm"
                                        @clickCancel="clickCancelEditDiscount" @clickSave="clickSaveDiscount" @updateFieldValue="updateEditDiscountFormValue"
                                    />
                                    <table-record v-else-if="!editingDiscountId || editingDiscountId != bulkDiscount.id"
                                        :recordClasses="bulkDiscount.id > 99999 ? 'orange-transparent' : ''"
                                    >
                                        <template #record>
                                            <div class="w-full flex items-center justify-start text-center">
                                                <div class="w-1/3">
                                                    <div class="flex items-center justify-start w-full pl-4 text-left">
                                                        <child-line-prefix />
                                                        <div>
                                                            {{ bulkDiscount.qty }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w-1/3">
                                                    {{ bulkDiscount.percentage }}%
                                                </div>
                                                <div class="w-1/3 text-right pr-6">
                                                    <input @change="clickToggleDiscountEnabled(bulkDiscount)" :id="`trait-bulk-discount-enabled-check-${trait.id}-${bulkDiscount.id}`" :checked="isDiscountEnabled(bulkDiscount)" type="checkbox" class="w-4 h-4 text-orange-500 border-gray-300 rounded edit-field-text-color" :class="{ 'background-orange-500': isDiscountEnabled(bulkDiscount) }">
                                                </div>
                                            </div>
                                            <div class="wizard-icon-container w-32">
                                                <div v-if="bulkDiscount.id > 99999 && !hideIcons" @click="clickEditDiscount(bulkDiscount)" class="cursor-pointer">
                                                    <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                                                </div>
                                                <div v-if="bulkDiscount.id > 99999 && !hideIcons" @click="clickDeleteDiscount(bulkDiscount.id)" class="cursor-pointer ml-2">
                                                    <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                                </div>
                                            </div>
                                        </template>
                                    </table-record>
                                </div>
                            </template>
                        </wizard-table-container>
                    </div>
                </template>
            </table-record>
        </template>
    </wizard-table-container>
</template>

<script>
import EditBulkDiscountRecord from '@/wizards/components/EditBulkDiscountRecord'
import EditTableRecord from '@/wizards/components/EditTableRecord'
import TableRecord from '@/wizards/components/TableRecord'

export default {
    props: [
        'bulkDiscounts',
        'hideIcons',
        'sellerHasBulkDiscounts',
        'traitId',
        'traits'
    ],
    components: {
        EditBulkDiscountRecord,
        EditTableRecord,
        TableRecord
    },
    data() {
        return {
            addDiscountForm: {
                enabled: true,
                id: null,
                percentage: null,
                qty: null
            },
            addingDiscount: false,
            duplicateDiscountQtyError: false,
            editDiscountForm: {
                enabled: null,
                percentage: null,
                qty: null
            },
            editingDiscountId: null,
            editGroupForm: {
                id: null
            },
            editingGroup: false,
            showMe: false,
            trait: null,
            validatingForm: false
        }
    },
    computed: {
        sortedBulkDiscountGroups() {
            if (!this.bulkDiscounts) {
                return []
            }

            return this.bulkDiscounts
                .sort((a, b) => a.label < b.label ? -1 : 1)
        },
        sortedBulkDiscounts() {
            let myVendorDiscounts = []

            if (this.bulkDiscounts && this.sellerHasBulkDiscounts && this.trait.bulkDiscountGroupId) {
                let myBulkDiscounts = this.bulkDiscounts
                    .find((myDiscount) => myDiscount.id == this.trait.bulkDiscountGroupId)
                
                if (myBulkDiscounts) {
                    myVendorDiscounts = myBulkDiscounts.values
                }
            }

            let myDiscounts = []

            if (this.trait.bulkDiscounts && this.trait.bulkDiscounts.length) {
                myDiscounts = this.trait.bulkDiscounts
                    .filter((myDiscount) => myDiscount.id > 99999)
            }

            return [...myVendorDiscounts, ...myDiscounts]
                .sort((a, b) => a.qty < b.qty ? -1 : 1)
        }
    },
    mounted() {
        this.trait = this.objCopy(this.traits.find((myTrait) => myTrait.id == this.traitId))
    },
    methods: {
        clickCancelEditDiscount() {
            this.editDiscountForm.enabled = false
            this.editDiscountForm.percentage = null
            this.editDiscountForm.qty = null
            this.editingDiscountId = null
            this.validatingForm = false
            this.$emit('switchHideIcons', false)
        },
        clickCancelEditGroup() {
            this.editGroupForm.id = null
            this.editingGroup = false
            this.validatingForm = false
            this.$emit('switchHideIcons', false)
        },
        clickCancelNewDiscount() {
            this.addDiscountForm.enabled = true
            this.addDiscountForm.id = null
            this.addDiscountForm.percentage = null
            this.addDiscountForm.qty = null
            this.addingDiscount = false
            this.validatingForm = false
            this.$emit('switchHideIcons', false)
        },
        clickDeleteBulkDiscounts() {
            if (confirm(`Confirm you wish to delete all Bulk Discounts for ${this.trait.label}?`)) {
                this.trait.bulkDiscounts = []
                this.trait.bulkDiscountGroupId = null
                this.$emit('updateTrait', this.trait)
            }
        },
        clickDeleteDiscount(inId) {
            if (confirm(`Confirm you wish to delete this Bulk Discount?`)) {
                this.trait.bulkDiscounts = this.trait.bulkDiscounts.filter((myDiscount) => myDiscount.id != inId)
                this.$emit('updateTrait', this.trait)
            }
        },
        clickEditDiscount(inDiscount) {
            this.editDiscountForm.enabled = inDiscount.enabled
            this.editDiscountForm.percentage = inDiscount.percentage
            this.editDiscountForm.qty = inDiscount.qty
            this.editingDiscountId = inDiscount.id
            this.$emit('switchHideIcons', true)

            this.$nextTick(() => {
                this.elGet('bulkDiscountQty').focus()
            })
        },
        clickEditGroup() {
            this.editGroupForm.id = this.trait.bulkDiscountGroupId ?? null
            this.editingGroup = true
            this.$emit('switchHideIcons', true)
        },
        clickNewDiscount() {
            this.showMe = true
            this.addingDiscount = true
            this.$emit('switchHideIcons', true)

            this.$nextTick(() => {
                this.elGet('bulkDiscountQty').focus()
            })
        },
        clickSaveDiscount() {
            if (((this.addingDiscount && this.addDiscountForm.percentage && this.addDiscountForm.qty) || (this.editingDiscountId && this.editDiscountForm.percentage && this.editDiscountForm.qty)) && this.isDiscountQtyUnique()) {
                this.validatingForm = false

                if (this.editingDiscountId) {
                    this.trait.bulkDiscounts.find((myBulkDiscount) => myBulkDiscount.id == this.editingDiscountId).enabled = this.editDiscountForm.enabled
                    this.trait.bulkDiscounts.find((myBulkDiscount) => myBulkDiscount.id == this.editingDiscountId).percentage = this.editDiscountForm.percentage
                    this.trait.bulkDiscounts.find((myBulkDiscount) => myBulkDiscount.id == this.editingDiscountId).qty = this.editDiscountForm.qty
                    this.editDiscountForm.enabled = null
                    this.editDiscountForm.percentage = null
                    this.editDiscountForm.qty = null
                    this.$emit('switchHideIcons', false)
                    this.editingDiscountId = null
                }
                else {
                    let myId = 99999

                    if (this.trait.bulkDiscounts) {    
                        this.trait.bulkDiscounts.forEach((myBulkDiscount) => {
                            if (myBulkDiscount.id > myId) {
                                myId = myBulkDiscount.id
                            }
                        })
                    }

                    this.addDiscountForm.id = myId + 1
                    this.trait.bulkDiscounts.push(this.objCopy(this.addDiscountForm))
                    this.addDiscountForm.enabled = true
                    this.addDiscountForm.id = null
                    this.addDiscountForm.percentage = null
                    this.addDiscountForm.qty = null

                    this.$nextTick(() => {
                        this.elGet('bulkDiscountQty').focus()
                    })
                }

                this.$emit('updateTrait', this.trait)
            }
            else {
                this.validatingForm = true
            }
        },
        clickSaveEditGroup() {
            if (this.trait.bulkDiscountGroupId) {
                this.trait.bulkDiscountGroupId = this.editGroupForm.id
            }
            else {
                if (this.editGroupForm.id) {
                    this.trait.bulkDiscountGroupId = this.editGroupForm.id
                }
                else {
                    this.validatingForm = true
                }
            }

            this.showMe = true
            this.$emit('updateTrait', this.trait)
            this.$emit('switchHideIcons', false)
            this.editGroupForm.id = null
            this.editingGroup = false
        },
        clickToggleDiscountEnabled(inBulkDiscount) {
            let myQtyOkay = true

            if (inBulkDiscount.id < 100000) {
                let myBulkDiscount = this.trait.bulkDiscounts.find((myDiscount) => myDiscount.id == inBulkDiscount.id)

                if (myBulkDiscount) {
                    this.trait.bulkDiscounts = this.trait.bulkDiscounts.filter((myDiscount) => myDiscount.id != inBulkDiscount.id)
                }
                else {
                    myQtyOkay = !Boolean(this.trait.bulkDiscounts.find((myBulkDiscount) => myBulkDiscount.qty == inBulkDiscount.qty && myBulkDiscount.enabled))

                    if (myQtyOkay) {
                        let myNewDiscount = this.objCopy(inBulkDiscount)
                        myNewDiscount.enabled = true
                        this.trait.bulkDiscounts.push(this.objCopy(myNewDiscount))
                    }
                }
            }
            else {
                if (!inBulkDiscount.enabled) {
                    myQtyOkay = !Boolean(this.trait.bulkDiscounts.find((myDiscount) => myDiscount.qty == inBulkDiscount.qty && myDiscount.enabled))
                }

                if (myQtyOkay) {
                    this.trait.bulkDiscounts.find((myDiscount) => myDiscount.id == inBulkDiscount.id).enabled = !inBulkDiscount.enabled
                }
            }
            
            if (myQtyOkay) {
                this.$emit('updateTrait', this.trait)
            }
            else {
                this.elGet(`trait-bulk-discount-enabled-check-${this.trait.id}-${inBulkDiscount.id}`).checked = false
                alert("Unable to enable this Bulk Discount, there is already an enabled Bulk Discount with this Purchase Quantity.")
            }
        },
        clickToggleShowBulkDiscounts() {
            this.showMe = !this.showMe
        },
        isDiscountEnabled(inBulkDiscount) {
            if (inBulkDiscount.id < 100000) {
                if (!this.trait.bulkDiscounts) {
                    return false
                }

                return this.trait.bulkDiscounts.find((myDiscount) => myDiscount.id == inBulkDiscount.id)
            }

            return inBulkDiscount.enabled
        },
        isDiscountQtyUnique() {
            let myDuplicateError = false
            
            if (this.addingDiscount && this.addDiscountForm.enabled) {
                myDuplicateError = Boolean(this.trait.bulkDiscounts.find((myBulkDiscount) => myBulkDiscount.qty == this.addDiscountForm.qty && myBulkDiscount.enabled))
            }
            else if (this.editingDiscountId && this.editDiscountForm.enabled) {
                myDuplicateError = Boolean(this.trait.bulkDiscounts.find((myBulkDiscount) => myBulkDiscount.id != this.editingDiscountId && myBulkDiscount.qty == this.editDiscountForm.qty && myBulkDiscount.enabled))
            }

            this.duplicateDiscountQtyError = myDuplicateError
            return !myDuplicateError
        },
        updateAddDiscountFormValue(inField, inValue) {
            this.addDiscountForm[inField] = inValue

            if (inField == "qty") {
                this.isDiscountQtyUnique()
            }
        },
        updateEditDiscountFormValue(inField, inValue) {
            this.editDiscountForm[inField] = inValue

            if (inField == "qty") {
                this.isDiscountQtyUnique()
            }
        },
        updateEditGroupFormValue(inField, inValue) {
            this.editGroupForm[inField] = inValue
        }
    }
}
</script>