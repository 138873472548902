<template>
    <wizard-container
        :openConditions="wizardData && sellerDataForm"
        @closeWizard="closeSellerWizard"
    >
        <template #main>
            <wizard-page v-if="formLoaded && sellerReady"
                :checkNonValidated="checkNonValidated" :currentPage="currentPage" :hideLogo="currentPage == 1" :hidePrevious="currentPage < 3" 
                :hideProgress="currentPage == 1" :ignorePages="ignorePages" :introPages="introPages" :myPage="currentPage" :outroPages="outroPages" 
                :pages="pages" :startPage="startPage" :wizardData="wizardData"
                @jumpToPage="jumpToPage" @nextPage="nextPage" @previousPage="previousPage" @resetCheckNonValidated="resetCheckNonValidated"
            >
                <template #main>
                    <page01 v-if="currentPage == 1"
                    />
                    <page02 v-if="currentPage == 2"
                        :sellerForm="sellerForm" :validating="validating"
                        @updateSellerFormValue="updateSellerFormValue"
                    />
                    <page03 v-if="currentPage == 3"
                        :sellerForm="sellerForm" :validating="validating"
                        @updateSellerFormValue="updateSellerFormValue"
                    />
                    <page04 v-if="currentPage == 4"
                        :avatarArray="avatarArray" :validating="validating"
                        @avatarSavedOkay="avatarSavedOkay" @saveLocalAvatar="saveLocalAvatar" @updateOfflineAvatar="updateOfflineAvatar"
                    />
                    <page05 v-if="currentPage == 5"
                        :imagesArray="imagesArray" :validating="validating"
                        @galleryImageSavedOkay="galleryImageSavedOkay" @saveLocalGallery="saveLocalGallery" @updateOfflineGallery="updateOfflineGallery"
                    />
                    <page06 v-if="currentPage == 6"
                        :sellerDataForm="sellerDataForm" :validating="validating"
                        @changeCountry="changeCountry"
                    />
                    <page07 v-if="currentPage == 7"
                        :sellerDataForm="sellerDataForm" :validating="validating"
                        @changeChargeTax="changeChargeTax" @updateSellerDataFormValue="updateSellerDataFormValue" @updateTaxRates="updateTaxRates"
                    />
                    <page08 v-if="currentPage == 8"
                        :sellerDataForm="sellerDataForm" :sellerForm="sellerForm" :validating="validating"
                        @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue" @updateSellerFormValue="updateSellerFormValue"
                    />
                    <page09 v-if="currentPage == 9"
                        :sellerDataForm="sellerDataForm" :validating="validating"
                        @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                    <page10 v-if="currentPage == 10"
                        :sellerDataForm="sellerDataForm" :sellerForm="sellerForm" :validating="validating"
                        @updatePaymentType="updatePaymentType"
                    />
                    <page11 v-if="currentPage == 11"
                    />
                </template>
                <template #popups>
                </template>
            </wizard-page>
        </template>
    </wizard-container>
</template>

<script>
import {debounce} from 'lodash'

import Page01 from './pages/Page01'
import Page02 from './pages/Page02'
import Page03 from './pages/Page03'
import Page04 from './pages/Page04'
import Page05 from './pages/Page05'
import Page06 from './pages/Page06'
import Page07 from './pages/Page07'
import Page08 from './pages/Page08'
import Page09 from './pages/Page09'
import Page10 from './pages/Page10'
import Page11 from './pages/Page11'
import WizardPage from '../WizardPage'

export default {
    components: {
        Page01,
        Page02,
        Page03,
        Page04,
        Page05,
        Page06,
        Page07,
        Page08,
        Page09,
        Page10,
        Page11,
        WizardPage
    },
    data() {
        return {
            activating: false,
            avatarGallery: [],
            avatarImage: null,
            checkNonValidated: false,
            currentPage: 1,
            formLoaded: false,
            gallery: [],
            ignorePages: [],
            introPages: 1,
            outroPages: 0,
            sellerDataForm: {
                chargeTax: null,
                countryCode: null,
                defaultCurrency: null,
                defaultTaxRate: null,
                lastTaxRateId: null,
                taxLabel: null,
                taxRates: null,
                usePayPal: null,
                useStripe: null,
                vendorPlan: null
            },
            sellerForm: {
                email: null,
                incomplete: null,
                name: null,
                payment_types: null,
                paypal_client_id: null,
                paypal_merchant_id: null
            },
            sellerId: null,
            sellerReady: false,
            startPage: null,
            validating: false,
            wizardData: null
        }
    },
    computed: {
        avatarArray() {
            if (this.avatarGallery && this.avatarGallery.length) {
                let myGallery = []

                this.avatarGallery.forEach((myImage) => {
                    myGallery.push(myImage)
                })

                return myGallery
            }
            else {
                return this.avatarImage ? [ 
                    {
                        url: this.avatarImage,
                        type: 'image'
                    }
                ] : []
            }
        },
        finalPageButtonText() {
            return this.wizardEditingTempSeller ? "Continue" : "Close"
        },
        imagesArray() {
            let myGallery = []

            this.gallery.forEach((myImage) => {
                myGallery.push(myImage)
            })

            return myGallery
        },
        pages() {
            return {
                1: {
                    next: 2,
                    previous: false,
                    validation: true
                },
                2: {
                    next: 3,
                    previous: false,
                    validation: Boolean(this.sellerForm.name)
                },
                3: {
                    next: 4,
                    previous: 2,
                    validation: Boolean(this.sellerForm.email)
                },
                4: {
                    next: 5,
                    previous: 3,
                    validation: Boolean(this.avatarArray && this.avatarArray.length)
                },
                5: {
                    next: 6,
                    previous: 4,
                    validation: Boolean(this.avatarArray && this.avatarArray.length)
                },
                6: {
                    next: 7,
                    previous: 5,
                    validation: Boolean(this.sellerDataForm.defaultCurrency) && Boolean(this.sellerDataForm.countryCode)
                },
                7: {
                    next: 8,
                    previous: 6,
                    validation: this.sellerDataForm.chargeTax != null && (!this.sellerDataForm.chargeTax || (Boolean(this.sellerDataForm.taxLabel) && Boolean(this.sellerDataForm.defaultTaxRate) && (Boolean(this.sellerDataForm.taxRates) && this.sellerDataForm.taxRates.length > 1)))
                },
                8: {
                    next: 9,
                    previous: 7,
                    validation: this.sellerDataForm.usePayPal == false || (this.sellerDataForm.usePayPal && Boolean(this.sellerForm.paypal_client_id) && Boolean(this.sellerForm.paypal_merchant_id))
                },
                9: {
                    next: 10,
                    previous: 8,
                    validation: this.sellerDataForm.useStripe != null && (this.sellerDataForm.useStripe || this.sellerDataForm.usePayPal) && (!this.sellerDataForm.useStripe || this.completeStripeAccount)
                },
                10: {
                    next: this.wizardEditingTempSeller ? 11 : false,
                    nextText: this.finalPageButtonText,
                    previous: 9,
                    validation: Boolean(this.sellerForm.payment_types) && this.sellerForm.payment_types.length
                },
                11: {
                    next: 11,
                    nextText: "Make Your New Seller Live",
                    previous: 10,
                    validation: true
                }
            }
        }
    },
    watch: {
       sellerVendor: {
            immediate: true,
            handler(val) {
                if (val) {
                    if (!this.wizardEditingTempSeller) {
                        this.setStateData([
                            { tempSellerVendorData: this.sellerVendorData },
                            { tempSellerVendor: this.sellerVendor },
                            { tempSellerVendorWhiteLabelData: this.sellerVendorWhiteLabelData }
                        ])

                        this.sellerReady = true
                    }
                    else if (this.loggedUser.temp_vendor_id) {
                        this.getTempSeller()
                    }
                    else {
                        this.sellerReady = true
                    }
                }
            }
        },
        tempSellerVendor: {
            immediate: true,
            handler(val) {
                if (!this.sellerId) {
                    if (val) {
                        this.sellerId = this.tempSellerVendor.id
                        this.avatarGallery = this?.tempSellerVendor?.avatar_gallery ?? []
                        this.avatarImage = this.tempSellerVendor.avatar
                        this.gallery = this?.tempSellerVendor?.gallery ?? []
                        this.sellerForm.email = this.tempSellerVendor.email
                        this.sellerForm.incomplete = this.tempSellerVendor.incomplete
                        this.sellerForm.name = this.tempSellerVendor.name
                        this.sellerForm.payment_types = this.tempSellerVendor.payment_types.types ?? []
                        this.sellerForm.paypal_client_id = this.tempSellerVendor.paypal_client_id
                        this.sellerForm.paypal_merchant_id = this.tempSellerVendor.paypal_merchant_id
                        
                    }
                    else {
                        this.sellerId = null
                        this.avatarGallery = []
                        this.avatarImage = null
                        this.gallery = []
                        this.sellerForm.email = null
                        this.sellerForm.incomplete = true
                        this.sellerForm.name = null
                        this.sellerForm.payment_types = []
                        this.sellerForm.paypal_client_id = null
                        this.sellerForm.paypal_merchant_id = null
                    }
                }
            }
        },
        tempSellerVendorData: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.sellerDataForm.chargeTax = (this?.tempSellerVendorData?.chargeTax ?? null) != null ? this.tempSellerVendorData.chargeTax : null
                    this.sellerDataForm.countryCode = this?.tempSellerVendorData?.countryCode ?? null
                    this.sellerDataForm.defaultCurrency = this?.tempSellerVendorData?.defaultCurrency ?? null
                    this.sellerDataForm.defaultTaxRate = this?.tempSellerVendorData?.defaultTaxRate ?? null
                    this.sellerDataForm.lastTaxRateId = this?.tempSellerVendorData?.lastTaxRateId ?? 1
                    this.sellerDataForm.taxLabel = this?.tempSellerVendorData?.taxLabel ?? null

                    this.sellerDataForm.taxRates = this?.tempSellerVendorData?.taxRates ?? [
                        {
                            id: 1,
                            label: 'Tax free',
                            percentage: 0
                        }
                    ]

                    this.sellerDataForm.usePayPal = (this?.tempSellerVendorData?.usePayPal ?? null) != null ? this.tempSellerVendorData.usePayPal : null
                    this.sellerDataForm.useStripe = (this?.tempSellerVendorData?.useStripe ?? null) != null ? this.tempSellerVendorData.useStripe : null
                }
                else {
                    this.sellerDataForm.chargeTax = null
                    this.sellerDataForm.countryCode = null
                    this.sellerDataForm.defaultCurrency = null
                    this.sellerDataForm.defaultTaxRate = null
                    this.sellerDataForm.lastTaxRateId = 1
                    this.sellerDataForm.taxLabel = null

                    this.sellerDataForm.taxRates = [
                        {
                            id: 1,
                            label: 'Tax free',
                            percentage: 0
                        }
                    ]

                    this.sellerDataForm.usePayPal = null
                    this.sellerDataForm.useStripe = null
                }
            }
        },
        wizardSellerData: {
            immediate: true,
            handler(val) {
                this.wizardData = this.wizardSellerData
            }
        }
    },
    mounted() {
        if (this.wizardSellerStartPage) {
            this.startPage = this.wizardSellerStartPage
        }

        if (!this.wizardEditingTempSeller) {
            this.outroPages = 1
        }

        this.formLoaded = true
    },
    beforeUnmount() {
        this.setStateData([
            { wizardEditingTempSeller: false },
            { wizardSellerStartPage: null },
            { tempSellerVendorData: null },
            { tempSellerVendor: null },
            { tempSellerVendorWhiteLabelData: null }
        ])
    },
    methods: {
        avatarSavedOkay(inImage, inIndex) {
            this.avatarGallery[inIndex] = inImage
            this.avatarImage = inImage.url
            this.checkValidation()
        },
        changeChargeTax(inField, inValue) {
            if (!inValue) {
                this.sellerDataForm.defaultTaxRate = 1
            }

            this.updateSellerDataFormSelectValue(inField, inValue)
        },
        changeCountry(inField, inValue) {
            if (this.coGetDefaultCurrency(inValue) && this.wizardEditingTempSeller) {
                this.sellerDataForm.defaultCurrency = this.coGetDefaultCurrency(inValue)
            }

            if (this.coGetDefaultTaxLabel(inValue)) {
                this.sellerDataForm.taxLabel = this.coGetDefaultTaxLabel(inValue)
            }

            this.updateSellerDataFormSelectValue(inField, inValue)
        },
        checkValidation() {
            this.checkNonValidated = true
        },
        async createSeller() {
            if (!this.loggedUser.temp_vendor_id) {
                let promise = new Promise((resolve, reject) => {
                    buildzAxios?.put(this.getRoute('seller'), 
                    {
                        name: this.sellerForm.name
                    })
                    .then((response) => {
                        if (response?.data?.vendor_id ?? false) {
                            this.sellerId = response.data.vendor_id

                            myLoggedUser = this.objCopy(this.loggedUser)
                            myLoggedUser.temp_vendor_id = response.data.vendor_id

                            this.setStateData([
                                { loggedUser: myLoggedUser }
                            ])
                        }

                        resolve(response)
                    })
                    .catch((error) => {
                        console.log('error creating seller')
                        reject(false)
                    })
                })

                this.saveStart()
                var myReturn = false

                await promise
                    .then((result) => {
                        myReturn = result
                    })
                    .catch((e) => {

                    })
            
                this.saveDone()
                return myReturn
            }
        },
        galleryImageSavedOkay(inImage, inIndex) {
            this.gallery[inIndex] = inImage
            this.checkValidation()
        },
        async getTempSeller() {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.get(this.getRoute('seller'), 
                {
                    params: {
                        vendor_id: this.loggedUser.temp_vendor_id
                    }
                })
                .then((response) => {
                    this.setStateData([
                        { tempSellerVendorData: response.data.marketplace_vendor_data },
                        { tempSellerVendor: response.data.marketplace_vendor },
                        { tempSellerVendorWhiteLabelData: response.data.marketplace_vendor_white_label_data }
                    ])

                    this.sellerReady = true
                    resolve(true)
                })
                .catch((error) => {
                    console.log('error getting temp seller')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        jumpToPage(inPageNumber) {
            this.currentPage = inPageNumber
        },
        nextPage() {
            if (this.pages[this.currentPage].validation) {
                this.validating = false

                if (!this.sellerId) {
                    this.createSeller()
                }

                if (this.currentPage == 11 && this.wizardEditingTempSeller) {
                    this.openSpinner()
                    this.activating = true
                    this.sellerForm.incomplete = false
                    this.save()
                }
                else if (this.currentPage == 11 || (this.currentPage == 10 && !this.wizardEditingTempSeller)) {
                    this.closeSellerWizard()
                }
                else {
                    this.currentPage = this.pages[this.currentPage].next
                }
            }
            else {
                this.validating = true
            }
        },
        previousPage() {
            this.currentPage = this.pages[this.currentPage].previous
        },
        resetCheckNonValidated() {
            this.checkNonValidated = false
        },
        save() {
            this.saveStart()
            this.checkValidation()

            let myData = { ...this.sellerForm, ...this.sellerDataForm }
            myData.vendor_id = this.sellerId

            buildzAxios?.post(this.getRoute('seller'), this.objCopy(myData))
                .then((response) => {
                    this.saveDone()

                    if (this.activating) {
                        buildzAxios?.put(this.getRoute('garageUpdate', this.loggedUser.id),
                            {
                                current_vendor_id: this.sellerId 
                            }
                        )
                        .then((response) => {
                            this.setStateData([
                                { activatingNewSeller: true }
                            ])

                            this.routeTo('/marketplace/seller?seller_complete=true')

                            setTimeout(() => {
                                window.location.reload()
                            }, 100)
                        })
                        .catch((error) => {
                            console.log(error)
                            this.closeSpinner()
                        })

                        this.closeSellerWizard()
                    }
                    else if (!this.wizardEditingTempSeller) {
                        this.setStateData([
                            { sellerVendor: response.data.marketplace_vendor },
                            { sellerVendorData: response.data.marketplace_vendor_data },
                            { sellerVendorWhiteLabelData: response.data.marketplace_vendor_white_label_data }
                        ])
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.saveDone()
                })
        },
        saveDebounce: debounce(function() {
            this.save()
        }, 2000),
        async saveLocalAvatar(inGallery) {
            this.avatarImage = null
            this.avatarGallery = inGallery
            this.checkValidation()
            await this.postGallery('sellerAvatar', inGallery, this.sellerId)
        },
        async saveLocalGallery(inGallery) {
            this.gallery = inGallery
            this.checkValidation()
            await this.postGallery('sellerGallery', inGallery, this.sellerId)
        },
        async updateOfflineAvatar(inGallery) {
            this.avatarImage = null
            this.avatarGallery = inGallery
            this.checkValidation()
            let myTempGallery = await this.buildOfflineTempGallery(inGallery, `sellerAvatar_temp${this.sellerId}`)
            this.tempAvatarGallery = myTempGallery
            
            setTimeout(() => {
                this.saveGalleryPrep(this.tempAvatarGallery, 'sellerAvatarUpdate', this.sellerId, 'sellerAvatar')
            }, 100)
        },
        async updateOfflineGallery(inGallery) {
            this.gallery = inGallery
            this.checkValidation()
            let myTempGallery = await this.buildOfflineTempGallery(inGallery, `sellerGallery_temp${this.sellerId}`)
            this.tempGallery = myTempGallery

            setTimeout(() => {
                this.saveGalleryPrep(this.tempGallery, 'sellerGalleryUpdate', this.sellerId, 'sellerGallery')
            }, 100)
        },
        updatePaymentType(inField, inValue) {
            let myTypes = this.sellerForm.payment_types

            if (inValue) {
                if (!myTypes.includes(inField)) {
                    myTypes.push(inField)
                }
            }
            else {
                if (myTypes.includes(inField)) {
                    myTypes = myTypes.filter((myType) => myType != inField)
                }
            }

            this.sellerForm.payment_types = null
            this.sellerForm.payment_types = myTypes
            this.save()
        },
        updateSellerDataFormSelectValue(inField, inValue) {
            this.sellerDataForm[inField] = inValue
            this.save()
        },
        updateSellerDataFormValue(inField, inValue) {
            this.sellerDataForm[inField] = inValue
            this.saveDebounce()
        },
        updateSellerFormValue(inField, inValue) {
            this.sellerForm[inField] = inValue

            if (this.sellerId) {
                this.saveDebounce()
            }
        },
        updateTaxRates(inTaxRates, inDefaultTaxRate) {
            this.sellerDataForm.taxRates = []
            this.sellerDataForm.taxRates = inTaxRates
            this.sellerDataForm.defaultTaxRate = inDefaultTaxRate
            this.save()
        }
    }
}
</script>