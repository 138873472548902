<template>
    <div class="w-full">
        <input-panel 
            fieldFormName="units" fieldLabel="Sale Unit" fieldLabelClasses="text-gray-800" fieldPlaceholder="Sale Unit" :fieldRequired="true" fieldSubLabel="E.g. Each, Metre, Kg."
            fieldType="text" :fieldValue="productForm.units" :forceMobile="true"
            @fieldInput="updateProductFormValue"
        >
            <validation-message v-if="validating && !productForm.units" 
                message="Sale Unit must be entered"
            />
        </input-panel>
        <input-panel 
            fieldFormName="price" fieldLabel="Sale Unit Price" fieldLabelClasses="text-gray-800" fieldPlaceholder="Sale Unit Price" :fieldRequired="true" fieldSubLabel="Please enter a tax inclusive price."
            fieldType="number" :fieldValue="productForm.price" :forceMobile="true"
            @fieldInput="updateProductFormValue"
        >
            <validation-message v-if="validating && !productForm.price" 
                message="Sale Unit Price must be entered"
            />
        </input-panel>
        <input-panel 
            fieldFormName="compare_at_price" fieldLabel="Original Sale Unit Price" fieldLabelClasses="text-gray-800" fieldPlaceholder="Original Sale Unit Price"
            fieldSubLabel="If this listing is on sale enter the original sale price before discount here. Please enter a tax inclusive price."
            fieldType="number" :fieldValue="productForm.compare_at_price" :forceMobile="true"
            @fieldInput="updateProductFormValue"
        >
            <validation-message v-if="validating && productForm.price && productForm.compare_at_price && productForm.compare_at_price <= productForm.price" 
                message="Original Sale Unit Price must be greater than Sale Unit Price"
            />
        </input-panel>
        <select-panel 
            :fieldData="sellerVendorData.taxRates" fieldDataLabel="label" fieldDataValue="id" fieldFormName="tax_rate_id" fieldLabel="Tax Rate" fieldLabelClasses="text-gray-800"
            :fieldRequired="true" :fieldValue="productForm.tax_rate_id" :forceMobile="true"
            @fieldChange="updateProductFormSelectValue"
        >
        <validation-message v-if="validating && !productForm.tax_rate_id" 
            message="Tax Rate must be selected"
        />
        </select-panel>
    </div>
</template>

<script>
export default {
    props: [
        'productForm',
        'validating'
    ],
    data() {
        return {
            itemConditionData: [
                'New', 
                'Used'
            ]
        }
    },
    mounted() {
        if (!this.productForm.tax_rate_id) {
            this.updateProductFormSelectValue('tax_rate_id', this.sellerVendorData.defaultTaxRate)
        }
    },
    methods: {
        updateProductFormSelectValue(inField, inValue) {
            this.$emit('updateProductFormSelectValue', inField, inValue)
        },
        updateProductFormValue(inField, inValue) {
            this.$emit('updateProductFormValue', inField, inValue)
        }
    }
}
</script>