<template>
    <div v-if="isMounted" class="relative w-full white-transparent mt-px" :class="{ 'py-3 px-2': !fieldNude }">
        <div v-if="!hideLabel" class="w-full">
            <panel-label 
                :fieldLabel="fieldLabel" :fieldLabelClasses="fieldLabelClasses" :fieldRequired="fieldRequired && !fieldNonEdit" :forceMobile="true"
            />
        </div>
        <div class="w-full">
            <div v-if="!fieldNonEdit" class="edit-field-text-color rounded-md" :class="{ 'p-1': !fieldNude, 'bg-white': !hideFieldModel, 'bg-gray-400': hideFieldModel }">
                <vue-editor v-model="fieldModel" 
                    :editorToolbar="customToolbar"
                    @text-change="fieldInput()">
                </vue-editor>
            </div>
            <div v-else class="w-full mt-2">
                <div class="form-input edit-field-text-color bg-gray-400 py-1 w-full" v-html="fieldModel">
                </div>
            </div>
        </div>
        <div v-if="hideIcon" class="absolute bg-orange-500 text-white w-12 rounded-md flex items-center justify-center cursor-pointer top-0 right-2" style="height: 34px;">
            <div v-if="hideFieldModel" @click="toggleHide()" class="cursor-pointer flex items-center">
                <fa-icon icon="eye-slash" type="fas" class="h-6 w-6" />
            </div>
            <div v-if="!hideFieldModel" @click="toggleHide()" class="cursor-pointer flex items-center">
                <fa-icon icon="eye" type="fas" class="h-6 w-6" />
            </div>
        </div>
        <div v-if="fieldSubLabel" class="text-xs text-center px-2">
            {{ fieldSubLabel }}
        </div>
    </div>
</template>

<script>
import { VueEditor } from 'vue3-editor'

export default {
    props: [
        'fieldFormName', 
        'fieldLabel',
        'fieldLabelClasses',
        'fieldNonEdit',
        'fieldNude',
        'fieldRequired', 
        'fieldSubLabel',
        'fieldValue',
        'hideFieldFormName',
        'hideFieldValue',
        'hideIcon',
        'hideLabel',
        'toolbar'
    ],
    components: {
        VueEditor
    },
    data() {
        return {
            customToolbar: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'],  
                [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
                ['blockquote'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check'}],
                [{ 'indent': '-1'}, { 'indent': '+1' }],  
                [{ 'color': [] }, { 'background': [] }],    
                ['link', 'clean']                    
            ],
            fieldModel: null,
            hideFieldModel: null,
            isMounted: false
        }
    },
    watch: {
        hideFieldValue: {
            handler(val) {
                this.hideFieldModel = val
            }
        }
    },
    mounted() {
        if (this.toolbar) {
            this.customToolbar = this.toolbar
        }

        this.fieldModel = this.fieldValue
        this.hideFieldModel = this.hideFieldValue
        this.isMounted = true
    },
    methods: {
        fieldInput() {
            if (this.fieldModel != this.fieldValue) {
                this.$emit('fieldInput', this.fieldFormName, this.fieldModel)
            }
        },
        toggleHide() {
            this.hideFieldModel = !this.hideFieldModel
            this.$emit('fieldInput', this.hideFieldFormName, this.hideFieldModel)
        }
    }
}
</script>