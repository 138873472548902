<template>
    <modal v-if="showNewListingModal" :open="showNewListingModal" @close="closeNewListingModal" class="text-white font-normal">
        <modal-background-image
            :bgImage="defaultHero"
        />
        <div class="absolute top-0 left-0 w-full" :class="{ 'pb-16': windowWidth < desktopMaxWidth, 'desktop-modal-box': windowWidth >= desktopMaxWidth }">
            <div class="relative" :class="{ 'desktop-modal': windowWidth >= desktopMaxWidth }">
                <div class="w-full mt-4 px-1">
                    <h3 class="section-heading mb-3 text-center">
                        Create a New Listing
                    </h3>
                    <div class="max-width-box">
                        <input-panel
                            fieldType="text" :fieldValue="variantForm.title" fieldLabel="Title" fieldPlaceholder="Title" :fieldRequired="true" 
                            fieldFormName="title"
                            @fieldInput="updateFormValue"
                        />
                        <div class="w-full mt-4" :class="{ 'desktop-button-box justify-end': isDesktopWidth }">
                            <div @click="clickSaveVariant()" class="mt-4" :class=" { 'standard-button-base': variantForm.title, 'disabled-button-base': !variantForm.title } ">
                                Create new listing
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/shared/Modal'
import ModalBackgroundImage from '@/shared/ModalBackgroundImage'

export default {
    components: {
        Modal,
        ModalBackgroundImage
    },
    data() {
        return {
            variantForm: {
                title: null
            }
        }
    },
    methods: {
        clickSaveVariant() {
            this.openSpinner()
            
            buildzAxios?.put(this.getRoute('product'), this.variantForm)
                .then((response) => {
                    let myRecord = response.data.newProduct
                    let myRecords = this.objCopy(this.vendorProducts)
                    myRecords = { ...myRecords, ...{myRecord} }

                    this.setStateData([
                        { vendorProducts: myRecords }
                    ])

                    if (this.showEditGroupBuyModal) {
                        this.setStateData([
                            { groupBuyNewProduct: myRecord }
                        ])
                    }

                    this.pageMessage('success', 'Listing created')
                    this.closeSpinner()
                    this.closeNewListingModal()
                    this.openEditListingModal(myRecord)
                })
                .catch((error) => {
                    console.log(error)
                    this.closeSpinner()
                    this.pageMessage('error', 'Error creating listing')
                })
        },
        updateFormValue(inField, inValue) {
            this.variantForm[inField] = inValue
        }
    }
}
</script>