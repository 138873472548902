<template>
    <wizard-table-container v-if="trait"
        :childTable="true" :hideHeader="true"
    >
        <template #records>
            <table-record v-if="trait.productCategoryId && !editingCategory">
                <template #record>
                    <div class="flex items-center justify-start w-full pl-2 relative">
                        <child-line-prefix />
                        <div>
                            {{ categoryName }} Product Groups
                        </div>
                        <div v-if="!hideIcons && showMe" class="absolute -bottom-3 right-0 flex items-center justify-end text-xxs text-center">
                            <div class="w-12">
                                ENABLED
                            </div>
                        </div>
                    </div>
                    <div v-if="!hideIcons" class="wizard-icon-container">
                        <div @click="clickToggleShowGroups()" v-show="!showMe" class="cursor-pointer">
                            <fa-icon icon="chevron-left" type="fas" class="h-5 w-5" />
                        </div>
                        <div @click="clickToggleShowGroups()" v-show="showMe" class="cursor-pointer">
                            <fa-icon icon="chevron-down" type="fas" class="h-5 w-5" />
                        </div>
                        <div @click="clickNewGroup()" class="cursor-pointer ml-2">
                            <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                        </div>
                        <div @click="clickEditCategory()" class="cursor-pointer ml-2">
                            <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                </template>
                <template #extra>
                    <div v-if="showMe" class="w-full">
                        <wizard-table-container
                            :childTable="true" :hideHeader="true"
                        >
                            <template #records>
                                <edit-table-record v-if="addingGroup"
                                    :duplicateValueError="duplicateGroupNameError" fieldFormName="name" fieldId="inputNewGroup" fieldType="text" :fieldValue="addGroupForm.name"
                                    :validatingForm="validatingForm"
                                    @clickCancel="clickCancelNewGroup" @clickSave="clickSaveGroup" @updateFieldValue="updateAddGroupFormValue"
                                />
                                <div v-for="group in sortedProductGroups" class="w-full">
                                    <table-record>
                                        <template #record>
                                            <div class="flex items-center justify-start w-full pl-4">
                                                <child-line-prefix />
                                                <div>
                                                    {{ group.name }}
                                                </div>
                                            </div>
                                            <div v-if="!hideIcons" class="w-12 mr-24">
                                                <input @change="clickToggleGroupSelected(group.id)" :checked="isGroupSelected(group.id)" type="checkbox" class="w-4 h-4 text-orange-500 border-gray-300 rounded edit-field-text-color" :class="{ 'background-orange-500': isGroupSelected(group.id) }">
                                            </div>
                                        </template>
                                    </table-record>
                                </div>
                            </template>
                        </wizard-table-container>
                    </div>
                </template>
            </table-record>
            <table-record v-else-if="!trait.productCategoryId && !addingCategory">
                <template #record>
                    <div class="flex items-center justify-start w-full pl-2">
                        <child-line-prefix />
                        <div>
                            Product Category
                        </div>
                    </div>
                    <div v-if="!hideIcons" class="wizard-icon-container">
                        <div @click="clickNewCategory()" class="cursor-pointer">
                            <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                </template>
            </table-record>
            <edit-table-record v-else-if="editingCategory"
                :fieldData="sortedSellerVendorProductCategories" fieldDataLabel="name" fieldDataValue="id" fieldFormName="id" fieldType="select" 
                :fieldValue="editCategoryForm.id" fieldValueErrorMessage="Product Category must be selected" :showChildLinePrefix="true" 
                :validatingForm="validatingForm"
                @clickCancel="clickCancelEditCategory" @clickSave="clickSaveCategory" @updateFieldValue="updateEditCategoryFormValue"
            />
            <edit-table-record v-else
                :fieldData="sortedSellerVendorProductCategories" fieldDataLabel="name" fieldDataValue="id" fieldFormName="id" fieldType="select" 
                :fieldValue="addCategoryForm.id" fieldValueErrorMessage="Product Category must be selected" :showChildLinePrefix="true" 
                :validatingForm="validatingForm"
                @clickCancel="clickCancelNewCategory" @clickSave="clickSaveCategory" @updateFieldValue="updateAddCategoryFormValue"
            />
        </template>
    </wizard-table-container>
</template>

<script>
import EditTableRecord from '@/wizards/components/EditTableRecord'
import TableRecord from '@/wizards/components/TableRecord'

export default {
    props: [
        'hideIcons',
        'productCategories',
        'traitId',
        'traits'
    ],
    components: {
        EditTableRecord,
        TableRecord
    },
    data() {
        return {
            addCategoryForm: {
                groups: [],
                id: null
            },
            addGroupForm: {
                id: null,
                name: null
            },
            addingCategory:false,
            addingGroup: false,
            duplicateGroupNameError: false,
            editCategoryForm: {
                id: null
            },
            editingCategory: false,
            showMe: false,
            trait: null,
            validatingForm: false
        }
    },
    computed: {
        categoryName() {
            return Object.values(this.vendorProductCategories).find((myCategory) => myCategory.id == this.trait.productCategoryId).name
        },
        sortedProductGroups() {
            let myCategory = this.productCategories.find((myProductCategory) => myProductCategory.id == this.trait.productCategoryId)
            let myGroups = []

            if (myCategory) {
                myGroups = myCategory.groups
            }

            let myVendorGroups = Object.values(this.vendorProductGroups)
                .filter((myVendorGroup) => myVendorGroup.product_category_id == this.trait.productCategoryId)

            return [...myVendorGroups, ...myGroups]
                .sort((a, b) => a.name < b.name ? -1 : 1)
        },
        sortedSellerVendorProductCategories() {
            if (!this.vendorProductCategories || !this.productCategories) {
                return []
            }

            return Object.values(this.vendorProductCategories)
                .filter((myCategory) => this.productCategories.find((myPCat) => myPCat.id == myCategory.id))
                .sort((a, b) => a.name < b.name ? -1 : 1)
        },
        vendorProductGroupsData() {
            if (!this.trait.productCategoryId) {
                return []
            }

            return Object.values(this.vendorProductGroups)
                .filter((myGroup) => myGroup.product_category_id == this.trait.productCategoryId)
        }
    },
    mounted() {
        this.trait = this.objCopy(this.traits.find((myTrait) => myTrait.id == this.traitId))
    },
    methods: {
        clickCancelEditCategory() {
            this.editCategoryForm.id = null
            this.editingCategory = false
            this.validatingForm = false
            this.$emit('switchHideIcons', false)
        },
        clickCancelNewCategory() {
            this.addCategoryForm.id = null
            this.addingCategory = false
            this.validatingForm = false
            this.$emit('switchHideIcons', false)
        },
        clickCancelNewGroup() {
            this.addGroupForm.name = null
            this.addGroupForm.id = null
            this.addingGroup = false
            this.validatingForm = false
            this.$emit('switchHideIcons', false)
        },
        clickEditCategory() {
            this.editCategoryForm.id = this.trait.productCategoryId
            this.editingCategory = true
            this.$emit('switchHideIcons', true)
        },
        clickNewCategory() {
            this.addingCategory = true
            this.$emit('switchHideIcons', true)
        },
        clickNewGroup() {
            this.addingGroup = true
            this.showMe = true
            
            this.$nextTick(() => {
                this.elGet('inputNewGroup').focus()
            })

            this.$emit('switchHideIcons', true)
        },
        clickSaveCategory() {
            if (this.trait.productCategoryId) {
                this.trait.productCategoryId = this.editCategoryForm.id
            }
            else {
                if (this.addCategoryForm.id) {
                    this.trait.productCategoryId = this.addCategoryForm.id
                }
                else {
                    this.validatingForm = true
                }
            }

            this.$emit('updateTrait', this.trait)
            this.$emit('switchHideIcons', false)
            this.addCategoryForm.id = null
            this.editCategoryForm.id = null
            this.addingCategory = false
            this.editingCategory = false
        },
        clickSaveGroup() {
            if (this.addGroupForm.name && this.isGroupNameUnique()) {
                this.validatingForm = false
                let myCategory = this.objCopy(this.productCategories.find((myPCategory) => myPCategory.id == this.trait.productCategoryId))
                
                if (myCategory) {
                    if (!myCategory.groups) {
                        myCategory.groups = []
                    }

                    let myId = 99999

                    myCategory.groups.forEach((myGroup) => {
                        if (myGroup.id > myId) {
                            myId = myGroup.id
                        }
                    })

                    this.addGroupForm.id = myId + 1
                    myCategory.groups.push(this.objCopy(this.addGroupForm))
                    this.$emit('updateProductCategory', myCategory)
                    this.addGroupForm.name = null
                    this.elGet('inputNewGroup').focus()
                }
            }
            else {
                this.validatingForm = true
            }
        },
        clickToggleGroupSelected(inId) {
            let myGroups = []

            if (this.trait.groups) {
                myGroups = this.trait.groups
            }

            if (myGroups.includes(inId)) {
                myGroups = myGroups.filter((myGroup) => myGroup != inId)
            }
            else {
                myGroups.push(inId)
            }

            this.trait.groups = myGroups
            this.$emit('updateTrait', this.trait)
        },
        clickToggleShowGroups() {
            this.showMe = !this.showMe
        },
        isGroupNameUnique() {
            let myDuplicateError = false
            let myCategory = this.productCategories.find((myPCategory) => myPCategory.id == this.trait.productCategoryId)
            let myGroups = []

            if (myCategory && myCategory.groups && myCategory.groups.length) {
                myGroups = myCategory.groups
            }

            myDuplicateError = Boolean([...this.vendorProductGroupsData, ...myGroups].find((myGroup) => myGroup.name.toUpperCase() == this.addGroupForm.name.toUpperCase()))
            this.duplicateGroupNameError = myDuplicateError
            return !myDuplicateError
        },
        isGroupSelected(inId) {
            let myGroups = []

            if (this.trait?.groups ?? false) {
                myGroups = this.trait.groups
            }

            return myGroups.includes(inId)
        },
        updateAddCategoryFormValue(inField, inValue) {
            this.addCategoryForm[inField] = inValue
        },
        updateAddGroupFormValue(inField, inValue) {
            inValue = this.capitalizeWordsFirstLetter(inValue.toLowerCase())
            this.addGroupForm[inField] = inValue
            this.isGroupNameUnique()
        },
        updateEditCategoryFormValue(inField, inValue) {
            this.editCategoryForm[inField] = inValue
        }
    }
}
</script>