<template>
    <div class="w-full flex flex-wrap items-center justify-center">
        <div :class="{ 'm-1': isDesktopWidth, 'w-full': !isDesktopWidth }">
            <div @click="clickNewBlock('image')" class="secondary-button-base w-full">
                Media
            </div>
        </div>
        <div :class="{ 'm-1': isDesktopWidth, 'w-full': !isDesktopWidth }">
            <div @click="clickNewBlock('text')" class="secondary-button-base w-full">
                Text
            </div>
        </div>
        <div :class="{ 'm-1': isDesktopWidth, 'w-full': !isDesktopWidth }">
            <div @click="clickNewBlock('youtube')" class="secondary-button-base w-full">
                YouTube
            </div>
        </div>
        <div :class="{ 'm-1': isDesktopWidth, 'w-full': !isDesktopWidth }">
            <div @click="clickNewBlock('feature')" class="secondary-button-base w-full">
                Features
            </div>
        </div>
        <div :class="{ 'm-1': isDesktopWidth, 'w-full': !isDesktopWidth }">
            <div @click="clickNewBlock('slider')" class="secondary-button-base w-full">
                Slider
            </div>
        </div>
        <div :class="{ 'm-1': isDesktopWidth, 'w-full': !isDesktopWidth }">
            <div @click="clickCloseSelectBlockType()" class="secondary-button-base w-full">
                Cancel
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        clickCloseSelectBlockType() {
            this.$emit('clickCloseSelectBlockType')
        },
        clickNewBlock(inType) {
            this.$emit('clickNewBlock', inType)
        }
    }
}
</script>