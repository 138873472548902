<template>
    <div class="w-full">
        <div v-for="paymentType in sortedPaymentTypes" class="w-full">
            <select-panel v-if="isOkay(paymentType)"
                :fieldData="yesNoData" :fieldFormName="paymentType.identifier" :fieldLabel="paymentType.name" fieldLabelClasses="text-gray-800" :fieldSubLabel="paymentType.description"
                :fieldRequired="true" :fieldValue="isSelected(paymentType.identifier)" :forceMobile="true" :isLabelAndValue="true"
                @fieldChange="updatePaymentType"
            />
        </div>
        <validation-message v-if="validating && (!sellerForm.payment_types || !sellerForm.payment_types.length)" 
            message="You must select at least one Payment Method"
        />
    </div>
</template>

<script>
export default {
    props: [
        'sellerDataForm',
        'sellerForm',
        'validating'
    ],
    computed: {
        sortedPaymentTypes() {
            return Object.values(this.vendorPaymentTypes)
                .sort((a, b) => a.name < b.name ? -1 : 1)
        }
    },
    mounted() {
        if (!this.sellerForm.payment_types.length) {
            Object.values(this.vendorPaymentTypes).forEach((paymentType) => {
                this.updatePaymentType(paymentType.identifier, true)
            })
        }
    },
    methods: {
        isOkay(inPaymentType) {
            return inPaymentType.gateway == 'PayPal' && this.sellerDataForm.usePayPal || inPaymentType.gateway == 'Stripe' && this.sellerDataForm.useStripe
        },
        isSelected(inIdentifier) {
            return this.sellerForm.payment_types.includes(inIdentifier)
        },
        updatePaymentType(inField, inValue) {
            this.$emit('updatePaymentType', inField, inValue)
        }
    }
}
</script>