<template>
    <div class="w-full text-sm">
        <wizard-table-container
            headingText="Shipping Rate Labels" :showNew="!addingLabel && editingLabel == null"
            @clickNewRecord="clickNewLabel"
        >
            <template #records>
                <div v-if="addingLabel" class="w-full">
                    <table-record>
                        <template #record>
                            <div class="pl-2 w-full">
                                <input-panel 
                                    fieldFormName="label" :fieldNude="true" fieldPlaceholder="Label" :fieldRequired="true" fieldType="text" 
                                    :fieldValue="newLabelForm.label" :forceMobile="true" :hideLabel="true"
                                    @fieldInput="updateNewLabelFormValue" 
                                />
                            </div>
                            <div class="wizard-icon-container">
                                <div @click="clickCancelNewLabel()" class="cursor-pointer">
                                    <fa-icon icon="ban" type="fas" class="h-5 w-5" />
                                </div>
                                <div @click="clickSaveNewLabel()" class="cursor-pointer ml-2">
                                    <fa-icon icon="floppy-disk" type="fas" class="h-5 w-5" />
                                </div>
                            </div>
                        </template>
                    </table-record>
                </div>
                <div v-for="(shippingLabel, index) in sortedShippingRateLabels" class="w-full">
                    <table-record v-if="editingLabel == null || editingLabel != index">
                        <template #record>
                            <div @click="clickEditLabel(shippingLabel, index)" class="w-full text-left pl-2">
                                {{ shippingLabel }}
                            </div>
                            <div v-if="editingLabel == null && !addingLabel" class="wizard-icon-container">
                                <div @click="clickEditLabel(shippingLabel, index)" class="cursor-pointer">
                                    <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                                </div>
                                <div @click="clickDeleteLabel(index)" class="cursor-pointer ml-2">
                                    <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                </div>
                            </div>
                        </template>
                    </table-record>
                    <table-record v-if="editingLabel != null && editingLabel == index">
                        <template #record>
                            <div class="pl-2 w-full">
                                <input-panel 
                                    fieldFormName="label" :fieldNude="true" fieldPlaceholder="Label" :fieldRequired="true" fieldType="text" 
                                    :fieldValue="editLabelForm.label" :forceMobile="true" :hideLabel="true"
                                    @fieldInput="updateEditLabelFormValue" 
                                />
                            </div>
                            <div class="wizard-icon-container">
                                <div @click="clickCancelEditLabel()" class="cursor-pointer">
                                    <fa-icon icon="ban" type="fas" class="h-5 w-5" />
                                </div>
                                <div @click="clickSaveEditLabel()" class="cursor-pointer ml-2">
                                    <fa-icon icon="floppy-disk" type="fas" class="h-5 w-5" />
                                </div>
                            </div> 
                        </template>
                    </table-record>
                </div>
            </template>
        </wizard-table-container>
        <validation-message v-if="validating && (!sellerDataForm.shippingRateLabels || sellerDataForm.shippingRateLabels.length == 1)" 
            message="You must add at least 1 shipping rate label"
        />
    </div>
</template>

<script>
import TableRecord from '@/wizards/components/TableRecord'

export default {
    props: [
        'sellerDataForm',
        'validating'
    ],
    components: {
        TableRecord
    },
    data() {
        return {
            addingLabel: false,
            editingLabel: null,
            editLabelForm: {
                label: null
            },
            newLabelForm: {
                label: null
            }
        }
    },
    computed: {
        sortedShippingRateLabels() {
            return this.sellerDataForm.shippingRateLabels
                .sort((a, b) => a < b ? -1 : 1)
        }
    },
    methods: {
        clickCancelEditLabel() {
            this.editLabelForm.label = null
            this.editingLabel = null
        },
        clickCancelNewLabel() {
            this.newLabelForm.label = null
            this.addingLabel = false
        },
        clickDeleteLabel(inIndex) {
            if (confirm('You wish to delete this label?')) {
                let myLabels = []
                
                this.sellerDataForm.shippingRateLabels.forEach((label, index) => {
                    if (index != inIndex) {
                        myLabels.push(label)
                    }
                })

                this.$emit('updateSellerDataFormSelectValue', 'shippingRateLabels', myLabels)
            }
        },
        clickEditLabel(inLabel, inIndex) {
            this.editLabelForm.label = inLabel
            this.editingLabel = inIndex
        },
        clickNewLabel() {
            this.newLabelForm.label = null
            this.addingLabel = true
        },
        clickSaveEditLabel() {
            let myLabels = this.objCopy(this.sellerDataForm.shippingRateLabels).filter((label, index) => index != this.editingLabel)
            myLabels.push(this.editLabelForm.label)
            this.$emit('updateSellerDataFormSelectValue', 'shippingRateLabels', myLabels)
            this.editLabelForm.label = null
            this.editingLabel = null
        },
        clickSaveNewLabel() {
            let myLabels = this.objCopy(this.sellerDataForm.shippingRateLabels)
            myLabels.push(this.objCopy(this.newLabelForm.label))
            this.$emit('updateSellerDataFormSelectValue', 'shippingRateLabels', myLabels)
            this.newLabelForm.label = null
            this.addingLabel = false
        },
        updateEditLabelFormValue(inField, inValue) {
            this.editLabelForm[inField] = inValue
        },
        updateNewLabelFormValue(inField, inValue) {
            this.newLabelForm[inField] = inValue
        }
    }
}
</script>